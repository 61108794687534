import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Table, Row, Col, FormCheck, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  competitionGameList,
  listCompetition,
  listLeagueRound,
} from "../actions/competitionActions";
import {
  listTeams,
  teamDetailsInfo,
  teamDetailsInfo1,
} from "../actions/teamActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Select from "react-select";

/**
 * @param {{ onAgree: () => void }} props
 * @constructor
 */

const RecorderTeam = ({ onNext }) => {
  const [competitionId, setCompetitionId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [gamePlace, setGamePlace] = useState("");
  const [gameName, setGameName] = useState("");
  const [round, setRound] = useState("");
  const [teamIdA, setTeamIdA] = useState("");
  const [teamIdB, setTeamIdB] = useState("");

  const [teamAOption, setTeamAOption] = useState([]);
  const [teamBOption, setTeamBOption] = useState([]);

  const [teamAPlayersSelected, setTeamAPlayersSelected] = useState([]);
  const [teamBPlayersSelected, setTeamBPlayersSelected] = useState([]);
  const [teamAPlayers, setTeamAPlayers] = useState([]);
  const [teamBPlayers, setTeamBPlayers] = useState([]);



  const [optionsRound, setOptionsRound] = useState([]);
  const [optionsPlace, setOptionsPlace] = useState([]);

  const [roundDisable, setRoundDisable] = useState(true);

  const [gameDate, setGameDate] = useState(new Date().toISOString().substring(0, 10));
  const gameRef = useRef();

  const [optionsCompetition, setOptionsCompetition] = useState([]);
  const [status, setStatus] = useState(1);

  const dispatch = useDispatch();

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const competitionRoundInfo = useSelector((state) => state.competitionRoundInfo);
  const {
    loading: loadingRoundInfo,
    error: errorRoundInfo,
    roundInfo
  } = competitionRoundInfo;

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition1,
    error: errorCompetition1,
    competition,
  } = competitionGame;

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamDetails1 = useSelector((state) => state.teamDetails1);
  const {
    loading: loadingTeamDetails1,
    error: errorTeamDetails1,
    team1,
  } = teamDetails1;

  const optionsTeam = [];

  teams &&
    teams.map((team) =>
      optionsTeam.push({
        value: team._id,
        label: team.name,
      })
    );

  const handleStart = (e) => {
    const value = gameRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    gameRef.current.value = result;
    setGameDate(e.target.value);
  };

  const handlePlaceChange = (selected) => {
    setGamePlace(selected.value);
    let copy = [...optionsPlace];
    copy.splice(copy.indexOf(selected), 1);
    copy.unshift(selected);
    setOptionsPlace(copy);
  };

  const handleCompetitionChange = (selected) => {
    setCompetitionId(selected.value);
    dispatch(listLeagueRound(selected.value));
    dispatch(competitionGameList(selected.value));
  };

  const handleRoundChange = (selected) => {
    if (selected.label === "직접입력") {
      setRoundDisable(false);
    } else {
      setRoundDisable(true);
    }
    setRound(selected.value);
  };

  const handleTeamChangeA = (selected) => {
    setTeamIdA(selected.value);
    dispatch(teamDetailsInfo(selected.value));
  };

  useEffect(() => {
    if (team) {
      let playerOption = [];
      team.memberInfo.map((m) =>
        playerOption.push({
          value: m.playerId,
          label: m.name,
        })
      );
      setTeamAOption(playerOption);
    }
  }, [team]);

  const handleTeamAPlayerList = (e) => {
    if (e.length < teamAPlayersSelected.length) {
      setTeamAPlayersSelected(e);
    } else {
      const copy = [...teamAPlayersSelected];
      const player = e[e.length - 1];
      copy.push(player);
      setTeamAPlayersSelected(copy);
    }
  };


  const handleTeamChangeB = (selected) => {
    setTeamIdB(selected.value);
    dispatch(teamDetailsInfo1(selected.value));
  };

  const handleTeamBPlayerList = (e) => {
    if (e.length < teamBPlayersSelected.length) {
      setTeamBPlayersSelected(e);
    } else {
      const copy = [...teamBPlayersSelected];
      const player = e[e.length - 1];
      copy.push(player);
      setTeamBPlayersSelected(copy);
    }
  };

  useEffect(() => {
    if (team1) {
      let playerOption = [];
      team1.memberInfo.map((m) =>
        playerOption.push({
          value: m.playerId,
          label: m.name,
        })
      );
      setTeamBOption(playerOption);
    }
  }, [team1]);


  useEffect(() => {
    if (roundInfo) {
      let roundOption = [];
      roundInfo.round.map((r) =>
        roundOption.push({
          value: r[1],
          label: r[1],
        })
      );
      roundOption.push({
        value: "",
        label: "직접입력",
      });
      setOptionsRound(roundOption);
    }
  }, [roundInfo]);

  useEffect(() => {
    if (competitions) {
      let tempOptionsCompetition = [];

      competitions
        .filter(competition => competition.status <= status)
        .map((competition) =>
          tempOptionsCompetition.push({
            value: competition._id,
            label: competition.name,
          })
        );
      setOptionsCompetition(tempOptionsCompetition);
    }
  }, [competitions, status]);


  useEffect(() => {
    if (competition) {
      let placeOption = [];
      competition.place.map((p) =>
        placeOption.push({
          value: p,
          label: p,
        })
      );
      setOptionsPlace(placeOption);
      setGamePlace(placeOption[0].value);
      // setGamePlace(placeOption[0].value);

      setTeamType(competition.teamType);
    }
  }, [competition]);

  const submitHandler = () => {
    let firstQuarterATeamCnt = 0;
    let firstQuarterBTeamCnt = 0;

    for (let i = 0; i < teamAPlayers.length; i++) {
      const jerseyNum = document.getElementById(`teamA${i}JerseyNum`).value;
      const firstQuarter = document.getElementById(`teamA${i}FirstQuarter`).checked;
      const formerPro = document.getElementById(`teamA${i}FormerPro`).checked;
      const plusOne = document.getElementById(`teamA${i}PlusOne`).checked;
      const stay = document.getElementById(`teamA${i}Stay`).checked;

      teamAPlayers[i].jerseyNum = jerseyNum;
      teamAPlayers[i].playingNow = firstQuarter;
      teamAPlayers[i].formerPro = formerPro;
      teamAPlayers[i].plusOne = plusOne;
      teamAPlayers[i].stay = stay;

      if (firstQuarter) {
        firstQuarterATeamCnt++;
      }
    }

    for (let i = 0; i < teamBPlayers.length; i++) {
      const jerseyNum = document.getElementById(`teamB${i}JerseyNum`).value;
      const firstQuarter = document.getElementById(`teamB${i}FirstQuarter`).checked;
      const formerPro = document.getElementById(`teamB${i}FormerPro`).checked;
      const plusOne = document.getElementById(`teamB${i}PlusOne`).checked;
      const stay = document.getElementById(`teamB${i}Stay`).checked;

      teamBPlayers[i].jerseyNum = jerseyNum;
      teamBPlayers[i].playingNow = firstQuarter;
      teamBPlayers[i].formerPro = formerPro;
      teamBPlayers[i].plusOne = plusOne;
      teamBPlayers[i].stay = stay;

      if (firstQuarter) {
        firstQuarterBTeamCnt++;
      }
    }

    if (firstQuarterATeamCnt !== 5 || firstQuarterBTeamCnt !== 5) {
      alert("1쿼터 선발 선수 5명을 선택해주세요.");
      return;
    }

    const teamA = { teamId: team._id, teamName: team.name, players: teamAPlayers };
    const teamB = { teamId: team1._id, teamName: team1.name, players: teamBPlayers };
    const gameInitSetting = {
      gameDate,
      competitionId,
      teamType,
      gamePlace,
      gameName,
      vsTeam: [teamIdA, teamIdB],
      gameResult: [0, 0],
      playerRecord: [],
      quarter: [[0, 0], [0, 0], [0, 0], [0, 0]],
      overtime: [0, 0],
      round,
      teamRebound: [[0, 0], [0, 0]],
      vsTeamName: [team.name, team1.name]
    }

    onNext(teamA, teamB, gameInitSetting);
  };


  useEffect(() => {
    dispatch(listCompetition());
    dispatch(listTeams());
  }, [dispatch]);

  const setTitleStyle = {
    border: '1px solid #ced4da',
    padding: '10px',
    width: '100px',
    height: '50px',
    textAlign: 'center',
    display: 'flex',       // 플렉스박스로 변환
    alignItems: 'center',  // 수직 중앙 정렬
    justifyContent: 'center' // 수평 중앙 정렬
  };

  const thStyle = { fontSize: '12px', padding: '2px', textAlign: 'center' };
  const tdStyle = { fontSize: '14px', padding: '2px', textAlign: 'center' };
  const checkboxStyle = { width: '20px', height: '20px' };

  return (
    <>
      <Container style={{ maxWidth: '900px' }}>
        {loadingCompetition || loadingTeam ? (
          <Loader />
        ) : errorCompetition || errorTeam ? (
          <Message variant="danger">{errorCompetition}</Message>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={setTitleStyle}>경기 일자</div>
                  <Form.Control
                    type="gameDate"
                    placeholder="예) 2022-01-01"
                    ref={gameRef}
                    value={gameDate}
                    onChange={handleStart}
                    style={{ marginLeft: '10px', width: '200px' }}
                  ></Form.Control>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={setTitleStyle}>대회명</div>
                  <div style={{ marginLeft: '10px', width: '400px' }}>
                    <Select
                      options={optionsCompetition}
                      isSearchable
                      onChange={handleCompetitionChange}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <p style={{ margin: '0 5px' }}>종료된 대회 표시</p>
                    <FormCheck
                      type="checkbox"
                      id="status"
                      checked={status === 2}
                      onChange={(e) => setStatus(e.target.checked ? 2 : 1)}
                      style={{ margin: '0' }}
                    ></FormCheck>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={setTitleStyle}>대회 장소</div>
                  <div style={{ marginLeft: '10px', width: '200px' }}>
                    <Select
                      options={optionsPlace}
                      onChange={handlePlaceChange}
                      value={optionsPlace[0]}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={setTitleStyle}>경기명</div>
                  <Form.Control
                    type="gameName"
                    placeholder="예) A1"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                    style={{ marginLeft: '10px', marginRight: '10px', width: '200px' }}
                  ></Form.Control>
                  <div style={setTitleStyle}>라운드</div>
                  <div style={{ marginLeft: '10px', width: '200px' }}>
                    <Select
                      options={optionsRound}
                      onChange={handleRoundChange}
                    />
                  </div>
                  <Form.Control
                    type="round"
                    value={round}
                    disabled={roundDisable}
                    onChange={(e) => setRound(e.target.value)}
                    style={{ marginLeft: '10px', width: '200px' }}
                  ></Form.Control>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="mb-3">
              <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'center' }}>
                <Col style={{ marginRight: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={setTitleStyle}>홈 팀</div>
                    <div style={{ marginLeft: '10px', width: '200px' }}>
                      <Select
                        options={optionsTeam}
                        isSearchable
                        onChange={handleTeamChangeA}
                      />
                    </div>
                  </div>
                  {team && (
                    <div>
                      <p style={{ marginBottom: '1px' }}>출전 선수 선택</p>
                      <Select
                        options={teamAOption}
                        closeMenuOnSelect={false}
                        isSearchable
                        isMulti
                        onChange={handleTeamAPlayerList}
                        value={teamAPlayersSelected}
                      />
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => { setTeamAPlayers(teamAPlayersSelected) }}
                      >선택 완료</Button>
                    </div>
                  )}
                  {teamAPlayers.length > 0 && (
                    <>
                      <Table striped bordered hover responsive className="table-sm">
                        <thead>
                          <tr>
                            <th style={thStyle}>순번</th>
                            <th style={thStyle}>선수명</th>
                            <th style={thStyle}>등번호</th>
                            <th style={thStyle}>1쿼터</th>
                            <th style={thStyle}>선출여부</th>
                            <th style={thStyle}>+1</th>
                            <th style={thStyle}>잔류선수</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamAPlayers.map((player, index) => (
                            <tr key={player.value}>
                              <td style={tdStyle}>{index + 1}</td>
                              <td style={tdStyle}>{player.label}</td>
                              <td style={tdStyle}>
                                <input
                                  type="text"
                                  style={{ width: '50px' }}
                                  id={`teamA${index}JerseyNum`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  defaultChecked
                                  id={`teamA${index}FirstQuarter`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamA${index}FormerPro`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamA${index}PlusOne`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamA${index}Stay`}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Col>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={setTitleStyle}>어웨이 팀</div>
                    <div style={{ marginLeft: '10px', width: '200px' }}>
                      <Select
                        options={optionsTeam}
                        isSearchable
                        onChange={handleTeamChangeB}
                      />
                    </div>
                  </div>
                  {team1 && (
                    <div>
                      <p style={{ marginBottom: '1px' }}>출전 선수 선택</p>
                      <Select
                        options={teamBOption}
                        closeMenuOnSelect={false}
                        isSearchable
                        isMulti
                        onChange={handleTeamBPlayerList}
                        value={teamBPlayersSelected}
                      />
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => { setTeamBPlayers(teamBPlayersSelected) }}
                      >선택 완료</Button>
                    </div>
                  )}
                  {teamBPlayers.length > 0 && (
                    <>
                      <Table striped bordered hover responsive className="table-sm">
                        <thead>
                          <tr>
                            <th style={thStyle}>순번</th>
                            <th style={thStyle}>선수명</th>
                            <th style={thStyle}>등번호</th>
                            <th style={thStyle}>1쿼터</th>
                            <th style={thStyle}>선출여부</th>
                            <th style={thStyle}>+1</th>
                            <th style={thStyle}>잔류선수</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamBPlayers.map((player, index) => (
                            <tr key={player.value}>
                              <td style={tdStyle}>{index + 1}</td>
                              <td style={tdStyle}>{player.label}</td>
                              <td style={tdStyle}>
                                <input
                                  type="text"
                                  style={{ width: '50px' }}
                                  id={`teamB${index}JerseyNum`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  defaultChecked
                                  id={`teamB${index}FirstQuarter`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamB${index}FormerPro`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamB${index}PlusOne`}
                                />
                              </td>
                              <td style={tdStyle}>
                                <input
                                  type="checkbox"
                                  style={checkboxStyle}
                                  id={`teamB${index}Stay`}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Col>
              </div>
            </Row>
            {teamAPlayers.length > 0 && teamBPlayers.length > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={submitHandler} variant="primary">설정 완료</Button>
              </div>
            )}
          </>
        )}
      </Container>
    </>
  )
};

export default RecorderTeam;