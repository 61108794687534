import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Image, Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  gameOrderChange,
  listCompetitionInfo,
} from "../actions/competitionActions";
import Loader from "./Loader";
import Message from "./Message";
import { deleteGame } from "../actions/competitionActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { tierAccess } from "../utils/access";

const GameSchedule = ({ id, index }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [orderChange, setOrderChange] = useState(false);
  const [order, setOrder] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  const [isListVisibleMobile, setIsListVisibleMobile] = useState(false);

  const buttonRef = useRef(null);

  const competitionInfoList = useSelector((state) => state.competitionInfoList);
  const { loading, error, competition } = competitionInfoList;

  const handleToggle = () => {
    setIsListVisible(!isListVisible);
  };

  const handleToggleMobile = () => {
    setIsListVisibleMobile(!isListVisibleMobile);
  };

  const orderChangeButton = (orderChange) => {
    if (orderChange) {
      return (
        <>
          <input
            type="number"
            min={1}
            max={competition.length}
            id="order"
            defaultValue={order}
          ></input>
          <Button
            variant="outline-primary"
            onClick={() => orderChangeHandler(competition[index]._id)}
          >
            변경
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOrderChange(!orderChange)}
          >
            취소
          </Button>
        </>
      );
    } else {
      return (
        <Button
          variant="outline-primary"
          onClick={() => setOrderChange(!orderChange)}
        >
          순서 변경
        </Button>
      );
    }
  };

  const orderChangeHandler = (id) => {
    const order = document.getElementById("order").value;
    if (order > competition.length) {
      alert("순서를 다시 입력해주세요.");
    } else {
      dispatch(gameOrderChange({ _id: id, gameNum: order }));
      setOrderChange(!orderChange);
    }
  };

  const deleteHandler = (id) => {
    if (window.confirm("정말로 경기를 지우겠습니까?")) {
      dispatch(deleteGame(id));
    }
  };

  const lose = {
    color: "red",
  };

  const win = {
    color: "blue",
  };

  const imgStyle = {
    maxHeight: 48,
    maxWidth: 48,
  };

  const defaultVideoHandler = (e) => {
    e.preventDefault();
    alert("영상 업로드 준비 중입니다.");
  };

  const isMyTeamGame = (game) => {
    if (userInfo) {
      for (let i of game.vsTeamMaster) {
        if (i.includes(userInfo._id)) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    dispatch(listCompetitionInfo(id));
  }, [dispatch, id]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const isMatchPlanned =
    competition[index] &&
    (competition[index].gameResult[0] === null ||
      competition[index].gameResult[1] === null);
  
  const isForfeit =
    competition[index] && competition[index].gameName.includes("몰수패");
  const winTeamIndex =
    competition[index] &&
    competition[index].gameResult[0] > competition[index].gameResult[1]
      ? 0
      : 1;

  const isDumbGame = (game) => {
    return (
      game &&
      game.vsTeam[0] === null &&
      game.vsTeam[1] === null
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <td
            className="align-middle"
            style={{
              "font-weight": "800",
              "padding-left": "0px",
              "padding-right": "0px",
            }}
          >
            <div style={{ "padding-bottom": "12px" }}>{index + 1}</div>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "5px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            <div className="hideextra" style={{ "min-width": "40px" }}>
              <h5 className="text__competition__mobile__h5">
                {competition[index] && competition[index].gameName}
              </h5>
              <span className="on__mobile__detail__btn__appear">
                {isMatchPlanned ? (
                  <>
                    <button
                      ref={buttonRef}
                      className="player__show__btn align-middle"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                      onClick={handleToggleMobile}
                    >
                      <span className="btn__mobile__font">출전명단</span>
                    </button>
                    {isListVisibleMobile &&
                      alert(
                        `${competition[index]?.gameName}-` +
                          `${competition[index]?.vsTeamName[0]} vs ${competition[index]?.vsTeamName[1]}\n\n` +
                          `${competition[index].vsTeamName[0]}\n${competition[
                            index
                          ].playerList[0]
                            .map((player) => player.name)
                            .join("\n")}\n\n` +
                          `${competition[index].vsTeamName[1]}\n${competition[
                            index
                          ].playerList[1]
                            .map((player) => player.name)
                            .join("\n")}`
                      )}
                  </>
                ) : (
                  !isDumbGame(competition[index]) && (
                  <>
                    <div className="mt-2">
                      {" "}
                      <Link
                        to={`/gamerecord/detail/${
                          competition[index] && competition[index]._id
                        }`}
                      >
                        <button
                          className="result__btn align-middle"
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <span className="btn__mobile__font">결과보기</span>
                        </button>
                      </Link>
                      {competition[index] && competition[index].videoId ? (
                        <Link
                          to={`/videodetail/${
                            competition[index] && competition[index]._id
                          }`}
                          className="align-middle"
                        >
                          <button className="btn__show__video">
                            <FontAwesomeIcon
                              icon={faCirclePlay}
                              style={{ fontSize: "21px" }}
                            />
                          </button>
                        </Link>
                      ) : (
                        <button
                          className="btn__show__video"
                          onClick={defaultVideoHandler}
                        >
                          <Image
                            src={"/default__video.png"}
                            style={{ width: "21px" }}
                            fluid
                            alt="default image"
                          />
                        </button>
                      )}
                    </div>
                  </>
                ))}
              </span>
            </div>
          </td>
          {isForfeit ? (
            <>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[0] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[0]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {/* {competition[index] && competition[index].vsTeamName[0]} */}
                </span>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3 className="competition__info__mobile">
                  <span
                    className="competition__wl__mobile align-middle"
                    style={winTeamIndex === 0 ? win : lose}
                  >
                    {winTeamIndex === 0 ? "승" : "패"}
                  </span>
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "10px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3
                  style={{
                    color: "gray",
                  }}
                >
                  VS
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3 className="competition__info__mobile">
                  <span
                    className="competition__wl__mobile align-middle"
                    style={winTeamIndex === 1 ? win : lose}
                  >
                    {winTeamIndex === 1 ? "승" : "패"}
                  </span>
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[1] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[1]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {competition[index] && competition[index].vsTeamName[1]}
                </span>
              </td>
            </>
          ) : isMatchPlanned ? (
            <>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[0] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[0]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {competition[index] && competition[index].vsTeamName[0]}
                </span>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h5 className="competition__info__mobile">
                  <span
                    className="competition__wl__mobile align-middle"
                    style={{
                      color: "gray",
                    }}
                  >
                    예정
                  </span>
                </h5>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "10px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3
                  style={{
                    color: "gray",
                  }}
                >
                  VS
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h5 className="competition__info__mobile">
                  <span
                    className="competition__wl__mobile align-middle"
                    style={{
                      color: "gray",
                    }}
                  >
                    예정
                  </span>
                </h5>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[1] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[1]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {competition[index] && competition[index].vsTeamName[1]}
                </span>
              </td>
            </>
          ) : (
            <>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[0] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[0]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[0]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {competition[index] && competition[index].vsTeamName[0]}
                </span>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3 className="competition__info__mobile">
                  {competition[index] && competition[index].gameResult[0]}
                  <br />
                  <span
                    className="competition__wl__mobile align-middle"
                    style={
                      competition[index] &&
                      competition[index].gameResult[1] >
                        competition[index].gameResult[0]
                        ? lose
                        : win
                    }
                  >
                    {competition[index] &&
                    competition[index].gameResult[0] ===
                      competition[index].gameResult[1]
                      ? "무"
                      : competition[index] &&
                        competition[index].gameResult[1] >
                          competition[index].gameResult[0]
                      ? "패"
                      : "승"}
                  </span>
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "10px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3>VS</h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                <h3 className="competition__info__mobile">
                  {competition[index] && competition[index].gameResult[1]}
                  <br />
                  <span
                    className="competition__wl__mobile"
                    style={
                      competition[index] &&
                      competition[index].gameResult[0] >
                        competition[index].gameResult[1]
                        ? lose
                        : win
                    }
                  >
                    {competition[index] &&
                    competition[index].gameResult[0] ===
                      competition[index].gameResult[1]
                      ? "무"
                      : competition[index] &&
                        competition[index].gameResult[0] >
                          competition[index].gameResult[1]
                      ? "패"
                      : "승"}
                  </span>
                </h3>
              </td>
              <td
                className="align-middle"
                style={{
                  padding: "0px",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                }}
              >
                {!isDumbGame(competition[index]) && (<>
                {competition[index] &&
                competition[index].vsTeamImage[1] === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                ) : (
                  <Image
                    src={
                      competition[index] && competition[index].vsTeamImage[1]
                    }
                    style={imgStyle}
                    alt={competition[index] && competition[index].vsTeamName[1]}
                    fluid
                    className="mx-3"
                  />
                )}
                </>)}
                {!isDumbGame(competition[index]) && (<><br /></>)}
                <span className="mobile__team__name">
                  {competition[index] && competition[index].vsTeamName[1]}
                </span>
              </td>
            </>
          )}
          {/* PC */}
          <span className="on__pc__detail__btn__appear">
            {isMatchPlanned ? (
              <>
                <td className="align-middle" style={{ padding: "0px" }}>
                  {" "}
                  <div style={{ display: "flex" }} className="mt-3">
                    <Button
                      ref={buttonRef}
                      variant="outline-primary"
                      style={{
                        "padding-left": "30px",
                        "padding-right": "30px",
                        "border-radius": "25px",
                      }}
                      onClick={handleToggle}
                    >
                      출전 명단
                    </Button>
                    {isListVisible && (
                      <div>
                        <Modal
                          show={isListVisible}
                          onHide={handleToggle}
                          className="text-center"
                          centered
                        >
                          <Modal.Body className="text-center">
                            <h3 className="pt-3">
                              {competition[index] &&
                                competition[index].gameName}
                            </h3>
                            <p>
                              예정일 :{" "}
                              {competition[index] &&
                                competition[index].gameDate &&
                                competition[index].gameDate.substring(0, 10)}
                            </p>
                            <h3>
                              {competition[index] &&
                                competition[index].vsTeamName[0]}{" "}
                              <span style={{ "font-size": "12px" }}>vs</span>{" "}
                              {competition[index] &&
                                competition[index].vsTeamName[1]}
                            </h3>
                            <hr className="mt-3 mb-3" />
                            <h4 className="mb-4">출전 선수명단</h4>
                            <h4>
                              {competition[index] &&
                                competition[index].vsTeamName[0]}
                            </h4>
                            <li style={{ "list-style": "none" }}>
                              {competition[index] &&
                                competition[index].playerList[0].map(
                                  (player, index) => (
                                    <li key={index}>{player.name}</li>
                                  )
                                )}
                            </li>
                            <h4 className="mt-4">
                              {competition[index] &&
                                competition[index].vsTeamName[1]}
                            </h4>
                            <li style={{ "list-style": "none" }}>
                              {competition[index] &&
                                competition[index].playerList[1].map(
                                  (player, index) => (
                                    <li key={index}>{player.name}</li>
                                  )
                                )}
                            </li>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button color="primary" onClick={handleToggle}>
                              닫기
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    )}
                    <button
                      className="btn__show__video"
                      style={{ marginLeft: "16px" }}
                      onClick={defaultVideoHandler}
                    >
                      <Image
                        src={"/default__video.png"}
                        style={{ width: "32px" }}
                        fluid
                        alt="default image"
                        display="none"
                      />
                    </button>
                  </div>
                </td>
              </>
            ) : (
              !isDumbGame(competition[index]) && (
              <>
                {" "}
                <td className="align-middle" style={{ padding: "0px" }}>
                  {" "}
                  <div style={{ display: "flex" }} className="mt-3">
                    <Link
                      to={`/gamerecord/detail/${
                        competition[index] && competition[index]._id
                      }`}
                    >
                      <Button
                        variant="primary"
                        style={{
                          "padding-left": "30px",
                          "padding-right": "30px",
                          "border-radius": "25px",
                        }}
                      >
                        결과 보기
                      </Button>
                    </Link>
                    {competition[index] && competition[index].videoId ? (
                      <Link
                        to={`/videodetail/${
                          competition[index] && competition[index]._id
                        }`}
                      >
                        <button
                          className="btn__show__video"
                          style={{ marginLeft: "16px", marginTop: "5px" }}
                        >
                          <FontAwesomeIcon
                            icon={faCirclePlay}
                            style={{ fontSize: "32px" }}
                          />
                        </button>
                      </Link>
                    ) : (
                      <button
                        className="btn__show__video"
                        style={{ marginLeft: "16px" }}
                        onClick={defaultVideoHandler}
                      >
                        <Image
                          src={"/default__video.png"}
                          style={{ width: "32px" }}
                          fluid
                          alt="default image"
                        />
                      </button>
                    )}
                  </div>
                </td>
              </>
            ))}
            {competition[index] && isMyTeamGame(competition[index]) && (
              <>
                <td>
                  <Link
                    class="btn btn-primary pull-right"
                    to={`/player-list/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    출전 선수
                  </Link>
                </td>
              </>
            )}
            {/* {userInfo && userInfo.tier === 101 && (
              <>
                              <td>
                  <Link
                    class="btn btn-success pull-right"
                    to={`/admin/videomodify/${competition[index] && competition[index]._id
                      }`}
                  >
                    영상수정
                  </Link>
                </td>
              </>
            )} */}
            {userInfo && (userInfo.isAdmin || userInfo.tier === 100) && (
              <>
                <td>
                  <Link
                    class="btn btn-warning pull-right"
                    to={`/admin/record-print/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    출력
                  </Link>
                </td>
                <td>
                  <Link
                    class="btn btn-success pull-right"
                    to={`/admin/videomodify/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    영상수정
                  </Link>
                </td>
                <td>
                  <Link
                    class="btn btn-primary pull-right"
                    to={`/admin/gamemodify/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    경기수정
                  </Link>
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() =>
                      deleteHandler(
                        competition[index] && competition[index]._id
                      )
                    }
                  >
                    경기삭제
                  </Button>
                </td>
                <td>{orderChangeButton(orderChange)}</td>
                <td>
                  <Link
                    class="btn btn-primary pull-right"
                    to={`/player-list/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    출전 선수
                  </Link>
                </td>
                <td>
                  <Link
                    class="btn btn-primary pull-right"
                    to={`/write-article/${
                      competition[index] && competition[index]._id
                    }`}
                  >
                    후기 작성
                  </Link>
                </td>
              </>
            )}
          </span>
        </>
      )}
    </>
  );
};

export default GameSchedule;
