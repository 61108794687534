import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";
import { listPlayers } from "../actions/playerActions";

const RecordSwapScreen = ({ history }) => {
  const [playerProfile0, setPlayerProfile0] = useState("");
  const [playerProfile1, setPlayerProfile1] = useState("");

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const playerList = useSelector((state) => state.playerList);
  const { loading, error, players, page, pages } = playerList;

  const optionsPlayer = [];

  players &&
    players.map((player) =>
      optionsPlayer.push({
        value: player._id,
        label: `${player.name} ${player.birthday} `,
      })
    );

  const submitHandler = async (e) => {
    e.preventDefault();

    if (playerProfile0 === "" || playerProfile1 === "") {
      alert("선수를 선택하세요");
      return;
    }

    if (window.confirm(`${playerProfile0.label}선수의 기록을 ${playerProfile1.label}선수의 기록으로 대치하시겠습니까?`)) {
      try {
        await axios.put(
          `${API_SERVER_URL}/api/game/record/swap?fromPlayer=${playerProfile0.value}&toPlayer=${playerProfile1.value}`,
          null,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );

      } catch (error) {
        alert(error);
      } finally {
        window.location.reload();
      }
    } else {
      return;
    }
    // try {
    //   const { data } = await axios.post(`${API_SERVER_URL}/api/award?teamId=${team}&rank=${rank}&grade=${grade}`);

    //   window.location.href = data.downloadUrl;
    // } catch (error) {
    //   setAwardError(error);
    // } finally {
    //   setAwardLoading(false);
    // }
  };

  useEffect(() => {
    accessControl([99], history);
    dispatch(listPlayers("", 0));
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <FormContainer>
            <h1>기록 대치(중복 선수 처리용)</h1>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="player0">
                <Select
                  options={optionsPlayer}
                  placeholder="선수를 선택하세요"
                  onChange={(e) => setPlayerProfile0(e)}
                />
                <p>(기록이 없어질 선수)</p>
              </Form.Group>
              <Form.Group controlId="player1">
                <Form.Label
                  style={{
                    color: "red",
                  }}
                >위 선수의 기록이 아래 선수의 기록으로 대치됩니다.</Form.Label>
                <Select
                  options={optionsPlayer}
                  placeholder="선수를 선택하세요"
                  onChange={(e) => setPlayerProfile1(e)}
                />
              </Form.Group>
              <p>(합친 기록을 가지게 될 선수)</p>
              <Button
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                type="submit"
                variant="primary"
              >
                대치
              </Button>
            </Form>
          </FormContainer>
        </>
      )}
    </>
  );
};

export default RecordSwapScreen;