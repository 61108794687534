import { Route } from "react-router-dom";
import SearchBox from "../components/SearchBox";
import ConsonantBox from "../components/ConsonantBox";
import TypeBox from "../components/TypeBox";

const Searcher = ({ searchType }) => {
  return (
    <div className="search__box">
      <Route
        render={({ history }) => (
          <SearchBox history={history} pageType={`${searchType}`} />
        )}
      />
      <hr />
      <Route
        render={({ history }) => (
          <ConsonantBox history={history} pageType={`${searchType}-c`} />
        )}
      />
      <hr />
      <Route
        render={({ history }) => (
          <TypeBox history={history} pageType={`${searchType}-t`} />
        )}
      />
      <hr />
    </div>
  );
};

export default Searcher;