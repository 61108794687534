import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const PaginateCommunity = ({ pages, page, keyword = "", pageType, category }) => {
  const [currentPageNum, setCurrentPageNum] = useState(0);

  function changePageNum(x) {
    setCurrentPageNum(x);
  }

  return (
    pages > 1 && (
      <>
        <Pagination>
          <LinkContainer
            to={
              keyword
                  ? `/${pageType}/search/${keyword}/page/1`
                  : (category ? `/${pageType}/category/${category}/page/1` : `/${pageType}/page/1`)
            }
          >
            <button className="pagination__custom__button">«</button>
          </LinkContainer>
          {[...Array(pages).keys()].map(
            (x) =>
              page - 5 < x &&
              x < page + 4 && (
                <>
                  <LinkContainer
                    key={x + 1}
                    to={
                      keyword
                          ? `/${pageType}/search/${keyword}/page/${x + 1}`
                          : (category ? `/${pageType}/category/${category}/page/${x + 1}` : `/${pageType}/page/${x + 1}`)
                    }
                  >
                    <Pagination.Item
                      active={x + 1 === page}
                      activeLabel={false}
                      onClick={(e) => changePageNum(e.target.text)}
                    >
                      {x + 1}
                    </Pagination.Item>
                  </LinkContainer>
                </>
              )
          )}
          <LinkContainer
            to={
              keyword
                  ? `/${pageType}/search/${keyword}/page/${pages}`
                  : (category ? `/${pageType}/category/${category}/page/${pages}` : `/${pageType}/page/${pages}`)
            }
          >
            <button className="pagination__custom__button">»</button>
          </LinkContainer>
        </Pagination>
      </>
    )
  );
};

export default PaginateCommunity;
