import React, { useEffect } from "react";
import { Table, Row, Image } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listCompetition } from "../actions/competitionActions";
import Paginate from "../components/Paginate";
import SearchBox from "../components/SearchBox";
import PaginateCompetition from "../components/PaginateCompetition";
import optionsCompetitionTeamType from "../static/optionsCompetitionTeamType";
import optionsCategory from "../static/optionsCategory";
import SearchBoxCompetition from "../components/SearchBoxCompetition";
import showRemainingTeams from "../static/showRemainingTeams";
import ShowRemainingTeams from "../components/ShowRemainingTeams";

const CompetitionDivisionListScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const category = match.params.category
  const division = match.params.division;
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const competitionList = useSelector((state) => state.competitionList);
  const { loading, error, competitions, page, pages } = competitionList;

  const startEndDate = (startDate, endDate) => {
    const getDayofWeek = (date) => {
      const dayOfWeek = new Date(date).getDay();
      switch (dayOfWeek) {
        case 0:
          return "일";
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
      }
    };

    if (startDate !== endDate) {
      return (
        <>
          {startDate && startDate.substring(2, 10)} ({getDayofWeek(startDate)}) ~ {endDate && endDate.substring(5, 10)} ({getDayofWeek(endDate)})
        </>
      )
    } else {
      return (
        <>
          {startDate && startDate.substring(2, 10)} ({getDayofWeek(startDate)})
        </>
      )
    }
  }

  const competitionStatus = (competition) => {
    if (competition.status < 2) {
      if (competition.status == 0) {
        return <>
          <div
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              fontSize: "0.8rem",
              padding: "2px 5px",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            진행 전
          </div>
        </>;
      } else {
        return <>
          <div
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              fontSize: "0.8rem",
              padding: "2px 5px",
              marginRight: "5px",
              fontWeight: "bold",
            }}
          >
            진행 중
          </div>
        </>;
      }
    } else {
      return <>
        <div
          style={{
            backgroundColor: "#000000",
            color: "#ffffff",
            fontSize: "0.8rem",
            padding: "2px 5px",
            marginRight: "5px",
            fontWeight: "bold",
          }}
        >
          종료
        </div>
      </>;
    }
  };

  const categoryLabel = (category) => {
    return optionsCategory.find((x) => x.value === category).label;
  };

  useEffect(() => {
    dispatch({ type: "COMPETITION_LIST_RESET" });
    dispatch(listCompetition(keyword, category, "", "", division === "all" ? "" : division, "", pageNumber, true));
  }, [dispatch, keyword, pageNumber, category, division]);

  return (
    <>
      <>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <div className="menu__header">
              <h2
                className="mt-3 mb-3"
                style={{ color: "white", "font-weight": 700 }}
              >
                {categoryLabel(category)} 대회 기록
              </h2>
              {/* <p style={{ color: "white" }}>
                조회하고자 하는 대회명을 검색하세요
              </p> */}
            </div>
            <div className="search__box">
              <Route
                render={({ history }) => (
                  <SearchBoxCompetition
                    history={history}
                    pageType={"competition-record"}
                  />
                )}
              />
              <hr />
            </div>
            <Table
              striped
              hover
              responsive
              className="table-sm text-center mt-3"
            >
              <thead>
                <tr className="table__header">
                  {/* <th style={{ textAlign: "left", width: "30%" }}>일정</th> */}
                  <th style={{ textAlign: "left" }}>대회</th>
                  <th style={{ width: "20%" }}>종별</th>
                  {userInfo && userInfo.isAdmin &&
                    <>
                      <th style={{ backgroundColor: "red" }}>조회수</th>
                      <th style={{ backgroundColor: "red" }}>수정</th>
                      <th style={{ backgroundColor: "red" }}>신청서</th>
                    </>
                  }
                </tr>
              </thead>
              <tbody>
                {competitions &&
                  competitions
                    .sort(
                      (a, b) => new Date(b.startDate) - new Date(a.startDate)
                    )
                    .map((competition) => (
                      <tr key={competition._id}>
                        {/* <td>
                          <div
                            className="hideextra"
                            style={{ "min-width": "130px", display: "flex" }}
                          >
                            {competitionStatus(competition)}
                            {startEndDate(competition && competition.startDate, competition && competition.endDate)}
                          </div>
                        </td> */}
                        <td style={{ textAlign: "left" }}>
                          {/* {competition.teamNames && showRemainingTeams(competition)} */}
                          {competition.teamNames && <ShowRemainingTeams competition={competition} />}
                        </td>
                        <td>
                          <div
                            className="hideextra"
                            style={{ minWidth: "110px" }}
                          >
                            {competition.teamType} {competition.additionalInfo ? `(${competition.additionalInfo})` : ""}
                          </div>
                        </td>
                        {userInfo && userInfo.isAdmin && (
                          <>
                            <td>
                              <div
                                className="hideextra"
                                style={{ "min-width": "60px" }}
                              >
                                {competition.view}
                              </div>
                            </td>
                            <td>
                              <Link
                                class="btn btn-primary pull-right"
                                to={`/admin/competition/${competition._id}`}
                              >
                                수정
                              </Link>
                            </td>
                            <td>
                              <Link
                                class="btn btn-primary pull-right"
                                to={`/admin/competition-apply/${competition._id}`}
                              >
                                신청서
                              </Link>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
              </tbody>
            </Table>
          </>
        )}
        <PaginateCompetition
          pages={pages}
          page={page}
          currentLink={`/competitionrecord/${category}/division/${division}`}
          keyword={keyword ? keyword : ""}
        />
      </>
    </>
  );
};

//   <>
//   {loading ? (
//       <Loader />
//     ) : error ? (
//       <Message variant="danger">{error}</Message>
//     ) : (
//       <h1>he</h1>
//     )}
// )
//     }
//     </>
// )
//   }

export default CompetitionDivisionListScreen;
