import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import optionsCompetitionTeamType from '../static/optionsCompetitionTeamType';
import { useDispatch, useSelector } from 'react-redux';
import { competitionGameList, listCompetition } from '../actions/competitionActions';
import { inputApply } from '../actions/competitionApplyActions';

const CompetitionApplyScreen = ({ match }) => {
  const competitionId = match.params.id;
  const dispatch = useDispatch();

  const [teamName, setTeamName] = useState('');
  const [leaderName, setLeaderName] = useState('');
  const [leaderPhoneNum, setLeaderPhoneNum] = useState('');
  const [applyPassword, setApplyPassword] = useState('');
  const [playerList, setPlayerList] = useState(Array(20).fill({ name: '', birthday: '', jerseyNum: '' }));

  const [optionsCompetition, setOptionsCompetition] = useState([]);

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const competitionGame = useSelector((state) => state.competitionGame);
  const { loading, error, competition } = competitionGame;

  useEffect(() => {
    dispatch(competitionGameList(competitionId));
  }, [dispatch, competitionId]);

  const handleInputChange = (index, field, value) => {
    const newPlayers = playerList.map((player, idx) => {
      if (idx === index) {
        return { ...player, [field]: value };
      }
      return player;
    });
    setPlayerList(newPlayers);
  };

  const formatPhoneNumber = (phoneNum) => {
    const cleaned = ("" + phoneNum).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNum;
  };

  const formatBirthday = (birthday) => {
    return birthday.slice(0, 6);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (teamName === '') {
      alert('팀명을 입력해주세요.');
      return;
    }
    if (leaderName === '') {
      alert('감독 성명을 입력해주세요.');
      return;
    }
    if (leaderPhoneNum === '') {
      alert('감독 휴대폰 번호를 입력해주세요.');
      return;
    }
    
    const newPlayerList = playerList.filter(player => player.name !== '');

    if (newPlayerList.length === 0) {
      alert('선수를 한 명 이상 입력해주세요.');
      return;
    }

    for (let i = 0; i < newPlayerList.length; i++) {
      if (newPlayerList[i].birthday.length !== 6) {
        alert('생년월일은 6자리로 입력해주세요.');
        return;
      }
    }

    if (applyPassword === '') {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    
    dispatch(inputApply({
      competitionId,
      teamName,
      leaderName,
      leaderPhoneNum,
      playerList: newPlayerList,
      applyPassword,
    })).then(res => {
      if (res === 400) {
        alert('사용 중인 비밀번호입니다.');
      }
    });
  };

  useEffect(() => {
    dispatch(listCompetition());
  }, [dispatch]);

  useEffect(() => {
    if (competitions) {
      let tempOptionsCompetition = [];

      competitions
        .filter(competition => competition.status <= 1)
        .map((competition) =>
          tempOptionsCompetition.push({
            value: competition._id,
            label: competition.name,
          })
        );
      setOptionsCompetition(tempOptionsCompetition);
    }
  }, [competitions]);

  return (
    <Container>
      <div style={{ display: 'flex' }}>
      <div>
        <span style={{ color: 'blue', fontSize: '30px' }}>{competition?.name}</span>
        <span style={{ fontSize: '30px' }}> 참가 신청서</span>
      </div>
      <Button
        variant="primary"
        onClick={() => {
          window.location.href = `/competition-apply-find/${competitionId}`;
        }}
        style={{ marginBottom: '20px', marginLeft: '20px'}}
      >
        신청서 조회
      </Button>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3" style={{ width: '70%' }}>
          <Form.Group as={Col}>
            <Form.Label>팀명</Form.Label>
            <Form.Control
              type="text"
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3" style={{ width: '70%' }}>
          <Form.Group as={Col}>
            <Form.Label>감독 성명</Form.Label>
            <Form.Control
              type="text"
              value={leaderName}
              onChange={e => setLeaderName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>감독 휴대폰 번호</Form.Label>
            <Form.Control
              type="text"
              value={leaderPhoneNum}
              onChange={e => setLeaderPhoneNum(formatPhoneNumber(e.target.value))}
            />
          </Form.Group>
        </Row>
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
          style={{ borderColor: "black", maxWidth: "700px" }}
        >
          <thead>
            <tr>
              <th
                style={{ width: '7%' }}
              >
                순번
              </th>
              <th
                style={{ width: '20%' }}
              >
                이름
              </th>
              <th
                style={{ width: '20%' }}
              >
                생년월일(6자리)
              </th>
              <th
                style={{ width: '15%' }}
              >
                등번호
              </th>
            </tr>
          </thead>
          <tbody>
            {playerList.map((player, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td><Form.Control style={{ height: "30px" }} type="text" value={player.name} onChange={e => handleInputChange(index, 'name', e.target.value)} /></td>
                <td><Form.Control style={{ height: "30px" }} type="text" value={player.birthday} onChange={e => handleInputChange(index, 'birthday', formatBirthday(e.target.value))} /></td>
                <td><Form.Control style={{ height: "30px" }} type="text" value={player.jerseyNum} onChange={e => handleInputChange(index, 'jerseyNum', e.target.value)} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="mb-3" style={{ width: '70%' }}>
          <Form.Group controlId="teamType" className="mt-3">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              value={applyPassword}
              onChange={e => setApplyPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button variant="primary" type="submit">신청하기</Button>
      </Form>
    </Container>
  );
};

export default CompetitionApplyScreen;
