import {
  PLAYER_LIST_REQUEST,
  PLAYER_LIST_SUCCESS,
  PLAYER_LIST_FAIL,
  PLAYER_LIST_RESET,
  PLAYER_LIST_CONSONANT_REQUEST,
  PLAYER_LIST_CONSONANT_SUCCESS,
  PLAYER_LIST_CONSONANT_FAIL,
  PLAYER_LIST_CONSONANT_RESET,
  PLAYER_LIST_TYPE_REQUEST,
  PLAYER_LIST_TYPE_SUCCESS,
  PLAYER_LIST_TYPE_FAIL,
  PLAYER_LIST_TYPE_RESET,
  PLAYER_DETAIL_REQUEST,
  PLAYER_DETAIL_SUCCESS,
  PLAYER_DETAIL_FAIL,
  PLAYER_DETAIL_RESET,
  PLAYER_SEASON_RECORD_REQUEST,
  PLAYER_SEASON_RECORD_SUCCESS,
  PLAYER_SEASON_RECORD_FAIL,
  PLAYER_SEASON_RECORD_RESET,
  PLAYER_COMPETITION_RECORD_REQUEST,
  PLAYER_COMPETITION_RECORD_SUCCESS,
  PLAYER_COMPETITION_RECORD_FAIL,
  PLAYER_COMPETITION_RECORD_RESET,
  PLAYER_ALL_RECORD_REQUEST,
  PLAYER_ALL_RECORD_SUCCESS,
  PLAYER_ALL_RECORD_FAIL,
  PLAYER_ALL_RECORD_RESET,
  PLAYER_INPUT_REQUEST,
  PLAYER_INPUT_SUCCESS,
  PLAYER_INPUT_FAIL,
  PLAYER_INPUT_RESET,
  PLAYER_UPDATE_REQUEST,
  PLAYER_UPDATE_SUCCESS,
  PLAYER_UPDATE_FAIL,
  PLAYER_DUPLICATE_REQUEST,
  PLAYER_DUPLICATE_SUCCESS,
  PLAYER_DUPLICATE_FAIL,
  PLAYER_DUPLICATE_RESET,
  PLAYER_DELETE_REQUEST,
  PLAYER_DELETE_SUCCESS,
  PLAYER_DELETE_FAIL,
  PLAYER_COMPETITION_LIST_REQUEST,
  PLAYER_COMPETITION_LIST_SUCCESS,
  PLAYER_COMPETITION_LIST_FAIL,
  PLAYER_COMPETITION_LIST_RESET,
} from "../constants/playerConstants";

export const playerListReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case PLAYER_LIST_REQUEST:
      return { loading: true };
    case PLAYER_LIST_SUCCESS:
      return {
        loading: false,
        players: action.payload.players,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PLAYER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_LIST_RESET:
      return { players: [] };
    default:
      return state;
  }
};

export const playerListConsonantReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case PLAYER_LIST_CONSONANT_REQUEST:
      return { loading: true };
    case PLAYER_LIST_CONSONANT_SUCCESS:
      return {
        loading: false,
        players: action.payload.players,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PLAYER_LIST_CONSONANT_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_LIST_CONSONANT_RESET:
      return { players: [] };
    default:
      return state;
  }
};

export const playerListTypeReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case PLAYER_LIST_TYPE_REQUEST:
      return { loading: true };
    case PLAYER_LIST_TYPE_SUCCESS:
      return {
        loading: false,
        players: action.payload.players,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PLAYER_LIST_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_LIST_TYPE_RESET:
      return { players: [] };
    default:
      return state;
  }
};

export const playerDetailsReducer = (state = { player: {} }, action) => {
  switch (action.type) {
    case PLAYER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case PLAYER_DETAIL_SUCCESS:
      return { loading: false, player: action.payload };
    case PLAYER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_DETAIL_RESET:
      return { player: [] };
    default:
      return state;
  }
};

export const playerSeasonRecordReducer = (state = { record: [] }, action) => {
  switch (action.type) {
    case PLAYER_SEASON_RECORD_REQUEST:
      return { ...state, loading: true };
    case PLAYER_SEASON_RECORD_SUCCESS:
      return { loading: false, record: action.payload };
    case PLAYER_SEASON_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_SEASON_RECORD_RESET:
      return { record: [] };
    default:
      return state;
  }
};

export const playerCompetitionRecordReducer = (
  state = { competitionRecord: [] },
  action
) => {
  switch (action.type) {
    case PLAYER_COMPETITION_RECORD_REQUEST:
      return { ...state, loading: true };
    case PLAYER_COMPETITION_RECORD_SUCCESS:
      return {
        loading: false,
        competitionRecord: [...state.competitionRecord, action.payload],
      };
    case PLAYER_COMPETITION_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_COMPETITION_RECORD_RESET:
      return { competitionRecord: [] };
    default:
      return state;
  }
};

export const playerAllRecordReducer = (state = { record: [] }, action) => {
  switch (action.type) {
    case PLAYER_ALL_RECORD_REQUEST:
      return { ...state, loading: true };
    case PLAYER_ALL_RECORD_SUCCESS:
      return { loading: false, record: action.payload };
    case PLAYER_ALL_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_ALL_RECORD_RESET:
      return {};
    default:
      return state;
  }
};

export const playerInputReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_INPUT_REQUEST:
      return { loading: true };
    case PLAYER_INPUT_SUCCESS:
      return { loading: false };
    case PLAYER_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const playerUpdateReducer = (state = { player: {} }, action) => {
  switch (action.type) {
    case PLAYER_UPDATE_REQUEST:
      return { loading: true };
    case PLAYER_UPDATE_SUCCESS:
      return { loading: false, success: true, player: action.payload };
    case PLAYER_UPDATE_FAIL:
      alert("[오류] 업데이트에 실패하였습니다.");
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playerDuplicateReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_DUPLICATE_REQUEST:
      return { loading: true };
    case PLAYER_DUPLICATE_SUCCESS:
      return { loading: false, isDuplicate: action.payload };
    case PLAYER_DUPLICATE_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_DUPLICATE_RESET:
      return {};
    default:
      return state;
  }
};

export const playerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_DELETE_REQUEST:
      return { loading: true };
    case PLAYER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PLAYER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const playerCompetitionListReducer = (
  state = { competitions: [] },
  action
) => {
  switch (action.type) {
    case PLAYER_COMPETITION_LIST_REQUEST:
      return { ...state, loading: true };
    case PLAYER_COMPETITION_LIST_SUCCESS:
      return { loading: false, competitions: action.payload };
    case PLAYER_COMPETITION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PLAYER_COMPETITION_LIST_RESET:
      return {};
    default:
      return state;
  }
};
