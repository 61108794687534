import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { listPlayers } from "../actions/playerActions";
import { teamName } from "../actions/teamActions";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import PaginateAdmin from "../components/PaginateAdmin";
import SearchBox from "../components/SearchBox";

const ManageUserListScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const playerList = useSelector((state) => state.playerList);
  const { loading, error, players, page, pages } = playerList;

  useEffect(() => {
    dispatch(listPlayers(keyword, pageNumber));
    dispatch(teamName());
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <h2 className="mt-3 mb-3">선수 개인기록</h2>
      <Route render={({ history }) => <SearchBox history={history} />} />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
        >
          <thead>
            <tr>
              <th>이름</th>
              <th>생년월일</th>
              <th>신장</th>
              <th>발크기</th>
              <th>윙스팬</th>
              <th>서전트</th>
              <th>포지션</th>
              <th>등번호</th>
              <th>소속팀</th>
            </tr>
          </thead>
          <tbody>
            {players &&
              players.map((player) => (
                <tr key={player._id}>
                  <td>{player.name}</td>
                  <td>{player.birthday}</td>
                  <td>{player.height}</td>
                  <td>{player.footSize}</td>
                  <td>{player.wingspan}</td>
                  <td>{player.sargent}</td>
                  <td>{player.position}</td>
                  <td>{player.teamName}</td>
                  <td>{player.jerseyNum}</td>
                  <td>
                    <Link
                      class="btn btn-danger pull-right"
                      to={`/admin/user/${player._id}`}
                    >
                      수정
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <PaginateAdmin
        pages={pages}
        page={page}
        keyword={keyword ? keyword : ""}
      />
    </>
  );
};

export default ManageUserListScreen;
