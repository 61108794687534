const optionsDivision = [
  {
    value: 0,
    label: "없음",
  },
  {
    value: 1,
    label: "디비전1",
  },
  {
    value: 2,
    label: "디비전2",
  },
  {
    value: 3,
    label: "디비전3",
  },
  {
    value: 4,
    label: "디비전4",
  },
  {
    value: 5,
    label: "디비전5",
  },
  {
    value: 6,
    label: "디비전6",
  },
  {
    value: 10,
    label: "여자부",
  },
  {
    value: 11,
    label: "대학부"
  },
  {
    value: 12,
    label: "기타부"
  }
];

export default optionsDivision;