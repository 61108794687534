import React, { useEffect } from "react";
import { competitionGameList } from "../actions/competitionActions";
import { useDispatch, useSelector } from "react-redux";
import GameSchedule from "../components/GameSchedule";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Image, Row, Table } from "react-bootstrap";
import {
  COMPETITION_INFO_RESET,
  COMPETITION_LIST_RESET,
} from "../constants/competitionConstants";

const CompetitionRecordScreen = ({ match }) => {
  const competitionId = match.params.id;

  const dispatch = useDispatch();

  const competitionGame = useSelector((state) => state.competitionGame);
  const { loading, error, competition } = competitionGame;

  useEffect(() => {
    dispatch(competitionGameList(competitionId));
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_INFO_RESET });
  }, [dispatch, competitionId]);

  const i = 0;

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {competition.games && competition.games.length !== 0 ? (
            <Row className="mt-3">
              <h1>{competition.name} 경기 기록</h1>
              <h5 className="mt-3 h5__competition__info">
                {competition.teamType.join(' / ')} / {competition.host} /{" "}
                {competition.place} <br />
                대회 기간 :{" "}
                {competition.startDate &&
                  competition.startDate.substring(0, 10)}{" "}
                ~ {competition.endDate && competition.endDate.substring(0, 10)}
              </h5>
              <Table responsive className="table-sm text-center mt-3">
                <thead>
                  <tr>
                    <th>경기</th>
                    <th>HOME</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>AWAY</th>
                  </tr>
                </thead>
                <tbody>
                  {competition.games.map((game, i) => (
                    <tr key={i}>
                      <GameSchedule key={i} id={game} index={i} />
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          ) : (
            <Row className="justify-content-center mt-5 mb-5">
              <h2 className="mt-5 mb-5 text-center">
                경기 결과가 곧 업데이트 됩니다.
              </h2>
              <Image
                src={`/time4_logo_message.png`}
                style={{ width: "26rem" }}
                className="text-center mt-5 mb-5"
              />
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default CompetitionRecordScreen;
