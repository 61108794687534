import {
  ASSOCIATION_OWN_REQUEST,
  ASSOCIATION_OWN_SUCCESS,
  ASSOCIATION_OWN_FAIL,
  ASSOCIATION_OWN_RESET,
} from "../constants/associationConstants";

export const associationOwnReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSOCIATION_OWN_REQUEST:
      return { loading: true };
    case ASSOCIATION_OWN_SUCCESS:
      return { loading: false, association: action.payload };
    case ASSOCIATION_OWN_FAIL:
      return { loading: false, error: action.payload };
    case ASSOCIATION_OWN_RESET:
      return {};
    default:
      return state;
  }
};