export const GAME_ARTICLE_LIST_REQUEST = "GAME_ARTICLE_LIST_REQUEST";
export const GAME_ARTICLE_LIST_SUCCESS = "GAME_ARTICLE_LIST_SUCCESS";
export const GAME_ARTICLE_LIST_FAIL = "GAME_ARTICLE_LIST_FAIL";
export const GAME_ARTICLE_LIST_RESET = "GAME_ARTICLE_LIST_RESET";

export const GAME_ARTICLE_READ_REQUEST = "GAME_ARTICLE_READ_REQUEST";
export const GAME_ARTICLE_READ_SUCCESS = "GAME_ARTICLE_READ_SUCCESS";
export const GAME_ARTICLE_READ_FAIL = "GAME_ARTICLE_READ_FAIL";

export const GAME_ARTICLE_WRITE_REQUEST = "GAME_ARTICLE_WRITE_REQUEST";
export const GAME_ARTICLE_WRITE_SUCCESS = "GAME_ARTICLE_WRITE_SUCCESS";
export const GAME_ARTICLE_WRITE_FAIL = "GAME_ARTICLE_WRITE_FAIL";

export const GAME_ARTICLE_UPDATE_REQUEST = "GAME_ARTICLE_UPDATE_REQUEST";
export const GAME_ARTICLE_UPDATE_SUCCESS = "GAME_ARTICLE_UPDATE_SUCCESS";
export const GAME_ARTICLE_UPDATE_FAIL = "GAME_ARTICLE_UPDATE_FAIL";

export const GAME_ARTICLE_DELETE_REQUEST = "GAME_ARTICLE_DELETE_REQUEST";
export const GAME_ARTICLE_DELETE_SUCCESS = "GAME_ARTICLE_DELETE_SUCCESS";
export const GAME_ARTICLE_DELETE_FAIL = "GAME_ARTICLE_DELETE_FAIL";