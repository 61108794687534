import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  teamDetailsInfo, updateTeam,
} from "../actions/teamActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Button } from "react-bootstrap";
import { accessControl } from "../utils/access";
import { TEAM_DETAIL_RESET } from "../constants/teamConstants";

const TeamApplyScreen = ({ match }) => {
  const teamId = match.params.id;

  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading, error, team } = teamDetails;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const submitHandler = (e) => {
    e.preventDefault();
    if (team.applyQueue.find((x) => x.playerId === userInfo.playerId)) {
      alert("이미 가입 요청을 했습니다.");
      return;
    }

    const confirm = window.confirm("팀 가입을 요청하시겠습니까?");
    if (confirm) {
      const applyForm = {
        playerId: userInfo.playerId,
        applyMessage: message,
      };

      dispatch(
        updateTeam({
          _id: teamId,
          name: team.name,
          image: team.image,
          leader: team.leader,
          foundedAt: team.foundedAt,
          teamType: team.teamType,
          members: team.members,
          masterId: team.masterId,
          applyQueue: [...team.applyQueue, applyForm],
        })
      );
    }
  };

  useEffect(() => {
    accessControl([]);
  }, []);

  useEffect(() => {
    dispatch(teamDetailsInfo(teamId));
  }, [dispatch, teamId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="post__main">
            <h3
              className="mt-3 mb-3"
              style={{ color: "white", "font-weight": 700 }}
            >
              {team?.name} 팀 가입 요청
            </h3>
          </div>
          <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
            <div style={{ marginBottom: "10px" }}>요청 메세지</div>
            <textarea
              type="text"
              onChange={(e) => setMessage(e.target.value)}
              style={{ width: "50%", height: "200px", textAlign: "left" }}
            />
          </div>
          <Button
            type="submit"
            className="btn btn-primary"
            style={{ width: "100px" }}
            onClick={submitHandler}
          >
            요청하기
          </Button>
        </>
      )}
    </>
  );
}

export default TeamApplyScreen;