import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { playerCompetitionRecord } from "../actions/playerActions";
import { PLAYER_COMPETITION_RECORD_RESET } from "../constants/playerConstants";
import Loader from "./Loader";
import Message from "./Message";

const AdminPlayerDetailCompetition = ({ record }) => {
  return (
    <>
      <td
        style={{
          position: "sticky",
          left: "0",
          "z-index": "1",
          background: "white",
          padding: "6px",
        }}
      >
        <div className="hideextra" style={{ "min-width": "50px" }}>
          {record && record.teamName}
        </div>
      </td>
      <td
        style={{
          position: "sticky",
          left: "0",
          "z-index": "1",
          background: "white",
          padding: "6px",
        }}
      >
        <div className="hideextra" style={{ "min-width": "50px" }}>
          {record && record.name}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div className="hideextra" style={{ "min-width": "30px" }}>
          {record && record.gameCount}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "30px", "line-height": "120%" }}
        >
          {record && record.score}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.fieldGoal[1]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.fieldGoal[0]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "30px", "line-height": "120%" }}
        >
          {record && record.fieldGoal[1] === 0
            ? 0
            : (
              (record &&
                record.fieldGoal[1] /
                record.fieldGoal[0]) * 100
            ).toFixed(1)}
          %
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.threePointThrow[1]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.threePointThrow[0]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "30px", "line-height": "120%" }}
        >
          {record &&
            record.threePointThrow[1] === 0
            ? 0
            : (
              (record &&
                record.threePointThrow[1] /
                record.threePointThrow[0]) * 100
            ).toFixed(1)}
          %
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.freeThrow[1]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.freeThrow[0]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "35px", "line-height": "120%" }}
        >
          {record && record.freeThrow[1] === 0
            ? 0
            : (
              (record &&
                record.freeThrow[1] /
                record.freeThrow[0]) * 100
            ).toFixed(1)}
          %
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.rebound[0]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.rebound[1]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && (record.rebound[1] + record.rebound[0])}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "35px", "line-height": "120%" }}
        >
          {record && record.steal}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "35px", "line-height": "120%" }}
        >
          {record && record.block}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "35px", "line-height": "120%" }}
        >
          {record && record.turnOver}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "35px", "line-height": "120%" }}
        >
          {record && record.foul[0]}
        </div>
      </td>
      <td
        style={{
          "z-index": "-2",
          position: "relative",
          "background-color": "white",
          padding: "6px",
        }}
      >
        <div
          className="hideextra"
          style={{ "min-width": "40px", "line-height": "120%" }}
        >
          {record && record.assist}
        </div>
      </td>
    </>
  );
};

export default AdminPlayerDetailCompetition;
