import React, { useState } from "react";

const ConsonantBox = ({ history, pageType }) => {
  const [keyword, setKeyword] = useState("");

  const onClickHandler = (keyword) => {
    history.push(`/${pageType}/search/${keyword}/page/1`);
  };

  return (
    <div className="name__search__container">
    <button className="name__search__btn" onClick={() => onClickHandler("ㄱ")}>ㄱ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㄴ")}>ㄴ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㄷ")}>ㄷ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㄹ")}>ㄹ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅁ")}>ㅁ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅂ")}>ㅂ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅅ")}>ㅅ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅇ")}>ㅇ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅈ")}>ㅈ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅊ")}>ㅊ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅋ")}>ㅋ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅌ")}>ㅌ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅍ")}>ㅍ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("ㅎ")}>ㅎ</button>
    <button className="name__search__btn" onClick={() => onClickHandler("a")}>A~Z</button>
  </div>
  );
};

export default ConsonantBox;
