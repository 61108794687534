import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import YouTube from "react-youtube";
import {
  detailCompetitionInfo,
  competitionGameUpdate,
} from "../actions/competitionActions";
import {
  COMPETITION_GAME_UPDATE_RESET,
  COMPETITION_LIST_RESET,
  COMPETITION_INFO_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";
import { accessControl } from "../utils/access";

const ModifyVideoScreen = ({ match, history }) => {
  const gameId = match.params.id;

  const opts = {
    width: "100%",
    height: "350",
    playerVars: {
      autoplay: 0,
      rel: 0,
    },
  };

  const [videoId, setVideoId] = useState("");
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoLink, setVideoLink] = useState("");

  const dispatch = useDispatch();

  const competitionDetailInfo = useSelector(
    (state) => state.competitionDetailInfo
  );
  const { loading, error, competitionDetail } = competitionDetailInfo;

  const adminCompetitionGameUpdate = useSelector(
    (state) => state.adminCompetitionGameUpdate
  );
  const { success: successUpdate } = adminCompetitionGameUpdate;

  const getYoutubeDetails = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/live|youtu\.be)\/([\w\-]+)(?:\?.*t=(\d+))?/i;
    const result = url.match(regex);
    if (result && result[1]) {
        return {
            videoId: result[1],
            videoStartTime: result[2] || 0
        };
    }
    return null;
}
  useEffect(() => {
    accessControl([100, 101], history);
    dispatch({ type: COMPETITION_INFO_RESET });
    if (successUpdate) {
      dispatch({ type: COMPETITION_GAME_UPDATE_RESET });
    } else {
      if (!competitionDetail || competitionDetail._id !== gameId) {
        dispatch(detailCompetitionInfo(gameId));
      } else {
        setVideoId(competitionDetail && competitionDetail.videoId);
        setVideoStartTime(competitionDetail && competitionDetail.videoStartTime);
      }
    }
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, gameId, competitionDetail, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      competitionGameUpdate({
        _id: gameId,
        videoId: videoId.length > 0 ? videoId : "",
        videoStartTime: videoStartTime ? videoStartTime : 0,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          영상 정보를 불러오는데 실패하였습니다
        </Message>
      ) : (
        <>
          <FormContainer>
            <h1 className="text-center">경기 영상 추가</h1>
            <h4 className="text-center">
              경기정보 : {competitionDetail && competitionDetail.gameName} /{" "}
              {competitionDetail && competitionDetail.vsTeamName[0]} VS{" "}
              {competitionDetail && competitionDetail.vsTeamName[1]}
            </h4>
            <Form onSubmit={submitHandler} className="mt-3">
            <Form.Group controlId="videoLink" className="mt-3">
                <Form.Label>경기영상 링크</Form.Label>
                <Form.Control
                  type="videoLink"
                  placeholder="영상 링크"
                  value={videoLink}
                  onChange={(e) => {
                    setVideoLink(e.target.value);
                    setVideoId(getYoutubeDetails(e.target.value).videoId);
                    setVideoStartTime(getYoutubeDetails(e.target.value).videoStartTime);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="videoId" className="mt-3">
                <Form.Label>경기영상 아이디(자동생성)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="영상 아이디"
                  value={videoId}
                  onChange={(e) => setVideoId(e.target.value)}
                ></Form.Control>
                <Form.Label>영상 시작 시간(초)(자동생성)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="영상 시작 시간"
                  value={videoStartTime}
                  onChange={(e) => setVideoStartTime(e.target.value === "" ? "0" : e.target.value)}
                ></Form.Control>
                {videoId !== "" && (
                  <YouTube
                    videoId={videoId}
                    opts={opts}
                    className="mt-3 mb-2"
                  />
                )}
              </Form.Group>
              <p className="mt-3">
                영상 아이디가 올바르게 들어갔다면, 정상적으로 유튜브 영상이
                표시되게 됩니다. 만약 영상이 재생되지 않거나 빈 화면이 나온다면
                다시 한번 영상 아이디를 확인해주세요.
              </p>
              <Button
                type="submit"
                variant="primary"
                className="mt-2"
                style={{ width: "100%" }}
              >
                영상 업데이트
              </Button>
            </Form>
          </FormContainer>
        </>
      )}
    </>
  );
};

export default ModifyVideoScreen;
