import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { deleteNotice, readNotice } from "../actions/noticeActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";

const NoticeDetailScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const noticeRead = useSelector((state) => state.noticeRead);
  const { loading, error, notice } = noticeRead;

  async function increaseView(id) {
    axios.put(`${API_SERVER_URL}/api/notice/increase-view/${id}`);
  }

  useEffect(() => {
    dispatch(readNotice(id));
    increaseView(id);
  }, [dispatch, id]);

  const deleteHandler = (e) => {
    e.preventDefault();
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      dispatch(deleteNotice(id));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="post__main">
            <h3 style={{ color: "white" }}>{notice && notice.title}</h3>
            <p>
              {notice && notice.date.substring(0, 10)} | 조회{" "}
              {notice && notice.view}
            </p>
          </div>
          <div className="post__content">
            {ReactHtmlParser(notice && notice.content)}
          </div>
          <Row className="text-center mt-5">
            <Col>
              <Link to="/">
                <Button>메인으로</Button>
              </Link>
            </Col>
            <Col>
              <Link to="/notice/list">
                <Button>공지 목록</Button>
              </Link>
            </Col>
            {userInfo && userInfo.isAdmin && (
              <>
                <Col>
                  <Link to={`/notice/edit/${notice && notice._id}`}>
                    <Button variant="success">수정하기</Button>
                  </Link>
                </Col>
                <Col>
                  <Button variant="danger" onClick={deleteHandler}>
                    삭제하기
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default NoticeDetailScreen;
