const optionsCompetitionTeamType = [
  {value: "유소년", label: "유소년"},
  {value: "중등부", label: "중등부"},
  {value: "고등부", label: "고등부"},
  {value: "대학부", label: "대학부"},
  {value: "직장인", label: "직장인"},
  {value: "일반부", label: "일반부"},
  {value: "마스터즈", label: "마스터즈"},
]

export default optionsCompetitionTeamType;