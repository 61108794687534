import React, { useState, useEffect } from "react";
import { listPosts } from "../actions/communityActions";
import { Row, Table, Button, ButtonGroup } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import SearchBox from "../components/SearchBox";
import CommunitySearchBox from "../components/CommunitySearchBox";
import { COMMUNITY_LIST_RESET } from "../constants/communityConstants";
import PaginateCommunity from "../components/PaginateCommunity";
import { COMPETITION_NOTICE_LIST_RESET } from "../constants/noticeConstants";
import { listCompetitionNotice } from "../actions/noticeActions";
import { getOwnAssociation } from "../actions/associationActions";

const CompetitionManageScreen = ({ match, history }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const [category, setCategory] = useState(
    match.params.category ? match.params.category : "자유게시판"
  );
  const [rSelected, setRSelected] = useState(1);
  const [associationName, setAssociationName] = useState("");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const competitionNoticeList = useSelector((state) => state.competitionNoticeList);
  const { loading, error, competitionNotices, page, pages } = competitionNoticeList;

  const associationOwn = useSelector((state) => state.associationOwn);
  const { association } = associationOwn;

  const categoryHandler = (cat, e) => {
    e.preventDefault();
    setCategory(cat);
    history.push(`/community/category/${cat}`);
  };

  useEffect(() => {
    dispatch(getOwnAssociation(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (association) {
      dispatch({ type: COMPETITION_NOTICE_LIST_RESET });
      dispatch(listCompetitionNotice(keyword, pageNumber, association && association._id));
    }
  }, [dispatch, keyword, pageNumber, association]);

  return (
    <>
      <h2 className="mt-3 mb-3">{association && association.name} 대회 공지</h2>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Link
                to={`/association/competition-notice/write`}
                style={{
                  "font-size": "16px",
                }}
              >
                글쓰기
              </Link>
            </div>
          </div>
          <span className="on__pc__info">
            <Table striped bordered hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "10px" }}>번호</th>
                  <th style={{ textAlign: "left", width: "300px" }}>제목</th>
                  <th style={{ width: "90px" }}>작성일</th>
                  <th style={{ width: "50px" }}>모집된 팀 수</th>
                  <th style={{ width: "50px" }}>조회</th>
                  <th style={{ width: "50px" }}>신청 현황</th>
                  <th style={{ width: "50px" }}>대회 생성</th>
                  <th style={{ width: "50px" }}>조 편성</th>
                </tr>
              </thead>
              <tbody>
                {competitionNotices &&
                  competitionNotices.map((competitionNotice, index) => (
                    <tr
                      key={competitionNotice._id}
                    >
                      <td style={{ textAlign: "center", width: "5px" }}>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {competitionNotice.postNum}
                        </div>
                      </td>
                      <td
                        style={{ textAlign: "left", width: "300px", cursor: "pointer" }}
                        onClick={() => history.push(`/competition-notice/${competitionNotice._id}`)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {competitionNotice.competitionName}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {competitionNotice.date.substring(0, 10)}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {competitionNotice.applyQueue.length}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {competitionNotice.view}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            variant="primary"
                            size="sm"
                            style={{
                              fontSize: "0.8rem",
                              height: "auto",
                              padding: "1px 10px"
                            }}
                            onClick={() => history.push(`/association/competition-manage/accept/${competitionNotice._id}`)}
                          >
                            신청 현황
                          </Button>
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            disabled={competitionNotice.acceptList.length === 0}
                            variant="primary"
                            size="sm"
                            style={{
                              fontSize: "0.8rem",
                              height: "auto",
                              padding: "1px 10px"
                            }}
                            onClick={() => history.push(`/association/competition-generate/${competitionNotice._id}`)}
                          >
                            대회 생성
                          </Button>
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            disabled={competitionNotice.competitionId === undefined || competitionNotice.competitionId === "" || competitionNotice.competitionId === null}
                            variant="primary"
                            size="sm"
                            style={{
                              fontSize: "0.8rem",
                              height: "auto",
                              padding: "1px 10px"
                            }}
                            onClick={() => history.push(`/association/competition-group/${competitionNotice._id}`)}
                          >
                            조 편성
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </span>
          {/* 모바일 버전 */}
          <span className="on__mobile__info">
            <Table hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "40px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    번호
                  </th>
                  <th>정보</th>
                </tr>
              </thead>
              <tbody>
                {competitionNotices &&
                  competitionNotices.map((competitionNotice, index) => (
                    <tr
                      key={competitionNotice._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/competition-notice/${competitionNotice._id}`)}
                    >
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "0px",
                        }}
                      >
                        <div
                          className="hideextra"
                          style={{ textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {competitionNotice.postNum}
                        </div>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <div>
                          <div>
                            {competitionNotice.competitionName}
                          </div>
                          <div className="community__list__container__mobile">
                            <span
                              className="community__list__mobile"
                              style={{ marginLeft: "0px" }}
                            >
                              {/* {post.author ? post.author.name : ""} */}
                            </span>
                            |
                            <span className="community__list__mobile">
                              {competitionNotice.date.substring(0, 10)}
                            </span>
                            |
                            <span className="community__list__mobile">
                              모집된 팀 수 {competitionNotice.applyQueue.length}
                            </span>
                            |
                            <span className="community__list__mobile">
                              조회 {competitionNotice.view}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </span>
        </>
      )}
      <PaginateCommunity
        pages={pages}
        page={page}
        keyword={keyword ? keyword : ""}
        pageType={"community"}
        category={category}
      />
    </>
  );
};

export default CompetitionManageScreen;