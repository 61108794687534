import React, { useState, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { competitionGameList, competitionInput, listLeagueRound, updateCompetition } from "../actions/competitionActions";
import { accessControl } from "../utils/access";
import optionsCompetitionTeamType from "../static/optionsCompetitionTeamType";
import optionsCategory from "../static/optionsCategory";

const ModifyCompetitionScreen = ({ match, history }) => {
  const competitionId = match.params.id;
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [place, setPlace] = useState("");
  const [teamType, setTeamType] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [category, setCategory] = useState(false);
  const [division, setDivision] = useState(0);
  // 경기 시작 하이픈 자동 생성
  const [startDate, setStartDate] = useState("");
  const startRef = useRef();

  const [isThree, setIsThree] = useState(false);

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competition,
  } = competitionGame;

  const competitionRoundInfo = useSelector((state) => state.competitionRoundInfo);

  const {
    loading: loadingRoundInfo,
    error: errorRoundInfo,
    roundInfo,
  } = competitionRoundInfo;

  useEffect(() => {
    accessControl([99, 100, 300], history);
    dispatch(competitionGameList(competitionId));
  }, [dispatch]);

  const handleStart = (e) => {
    const value = startRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    startRef.current.value = result;
    setStartDate(e.target.value);
  };

  // 경기 종료일 하이픈 자동 생성
  const [endDate, setEndDate] = useState("");
  const endRef = useRef();

  const handleEnd = (e) => {
    const value = endRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    endRef.current.value = result;
    setEndDate(e.target.value);
  };

  useEffect(() => {
    if (competition) {
      setName(competition.name);
      setHost(competition.host);
      setPlace(competition.place.join(","));
      setTeamType(competition.teamType);
      setAdditionalInfo(competition.additionalInfo);
      setStartDate(competition.startDate.substring(0, 10));
      setEndDate(competition.endDate.substring(0, 10));
      setCategory(competition.category);
      setDivision(competition.division);
    }
  }, [competition]);

  useEffect(() => {
    dispatch(listLeagueRound(competitionId));
  }, [dispatch, competitionId]);

  const divisionOption = [
    {
      value: 0,
      label: "없음",
    },
    {
      value: 1,
      label: "디비전1",
    },
    {
      value: 2,
      label: "디비전2",
    },
    {
      value: 3,
      label: "디비전3",
    },
    {
      value: 4,
      label: "디비전4",
    },
    {
      value: 5,
      label: "디비전5",
    },
    {
      value: 6,
      label: "디비전6",
    }
  ];

  const adminCompetitionInput = useSelector(
    (state) => state.adminCompetitionInput
  );
  const { loading, error } = adminCompetitionInput;

  const submitHandler = (e) => {
    e.preventDefault();

    const places = place.split(',');
    for (let i = 0; i < places.length; i++) {
      if (places[i][0] === ' ') { places[i] = places[i].slice(1) }
      if (places[i][places[i].length - 1] === ' ') { places[i] = places[i].slice(0, -1) }
    }

    const statStandard = [];
    if (competition && competition.division === 0) {
      statStandard.push(
        document.getElementById("standard-2-score").value,
        document.getElementById("standard-3-score").value,
        document.getElementById("standard-f-score").value,
        document.getElementById("standard-etc-gamecnt").value
      )
    }

    const roundStatStandard = [];
    roundInfo && roundInfo.round.map((round, index) => {
      roundStatStandard.push([
        document.getElementById(`round${index}-2-score`).value,
        document.getElementById(`round${index}-3-score`).value,
        document.getElementById(`round${index}-f-score`).value,
        document.getElementById(`round${index}-etc-gamecnt`).value
      ])
    })

    dispatch(
      updateCompetition({
        _id: competitionId,
        name: name,
        host: host,
        place: places,
        isThree: isThree,
        category: category,
        teamType: teamType,
        startDate: startDate,
        endDate: endDate,
        division: division,
        isRecruiting: competition.isRecruiting,
        statStandard: statStandard,
        roundStatStandard: roundStatStandard,
        additionalInfo: additionalInfo,
      })
    );
  };

  return (
    <FormContainer>
      <h1>대회 / 리그 생성</h1>
      {error && (
        <Message variant="danger">
          대회 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Form.Group controlId="name">
          <Form.Label>대회명</Form.Label>
          <Form.Control
            type="name"
            placeholder="대회명"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <p className="mt-3">경기 종류</p>
        <Form.Check
          label="5대5"
          type="radio"
          inline
          onChange={() => setIsThree(false)}
          defaultChecked={isThree === false}
          name="group2"
          id={"2"}
        />
        <Form.Check
          label="3대3"
          type="radio"
          inline
          onChange={() => setIsThree(true)}
          defaultChecked={isThree === true}
          name="group2"
          id={"2"}
        />
        <Form.Group controlId="teamType" className="mt-2">
          <Form.Label>카테고리</Form.Label>
          <Select
            options={optionsCategory}
            value={optionsCategory.find((option) => option.value === category)}
            onChange={(e) => setCategory(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="host" className="mt-2">
          <Form.Label>대회 개최자</Form.Label>
          <Form.Control
            type="host"
            placeholder="대회 개최자"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="place" className="mt-2">
          <Form.Label>개최지</Form.Label>
          <Form.Control
            type="name"
            placeholder="쉼표(,)로 구분해서 여러 장소 입력 가능. 예) 와동체육관,선부체육관"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="teamType" className="mt-2">
          <Form.Label>종별</Form.Label>
          <Select
            options={optionsCompetitionTeamType}
            value={optionsCompetitionTeamType.find((option) => option.value === teamType)}
            onChange={(e) => setTeamType(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="additionalInfo" className="mt-2">
          <Form.Label>추가 정보</Form.Label>
          <Form.Control
            type="text"
            placeholder="종별 옆에 표시됩니다."
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="division" className="mt-2">
          <Form.Label>디비전</Form.Label>
          <Select
            isDisabled={!["Time4Division", "NonguLab"].includes(category)}
            options={divisionOption}
            value={divisionOption.find((option) => option.value === division)}
            onChange={(e) => setDivision(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="startDate" className="mt-2">
          <Form.Label>대회 시작일</Form.Label>
          <Form.Control
            type="startDate"
            placeholder="예) 2022-01-01"
            ref={startRef}
            value={startDate}
            onChange={handleStart}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="endDate" className="mt-2">
          <Form.Label>대회 종료일</Form.Label>
          <Form.Control
            type="endDate"
            placeholder="예) 2022-01-31"
            ref={endRef}
            value={endDate}
            onChange={handleEnd}
          ></Form.Control>
        </Form.Group>
        {competition && competition.division === 0 && (
          <Form.Group controlId="standard" className="mt-2">
            <Form.Label>대회 전체 통계 기준</Form.Label>
            <table>
              <tr>
                <td>2점 성공:</td>
                <td>
                  <input
                    type="number"
                    id="standard-2-score"
                    defaultValue={competition && competition.statStandard.length === 0 ? 0 : competition && competition.statStandard[0]}
                  />
                </td>
              </tr>
              <tr>
                <td>3점 성공:</td>
                <td>
                  <input
                    type="number"
                    id="standard-3-score"
                    defaultValue={competition && competition.statStandard.length === 0 ? 0 : competition && competition.statStandard[1]}
                    disabled={competition && competition.teamType === "유소년"}
                  />
                </td>
              </tr>
              <tr>
                <td>자유투 성공:</td>
                <td>
                  <input
                    type="number"
                    id="standard-f-score"
                    defaultValue={competition && competition.statStandard.length === 0 ? 0 : competition && competition.statStandard[2]}
                  />
                </td>
              </tr>
              <tr>
                <td>이외 통계 경기 수:</td>
                <td>
                  <input
                    type="number"
                    id="standard-etc-gamecnt"
                    defaultValue={competition && competition.statStandard.length === 0 ? 0 : competition && competition.statStandard[3]}
                  />
                </td>
              </tr>
            </table>

          </Form.Group>
        )}
        <hr style={{ margin: '20px 0' }} />
        <Form.Group controlId="division" className="mt-2">
          <Form.Label>조(라운드)별 통계 기준</Form.Label>
          <div>
            {roundInfo && (
              <table style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Round</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>2점 성공</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>3점 성공</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>자유투 성공</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>이외 통계 경기 수</th>
                  </tr>
                </thead>
                <tbody>
                  {roundInfo.round.map((round, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{round[1]}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          style={{ width: "50px" }}
                          type="number"
                          id={`round${index}-2-score`}
                          defaultValue={competition?.roundStatStandard[index]?.[0] ? competition?.roundStatStandard[index]?.[0] : 0}
                        />
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          style={{ width: "50px" }}
                          type="number"
                          id={`round${index}-3-score`}
                          defaultValue={competition?.roundStatStandard[index]?.[1] ? competition?.roundStatStandard[index]?.[1] : 0}
                          disabled={competition && competition.teamType === "유소년"}
                        />
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          style={{ width: "50px" }}
                          type="number"
                          id={`round${index}-f-score`}
                          defaultValue={competition?.roundStatStandard[index]?.[2] ? competition?.roundStatStandard[index]?.[2] : 0}
                        />
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        <input
                          style={{ width: "50px" }}
                          type="number"
                          id={`round${index}-etc-gamecnt`}
                          defaultValue={competition?.roundStatStandard[index]?.[3] ? competition?.roundStatStandard[index]?.[3] : 0}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          대회 등록
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ModifyCompetitionScreen;