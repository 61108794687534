import {
  COMPETITION_APPLY_DETAIL_REQUEST,
  COMPETITION_APPLY_DETAIL_SUCCESS,
  COMPETITION_APPLY_DETAIL_FAIL,
  COMPETITION_APPLY_DETAIL_RESET,
  COMPETITION_APPLY_LIST_REQUEST,
  COMPETITION_APPLY_LIST_SUCCESS,
  COMPETITION_APPLY_LIST_FAIL,
  COMPETITION_APPLY_LIST_RESET,
  COMPETITION_APPLY_INPUT_REQUEST,
  COMPETITION_APPLY_INPUT_SUCCESS,
  COMPETITION_APPLY_INPUT_FAIL,
  COMPETITION_APPLY_INPUT_RESET,
  COMPETITION_APPLY_UPDATE_REQUEST,
  COMPETITION_APPLY_UPDATE_SUCCESS,
  COMPETITION_APPLY_UPDATE_FAIL,
  COMPETITION_APPLY_UPDATE_RESET,
} from "../constants/competitionApplyConstants";

export const competitionApplyDetailReducer = (state = { apply: {} }, action) => {
  switch (action.type) {
    case COMPETITION_APPLY_DETAIL_REQUEST:
      return { ...state, loading: true };
    case COMPETITION_APPLY_DETAIL_SUCCESS:
      return { loading: false, apply: action.payload };
    case COMPETITION_APPLY_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_APPLY_DETAIL_RESET:
      return { apply: {} };
    default:
      return state;
  }
}

export const competitionApplyInputReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_APPLY_INPUT_REQUEST:
      return { loading: true };
    case COMPETITION_APPLY_INPUT_SUCCESS:
      return { loading: false, success: true };
    case COMPETITION_APPLY_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_APPLY_INPUT_RESET:
      return {};
    default:
      return state;
  }
}

export const competitionApplyListReducer = (state = { applies: [] }, action) => {
  switch (action.type) {
    case COMPETITION_APPLY_LIST_REQUEST:
      return { loading: true };
    case COMPETITION_APPLY_LIST_SUCCESS:
      return { loading: false, applies: action.payload };
    case COMPETITION_APPLY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_APPLY_LIST_RESET:
      return { applies: [] };
    default:
      return state;
  }
}

export const competitionApplyUpdateReducer = (state = { apply: {} }, action) => {
  switch (action.type) {
    case COMPETITION_APPLY_UPDATE_REQUEST:
      return { loading: true };
    case COMPETITION_APPLY_UPDATE_SUCCESS:
      return { loading: false, success: true, apply: action.payload };
    case COMPETITION_APPLY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_APPLY_UPDATE_RESET:
      return { apply: {} };
    default:
      return state;
  }
}