import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Card, Button, Image } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  listTeamCompetition,
  teamDetailsInfo,
  teamSeasonMembersRecord,
  teamSeasonRecord,
} from "../actions/teamActions";
import PlayerSeasonTeam from "../components/PlayerSeasonTeam";
import { PLAYER_SEASON_RECORD_RESET } from "../constants/playerConstants";
import { TEAM_DETAIL_RESET } from "../constants/teamConstants";
import TeamDetailCompetition from "../components/TeamDetailCompetition";
import Select from "react-select";
import { getUserTeam } from "../actions/userActions";
import { getScheduledGameList, getTeamGameList } from "../actions/competitionActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MyTeamPageScreen = () => {
  const [year, setYear] = useState("2024");
  const [selectTeam, setSelectTeam] = useState("");

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading, error, team } = teamDetails;

  const teamSeason = useSelector((state) => state.teamSeason);
  const {
    loading: loadingTeamSeason,
    error: errorTeamSeason,
    seasonTeam,
  } = teamSeason;

  const teamSeasonMembers = useSelector((state) => state.teamSeasonMembers);
  const {
    loading: loadingTeamSeasonMembers,
    error: errorTeamSeasonMembers,
    seasonMembersTeam,
  } = teamSeasonMembers;

  const teamCompetitionList = useSelector((state) => state.teamCompetitionList);
  const {
    loading: competitionListLoading,
    error: competitionListError,
    competitions,
  } = teamCompetitionList;

  const teamGameList = useSelector((state) => state.teamGameList);
  const {
    loading: gameListLoading,
    error: gameListError,
    games,
  } = teamGameList;

  const scheduledGameList = useSelector((state) => state.scheduledGameList);
  const {
    loading: scheduledGameListLoading,
    error: scheduledGameListError,
    scheduledGames,
  } = scheduledGameList;

  const handleSeasonChange = (selected) => {
    setYear(selected.value);
  };

  const options = [
    {
      value: 2022,
      label: "시즌 2022",
    },
    {
      value: 2023,
      label: "시즌 2023",
    },
    {
      value: 2024,
      label: "시즌 2024",
    }
  ];

  useEffect(() => {
    if (selectTeam !== "" && year !== "") {
      dispatch({ type: TEAM_DETAIL_RESET });
      dispatch({ type: PLAYER_SEASON_RECORD_RESET });
      dispatch(teamSeasonRecord({ teamId: selectTeam, year: year }));
      dispatch(teamSeasonMembersRecord({ teamId: selectTeam, year: year }));
      dispatch(teamDetailsInfo(selectTeam));
      dispatch(getTeamGameList({ _id: selectTeam, year: year }));
      dispatch(listTeamCompetition({ _id: selectTeam, year: year }));
      dispatch(getScheduledGameList({ _id: selectTeam }));
    }
  }, [dispatch, selectTeam, year]);

  const [showCompetitionRecord, setShowCompetitionRecord] = useState(false);
  const [showSeasonRecord, setShowSeasonRecord] = useState(false);
  const [showGames, setShowGames] = useState(false);


  const userTeam = useSelector((state) => state.userTeam);
  const {
    loading: loadingUserTeam,
    error: errorUserTeam,
    userTeams,
  } = userTeam;

  const teamGameLink = (game, linkType) => {
    let linkText = "";
    let linkPath = "";
    if (linkType === "detail") {
      linkText = "결과 보기";
      linkPath = `/gamerecord/detail/${game._id}`
    }
    else if (linkType === "player-list") {
      linkText = "선수 명단";
      linkPath = `/player-list/${game._id}`
    }
    return (
      <div
        style={{
          "background-color": "#013B70",
          "border-radius": "25px",
        }}
      >
        <Link to={`${linkPath}/${game._id}`} style={{ color: "white" }}>
          {linkText}
        </Link>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getUserTeam(userId));
  }, [dispatch, userId]);

  const handleTeamChange = (e) => {
    setSelectTeam(e.value);
    dispatch(teamDetailsInfo(e.value));
  };

  const teamOption = [];

  userTeams &&
    userTeams.map((team) => {
      teamOption.push({
        value: team._id,
        label: team.name,
      });
    });

  return (
    <>
      <h1
        style={{
          color: "black",
          "background-color": "#e5e5e5",
          "padding-top": "20px",
          "padding-bottom": "20px",
          "padding-left": "30px",
          "margin-top": "-16px",
          "margin-left": "-12px",
          "margin-right": "-12px",
        }}
      >
        팀 페이지
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          width: "40%",
          padding: "20px",
        }}
      >
        <Select
          options={teamOption}
          isSearchable
          onChange={handleTeamChange}
          style={{
            width: "50%",
            marginBottom: "16px"
          }}
          placeholder="팀 선택"
        />
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        selectTeam !== "" &&
        <>
          {/* pc */}
          <span className="on__pc__info">
            <div
              xs
              lg="5"
              className="text-center"
              style={{
                "background-color": "#e5e5e5",
                "margin-top": "100px",
                "padding-bottom": "30px",
                "border-radius": "40px 40px 10px 10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                {team && team.image === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={{
                      width: "18rem",
                      border: "0px solid rgba(0, 0, 0, 0)",
                      "margin-top": "10px",
                      "text-align": "right",
                      padding: "10px",
                    }}
                    alt={team && team.name}
                    fluid
                    className="mb-3 imgStyle"
                  />
                ) : (
                  <Image
                    src={team && team.image}
                    style={{
                      width: "19rem",
                      border: "0px solid rgba(0, 0, 0, 0)",
                      "margin-top": "10px",
                      "text-align": "right",
                      background: "white",
                      padding: "10px",
                    }}
                    alt={team && team.name}
                    fluid
                    className="mb-3 imgStyle"
                  />
                )}
              </div>
              <div style={{ "min-width": "350px", "margin-top": "50px" }}>
                {team && (
                  <Row>
                    <h2
                      style={{
                        "text-align": "left",
                        color: "black",
                        "margin-bottom": "30px",
                      }}
                    >
                      {team && team.name}
                    </h2>
                    <Col style={{ "text-align": "left" }}>
                      <p className="player__mypage__detail__p__title">감독</p>
                      <p className="player__mypage__detail__p__title">종별</p>
                      <p className="player__mypage__detail__p__title">팀 멤버수</p>
                      {team && team.foundedAt == null ? (
                        <p className="player__mypage__detail__p__title">창단일</p>
                      ) : (
                        <p className="player__mypage__detail__p__title">창단일</p>
                      )}
                    </Col>
                    <Col style={{ "text-align": "right" }}>
                      <p className="player__mypage__detail__p">{team && team.leader ? team.leader : "-"}</p>
                      <p className="player__mypage__detail__p">
                        {team && team.teamType}
                      </p>
                      <p className="player__mypage__detail__p">
                        {team && team.members.length}
                      </p>
                      {team && team.foundedAt == null ? (
                        <p className="player__mypage__detail__p">-</p>
                      ) : (
                        <p className="player__mypage__detail__p">
                          {team && team.foundedAt.substring(0, 10)}
                        </p>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </span>
          {/* mobile */}
          <span className="on__mobile__info">
            <div className="text-center on__mobile__info">
              <div style={{ "background-color": "#B4BBC2" }}>
                {team && team.image === "" ? (
                  <Image
                    src={"/team_default_img.png"}
                    style={{
                      width: "12rem",
                      border: "0px solid rgba(0, 0, 0, 0)",
                      "text-align": "center",
                      "margin-top": "16px",
                    }}
                    alt={team && team.name}
                    fluid
                    className="mb-3 imgStyle"
                  />
                ) : (
                  <Image
                    src={team && team.image}
                    style={{
                      width: "12rem",
                      border: "0px solid rgba(0, 0, 0, 0)",
                      "text-align": "center",
                      "margin-top": "16px",
                    }}
                    alt={team && team.name}
                    fluid
                    className="mb-3 imgStyle"
                  />
                )}
              </div>
            </div>
            <Card
              className="card__for__mobile"
              style={{
                border: "0",
                "background-color": "#013B70",
                color: "white",
              }}
            >
              <Card.Body>
                <br />
                <Row className="mb-2">
                  <h1 style={{ color: "white" }}>{team && team.name}</h1>
                  <hr
                    style={{
                      width: "100px",
                      height: "2px",
                      background: "rgba(247, 247, 247, 0.5)",
                      "margin-left": "10px",
                    }}
                  />
                  <Row className="mt-3">
                    <Col style={{ "text-align": "left" }}>
                      <p
                        className="player__mypage__detail__p__title"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        감독
                      </p>
                      <p
                        className="player__mypage__detail__p__title"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        종별
                      </p>
                      <p
                        className="player__mypage__detail__p__title"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        팀 멤버수
                      </p>
                      {team && team.foundedAt == null ? (
                        <p
                          className="player__mypage__detail__p__title"
                          style={{
                            "margin-bottom": "7px",
                            "font-size": "16px",
                          }}
                        >
                          창단일
                        </p>
                      ) : (
                        <p
                          className="player__mypage__detail__p__title"
                          style={{
                            "margin-bottom": "7px",
                            "font-size": "16px",
                          }}
                        >
                          창단일
                        </p>
                      )}
                    </Col>
                    <Col style={{ "text-align": "right" }}>
                      <p
                        className="player__mypage__detail__p"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        {team && team.leader ? team.leader : "-"}
                      </p>
                      <p
                        className="player__mypage__detail__p"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        {team && team.teamType}
                      </p>
                      <p
                        className="player__mypage__detail__p"
                        style={{ "margin-bottom": "7px", "font-size": "16px" }}
                      >
                        {team && team.members.length}
                      </p>
                      {team && team.foundedAt == null ? (
                        <p className="player__mypage__detail__p">-</p>
                      ) : (
                        <p className="player__mypage__detail__p">
                          {team && team.foundedAt.substring(0, 10)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Row>
              </Card.Body>
            </Card>
          </span>

          <h3 className="mt-5 mx-1">팀 기록</h3>
          <Select
            options={options}
            defaultValue={{
              label: "시즌 " + year,
              value: year
            }}
            onChange={handleSeasonChange}
            placeholder="조회하고자 하는 시즌을 선택해주세요"
            className="mb-3"
          />
          {loadingTeamSeason ? (
            <Loader />
          ) : errorTeamSeason ? (
            <Message variant="success">팀 기록 정보가 없습니다.</Message>
          ) : (
            <Table
              striped
              hover
              responsive
              className="table-sm text-center"
              style={{ borderCollapse: "collapse" }}
            >
              <thead
                style={{
                  "background-color": "white",
                  postition: "sticky",
                  top: "0",
                }}
              >
                <tr className="table__header">
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "30px",
                      }}
                    >
                      경기수
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      득점
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      2점 <br />
                      성공-시도
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      2점 <br />
                      성공률
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      3점 <br />
                      성공-시도
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      3점 <br />
                      성공률
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      자유투 <br />
                      성공-시도
                    </div>
                  </th>
                  <th style={{ position: "sticky", top: "0", padding: "4px" }}>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      자유투 <br />
                      성공률
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      공격 <br />
                      리바운드
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      수비 <br />
                      리바운드
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      스틸
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      블락
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      턴오버
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      파울
                    </div>
                  </th>
                  <th
                    style={{ position: "sticky", top: "0", padding: "4px" }}
                    className="align-middle"
                  >
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      어시스트
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle" style={{ "line-height": "120%" }}>
                <td>{seasonTeam && seasonTeam.gameCount}</td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.score / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.score})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.fieldGoal[1] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}{" "}-{" "}
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.fieldGoal[0] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.fieldGoal[1]} -{" "}
                  {seasonTeam && seasonTeam.fieldGoal[0]})
                </td>
                <td>
                  {seasonTeam && seasonTeam.fieldGoal[1] === 0
                    ? 0
                    : (
                      (seasonTeam &&
                        seasonTeam.fieldGoal[1] / seasonTeam.fieldGoal[0]) *
                      100
                    ).toFixed(1)}
                  %
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.threePointThrow[1] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}{" "}
                  -{" "}
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.threePointThrow[0] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.threePointThrow[1]} -{" "}
                  {seasonTeam && seasonTeam.threePointThrow[0]})
                </td>
                <td>
                  {seasonTeam && seasonTeam.threePointThrow[1] === 0
                    ? 0
                    : (
                      (seasonTeam &&
                        seasonTeam.threePointThrow[1] /
                        seasonTeam.threePointThrow[0]) * 100
                    ).toFixed(1)}
                  %
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.freeThrow[1] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}{" "}-{" "}
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.freeThrow[0] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.freeThrow[1]} -{" "}
                  {seasonTeam && seasonTeam.freeThrow[0]})
                </td>
                <td>
                  {seasonTeam && seasonTeam.freeThrow[1] === 0
                    ? 0
                    : (
                      (seasonTeam &&
                        seasonTeam.freeThrow[1] / seasonTeam.freeThrow[0]) *
                      100
                    ).toFixed(1)}
                  %
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.rebound[0] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.rebound[0]})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.rebound[1] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.rebound[1]})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.steal / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.steal})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.block / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.block})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.turnOver / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.turnOver})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.foul[0] / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.foul[0]})
                </td>
                <td>
                  {seasonTeam &&
                    (seasonTeam.gameCount != 0) ? (seasonTeam.assist / seasonTeam.gameCount).toFixed(1) : (0).toFixed(1)}
                  <br />({seasonTeam && seasonTeam.assist})
                </td>
              </tbody>
            </Table>
          )}
          <Button
            onClick={() => setShowCompetitionRecord(!showCompetitionRecord)}
            style={{ "border-radius": "25px" }}
            className="mt-3"
          >
            시즌 {year} 대회별 기록 보기 ▼
          </Button>
          {showCompetitionRecord && (
            <>
              <h3 className="mt-4 mb-2">{year} 대회 기록</h3>
              <Row>
                {/* {competitions &&
              competitions.map((competition, index) => (
                <>
                  <TeamDetailCompetition
                    id={selectTeam}
                    i={index}
                    competitionId={competition}
                  />
                </>
              ))} */}
                {competitions && competitions.length > 0 ? (
                  competitions.map((competition, index) => (
                    <TeamDetailCompetition
                      id={selectTeam}
                      i={index}
                      competitionId={competition}
                    />
                  ))
                ) : (
                  <Message variant="success">{year} 시즌 기록이 없습니다.</Message>
                )}
              </Row>
            </>
          )}
          <br />
          <Button
            onClick={() => setShowSeasonRecord(!showSeasonRecord)}
            style={{ "border-radius": "25px" }}
            className="mt-3"
          >
            시즌 {year} 개별 기록 보기 ▼
          </Button>
          {showSeasonRecord && (
            <>
              <p
                className="pt-2 mx-2"
                style={{ color: "#011241", "font-weight": "600" }}
              >
                선수명을 클릭하면, 개인기록 조회가 가능합니다.
              </p>
              <div id="seasonRecordTable">
                <Table
                  striped
                  hover
                  responsive
                  className="table-sm text-center"
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    style={{
                      "background-color": "white",
                      postition: "sticky",
                      top: "0",
                    }}
                  >
                    <tr className="table__header">
                      <th
                        style={{
                          position: "sticky",
                          left: "0",
                          "background-color": "#031b31",
                          "z-index": "2",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          이름
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{
                            "min-width": "30px",
                          }}
                        >
                          경기수
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "40px" }}
                        >
                          득점
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "100px" }}
                        >
                          2점 <br />
                          성공-시도
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "40px" }}
                        >
                          2점 <br />
                          성공률
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "100px" }}
                        >
                          3점 <br />
                          성공-시도
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "30px" }}
                        >
                          3점 <br />
                          성공률
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "100px" }}
                        >
                          자유투 <br />
                          성공-시도
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "35px" }}
                        >
                          자유투 <br />
                          성공률
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "40px" }}
                        >
                          공격 <br />
                          리바운드
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "40px" }}
                        >
                          수비 <br />
                          리바운드
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "35px" }}
                        >
                          스틸
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "35px" }}
                        >
                          블락
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "35px" }}
                        >
                          턴오버
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "35px" }}
                        >
                          파울
                        </div>
                      </th>
                      <th
                        style={{ position: "sticky", top: "0", padding: "4px" }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "40px" }}
                        >
                          어시스트
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {seasonMembersTeam &&
                      seasonMembersTeam.map((record, index) => (
                        <tr key={index} className="align-middle">
                          <PlayerSeasonTeam
                            id={record.id}
                            i={index}
                            record={record}
                          />
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          <br />
          <Button
            onClick={() => setShowGames(!showGames)}
            style={{ "border-radius": "25px" }}
            className="mt-3"
          >
            시즌 {year} 참가 경기 보기 ▼
          </Button>
          {showGames && (
            games && games.length > 0 ? (
              // games.map((game, index) => (
              // <div>
              //   {teamGameLink(game)}
              // </div>
              <div
                style={{
                  "margin-top": "20px",
                  "padding": "10px",
                  "border-radius": "10px",
                  "text-align": "center",
                  "font-size": "13px",
                  // "width": "50%",
                }}
              >
                <Table
                  striped
                  hover
                  responsive
                  className="table-sm text-center"
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    style={{
                      "background-color": "white",
                      postition: "sticky",
                      top: "0",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          순번
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          대회명
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          경기명
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          경기일
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          결과
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {games.map((game, index) => (
                      <tr key={index} className="align-middle">
                        <td>{index + 1}</td>
                        <td>{game.competitionName}</td>
                        <td>{game.gameName}</td>
                        <td>{game.gameDate.substring(0, 10)}</td>
                        <td>{teamGameLink(game, "detail")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <Message variant="success">{year} 시즌 경기 기록이 없습니다.</Message>
            )
          )}
          <h3 className="mt-5 mx-1">출전 선수 등록</h3>
          {scheduledGames && scheduledGames.length > 0 ? (
              // games.map((game, index) => (
              // <div>
              //   {teamGameLink(game)}
              // </div>
              <div
                style={{
                  "margin-top": "20px",
                  "padding": "10px",
                  "border-radius": "10px",
                  "text-align": "center",
                  "font-size": "13px",
                }}
              >
                <Table
                  striped
                  hover
                  responsive
                  className="table-sm text-center"
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    style={{
                      "background-color": "white",
                      postition: "sticky",
                      top: "0",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          순번
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          대회명
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          경기명
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          경기일
                        </div>
                      </th>
                      <th
                        style={{
                          position: "sticky",
                          top: "0",
                          padding: "4px",
                        }}
                        className="align-middle"
                      >
                        <div
                          className="hideextra"
                          style={{ "min-width": "50px" }}
                        >
                          결과
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduledGames.map((scheduledGame, index) => (
                      <tr key={index} className="align-middle">
                        <td>{index + 1}</td>
                        <td>{scheduledGame.competitionName}</td>
                        <td>{scheduledGame.gameName}</td>
                        <td>{scheduledGame.gameDate.substring(0, 10)}</td>
                        <td>{teamGameLink(scheduledGame, "player-list")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <Message variant="success">예정된 경기가 없습니다.</Message>
            )
          }
        </>
      )}
    </>
  );
};

export default MyTeamPageScreen;