import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readNotice, updateNotice } from "../actions/noticeActions";
import { NOTICE_UPDATE_RESET } from "../constants/noticeConstants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Row } from "react-bootstrap";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";

const ModifyNoticeScreen = ({ match, history }) => {
  const id = match.params.id;

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");

  const dispatch = useDispatch();

  const noticeRead = useSelector((state) => state.noticeRead);
  const { loading, error, notice } = noticeRead;

  const noticeUpdate = useSelector((state) => state.noticeUpdate);
  const { success: successUpdate } = noticeUpdate;

  useEffect(() => {
    accessControl([], history);
    if (successUpdate) {
      dispatch({ type: NOTICE_UPDATE_RESET });
    } else {
      if (!notice || notice._id !== id) {
        dispatch(readNotice(id));
      } else {
        setTitle(notice && notice.title);
        setDate(notice && notice.date);
        setContent(notice && notice.content);
      }
    }
  }, [dispatch, id]);

  const submitHandler = () => {
    dispatch(updateNotice({ _id: id, title, date, content }));
  };

  const Editor = ({ setContent, content, setImage }) => {
    const [flag, setFlag] = useState(false);

    const customUploadAdapter = (loader) => {
      return {
        upload() {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            loader.file
              .then((file) => {
                data.append("name", file.name);
                data.append("image", file);
                const config = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                };
                axios
                  .post(`${API_SERVER_URL}/api/upload`, data, config)
                  .then((res) => {
                    if (!flag) {
                      setFlag(true);
                      setImage(res.data);
                    }
                    resolve({ default: res.data });
                  });
              })
              .catch((err) => reject(err));
          });
        },
      };
    };
    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return customUploadAdapter(loader);
      };
    }

    return (
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
        }}
      />
    );
  };

  return (
    <div className="hideextra" style={{ "min-width": "5px" }}>
      <div className="form-wrapper">
        <h2 className="mx-1">공지 수정</h2>
        <Row>
          <div style={{ "text-align": "right" }}>
            <Button
              className="submit-button mt-2"
              onClick={submitHandler}
              style={{ width: "100px" }}
            >
              수정하기
            </Button>
          </div>
          <input
            className="title-input mt-2 mb-3"
            type="text"
            placeholder="제목을 입력하세요"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            style={{
              width: "100%",
              height: "25px",
              "border-top": "none",
              "border-left": "none",
              "border-right": "none",
              "border-bottom": "3px solid #011241",
            }}
          />
        </Row>
        {Editor({ setContent, content, setImage })}
      </div>
    </div>
  );
};

export default ModifyNoticeScreen;
