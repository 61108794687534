import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { inputPickupCompetition } from "../actions/pickupActions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { accessControl } from "../utils/access";

const PickupGameBookingCreateScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [location1, setLocation1] = useState("");
  const [location2, setLocation2] = useState("");
  const [place, setPlace] = useState("");
  const [gameDate, setGameDate] = useState("");
  const [notice, setNotice] = useState("");
  const [recruitCount, setRecruitCount] = useState(0);

  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const options = [
    { value: 0, label: "서울" },
    { value: 1, label: "경기도" },
    { value: 2, label: "강원도" },
    { value: 3, label: "충청도" },
    { value: 4, label: "전라도" },
    { value: 5, label: "경상도" }
  ];

  const dateRef = useRef();

  const handleDate = (e) => {
    const value = dateRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    dateRef.current.value = result;
    setGameDate(e.target.value);
  };

  const handleLocation1Change = (selected) => {
    setLocation1(selected.value);
  };

  const pickupCompetitionInput = useSelector((state) => state.pickupCompetitionInput);
  const { loading, error } = pickupCompetitionInput;

  const submitHandler = (e) => {
    e.preventDefault();
    const hostId = userInfo._id;

    dispatch(
      inputPickupCompetition(
        name,
        hostId,
        location1,
        location2,
        place,
        gameDate,
        notice,
        recruitCount
      )
    );
  };

  useEffect(() => {
    accessControl([200], history);
  }, [history]);

  return (
    <FormContainer>
      <h1>픽업게임 모집</h1>
      {error && (
        <Message variant="danger">
          모집 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Row className="mb-2">
          <Col>
            <Form.Group controlId="name">
              <Form.Label>픽업게임명</Form.Label>
              <Form.Control
                type="name"
                placeholder="픽업게임명"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="location1" className="mt-3">
          <Form.Label>지역</Form.Label>
          <Select
            options={options}
            isSearchable
            onChange={handleLocation1Change}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="location2" className="mt-3">
              <Form.Label>지역 상세</Form.Label>
              <Form.Control
                type="location2"
                placeholder="ex) 양천구"
                value={location2}
                onChange={(e) => setLocation2(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="place" className="mt-3">
              <Form.Label>장소</Form.Label>
              <Form.Control
                type="place"
                placeholder="장소"
                value={place}
                onChange={(e) => setPlace(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="gameDate" className="mt-3">
              <Form.Label>일자</Form.Label>
              <Form.Control
                type="gameDate"
                placeholder="ex) 2023-01-01"
                ref={dateRef}
                value={gameDate}
                onChange={handleDate}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="recruitCount" className="mt-3">
              <Form.Label>모집 인원</Form.Label>
              <Form.Control
                type="recruitCount"
                placeholder="모집 인원"
                value={recruitCount}
                onChange={(e) => setRecruitCount(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <CKEditor
              editor={ClassicEditor}
              data=""
              onChange={(event, editor) => {
                const data = editor.getData();
                setNotice(data);
              }}
            />
          </Col>
        </Row>
        <Button
          type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          모집 등록
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PickupGameBookingCreateScreen;