import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  playerListReducer,
  playerListConsonantReducer,
  playerListTypeReducer,
  playerDetailsReducer,
  playerSeasonRecordReducer,
  playerCompetitionRecordReducer,
  playerAllRecordReducer,
  playerInputReducer,
  playerUpdateReducer,
  playerDuplicateReducer,
  playerDeleteReducer,
  playerCompetitionListReducer,
} from "./reducers/playerReducer";
import {
  teamDetailsReducer,
  teamListReducer,
  teamListConsonantReducer,
  teamListTypeReducer,
  teamNameReducer,
  teamInputReducer,
  teamDetailsReducer1,
  teamSeasonRecordReducer,
  teamSeasonMembersRecordReducer,
  teamCompetitionRecordReducer,
  teamCompetitionListReducer,
  teamUpdateReducer,
  teamDeleteReducer
} from "./reducers/teamReducer";
import {
  competitionGameInputReducer,
  competitionGameUpdateReducer,
  competitionGameReducer,
  competitionInfoReducer,
  competitionInputReducer,
  competitionListReducer,
  detailCompetitionInfoReducer,
  competitionResultReducer,
  competitionRoundInfoReducer,
  competitionRoundGameReducer,
  competitionParticipantsReducer,
  competitionUpdateReducer,
  competitionGameOrderReducer,
  competitionStatsReducer,
  competitionRoundRankReducer,
  competitionGameAssociationInputReducer,
  teamGameListReducer,
  scheduledGameListReducer
} from "./reducers/competitionReducer";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userTeamReducer,
  userMyPageReducer,
} from "./reducers/userReducers";
import {
  noticeWriteReducer,
  noticeReadReducer,
  noticeListReducer,
  noticeUpdateReducer,
  noticeDeleteReducer,
  competitionNoticeWriteReducer,
  competitionNoticeReadReducer,
  competitionNoticeListReducer,
  competitionNoticeUpdateReducer,
  competitionNoticeDeleteReducer,
} from "./reducers/noticeReducer";
import {
  communityListReducer,
  communityReadReducer,
  communityWriteReducer,
  communityUpdateReducer,
} from "./reducers/communityReducer";
import {
  pickupCompetitionListReducer,
  pickupCompetitionDetailReducer,
  pickupCompetitionInputReducer,
  pickupCompetitionUpdateReducer,
  pickupCompetitionHostListReducer,
  pickupCompetitionStatsReducer,
  pickupGameInputReducer,
  pickupGameDetailReducer,
  pickupGameListReducer,
  pickupGameResultReducer,
  pickupPlayerHostListReducer,
  pickupPlayerHostRecordReducer,
} from "./reducers/pickupReducer";
import { associationOwnReducer } from "./reducers/associationReducer";
import {
  gameArticleDeleteReducer,
  gameArticleReadReducer,
  gameArticleUpdateReducer
} from "./reducers/gameArticleReducer";
import {
  competitionApplyDetailReducer,
  competitionApplyInputReducer,
  competitionApplyListReducer,
  competitionApplyUpdateReducer
} from "./reducers/competitionApplyReducer";

const reducer = combineReducers({
  playerList: playerListReducer,
  playerListConsonant: playerListConsonantReducer,
  playerListType: playerListTypeReducer,
  playerDetails: playerDetailsReducer,
  playerSeason: playerSeasonRecordReducer,
  playerCompetition: playerCompetitionRecordReducer,
  playerUpdate: playerUpdateReducer,
  playerDelete: playerDeleteReducer,
  playerAll: playerAllRecordReducer,
  playerCompetitionList: playerCompetitionListReducer,
  playerDuplicate: playerDuplicateReducer,
  adminPlayerInput: playerInputReducer,
  getTeam: teamNameReducer,
  teamList: teamListReducer,
  teamListConsonant: teamListConsonantReducer,
  teamListType: teamListTypeReducer,
  teamDetails: teamDetailsReducer,
  teamDetails1: teamDetailsReducer1,
  teamSeason: teamSeasonRecordReducer,
  teamSeasonMembers: teamSeasonMembersRecordReducer,
  teamCompetition: teamCompetitionRecordReducer,
  teamCompetitionList: teamCompetitionListReducer,
  teamUpdate: teamUpdateReducer,
  teamDelete: teamDeleteReducer,
  adminTeamInput: teamInputReducer,
  competitionList: competitionListReducer,
  competitionInfoList: competitionInfoReducer,
  competitionDetailInfo: detailCompetitionInfoReducer,
  competitionResultInfo: competitionResultReducer,
  competitionGame: competitionGameReducer,
  competitionRoundGame: competitionRoundGameReducer,
  competitionRoundInfo: competitionRoundInfoReducer,
  competitionParticipants: competitionParticipantsReducer,
  competitionUpdate: competitionUpdateReducer,
  competitionGameOrder: competitionGameOrderReducer,
  competitionStats: competitionStatsReducer,
  adminCompetitionInput: competitionInputReducer,
  adminCompetitionGameInput: competitionGameInputReducer,
  adminCompetitionGameUpdate: competitionGameUpdateReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userTeam: userTeamReducer,
  userMyPage: userMyPageReducer,
  noticeRead: noticeReadReducer,
  noticeWrite: noticeWriteReducer,
  noticeUpdate: noticeUpdateReducer,
  noticeList: noticeListReducer,
  noticeDelete: noticeDeleteReducer,
  competitionNoticeWrite: competitionNoticeWriteReducer,
  competitionNoticeRead: competitionNoticeReadReducer,
  competitionNoticeList: competitionNoticeListReducer,
  competitionNoticeUpdate: competitionNoticeUpdateReducer,
  competitionNoticeDelete: competitionNoticeDeleteReducer,
  competitionApplyInput: competitionApplyInputReducer,
  competitionApplyList: competitionApplyListReducer,
  competitionGameAssociationInput: competitionGameAssociationInputReducer,
  teamGameList: teamGameListReducer,
  scheduledGameList: scheduledGameListReducer,
  communityList: communityListReducer,
  communityRead: communityReadReducer,
  communityWrite: communityWriteReducer,
  communityUpdate: communityUpdateReducer,
  pickupCompetitionList: pickupCompetitionListReducer,
  pickupCompetitionDetail: pickupCompetitionDetailReducer,
  pickupCompetitionInput: pickupCompetitionInputReducer,
  pickupCompetitionUpdate: pickupCompetitionUpdateReducer,
  pickupCompetitionHostList: pickupCompetitionHostListReducer,
  pickupCompetitionStats: pickupCompetitionStatsReducer,
  pickupGameInput: pickupGameInputReducer,
  pickupGameDetail: pickupGameDetailReducer,
  pickupGameList: pickupGameListReducer,
  pickupGameResult: pickupGameResultReducer,
  pickupPlayerHostList: pickupPlayerHostListReducer,
  pickupPlayerHostRecord: pickupPlayerHostRecordReducer,
  associationOwn: associationOwnReducer,
  roundRank: competitionRoundRankReducer,
  gameArticleRead: gameArticleReadReducer,
  gameArticleUpdate: gameArticleUpdateReducer,
  gameArticleDelete: gameArticleDeleteReducer,
  competitionApplyDetail: competitionApplyDetailReducer,
  competitionApplyInput: competitionApplyInputReducer,
  competitionApplyList: competitionApplyListReducer,
  competitionApplyUpdate: competitionApplyUpdateReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = { userLogin: { userInfo: userInfoFromStorage } };

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
