import React, { useState, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { competitionInput } from "../actions/competitionActions";
import { accessControl } from "../utils/access";
import optionsCompetitionTeamType from "../static/optionsCompetitionTeamType";
import optionsCategory from "../static/optionsCategory";
import optionsDivision from "../static/optionsDivision";

const TempCompetitionInputScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [place, setPlace] = useState("");
  const [teamType, setTeamType] = useState("");
  const [category, setCategory] = useState(false);
  const [division, setDivision] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState("");
  // 경기 시작 하이픈 자동 생성
  const [startDate, setStartDate] = useState("");
  const startRef = useRef();

  const [isThree, setIsThree] = useState(false);

  useEffect(() => {
    accessControl([99, 100, 300], history);
  }, [dispatch]);

  const handleStart = (e) => {
    const value = startRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    startRef.current.value = result;
    setStartDate(e.target.value);
  };

  // 경기 종료일 하이픈 자동 생성
  const [endDate, setEndDate] = useState("");
  const endRef = useRef();

  const handleEnd = (e) => {
    const value = endRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    endRef.current.value = result;
    setEndDate(e.target.value);
  };

  const adminCompetitionInput = useSelector(
    (state) => state.adminCompetitionInput
  );
  const { loading, error } = adminCompetitionInput;

  const submitHandler = (e) => {
    e.preventDefault();
    
    const places = place.split(',');
    for (let i = 0; i < places.length; i++) {
      if (places[i][0] === ' ') { places[i] = places[i].slice(1) }
      if (places[i][places[i].length - 1] === ' ') { places[i] = places[i].slice(0, -1) }
    }

    dispatch(
      competitionInput({
        name: name,
        host: host,
        place: places,
        teamType: teamType,
        startDate: startDate,
        endDate: endDate,
        division: division,
        isThree: isThree,
        category: category,
        additionalInfo: additionalInfo,
      })
    );
  };

  return (
    <FormContainer>
      <h1>대회 / 리그 생성</h1>
      {error && (
        <Message variant="danger">
          대회 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Form.Group controlId="name">
          <Form.Label>대회명</Form.Label>
          <Form.Control
            type="name"
            placeholder="대회명"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        
        <p className="mt-3">경기 종류</p>
        <Form.Check
          label="5대5"
          type="radio"
          inline
          onChange={() => setIsThree(false)}
          name="group2"
          id={"2"}
        />
        <Form.Check
          label="3대3"
          type="radio"
          inline
          onChange={() => setIsThree(true)}
          name="group2"
          id={"2"}
        />
        <Form.Group controlId="teamType" className="mt-2">
          <Form.Label>카테고리</Form.Label>
          <Select
            options={optionsCategory}
            onChange={(e) => setCategory(e.value)}
          />
        </Form.Group>

        <Form.Group controlId="host" className="mt-2">
          <Form.Label>대회 개최자</Form.Label>
          <Form.Control
            type="host"
            placeholder="대회 개최자"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="place" className="mt-2">
          <Form.Label>개최지</Form.Label>
          <Form.Control
            type="name"
            placeholder="쉼표(,)로 구분해서 여러 장소 입력 가능. 예) 와동체육관,선부체육관"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="teamType" className="mt-2">
          <Form.Label>종별</Form.Label>
          <Select
            options={optionsCompetitionTeamType}
            onChange={(e) => setTeamType(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="additionalInfo" className="mt-2">
          <Form.Label>추가 정보</Form.Label>
          <Form.Control
            type="text"
            placeholder="종별 옆에 표시됩니다."
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="division" className="mt-2">
          <Form.Label>디비전</Form.Label>
          <Select
            isDisabled={!["Time4Division", "NonguLab"].includes(category)}
            options={optionsDivision}
            value={optionsDivision && optionsDivision[0]}
            onChange={(e) => setDivision(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="startDate" className="mt-2">
          <Form.Label>대회 시작일</Form.Label>
          <Form.Control
            type="startDate"
            placeholder="예) 2022-01-01"
            ref={startRef}
            value={startDate}
            onChange={handleStart}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="endDate" className="mt-2">
          <Form.Label>대회 종료일</Form.Label>
          <Form.Control
            type="endDate"
            placeholder="예) 2022-01-31"
            ref={endRef}
            value={endDate}
            onChange={handleEnd}
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          대회 등록
        </Button>
      </Form>
    </FormContainer>
  );
};

export default TempCompetitionInputScreen;