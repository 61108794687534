import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { API_SERVER_URL } from "../actions/actionUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  competitionGameUpdate,
  detailCompetitionInfo,
} from "../actions/competitionActions";
import FormContainer from "../components/FormContainer";
import {
  teamDetailsInfo,
  teamDetailsInfo1,
} from "../actions/teamActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  COMPETITION_INFO_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";

export const GamePlayersManageScreen = ({ match }) => {
  const gameId = match.params.id;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const dispatch = useDispatch();

  const competitionDetailInfo = useSelector(
    (state) => state.competitionDetailInfo
  );
  const { loading, error, competitionDetail } = competitionDetailInfo;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamDetails1 = useSelector((state) => state.teamDetails1);
  const {
    loading: loadingTeamDetails1,
    error: errorTeamDetails1,
    team1,
  } = teamDetails1;

  const indexOfMyTeam = (game) => {
    if (userInfo) {
      for (let i of game.vsTeamMaster) {
        if (i.includes(userInfo._id)) {
          return game.vsTeamMaster.indexOf(i);
        }
      }
    }
    return false;
  };

  useEffect(() => {
    dispatch({ type: COMPETITION_INFO_RESET });
    if (!team) {
      dispatch(teamDetailsInfo(competitionDetail && competitionDetail.vsTeam[0]));
    }
    if (!team1) {
      dispatch(teamDetailsInfo1(competitionDetail && competitionDetail.vsTeam[1]));
    }

    if (!competitionDetail) {
      dispatch(detailCompetitionInfo(gameId));
    }

    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, gameId, competitionDetail, team, team1]);

  const checkAllandSetJerseyNum0 = () => {
    const teamLength = team && team.members.length;
    const team1Length = team1 && team1.members.length;

    for (let i = 0; i < teamLength; i++) {
      document.getElementById(`isPlaying${i}_h`).checked = true;
      document.getElementById(`jerseyNum${i}_h`).value = 0;
    }

    for (let i = 0; i < team1Length; i++) {
      document.getElementById(`isPlaying${i}_a`).checked = true;
      document.getElementById(`jerseyNum${i}_a`).value = 0;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const playerList = [[], []];
    const teamLength = team && team.members.length;
    const team1Length = team1 && team1.members.length;

    for (let i = 0; i < teamLength; i++) {
      if (document.getElementById(`isPlaying${i}_h`).checked === true) {
        playerList[0].push({
          name: team && team.memberName.sort()[i],
          playerId: team && team.memberInfo.find((member) => member.name === team.memberName.sort()[i]).playerId,
          jerseyNum: document.getElementById(`jerseyNum${i}_h`).value,
        });
      }
    }

    for (let i = 0; i < team1Length; i++) {
      if (document.getElementById(`isPlaying${i}_a`).checked === true) {
        playerList[1].push({
          name: team1 && team1.memberName.sort()[i],
          playerId: team1 && team1.memberInfo.find((member) => member.name === team1.memberName.sort()[i]).playerId,
          jerseyNum: document.getElementById(`jerseyNum${i}_a`).value,
        });
      }
    }

    dispatch(competitionGameUpdate({
      _id: gameId,
      gameDate: competitionDetail.gameDate,
      competitionId: competitionDetail.competitionId,
      teamType: competitionDetail.teamType,
      gamePlace: competitionDetail.gamePlace,
      gameNum: competitionDetail.gameNum,
      gameName: competitionDetail.gameName,
      round: competitionDetail.round,
      roundNum: competitionDetail.roundNum,
      vsTeam: competitionDetail.vsTeam,
      gameResult: competitionDetail.gameResult,
      quarter: competitionDetail.quarter,
      overtime: competitionDetail.overtime,
      playerRecord: competitionDetail.playerRecord,
      playerList: playerList,
      image: competitionDetail.image,
    }));

  };

  return (
    <>
      {loading && team ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <FormContainer>
            <h1>출전 선수 등록</h1>
            <Button
              variant="danger"
              onClick={checkAllandSetJerseyNum0}
            >
              전체 체크/등번호 0으로 초기화
            </Button>
            {error && (
              <Message variant="출전 선수 등록에 실패하였습니다. 정보 확인 부탁드립니다."></Message>
            )}
            {loading && <Loader />}
          </FormContainer>
          {userInfo && userInfo.isAdmin ? (
            <>
              <h3 className="mt-5 mx-5">{competitionDetail && competitionDetail.vsTeamName[0]} 팀</h3>
              <table style={{ borderCollapse: "collapse", width: "70%" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>출전</th>
                    <th style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>이름</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>등번호</th>
                  </tr>
                </thead>
                <tbody>
                  {team && team.memberName.sort().map((name, index) => (
                    <tr key={name}>
                      <td style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>
                        <input
                          id={`isPlaying${index}_h`}
                          type="checkbox"
                          onChange={() => document.getElementById(`jerseyNum${index}_h`).disabled = !document.getElementById(`jerseyNum${index}_h`).disabled}
                          style={{ width: "40px", height: "40px" }}
                          defaultChecked={competitionDetail && competitionDetail.playerList[0].find((player) => player.name === name)}
                        />
                      </td>
                      <td style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>{name}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        <input
                          id={`jerseyNum${index}_h`}
                          disabled={competitionDetail && !competitionDetail.playerList[0].find((player) => player.name === name)}
                          type="text"
                          style={{ width: "100%", padding: "4px", height: "40px" }}
                          defaultValue={competitionDetail && competitionDetail.playerList[0].find((player) => player.name === name) && competitionDetail.playerList[0].find((player) => player.name === name).jerseyNum}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3 className="mt-5 mx-5">{competitionDetail && competitionDetail.vsTeamName[1]} 팀</h3>
              <table style={{ borderCollapse: "collapse", width: "70%" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>출전</th>
                    <th style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>이름</th>
                    <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>등번호</th>
                  </tr>
                </thead>
                <tbody>
                  {team1 && team1.memberName.sort().map((name, index) => (
                    <tr key={name}>
                      <td style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>
                        <input
                          id={`isPlaying${index}_a`}
                          type="checkbox"
                          onChange={() => document.getElementById(`jerseyNum${index}_a`).disabled = !document.getElementById(`jerseyNum${index}_a`).disabled}
                          style={{ width: "40px", height: "40px" }}
                          defaultChecked={competitionDetail && competitionDetail.playerList[1].find((player) => player.name === name) ? true : false}
                        />
                      </td>
                      <td style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>{name}</td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        <input
                          id={`jerseyNum${index}_a`}
                          disabled={competitionDetail && !competitionDetail.playerList[1].find((player) => player.name === name)}
                          type="text"
                          style={{ width: "100%", padding: "4px", height: "40px" }}
                          defaultValue={competitionDetail && competitionDetail.playerList[1].find((player) => player.name === name) && competitionDetail.playerList[1].find((player) => player.name === name).jerseyNum}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {competitionDetail && indexOfMyTeam(competitionDetail) === 0 && (
                <>
                  <h3 className="mt-5 mx-5">{competitionDetail && competitionDetail.vsTeamName[0]} 팀</h3>
                  <table style={{ borderCollapse: "collapse", width: "70%" }}>
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>출전</th>
                        <th style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>이름</th>
                        <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>등번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team && team.memberName.sort().map((name, index) => (
                        <tr key={name}>
                          <td style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>
                            <input
                              id={`isPlaying${index}_h`}
                              type="checkbox"
                              onChange={() => document.getElementById(`jerseyNum${index}_h`).disabled = !document.getElementById(`jerseyNum${index}_h`).disabled}
                              style={{ width: "40px", height: "40px" }}
                              defaultChecked={competitionDetail && competitionDetail.playerList[0].find((player) => player.name === name)}
                            />
                          </td>
                          <td style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>{name}</td>
                          <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                            <input
                              id={`jerseyNum${index}_h`}
                              disabled={competitionDetail && !competitionDetail.playerList[0].find((player) => player.name === name)}
                              type="text"
                              style={{ width: "100%", padding: "4px", height: "40px" }}
                              defaultValue={competitionDetail && competitionDetail.playerList[0].find((player) => player.name === name) && competitionDetail.playerList[0].find((player) => player.name === name).jerseyNum}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {competitionDetail && indexOfMyTeam(competitionDetail) === 1 && (
                <>
                  <h3 className="mt-5 mx-5">{competitionDetail && competitionDetail.vsTeamName[1]} 팀</h3>
                  <table style={{ borderCollapse: "collapse", width: "70%" }}>
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>출전</th>
                        <th style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>이름</th>
                        <th style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>등번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team1 && team1.memberName.sort().map((name, index) => (
                        <tr key={name}>
                          <td style={{ border: "1px solid #ccc", padding: "8px", textAlign: "center" }}>
                            <input
                              id={`isPlaying${index}_a`}
                              type="checkbox"
                              onChange={() => document.getElementById(`jerseyNum${index}_a`).disabled = !document.getElementById(`jerseyNum${index}_a`).disabled}
                              style={{ width: "40px", height: "40px" }}
                              defaultChecked={competitionDetail && competitionDetail.playerList[1].find((player) => player.name === name) ? true : false}
                            />
                          </td>
                          <td style={{ border: "1px solid #ccc", padding: "18px", textAlign: "center" }}>{name}</td>
                          <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                            <input
                              id={`jerseyNum${index}_a`}
                              disabled={competitionDetail && !competitionDetail.playerList[1].find((player) => player.name === name)}
                              type="text"
                              style={{ width: "100%", padding: "4px", height: "40px" }}
                              defaultValue={competitionDetail && competitionDetail.playerList[1].find((player) => player.name === name) && competitionDetail.playerList[1].find((player) => player.name === name).jerseyNum}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}

          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            style={{ width: "100%" }}
            onClick={submitHandler}
          >
            출전 선수 등록
          </Button>
        </>
      )}
    </>
  );
};

export default GamePlayersManageScreen;