import { trusted } from "mongoose";
import {
  COMPETITION_INFO_REQUEST,
  COMPETITION_INFO_SUCCESS,
  COMPETITION_INFO_FAIL,
  COMPETITION_INFO_RESET,
  COMPETITION_LIST_FAIL,
  COMPETITION_LIST_REQUEST,
  COMPETITION_LIST_SUCCESS,
  COMPETITION_LIST_RESET,
  COMPETITION_RESULT_REQUEST,
  COMPETITION_RESULT_SUCCESS,
  COMPETITION_RESULT_FAIL,
  COMPETITION_GAME_REQUEST,
  COMPETITION_GAME_SUCCESS,
  COMPETITION_GAME_FAIL,
  COMPETITION_GAME_RESET,
  COMPETITION_INPUT_REQUEST,
  COMPETITION_INPUT_SUCCESS,
  COMPETITION_INPUT_FAIL,
  COMPETITION_ASSOCIATION_INPUT_REQUEST,
  COMPETITION_ASSOCIATION_INPUT_SUCCESS,
  COMPETITION_ASSOCIATION_INPUT_FAIL,
  COMPETITION_GAME_INPUT_REQUEST,
  COMPETITION_GAME_INPUT_SUCCESS,
  COMPETITION_GAME_INPUT_FAIL,
  COMPETITION_GAME_UPDATE_REQUEST,
  COMPETITION_GAME_UPDATE_SUCCESS,
  COMPETITION_GAME_UPDATE_FAIL,
  COMPETITION_ROUNDINFO_REQUEST,
  COMPETITION_ROUNDINFO_SUCCESS,
  COMPETITION_ROUNDINFO_FAIL,
  COMPETITION_ROUNDINFO_RESET,
  COMPETITION_ROUNDGAME_REQUEST,
  COMPETITION_ROUNDGAME_SUCCESS,
  COMPETITION_ROUNDGAME_FAIL,
  COMPETITION_ROUNDGAME_RESET,
  COMPETITION_PARTICIPANTS_REQUEST,
  COMPETITION_PARTICIPANTS_SUCCESS,
  COMPETITION_PARTICIPANTS_FAIL,
  COMPETITION_UPDATE_REQUEST,
  COMPETITION_UPDATE_SUCCESS,
  COMPETITION_UPDATE_FAIL,
  COMPETITION_DETAIL_INFO_REQUEST,
  COMPETITION_DETAIL_INFO_SUCCESS,
  COMPETITION_DETAIL_INFO_FAIL,
  COMPETITION_DETAIL_INFO_RESET,
  COMPETITION_GAME_ORDER_REQUEST,
  COMPETITION_GAME_ORDER_SUCCESS,
  COMPETITION_GAME_ORDER_FAIL,
  COMPETITION_STATS_REQUEST,
  COMPETITION_STATS_SUCCESS,
  COMPETITION_STATS_FAIL,
  COMPETITION_STATS_RESET,
  COMPETITION_ROUND_RANK_REQUEST,
  COMPETITION_ROUND_RANK_SUCCESS,
  COMPETITION_ROUND_RANK_FAIL,
  COMPETITION_ROUND_RANK_RESET,
  TEAM_GAME_LIST_REQUEST,
  TEAM_GAME_LIST_SUCCESS,
  TEAM_GAME_LIST_FAIL,
  TEAM_GAME_LIST_RESET,
  SCHEDULED_GAME_LIST_REQUEST,
  SCHEDULED_GAME_LIST_SUCCESS,
  SCHEDULED_GAME_LIST_FAIL,
  SCHEDULED_GAME_LIST_RESET,
} from "../constants/competitionConstants";

export const competitionListReducer = (state = { competition: [] }, action) => {
  switch (action.type) {
    case COMPETITION_LIST_REQUEST:
      return { loading: true };
    case COMPETITION_LIST_SUCCESS:
      return {
        loading: false,
        competitions: action.payload.competitions,
        // competitions: [...state.competitions, action.payload].sort((a, b) => {
        //   return a.gameNum - b.gameNum;
        // }),
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case COMPETITION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_LIST_RESET:
      return { competitions: [] };
    default:
      return state;
  }
};

export const competitionRoundInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_ROUNDINFO_REQUEST:
      return { loading: true };
    case COMPETITION_ROUNDINFO_SUCCESS:
      return {
        loading: false,
        roundInfo: action.payload,
      };
    case COMPETITION_ROUNDINFO_FAIL:
      return { loading: false, error: action.payload };

    // competition: [...state.competition, action.payload].sort((a, b) => {
    //   return a.gameNum - b.gameNum;
    case COMPETITION_ROUNDINFO_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionRoundGameReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_ROUNDGAME_REQUEST:
      return { loading: true };
    case COMPETITION_ROUNDGAME_SUCCESS:
      return {
        loading: false,
        roundGame: action.payload,
      };
    case COMPETITION_ROUNDGAME_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_ROUNDGAME_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionInfoReducer = (state = { competition: [] }, action) => {
  switch (action.type) {
    case COMPETITION_INFO_REQUEST:
      return { loading: true, ...state };
    case COMPETITION_INFO_SUCCESS:
      return {
        loading: false,
        competition: [...state.competition, action.payload].sort((a, b) => {
          return a.gameNum - b.gameNum;
        }),
      };
    case COMPETITION_INFO_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_INFO_RESET:
      return { competition: [] };
    default:
      return state;
  }
};

export const detailCompetitionInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_DETAIL_INFO_REQUEST:
      return { loading: true };
    case COMPETITION_DETAIL_INFO_SUCCESS:
      return {
        loading: false,
        competitionDetail: action.payload,
      };
    case COMPETITION_DETAIL_INFO_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_DETAIL_INFO_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionResultReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case COMPETITION_RESULT_REQUEST:
      return { loading: true, ...state };
    case COMPETITION_RESULT_SUCCESS:
      return { loading: false, result: action.payload };
    case COMPETITION_RESULT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionGameReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case COMPETITION_GAME_REQUEST:
      return { loading: true, ...state };
    case COMPETITION_GAME_SUCCESS:
      return { loading: false, competition: action.payload };
    case COMPETITION_GAME_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_GAME_RESET:
      return { competition: [] };
    default:
      return state;
  }
};

export const competitionInputReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_INPUT_REQUEST:
      return { loading: true };
    case COMPETITION_INPUT_SUCCESS:
      return { loading: false };
    case COMPETITION_INPUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionGameInputReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_GAME_INPUT_REQUEST:
      return { loading: true };
    case COMPETITION_GAME_INPUT_SUCCESS:
      return { loading: false };
    case COMPETITION_GAME_INPUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionGameUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_GAME_UPDATE_REQUEST:
      return { loading: true };
    case COMPETITION_GAME_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        competitionDetail: action.payload,
      };
    case COMPETITION_GAME_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionParticipantsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_PARTICIPANTS_REQUEST:
      return { loading: true };
    case COMPETITION_PARTICIPANTS_SUCCESS:
      return { loading: false, competition: action.payload };
    case COMPETITION_PARTICIPANTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionUpdateReducer = (
  state = { competition: {} },
  action
) => {
  switch (action.type) {
    case COMPETITION_UPDATE_REQUEST:
      return { loading: true };
    case COMPETITION_UPDATE_SUCCESS:
      alert("[성공] 정상적으로 정보가 업데이트 되었습니다.");
      return { loading: false, success: true, competition: action.payload };
    case COMPETITION_UPDATE_FAIL:
      alert(`[오류] 업데이트에 실패하였습니다. ${action.payload}`);
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionGameOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_GAME_ORDER_REQUEST:
      return { loading: true };
    case COMPETITION_GAME_ORDER_SUCCESS:
      return { loading: false };
    case COMPETITION_GAME_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_STATS_REQUEST:
      return { loading: true };
    case COMPETITION_STATS_SUCCESS:
      return { loading: false, stats: action.payload };
    case COMPETITION_STATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionRoundRankReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_ROUND_RANK_REQUEST:
      return { loading: true };
    case COMPETITION_ROUND_RANK_SUCCESS:
      return {
        loading: false,
        ranking: action.payload,
      };
    case COMPETITION_ROUND_RANK_FAIL:
      return { loading: false, error: action.payload };

    case COMPETITION_ROUND_RANK_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionGameAssociationInputReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_ASSOCIATION_INPUT_REQUEST:
      return { loading: true };
    case COMPETITION_ASSOCIATION_INPUT_SUCCESS:
      return { loading: false, success: true };
    case COMPETITION_ASSOCIATION_INPUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamGameListReducer = (state = { games: [] }, action) => {
  switch (action.type) {
    case TEAM_GAME_LIST_REQUEST:
      return { loading: true };
    case TEAM_GAME_LIST_SUCCESS:
      return { loading: false, games: action.payload };
    case TEAM_GAME_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_GAME_LIST_RESET:
      return { games: [] };
    default:
      return state;
  }
}

export const scheduledGameListReducer = (state = { scheduledGames: [] }, action) => {
  switch (action.type) {
    case SCHEDULED_GAME_LIST_REQUEST:
      return { loading: true };
    case SCHEDULED_GAME_LIST_SUCCESS:
      return { loading: false, scheduledGames: action.payload };
    case SCHEDULED_GAME_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SCHEDULED_GAME_LIST_RESET:
      return { games: [] };
    default:
      return state;
  }
}