import React, { useEffect, useState } from "react";
import { Table, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import YouTube from "react-youtube";
import ReactHtmlParser from "react-html-parser";
import { detailCompetitionInfo, listCompetitionInfo } from "../actions/competitionActions";
import {
  COMPETITION_LIST_RESET,
  COMPETITION_INFO_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";
import PlayerName from "../components/PlayerName";
import { readGameArticle } from "../actions/gameArticleActions";

const VideoDetailScreen = ({ match }) => {
  const gameId = match.params.id;

  const dispatch = useDispatch();

  const [videoPlayer, setVideoPlayer] = useState(null);

  const onReady = (event) => {
    setVideoPlayer(event.target);
  };

  const seekToTime = (time) => {
    if (videoPlayer) {
      videoPlayer.seekTo(time);
    }
  };

  const competitionInfoList = useSelector((state) => state.competitionInfoList);
  const { loading: loadingCompetition, error: errorCompetition, competition } = competitionInfoList;

  const competitionDetailInfo = useSelector(
    (state) => state.competitionDetailInfo
  );
  const { loading, error, competitionDetail } = competitionDetailInfo;

  const gameArticleRead = useSelector((state) => state.gameArticleRead);
  const { article } = gameArticleRead;

  useEffect(() => {
    dispatch({ type: COMPETITION_INFO_RESET });
    dispatch(detailCompetitionInfo(gameId));
    dispatch(listCompetitionInfo(gameId));
    dispatch(readGameArticle(gameId));
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, gameId]);

  const reduceLength =
    competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .length;

  const bestScore = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.score > current.score ? prev : current;
    }).score
    : 0;

  const bestScorePlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.score === bestScore;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const opts = {
    width: "1000",
    height: "562",
    playerVars: {
      autoplay: 0, // 자동재생 유무
      rel: 0, // 관련 영상 X
      start: competitionDetail && competitionDetail.videoStartTime,
    },
  };

  const opts_mobile = {
    width: "350",
    height: "200",
    playerVars: {
      autoplay: 0, // 자동재생 유무
      rel: 0, // 관련 영상 X
      start: competitionDetail && competitionDetail.videoStartTime,
    },
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          영상을 불러오는데 실패하였습니다. <br />
          새로고침을 한번 해주시고, 지속적으로 에러 발생시 문의 부탁드립니다.
        </Message>
      ) : (
        <div className="text-center">
          <h1>{competitionDetail && competitionDetail.gameName}</h1>
          <h5>
            경기일{" "}
            {competitionDetail && competitionDetail.gameDate.substring(0, 10)}
          </h5>
          <div className="video__record__flex">
            <div>
              {competitionDetail &&
                competitionDetail.vsTeamImage[0] === "" ? (
                <Image
                  src={"/team_default_img.png"}
                  className="video__imageStyle"
                  alt={competitionDetail && competitionDetail.vsTeamName[0]}
                  fluid
                />
              ) : (
                <Image
                  src={competitionDetail && competitionDetail.vsTeamImage[0]}
                  className="video__imageStyle"
                  alt={competitionDetail && competitionDetail.vsTeamName[0]}
                  fluid
                />
              )}
              <h3>{competitionDetail && competitionDetail.vsTeamName[0]}</h3>
              <h2>{competitionDetail && competitionDetail.gameResult[0]}</h2>
            </div>
            <div>
              <h1>VS</h1>
            </div>
            <div>
              {competitionDetail &&
                competitionDetail.vsTeamImage[1] === "" ? (
                <Image
                  src={"/team_default_img.png"}
                  className="video__imageStyle"
                  alt={competitionDetail && competitionDetail.vsTeamName[1]}
                  fluid
                />
              ) : (
                <Image
                  src={competitionDetail && competitionDetail.vsTeamImage[1]}
                  className="video__imageStyle"
                  alt={competitionDetail && competitionDetail.vsTeamName[1]}
                  fluid
                />
              )}
              <h3>{competitionDetail && competitionDetail.vsTeamName[1]}</h3>
              <h2>{competitionDetail && competitionDetail.gameResult[1]}</h2>
            </div>
          </div>
          <span className="on__pc__info">
            <YouTube
              videoId={competitionDetail && competitionDetail.videoId}
              opts={opts}
              onReady={onReady}
            />
            <Button
              variant="outline-primary"
              onClick={() => {
                seekToTime(competitionDetail && competitionDetail.videoStartTime);
              }}
            >
              경기 시작 시간으로 이동
            </Button>
            <p>해당 경기를 바로 보시려면, 눌러주세요</p>
          </span>
          <span className="on__mobile__info">
            <YouTube
              videoId={competitionDetail && competitionDetail.videoId}
              opts={opts_mobile}
            />
            <Button
              variant="outline-primary"
              onClick={() => {
                seekToTime(competitionDetail && competitionDetail.videoStartTime);
              }}
            >
              경기 시작 시간으로 이동
            </Button>
          </span>
        </div>
      )}
      <hr />
      {article && article.content === "" ? (
        <>
          <h2 className="mt-3 text-center">경기 후기</h2>
          <div className="post__content">{ReactHtmlParser(article && article.content)}</div>
          <hr />
        </>
      ) : (
        ""
      )}
      <h2 className="mt-3 text-center">경기 상세 기록</h2>
      <p
        className="text-center"
        style={{ color: "#011241", "font-weight": "600" }}
      >
        선수명을 클릭하면, 개인기록 조회가 가능합니다.
      </p>
      <h3 className="mt-4">
        {competition[0] && competition[0].vsTeamName[0]} 기록
      </h3>
      <Table
        striped
        hover
        responsive
        className="table-sm text-center table__padding__sm"
      >
        <thead>
          <tr className="table__header">
            <th>
              <div
                className="hideextra"
                style={{
                  width: "35px",
                }}
              >
                🏆️
              </div>
            </th>
            <th
              style={{
                position: "sticky",
                left: "0",
                "z-index": "2",
                "background-color": "#031b31",
              }}
            >
              <div
                className="hideextra"
                style={{
                  "min-width": "50px",
                  textAlign: "left",
                }}
              >
                이름
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "30px",
                }}
              >
                득점
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                2점
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                야투율
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                3점
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "30px",
                }}
              >
                3점
                <br /> 성공률
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                자유투
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "35px",
                }}
              >
                자유투
                <br />
                성공율
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                공격
                <br /> 리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                수비
                <br /> 리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                총<br />
                리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                어시스트
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                스틸
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                블락
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                턴오버
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                파울
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {competition[0] &&
            competition[0].playerRecord[0].map((player) => (
              <tr key={player._id}>
                <td style={{ padding: 0, width: "30px" }}>
                  {bestScorePlayerIdArray.includes(player.playerId) && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0,
                          marginTop: "-7px",
                          color: "#d63f04",
                          fontWeight: "700",
                        }}
                      >
                        득점
                      </p>
                    </span>
                  )}
                  {/* {bestAssistPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            어시
                          </p>
                        </span>
                      )}
                      {bestStealPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            스틸
                          </p>
                        </span>
                      )}
                      {bestReboundPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            리바
                          </p>
                        </span>
                      )}
                      {bestBlockPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            블락
                          </p>
                        </span>
                      )} */}
                  {player.isMVP && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "20px", margin: 0 }}>🏆</p>
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0,
                          marginTop: "-7px",
                          color: "#d63f04",
                          fontWeight: "700",
                        }}
                      >
                        MVP
                      </p>
                    </span>
                  )}
                </td>
                <td
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "1",
                    "background-color": "white",
                    paddingRight: 0,
                    paddingLeft: 0,
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      textAlign: "left",
                    }}
                  >
                    <PlayerName key={player._id} id={player.playerId} />
                    {player.jerseyNum ? (
                      <span
                        style={{
                          fontSize: "10px",
                          color: "#031b31",
                          fontWeight: "700",
                        }}
                      >
                        {player.jerseyNum}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{player.score}</td>
                <td>
                  {player.fieldGoal[1]} - {player.fieldGoal[0]}
                </td>
                <td>
                  {player.fieldGoal[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                    )}
                  %
                </td>
                <td>
                  {player.threePointThrow[1]} - {player.threePointThrow[0]}
                </td>
                <td>
                  {player.threePointThrow[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.threePointThrow[1] /
                        player.threePointThrow[0]) *
                      100
                    )}
                  %
                </td>
                <td>
                  {player.freeThrow[1]} - {player.freeThrow[0]}
                </td>
                <td>
                  {player.freeThrow[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.freeThrow[1] / player.freeThrow[0]) * 100
                    )}
                  %
                </td>
                <td>{player.rebound[0]}</td>
                <td>{player.rebound[1]}</td>
                <td>{player.rebound[0] + player.rebound[1]}</td>
                <td>{player.assist}</td>
                <td>{player.steal}</td>
                <td>{player.block}</td>
                <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                <td>{player.foul[0] + player.foul[1]}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      <h3 className="mt-3">
        {competition[0] && competition[0].vsTeamName[1]} 기록
      </h3>
      <Table
        striped
        hover
        responsive
        className="table-sm text-center table__padding__sm"
      >
        <thead>
          <tr className="table__header">
            <th>
              <div
                className="hideextra"
                style={{
                  width: "35px",
                }}
              >
                🏆️
              </div>
            </th>
            <th
              style={{
                position: "sticky",
                left: "0",
                "z-index": "2",
                "background-color": "#031b31",
              }}
            >
              <div
                className="hideextra"
                style={{
                  "min-width": "50px",
                  textAlign: "left",
                }}
              >
                이름
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "30px",
                }}
              >
                득점
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                2점
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                야투율
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                3점
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "30px",
                }}
              >
                3점
                <br /> 성공률
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                자유투
                <br /> 성공-시도
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "35px",
                }}
              >
                자유투
                <br />
                성공율
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                공격
                <br /> 리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                수비
                <br /> 리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                총<br />
                리바운드
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "40px",
                }}
              >
                어시스트
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                스틸
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                블락
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                턴오버
              </div>
            </th>
            <th>
              <div
                className="hideextra"
                style={{
                  "min-width": "25px",
                }}
              >
                파울
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {competition[0] &&
            competition[0].playerRecord[1].map((player) => (
              <tr key={player._id}>
                <td style={{ padding: 0, width: "30px" }}>
                  {bestScorePlayerIdArray.includes(player.playerId) && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0,
                          marginTop: "-7px",
                          color: "#d63f04",
                          fontWeight: "700",
                        }}
                      >
                        득점
                      </p>
                    </span>
                  )}
                  {/* {bestAssistPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            어시
                          </p>
                        </span>
                      )}
                      {bestStealPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            스틸
                          </p>
                        </span>
                      )}
                      {bestReboundPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            리바
                          </p>
                        </span>
                      )}
                      {bestBlockPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            블락
                          </p>
                        </span>
                      )} */}
                  {player.isMVP && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "20px", margin: 0 }}>🏆</p>
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0,
                          marginTop: "-7px",
                          color: "#d63f04",
                          fontWeight: "700",
                        }}
                      >
                        MVP
                      </p>
                    </span>
                  )}
                </td>
                <td
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "1",
                    "background-color": "white",
                    paddingRight: 0,
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ margin: "auto", textAlign: "left" }}>
                    <PlayerName key={player._id} id={player.playerId} />
                    {player.jerseyNum ? (
                      <span
                        style={{
                          fontSize: "10px",
                          color: "#031b31",
                          fontWeight: "700",
                        }}
                      >
                        {player.jerseyNum}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{player.score}</td>
                <td>
                  {player.fieldGoal[1]} - {player.fieldGoal[0]}
                </td>
                <td>
                  {player.fieldGoal[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                    )}
                  %
                </td>
                <td>
                  {player.threePointThrow[1]} - {player.threePointThrow[0]}
                </td>
                <td>
                  {player.threePointThrow[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.threePointThrow[1] /
                        player.threePointThrow[0]) *
                      100
                    )}
                  %
                </td>
                <td>
                  {player.freeThrow[1]} - {player.freeThrow[0]}
                </td>
                <td>
                  {player.freeThrow[1] === 0
                    ? 0
                    : Math.ceil(
                      (player.freeThrow[1] / player.freeThrow[0]) * 100
                    )}
                  %
                </td>
                <td>{player.rebound[0]}</td>
                <td>{player.rebound[1]}</td>
                <td>{player.rebound[0] + player.rebound[1]}</td>
                <td>{player.assist}</td>
                <td>{player.steal}</td>
                <td>{player.block}</td>
                <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                <td>{player.foul[0] + player.foul[1]}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default VideoDetailScreen;