import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { listPickupCompetitionDetail, updatePickupCompetition } from "../actions/pickupActions";
import { Button, Table } from "react-bootstrap";
import { PICKUP_COMPETITION_DETAIL_RESET } from "../constants/pickupConstants";

const PickupGameBookingDetailScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const pickupCompetitionDetail = useSelector((state) => state.pickupCompetitionDetail);
  const { loading, error, pickupCompetition } = pickupCompetitionDetail;

  const pickupCompetitionUpdate = useSelector((state) => state.pickupCompetitionUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = pickupCompetitionUpdate;


  const locationNumToString = (location) => {
    switch (location) {
      case 0:
        return "서울";
      case 1:
        return "경기도";
      case 2:
        return "강원도";
      case 3:
        return "충청도";
      case 4:
        return "전라도";
      case 5:
        return "경상도";
    }
  }

  const dayOfWeekToString = (dayOfWeek) => {
    switch (dayOfWeek) {
      case 0:
        return "일";
      case 1:
        return "월";
      case 2:
        return "화";
      case 3:
        return "수";
      case 4:
        return "목";
      case 5:
        return "금";
      case 6:
        return "토";
    }
  }

  const isFull = (pickupCompetition && pickupCompetition.recruitCount) <= (pickupCompetition && pickupCompetition.applyQueue && pickupCompetition.applyQueue.length);

  const isApplied = (id) => {
    if (pickupCompetition && pickupCompetition.applyQueue.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  const recruitHandler = () => {
    dispatch(
      updatePickupCompetition({
        _id: id,
        name: pickupCompetition.name,
        host: pickupCompetition.host,
        hostId: pickupCompetition.hostId,
        location1: pickupCompetition.location1,
        location2: pickupCompetition.location2,
        place: pickupCompetition.place,
        gameDate: pickupCompetition.gameDate,
        group: pickupCompetition.group,
        isRecruiting: !pickupCompetition.isRecruiting,
        recruitCount: pickupCompetition.recruitCount,
        notice: pickupCompetition.notice,
        applyQueue: pickupCompetition.applyQueue
      }));
  };

  const applyHandler = () => {
    let applyQueue = pickupCompetition.applyQueue;
    if (!applyQueue.includes(userInfo._id)) {
      applyQueue.push(userInfo._id);
    }

    dispatch(
      updatePickupCompetition({
        _id: id,
        name: pickupCompetition.name,
        host: pickupCompetition.host,
        hostId: pickupCompetition.hostId,
        location1: pickupCompetition.location1,
        location2: pickupCompetition.location2,
        place: pickupCompetition.place,
        gameDate: pickupCompetition.gameDate,
        group: pickupCompetition.group,
        isRecruiting: pickupCompetition.isRecruiting,
        recruitCount: pickupCompetition.recruitCount,
        notice: pickupCompetition.notice,
        applyQueue: applyQueue
      }));

    alert("신청되었습니다.");
    window.location.reload();
  };

  const unapplyHandler = () => {
    let applyQueue = pickupCompetition.applyQueue;
    if (applyQueue.includes(userInfo._id)) {
      applyQueue.splice(applyQueue.indexOf(userInfo._id), 1);
    }

    dispatch(
      updatePickupCompetition({
        _id: id,
        name: pickupCompetition.name,
        host: pickupCompetition.host,
        hostId: pickupCompetition.hostId,
        location1: pickupCompetition.location1,
        location2: pickupCompetition.location2,
        place: pickupCompetition.place,
        gameDate: pickupCompetition.gameDate,
        group: pickupCompetition.group,
        isRecruiting: pickupCompetition.isRecruiting,
        recruitCount: pickupCompetition.recruitCount,
        notice: pickupCompetition.notice,
        applyQueue: applyQueue
      }));

    alert("신청이 취소되었습니다.");
    window.location.reload();
  };

//   <Button
//   className="submit-button mt-2"
//   style={{ width: "100px" }}
//   disabled={true}
// >
//   모집이 종료되었습니다. {console.log((pickupCompetition && pickupCompetition.recruitCount) <= (pickupCompetition && pickupCompetition.applyQueue && pickupCompetition.applyQueue.length))}
// </Button>

  const applyButton = () => {
    if (isApplied(userInfo && userInfo._id)) {

        return (
          <Button
            className="submit-button mt-2"
            onClick={unapplyHandler}
            style={{ width: "100px" }}
          >
            신청취소
          </Button>
        );
    } else {
      return (
        <Button
          className="submit-button mt-2"
          onClick={applyHandler}
          style={{ width: "100px" }}
          disabled={isFull}
        >
          {isFull ? "모집종료" : "신청하기"}
        </Button>
      );
    }
  };

  useEffect(() => {
    dispatch({ type: PICKUP_COMPETITION_DETAIL_RESET })
    dispatch(listPickupCompetitionDetail(id));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="post__main">
            <h3
              className="mt-3 mb-3"
              style={{ color: "white", "font-weight": 700 }}
            >{pickupCompetition && pickupCompetition.name}</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >

            <Table
              style={{ borderCollapse: "collapse", width: "100%" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>주최자</th>
                  <th style={{ width: "20%" }}>지역</th>
                  <th style={{ width: "20%" }}>장소</th>
                  <th style={{ width: "20%" }}>날짜</th>
                  <th style={{ width: "20%" }}>모집인원 (현재/전체)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{pickupCompetition && pickupCompetition.hostNickname}</td>
                  <td>{locationNumToString(pickupCompetition && pickupCompetition.location1)} / {pickupCompetition && pickupCompetition.location2}</td>
                  <td>{pickupCompetition && pickupCompetition.place}</td>
                  <td>{pickupCompetition && pickupCompetition.gameDate.substring(0, 10)} ({dayOfWeekToString(new Date(pickupCompetition && pickupCompetition.gameDate).getDay())})</td>
                  <td>{pickupCompetition && pickupCompetition.applyQueue && pickupCompetition.applyQueue.length} / {pickupCompetition && pickupCompetition.recruitCount}
                    {((pickupCompetition && pickupCompetition.recruitCount) <= (pickupCompetition && pickupCompetition.applyQueue && pickupCompetition.applyQueue.length)) && (
                      <span style={{ color: "red" }}> (모집완료)</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="post__content">{ReactHtmlParser(pickupCompetition && pickupCompetition.notice)}</div>

          {applyButton()}
          <br />

          {userInfo && pickupCompetition && ((userInfo.isAdmin) || (userInfo._id === pickupCompetition.hostId)) && (
            <>
              {/* <Button
                className="submit-button mt-2"
                onClick={recruitHandler}
                style={{ width: "100px" }}
              >
                {pickupCompetition && pickupCompetition.isRecruiting ? "모집종료" : "모집시작"}
              </Button> */}
              <Link
                to={`/pickupgame/team-maker/${id}`}
                className="btn btn-primary my-3"
              >
                팀 편성
              </Link>
            </>

          )}
        </>
      )}
    </>
  );
};

export default PickupGameBookingDetailScreen;
