import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import {
  ASSOCIATION_OWN_REQUEST,
  ASSOCIATION_OWN_SUCCESS,
  ASSOCIATION_OWN_FAIL,
  ASSOCIATION_OWN_RESET,
} from "../constants/associationConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getOwnAssociation = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ASSOCIATION_OWN_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/association/own-association/${userId}`,
      config
    );
    dispatch({
      type: ASSOCIATION_OWN_SUCCESS,
      payload: data,
    });
  }
  catch (error) {
    dispatch({
      type: ASSOCIATION_OWN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};