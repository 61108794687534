import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ProgressBar, Table, Image } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import PlayerName from "../components/PlayerName";
import { pickupGameRecord, pickupGameResultInfo } from "../actions/pickupActions";
import { PICKUP_GAME_DETAIL_RESET } from "../constants/pickupConstants";

const DetailPickupGameRecordScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const pickupGameDetail = useSelector((state) => state.pickupGameDetail);
  const { loading, error, pickupGame } = pickupGameDetail;

  const pickupGameResult = useSelector((state) => state.pickupGameResult);
  const {
    loading: loadingResult,
    error: errorResult,
    result,
  } = pickupGameResult;

  const imgStyle = {
    maxHeight: 128,
    maxWidth: 128,
  };

  useEffect(() => {
    dispatch(pickupGameRecord(id));
    dispatch(pickupGameResultInfo(id));
    dispatch({ type: PICKUP_GAME_DETAIL_RESET });
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h3
            className="text-center"
            style={{
              color: "white",
              "background-color": "#031b31",
              "padding-top": "20px",
              "padding-bottom": "20px",
              "margin-top": "-16px",
              "margin-left": "-12px",
              "margin-right": "-12px",
            }}
          >
            {pickupGame && pickupGame.gameName} 정보
          </h3>
          {loadingResult ? (
            <Loader />
          ) : errorResult ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Row className="text-center mt-5">
              <span className="on__pc__gameDetails">
                <Row className="text-center mx-5">
                  <Col md={5}>
                      <Image
                        src={"/dncurry_logo.png"}
                        style={imgStyle}
                        alt={"/dncurry_logo.png"}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    <h5>{pickupGame && pickupGame.vsTeam[0]}</h5>
                    <h1 className="text-center mt-3">
                      {result.gameResult && result.gameResult[0]}
                    </h1>
                  </Col>
                  <Col md={2} className="pt-5">
                    <h1>VS</h1>
                  </Col>
                  <Col md={5}>
                  <Image
                        src={"/dncurry_logo.png"}
                        style={imgStyle}
                        alt={"/dncurry_logo.png"}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    <h5>{pickupGame && pickupGame.vsTeam[1]}</h5>
                    <h1 className="text-center mt-3">
                      {result.gameResult && result.gameResult[1]}
                    </h1>
                  </Col>
                </Row>
              </span>
              <span
                className="on__mobile__gameDetails"
                style={{ "padding-left": "4px", "padding-right": "4px" }}
              >
                <div
                  className="detailGame__justify__content"
                  style={{
                    border: "1px solid #D2D2D2",
                    "padding-top": "20px",
                    "padding-right": "10px",
                    "padding-left": "10px",
                  }}
                >
                  <div>
                  <Image
                        src={"/dncurry_logo.png"}
                        style={imgStyle}
                        alt={"/dncurry_logo.png"}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    <h5>{pickupGame && pickupGame.vsTeam[0]}</h5>
                  </div>
                  <h1
                    className="text-center"
                    style={{ "font-size": "20px", "font-weight": 700 }}
                  >
                    {result.gameResult && result.gameResult[0]}
                  </h1>
                  <div>
                    <h1>:</h1>
                  </div>
                  <h1
                    className="text-center"
                    style={{ "font-size": "20px", "font-weight": 700 }}
                  >
                    {result.gameResult && result.gameResult[1]}
                  </h1>
                  <div>
                  <Image
                        src={"/dncurry_logo.png"}
                        style={imgStyle}
                        alt={"/dncurry_logo.png"}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    <h5>{pickupGame && pickupGame.vsTeam[1]}</h5>
                  </div>
                </div>
              </span>
              <Row>
                <Col md={2}></Col>
                <Col
                  md={8}
                  className="text-center"
                  style={{
                    "margin-left": "12px",
                    "margin-right": "12px",
                    "padding-left": "4px",
                    "padding-right": "4px",
                  }}
                >
                  <Table responsive className="table-sm text-center">
                    <thead>
                      <tr
                        style={{
                          color: "white",
                          "background-color": "#031b31",
                        }}
                      >
                        <th>팀</th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            1Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            2Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            3Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            4Q
                          </div>
                        </th>
                        {pickupGame &&
                          pickupGame.overtime[0] +
                            pickupGame.overtime[1] !==
                            0 &&
                          pickupGame.overtime[0] !== null && (
                            <th>
                              <div
                                className="hideextra"
                                style={{ "min-width": "10px" }}
                              >
                                연장
                              </div>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        border: "1px solid #D2D2D2",
                      }}
                    >
                      <tr key={pickupGame && pickupGame._id}>
                        <td>
                          {pickupGame && pickupGame.vsTeam[0]}{" "}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[0][0] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[0][0]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[1][0] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[1][0]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[2][0] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[2][0]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[3][0] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[3][0]}
                        </td>
                        {pickupGame &&
                          pickupGame.overtime[0] +
                            pickupGame.overtime[1] !==
                            0 && (
                            <td>
                              {pickupGame && pickupGame.overtime[0]}
                            </td>
                          )}
                      </tr>
                      <tr key={pickupGame && pickupGame._id}>
                        <td>
                          {pickupGame && pickupGame.vsTeam[1]}{" "}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[0][1] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[0][1]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[1][1] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[1][1]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[2][1] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[2][1]}
                        </td>
                        <td>
                          {pickupGame &&
                          pickupGame.quarter &&
                          pickupGame.quarter[3][1] < 0
                            ? "N/A"
                            : pickupGame && pickupGame.quarter[3][1]}
                        </td>
                        {pickupGame &&
                          pickupGame.overtime[0] +
                            pickupGame.overtime[1] !==
                            0 && (
                            <td>
                              {pickupGame && pickupGame.overtime[1]}
                            </td>
                          )}
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={2}></Col>
              </Row>
              <span className="on__pc__gameDetails">
                <Row className="mt-5">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    2점 성공
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].fieldGoal[1] /
                            result.playerRecord[0].fieldGoal[0]) *
                            100
                        )
                      }
                      max={100}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].fieldGoal[1] /
                            result.playerRecord[0].fieldGoal[0]) *
                            100
                        )
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    2점 성공률
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].fieldGoal[1] /
                            result.playerRecord[1].fieldGoal[0]) *
                            100
                        )
                      }
                      max={100}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].fieldGoal[1] /
                            result.playerRecord[1].fieldGoal[0]) *
                            100
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    3점슛 성공
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].threePointThrow[1] /
                            result.playerRecord[0].threePointThrow[0]) *
                            100
                        )
                      }
                      max={80}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].threePointThrow[1] /
                            result.playerRecord[0].threePointThrow[0]) *
                            100
                        )
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    3점슛 성공률
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].threePointThrow[1] /
                            result.playerRecord[1].threePointThrow[0]) *
                            100
                        )
                      }
                      max={80}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].threePointThrow[1] /
                            result.playerRecord[1].threePointThrow[0]) *
                            100
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    리바운드
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].assist}
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].assist
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    어시스트
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].assist}
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].assist
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].steal}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].steal
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    스틸
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].steal}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].steal
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].block}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].block
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    블록슛
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].block}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].block
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                          result.playerRecord[0].foul[1]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                          result.playerRecord[0].foul[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    파울
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                          result.playerRecord[1].foul[1]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                          result.playerRecord[1].foul[1]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        (result.playerRecord[0].turnOver < 0
                          ? 0
                          : result.playerRecord[0].turnOver)
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    턴오버
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        (result.playerRecord[1].turnOver < 0
                          ? 0
                          : result.playerRecord[1].turnOver)
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                    />
                  </Col>
                </Row>
              </span>

              <span className="on__mobile__gameDetails">
                <hr />
                <h2 className="mt-3 mb-3">팀 간 결과비교</h2>
                2점 성공
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                    />
                  </div>
                </div>
                2점 성공률
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].fieldGoal[1] /
                            result.playerRecord[0].fieldGoal[0]) *
                            100
                        )
                      }
                      max={100}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].fieldGoal[1] /
                            result.playerRecord[0].fieldGoal[0]) *
                            100
                        )
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].fieldGoal[1] /
                            result.playerRecord[1].fieldGoal[0]) *
                            100
                        )
                      }
                      max={100}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].fieldGoal[1] /
                            result.playerRecord[1].fieldGoal[0]) *
                            100
                        )
                      }
                    />
                  </div>
                </div>
                3점슛 성공
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                    />
                  </div>
                </div>
                3점슛 성공률
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].threePointThrow[1] /
                            result.playerRecord[0].threePointThrow[0]) *
                            100
                        )
                      }
                      max={80}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[0].threePointThrow[1] /
                            result.playerRecord[0].threePointThrow[0]) *
                            100
                        )
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].threePointThrow[1] /
                            result.playerRecord[1].threePointThrow[0]) *
                            100
                        )
                      }
                      max={80}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        Math.round(
                          (result.playerRecord[1].threePointThrow[1] /
                            result.playerRecord[1].threePointThrow[0]) *
                            100
                        )
                      }
                    />
                  </div>
                </div>
                리바운드
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                    />
                  </div>
                </div>
                어시스트
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].assist}
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].assist
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].assist}
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].assist
                      }
                    />
                  </div>
                </div>
                스틸
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].steal}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].steal
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].steal}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].steal
                      }
                    />
                  </div>
                </div>
                블록슛
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].block}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].block
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].block}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].block
                      }
                    />
                  </div>
                </div>
                파울
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                          result.playerRecord[0].foul[1]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                          result.playerRecord[0].foul[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                          result.playerRecord[1].foul[1]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                          result.playerRecord[1].foul[1]
                      }
                    />
                  </div>
                </div>
                턴오버
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                    />
                  </div>
                </div>
              </span>
            </Row>
          )}
          <hr />
          <h2 className="mt-3 text-center">경기 상세 기록</h2>
          <p
            className="text-center"
            style={{ color: "#011241", "font-weight": "600" }}
          >
            선수명을 클릭하면, 개인기록 조회가 가능합니다.
          </p>
          <h3 className="mt-4">
            {pickupGame && pickupGame.vsTeam[0]} 기록
          </h3>
          <Table
            striped
            hover
            responsive
            className="table-sm text-center table__padding__sm"
          >
            <thead>
              <tr className="table__header">
                <th
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "2",
                    "background-color": "#031b31",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "60px",
                    }}
                  >
                    이름
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    득점
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    2점
                    <br /> 성공-시도
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    야투율
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    3점
                    <br /> 성공-시도
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    3점
                    <br /> 성공률
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    자유투
                    <br /> 성공-시도
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "35px",
                    }}
                  >
                    자유투
                    <br />
                    성공율
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    공격
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    수비
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    총<br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    어시스트
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    스틸
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    블락
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    턴오버
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    파울
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {pickupGame &&
                pickupGame.playerRecord[0].map((player) => (
                  <tr key={player._id}>
                    <td
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "1",
                        "background-color": "white",
                      }}
                    >
                      <PlayerName key={player._id} id={player.playerId} isPickup={true} />
                    </td>
                    <td>{player.score}</td>
                    <td>
                      {player.fieldGoal[1]} - {player.fieldGoal[0]}
                    </td>
                    <td>
                      {player.fieldGoal[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                          )}
                      %
                    </td>
                    <td>
                      {player.threePointThrow[1]} - {player.threePointThrow[0]}
                    </td>
                    <td>
                      {player.threePointThrow[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.threePointThrow[1] /
                              player.threePointThrow[0]) *
                              100
                          )}
                      %
                    </td>
                    <td>
                      {player.freeThrow[1]} - {player.freeThrow[0]}
                    </td>
                    <td>
                      {player.freeThrow[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.freeThrow[1] / player.freeThrow[0]) * 100
                          )}
                      %
                    </td>
                    <td>{player.rebound[0]}</td>
                    <td>{player.rebound[1]}</td>
                    <td>{player.rebound[0] + player.rebound[1]}</td>
                    <td>{player.assist}</td>
                    <td>{player.steal}</td>
                    <td>{player.block}</td>
                    <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                    <td>{player.foul[0] + player.foul[1]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <h3 className="mt-3">
            {pickupGame && pickupGame.vsTeam[1]} 기록
          </h3>
          <Table
            striped
            hover
            responsive
            className="table-sm text-center table__padding__sm"
          >
            <thead>
              <tr className="table__header">
                <th
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "2",
                    "background-color": "#031b31",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "60px",
                    }}
                  >
                    이름
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    득점
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    2점
                    <br /> 시도-성공
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    야투율
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    3점
                    <br /> 시도-성공
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    3점
                    <br /> 성공률
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    자유투
                    <br /> 시도-성공
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "35px",
                    }}
                  >
                    자유투
                    <br />
                    성공율
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    공격
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    수비
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    총<br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    어시스트
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    스틸
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    블락
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    턴오버
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    파울
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {pickupGame &&
                pickupGame.playerRecord[1].map((player) => (
                  <tr key={player._id}>
                    <td
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "1",
                        "background-color": "white",
                      }}
                    >
                      <PlayerName key={player._id} id={player.playerId} />
                    </td>
                    <td>{player.score}</td>
                    <td>
                      {player.fieldGoal[0]} - {player.fieldGoal[1]}
                    </td>
                    <td>
                      {player.fieldGoal[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                          )}
                      %
                    </td>
                    <td>
                      {player.threePointThrow[0]} - {player.threePointThrow[1]}
                    </td>
                    <td>
                      {player.threePointThrow[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.threePointThrow[1] /
                              player.threePointThrow[0]) *
                              100
                          )}
                      %
                    </td>
                    <td>
                      {player.freeThrow[0]} - {player.freeThrow[1]}
                    </td>
                    <td>
                      {player.freeThrow[1] === 0
                        ? 0
                        : Math.ceil(
                            (player.freeThrow[1] / player.freeThrow[0]) * 100
                          )}
                      %
                    </td>
                    <td>{player.rebound[0]}</td>
                    <td>{player.rebound[1]}</td>
                    <td>{player.rebound[0] + player.rebound[1]}</td>
                    <td>{player.assist}</td>
                    <td>{player.steal}</td>
                    <td>{player.block}</td>
                    <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                    <td>{player.foul[0] + player.foul[1]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {pickupGame &&
            pickupGame.image &&
            pickupGame.image.length > 1 && (
              <div className="text-center mt-3">
                <Image
                  src={pickupGame && pickupGame.image}
                  style={{ "max-width": "80%" }}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};

export default DetailPickupGameRecordScreen;
