import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { Step, Stepper } from "react-form-stepper";
import RegistrationTerm from "../static/registrationTerm";
import FormContainer from "../components/FormContainer";
import PopupPostCode from "../components/PopupPostCode";
import PopupDom from "../components/PopupDom";
import {
  getNiceButtonSession,
  getNiceIdentificationResult,
} from "../actions/nice";
import useQuery from "../utils/useQuery";
import axios from "axios";
import { useDispatch } from "react-redux";
import { register } from "../actions/userActions";
import { API_SERVER_URL } from "../actions/actionUrl";

/**
 * @param {string} text
 * @param {(e) => void} onClick
 * @constructor
 */
const ActionButton = ({ text, onClick }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
    >
      <button
        style={{
          height: "50px",
          fontSize: "18px",
          fontWeight: "bold",
          border: "none",
          backgroundColor: "#031b31",
          color: "white",
          width: "50%",
        }}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
};

/** @type {import('react').CSSProperties} */
const IdentificationCommonStyle = {
  width: "220px",
  height: "110px",
  fontWeight: "bold",
  fontSize: "32px",
  border: "none",
  backgroundColor: "#031B31",
  color: "white",
};

/**
 * @constructor
 */
const IdentificationComponent = () => {
  const [formData, setFormData] = useState({
    token_version_id: "",
    enc_data: "",
    integrity_value: "",
  });

  useEffect(() => {
    getNiceButtonSession()
      .then((sessionResult) => {
        const { sid, ...formData } = sessionResult;
        setFormData(formData);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <h3 className="text-center mt-3">본인인증</h3>
      <p className="text-center mb-4">
        정확한 기록을 위해, 본인인증을 완료해주세요.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "4px",
          marginTop: "8px",
        }}
      >
        <>
          <form
            name="nice-ident-form"
            id="nice-ident-form"
            method={"post"}
            action={
              "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
            }
          >
            <input type={"hidden"} id={"m"} name={"m"} value={"service"} />
            <input
              type={"hidden"}
              id={"token_version_id"}
              name={"token_version_id"}
              value={formData.token_version_id}
            />
            <input
              type={"hidden"}
              id={"enc_data"}
              name={"enc_data"}
              value={formData.enc_data}
            />
            <input
              type={"hidden"}
              id={"integrity_value"}
              name={"integrity_value"}
              value={formData.integrity_value}
            />
            <button
              type={"submit"}
              className={"btn-ident-submit"}
              style={IdentificationCommonStyle}
              disabled={
                !(
                  formData.token_version_id &&
                  formData.enc_data &&
                  formData.integrity_value
                )
              }
            >
              본인인증
            </button>
          </form>
        </>
      </div>
    </>
  );
};

/**
 * @param {{ onAgree: () => void }} props
 * @constructor
 */
const TermsComponent = ({ onAgree }) => {
  const onClickNext = () => {
    const checked = document.getElementById("term-check").checked;
    if (!checked) alert("이용약관에 동의하여야 회원가입이 가능합니다.");
    else onAgree();
  };

  return (
    <>
      <h3 className="text-center mt-3">이용약관</h3>
      <RegistrationTerm />
      <div className="text-center">
        <label htmlFor="term-check">
          <span style={{ position: "relative", top: "1px" }}>
            이용약관 동의
          </span>
          <span style={{ color: "red", top: "1px" }}> [필수]</span>
        </label>
        <input
          type="checkbox"
          id="term-check"
          className="mx-2"
          style={{
            width: "16px",
            height: "16px",
            position: "relative",
            top: "5px",
          }}
        />
      </div>
      <ActionButton
        onClick={onClickNext}
        text={"다음"}
        style={{
          border: "none",
          backgroundColor: "#031b31",
          color: "white",
          width: "40%",
        }}
      />
    </>
  );
};

/**
 * @param {string} session
 * @param {{ name: string, mobile: string, birthdate: string }} preform
 * @constructor
 */
const FormComponent = ({ session, preform }) => {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [address1, setAddress1] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [playerId, setPlayerId] = useState("");
  
  const [message, setMessage] = useState("");

  const [popupOpen, setPopupOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  const [matched, setMatched] = useState(false);
  const [matchedPlayer, setMatchedPlayer] = useState("");
  const [notMatchedOption, setNotMatchedOption] = useState([]);

  const dispatch = useDispatch();

  async function checkDuplicateId(userId) {
    let res = await axios.get(
      `${API_SERVER_URL}/api/user/duplicate-id/check?userId=${userId}`
    );
    let data = res.data;
    if (data === true) {
      alert("이미 사용중인 아이디입니다.");
    } else {
      alert("사용 가능한 아이디입니다.");
      setFlag(true);
    }
  }

  const duplicateIdHandler = () => {
    checkDuplicateId(userId);
  };

  const submitForm = (e) => {
    // Validate forms & send register request with session -- read-only data will fill up by serverside
    if (!flag) {
      alert("아이디 중복확인을 해주세요.");
      return;
    }
    if (
      userId === "" ||
      email === "" ||
      password === "" ||
      confPassword === "" ||
      address1 === "" ||
      addressDetail === ""
    ) {
      alert("모든 정보를 입력해주세요.");
      return;
    } else if (password !== confPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    } else {
      let address = address1 + "," + addressDetail;
      let sid = session;
      e.preventDefault();
      dispatch(register(sid, userId, password, email, address, playerId));
      // window.location.href = "/welcome";
    }
  };

  useEffect(async () => {
    if (preform) {
      let existUser = await axios.get(
        `${API_SERVER_URL}/api/user/find/exist?name=${preform.name}&birthday=${preform.birthdate}`
      );
      let existData = existUser.data;
      if (existData) {
        alert(`이미 가입 된 사용자입니다. 로그인 해 주십시오. ID:${existData}`);
        window.location.href = "/login";
      }
      
      let res = await axios.get(
        `${API_SERVER_URL}/api/user/match/player?name=${preform.name}&birthday=${preform.birthdate}`
      );
      let data = res.data;
      if (data.matched === true) {
        setMatched(true);
        setMatchedPlayer(data.playerProfile);
        setMessage(data.message)
        setPlayerId(data.playerProfile._id);
      } else {
        setMatched(false);
        setMessage("등록된 선수가 없습니다.")
        let option = [];
        // data.playerProfile.forEach((player) => {
        //   option.push({
        //     label : player.name + " / " + (player.teamName ? player.teamName.join(", ") : "팀 없음"),
        //     value : player._id
        //   });
        // });
        option.push({
          label : "(새 선수 등록)",
          value : ""
        })
        setNotMatchedOption(option);
      }
    }
  }, [preform]);


  return (
    <>
      <FormContainer>
        <Form>
          <Form.Group controlId="name" className="mt-1">
            <div>
              <Row>
                <Col>
                  <Form.Label>이름</Form.Label>
                  <Form.Control
                    type="name"
                    disabled
                    value={preform.name}
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>생년월일</Form.Label>
                  <Form.Control
                    type="birthday"
                    disabled
                    value={preform.birthdate}
                  ></Form.Control>
                </Col>
              </Row>
            </div>
          </Form.Group>
          <Form.Group controlId="phoneNum" className="mt-2">
            <Form.Label>휴대폰 번호</Form.Label>
            <Form.Control
              type="phoneNum"
              disabled
              value={preform.mobile}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="userId" className="mt-1">
            <Form.Label>아이디</Form.Label>
            <div className="register__id__container">
              <Form.Control
                type="userId"
                placeholder="아이디"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              ></Form.Control>
              <button
                type="button"
                className="btn__isUser"
                onClick={duplicateIdHandler}
              >
                중복확인
              </button>
            </div>
          </Form.Group>

          <Form.Group controlId="password" className="mt-2">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="비밀번호"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="confirmPassword" className="mt-2">
            <Form.Label>비밀번호 확인</Form.Label>
            <Form.Control
              type="password"
              placeholder="비밀번호 확인"
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email" className="mt-2">
            <Form.Label>이메일 주소</Form.Label>
            <Form.Control
              type="email"
              placeholder="아이디 / 비밀번호 찾기에 활용됩니다"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="address" className="mt-2">
            <Form.Label>주소</Form.Label>
            <div id="popupDom">
              {popupOpen && (
                <PopupDom>
                  <PopupPostCode
                    onClose={() => setPopupOpen(false)}
                    onAddData={(data) => setAddress1(data.address)}
                  />
                </PopupDom>
              )}
            </div>
            <div className="register__id__container">
              <Form.Control
                type="address1"
                value={address1}
                disabled
                onChange={() => {
                  // setAddress1(e.target.value);
                }}
              ></Form.Control>
              <button
                type="button"
                onClick={() => setPopupOpen(true)}
                className="btn__postcode"
              >
                주소찾기
              </button>
            </div>
            <Form.Control
              type="addressDetail"
              placeholder="[필수] 상세 주소(동∙호수∙층)"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Label>선수 계정 연동</Form.Label>
          {matched ? (
            <Form.Group controlId="matched" className="mt-2">
              <Form.Label>{message}</Form.Label>
              <Form.Control
                type="matched"
                disabled
                value={matchedPlayer.name + " / " + (matchedPlayer.teamName ? matchedPlayer.teamName.join(", ") : "팀 없음")}
              ></Form.Control>
            </Form.Group>
          ) : (
            <Form.Group controlId="notMatched" className="mt-2">
              <Form.Label>{message}</Form.Label>
              {notMatchedOption.map((option) => (
                <Form.Check
                  type="radio"
                  label={option.label}
                  value={option.value}
                  name="notMatched"
                  defaultChecked={option.value === ""}
                  onChange={(e) => setPlayerId(e.target.value)}
                />
              ))}
            </Form.Group>
          )}
        </Form>
      </FormContainer>
      <ActionButton
        type="submit"
        onClick={submitForm}
        style={{
          border: "none",
          backgroundColor: "#031b31",
          color: "white",
          width: "40%",
        }}
        text={"가입하기"}
      />
    </>
  );
};

// const RegistrationComplete = () => {
//   return (
//     <>
//       <div className="register__summary">
//         <h1>환영합니다</h1>
//         <Image
//           src={`/time4_img_register.png`}
//           style={{ width: "22rem", borderRadius: "15px" }}
//         />
//         <p>
//           회원가입이 완료 되었습니다!
//           <br />
//           지금 바로 로그인하여 다양한 기능을 이용해보세요
//         </p>
//         <a href={"/"}>메인으로</a>
//       </div>
//     </>
//   );
// };

const RegisterScreen = () => {
  const { sid } = useParams();
  const isCreatedSession = () => !!sid;

  const [stage, setStage] = useState(isCreatedSession() ? 1 : 0);
  const [preform, setPreform] = useState({
    name: "",
    mobile: "",
    birthdate: "",
  });

  useEffect(() => {
    if (isCreatedSession()) {
      getNiceIdentificationResult(sid).then((result) => {
        if (result)
          setPreform({
            name: result.name,
            mobile: result.mobile,
            birthdate: result.birthdate,
          });
      });
    }
  }, []);

  return (
    <>
      <div className="menu__header" style={{ marginBottom: "14px" }}>
        <h2 className="mt-3 mb-3" style={{ color: "white", fontWeight: 700 }}>
          타임4 가입하기
        </h2>
        <p style={{ color: "white" }}>
          회원가입하여 지금 바로 모든 기록을 확인하세요!
        </p>
      </div>
      <Stepper
        activeStep={stage}
        styleConfig={{
          activeBgColor: "#031b31",
          activeTextColor: "#fff",
          inactiveBgColor: "#fff",
          inactiveTextColor: "#031b31",
          completedBgColor: "#fff",
          completedTextColor: "#5A5A5A",
          size: "3em",
        }}
      >
        <Step label="본인인증" />
        <Step label="약관동의" />
        <Step label="회원정보" />
        <Step label="가입완료" />
      </Stepper>
      {stage === 0 && <IdentificationComponent />}
      {stage === 1 && <TermsComponent onAgree={() => setStage(2)} />}
      {stage === 2 &&  <FormComponent
          session={sid}
          preform={preform}
        />
      }
      {/* {stage === 3 && <RegistrationComplete />} */}
    </>
  );
};
export default RegisterScreen;