export const TEAM_NAME_REQUEST = "TEAM_NAME_REQUEST";
export const TEAM_NAME_SUCCESS = "TEAM_NAME_SUCCESS";
export const TEAM_NAME_FAIL = "TEAM_NAME_FAIL";
export const TEAM_NAME_RESET = "TEAM_NAME_RESET";

export const TEAM_LIST_REQUEST = "TEAM_LIST_REQUEST";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_FAIL = "TEAM_LIST_FAIL";
export const TEAM_LIST_RESET = "TEAM_LIST_RESET";

export const TEAM_LIST_CONSONANT_REQUEST = "TEAM_LIST_CONSONANT_REQUEST";
export const TEAM_LIST_CONSONANT_SUCCESS = "TEAM_LIST_CONSONANT_SUCCESS";
export const TEAM_LIST_CONSONANT_FAIL = "TEAM_LIST_CONSONANT_FAIL";
export const TEAM_LIST_CONSONANT_RESET = "TEAM_LIST_CONSONANT_RESET";

export const TEAM_LIST_TYPE_REQUEST = "TEAM_LIST_CONSONANT_REQUEST";
export const TEAM_LIST_TYPE_SUCCESS = "TEAM_LIST_CONSONANT_SUCCESS";
export const TEAM_LIST_TYPE_FAIL = "TEAM_LIST_CONSONANT_FAIL";
export const TEAM_LIST_TYPE_RESET = "TEAM_LIST_CONSONANT_RESET";

export const TEAM_DETAIL_REQUEST = "TEAM_DETAIL_REQUEST";
export const TEAM_DETAIL_SUCCESS = "TEAM_DETAIL_SUCCESS";
export const TEAM_DETAIL_FAIL = "TEAM_DETAIL_FAIL";
export const TEAM_DETAIL_RESET = "TEAM_DETAIL_RESET";

export const TEAM_DETAIL_REQUEST1 = "TEAM_DETAIL_REQUEST1";
export const TEAM_DETAIL_SUCCESS1 = "TEAM_DETAIL_SUCCESS1";
export const TEAM_DETAIL_FAIL1 = "TEAM_DETAIL_FAIL1";
export const TEAM_DETAIL_RESET1 = "TEAM_DETAIL_RESET1";

export const TEAM_UPDATE_REQUEST = "TEAM_UPDATE_REQUEST";
export const TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS";
export const TEAM_UPDATE_FAIL = "TEAM_UPDATE_FAIL";
export const TEAM_UPDATE_RESET = "TEAM_UPDATE_RESET";

export const TEAM_INPUT_REQUEST = "TEAM_INPUT_REQUEST";
export const TEAM_INPUT_SUCCESS = "TEAM_INPUT_SUCCESS";
export const TEAM_INPUT_FAIL = "TEAM_INPUT_FAIL";

export const TEAM_SEASON_RECORD_REQUEST = "TEAM_SEASON_RECORD_REQUEST";
export const TEAM_SEASON_RECORD_SUCCESS = "TEAM_SEASON_RECORD_SUCCESS";
export const TEAM_SEASON_RECORD_FAIL = "TEAM_SEASON_RECORD_FAIL";

export const TEAM_SEASON_MEMBERS_RECORD_REQUEST = "TEAM_SEASON_MEMBERS_RECORD_REQUEST";
export const TEAM_SEASON_MEMBERS_RECORD_SUCCESS = "TEAM_SEASON_MEMBERS_RECORD_SUCCESS";
export const TEAM_SEASON_MEMBERS_RECORD_FAIL = "TEAM_SEASON_MEMBERS_RECORD_FAIL";

export const TEAM_COMPETITION_RECORD_REQUEST = "TEAM_COMPETITION_RECORD_REQUEST";
export const TEAM_COMPETITION_RECORD_SUCCESS = "TEAM_COMPETITION_RECORD_SUCCESS";
export const TEAM_COMPETITION_RECORD_FAIL = "TEAM_COMPETITION_RECORD_FAIL";
export const TEAM_COMPETITION_RECORD_RESET = "TEAM_COMPETITION_RECORD_RESET";

export const TEAM_COMPETITION_LIST_REQUEST = "TEAM_COMPETITION_LIST_REQUEST";
export const TEAM_COMPETITION_LIST_SUCCESS = "TEAM_COMPETITION_LIST_SUCCESS";
export const TEAM_COMPETITION_LIST_FAIL = "TEAM_COMPETITION_LIST_FAIL";
export const TEAM_COMPETITION_LIST_RESET = "TEAM_COMPETITION_LIST_RESET";

export const TEAM_DELETE_REQUEST = "TEAM_DELETE_REQUEST";
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS";
export const TEAM_DELETE_FAIL = "TEAM_DELETE_FAIL";
export const TEAM_DELETE_RESET = "TEAM_DELETE_RESET";