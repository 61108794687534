import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Button, Image, Form } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  deleteTeam,
  teamDetailsInfo,
  updateTeam,
} from "../actions/teamActions";
import { TEAM_UPDATE_RESET } from "../constants/teamConstants";
import Select from "react-select";
import { API_SERVER_URL } from "../actions/actionUrl";
import axios from "axios";
import { accessControl } from "../utils/access";
import optionsType from "../static/optionsType";
import FormContainer from "../components/FormContainer";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ModifyTeamScreen = ({ match, history }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [leader, setLeader] = useState("");
  const [foundedAt, setFoundedAt] = useState("");
  const [teamType, setTeamType] = useState("");
  const [uploading, setUploading] = useState(false);

  const foundedRef = useRef();

  const teamId = match.params.id;

  const [year, setYear] = useState("2023");

  const dispatch = useDispatch();

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading, error, team } = teamDetails;

  const teamUpdate = useSelector((state) => state.teamUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = teamUpdate;

  const teamDelete = useSelector((state) => state.teamDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = teamDelete;

  const cropperRef = useRef(null);
  const [inputImage, setInputImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageDeleteFlag, setImageDeleteFlag] = useState(false);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas());
  };

  const imageContainerStyle = {
    position: 'relative',
    display: 'inline-block'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'red',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '18px'
  };

  const handleFounded = (e) => {
    const value = foundedRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    foundedRef.current.value = result;
    setFoundedAt(e.target.value);
  };

  const onImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setInputImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const deleteFileHandler = async (key) => {
    try {
      await axios.delete(`${API_SERVER_URL}/api/upload/${key}`);
    }
    catch (error) {
      console.error(error);
    }
  };

  const deleteImageHandler = () => {
    setImage("");
    setImageDeleteFlag(true);
  };

  const deleteTeamHandler = () => {
    //window.confirm에서 팀 이름을 입력해야 삭제 가능
    const input = prompt(`삭제를 위해 팀명 "${team.name}" 을 입력해주세요.`);

    if (input === team.name) {
      dispatch(deleteTeam(teamId));
    } else {
      alert("팀명이 일치하지 않습니다.");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // if ((new Date() - new Date(team.lastNameModified) < 1000 * 60 * 60 * 24 * 30) && team.name !== name) {
    //   alert(`팀명 변경은 30일에 한 번만 가능합니다. (마지막 변경 일자 : ${team.lastNameModified.slice(0, 10)})`);
    //   return;
    // }
    const banLetter = ["<", ">", "/", "\\", "*", "?", '"', "|", ":", "@", "!", "#", "$", "^", "~", "`", "+", "="];
    if (banLetter.some((letter) => name.includes(letter))) {
      alert("팀명에 특수문자는 사용할 수 없습니다.");
      return;
    }

    const isDelete = imageDeleteFlag || (originalImage !== "" && image !== originalImage);

    if (isDelete) {
      let key = originalImage.match(/\/([^\/]+)$/) ? originalImage.match(/\/([^\/]+)$/)[1] : "";
      if (key !== "") { deleteFileHandler(key); }
    }

    if (croppedImage !== null) {
      const formData = new FormData();

      croppedImage.toBlob(async (blob) => {
        formData.append("image", blob, "image.jpg");

        setUploading(true);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const { data } = await axios.post(
            `${API_SERVER_URL}/api/upload/teamlogo`,
            formData,
            config
          );

          setUploading(false);

          dispatch(
            updateTeam({
              _id: teamId,
              name: name,
              image: data,
              leader: leader,
              foundedAt: foundedAt,
              teamType: teamType,
              members: team.members,
              masterId: team.masterId,
              applyQueue: team.applyQueue
            })
          );
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      }, "image/jpeg");
    } else {
      dispatch(
        updateTeam({
          _id: teamId,
          name: name,
          image: image,
          leader: leader,
          foundedAt: foundedAt,
          teamType: teamType,
          members: team.members,
          masterId: team.masterId,
          applyQueue: team.applyQueue
        })
      );
    }
  };

  useEffect(() => {
    accessControl([99, 100], history);
    if (successUpdate) {
      dispatch({ type: TEAM_UPDATE_RESET });
    } else {
      if (!team || team._id !== teamId) {
        dispatch(teamDetailsInfo(teamId));
      } else {
        setName(team.name);
        setImage(team.image);
        setOriginalImage(team.image);
        setLeader(team.leader);
        setFoundedAt(team.foundedAt ? team.foundedAt.slice(0, 10) : "");
        setTeamType(team.teamType);
      }
    }
  }, [dispatch, history, teamId, team, successUpdate]);

  return (
    <FormContainer>
      <h1>팀 정보 수정</h1>
      {error && (
        <Message variant="danger">
          팀 수정에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Form.Group controlId="name">
          <Form.Label>팀명</Form.Label>
          <Form.Control
            type="name"
            placeholder="팀명"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="image" className="mt-3">
          <Form.Label>이미지</Form.Label>
          <Form.Group controlId="crop" className="mt-3">
            <input type="file" accept="image/*" onChange={onImageChange} />
            <Cropper
              ref={cropperRef}
              src={inputImage}
              viewMode={1}
              crop={onCrop}
              zoomable={false}
              initialAspectRatio={16 / 16}
            />
          </Form.Group>
          <div style={imageContainerStyle}>
            {(image !== "") && (croppedImage === null) && <>
              <img src={image} alt="image" style={{ width: '100%' }} />
              <button style={closeButtonStyle} onClick={deleteImageHandler}>X</button>
            </>}
            {image === "" && croppedImage === null &&
              <>
                <img src={"/player_default_img.png"} alt="image" style={{ width: '100%' }} />
              </>}
          </div>
        </Form.Group>
        {uploading && <Loader />}
        <Form.Group controlId="leader">
          <Form.Label>감독</Form.Label>
          <Form.Control
            type="leader"
            placeholder="감독명"
            value={leader}
            onChange={(e) => setLeader(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="foundedAt" className="mt-3">
          <Form.Label>팀 창설일</Form.Label>
          <Form.Control
            type="foundedAt"
            placeholder="예) 1992-01-01"
            ref={foundedRef}
            value={foundedAt}
            onChange={handleFounded}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="teamType" className="mt-3">
          <Form.Label>종별</Form.Label>
          <Select
            options={optionsType}
            isSearchable
            onChange={(e) => setTeamType(e.value)}
            defaultValue={optionsType.find(x => x.value === teamType)}
          />
        </Form.Group>
        <Button
          onClick={submitHandler}
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          팀 수정
        </Button>
      </Form>
    </FormContainer>
  );
};


export default ModifyTeamScreen;