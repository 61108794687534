export const COMPETITION_APPLY_LIST_REQUEST = "COMPETITION_APPLY_LIST_REQUEST";
export const COMPETITION_APPLY_LIST_SUCCESS = "COMPETITION_APPLY_LIST_SUCCESS";
export const COMPETITION_APPLY_LIST_FAIL = "COMPETITION_APPLY_LIST_FAIL";
export const COMPETITION_APPLY_LIST_RESET = "COMPETITION_APPLY_LIST_RESET";

export const COMPETITION_APPLY_DETAIL_REQUEST = "COMPETITION_APPLY_READ_REQUEST";
export const COMPETITION_APPLY_DETAIL_SUCCESS = "COMPETITION_APPLY_READ_SUCCESS";
export const COMPETITION_APPLY_DETAIL_FAIL = "COMPETITION_APPLY_READ_FAIL";
export const COMPETITION_APPLY_DETAIL_RESET = "COMPETITION_APPLY_READ_RESET";

export const COMPETITION_APPLY_INPUT_REQUEST = "COMPETITION_APPLY_INPUT_REQUEST";
export const COMPETITION_APPLY_INPUT_SUCCESS = "COMPETITION_APPLY_INPUT_SUCCESS";
export const COMPETITION_APPLY_INPUT_FAIL = "COMPETITION_APPLY_INPUT_FAIL";
export const COMPETITION_APPLY_INPUT_RESET = "COMPETITION_APPLY_INPUT_RESET";

export const COMPETITION_APPLY_UPDATE_REQUEST = "COMPETITION_APPLY_INPUT_REQUEST";
export const COMPETITION_APPLY_UPDATE_SUCCESS = "COMPETITION_APPLY_INPUT_SUCCESS";
export const COMPETITION_APPLY_UPDATE_FAIL = "COMPETITION_APPLY_INPUT_FAIL";
export const COMPETITION_APPLY_UPDATE_RESET = "COMPETITION_APPLY_INPUT_RESET";