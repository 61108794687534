import React, { useState, useEffect } from "react";
import { competitionGameList, competitionRoundGameList, competitionRoundRank, competitionStatsAction, listLeagueRound } from "../actions/competitionActions";
import { useDispatch, useSelector } from "react-redux";
import GameSchedule from "../components/GameSchedule";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  Table,
  Card,
  Row,
  Col,
  ListGroup,
  Image,
  Button,
} from "react-bootstrap";
import {
  COMPETITION_INFO_RESET,
  COMPETITION_LIST_RESET,
  COMPETITION_STATS_RESET,
} from "../constants/competitionConstants";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import { viewLocalSetup, increaseView } from "../utils/viewSetup";
import Select from "react-select";

const LeagueRoundDetailScreen = ({ match }) => {
  const competitionId = match.params.id;
  const round = match.params.round;

  const [statsType, setStatsType] = useState("score");

  useEffect(() => {
    const reloadFlag = sessionStorage.getItem("reloadFlag");
    sessionStorage.setItem("reloadFlag", "false");
  }, []);

  const dispatch = useDispatch();

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competition,
  } = competitionGame;

  const competitionRoundGame = useSelector(
    (state) => state.competitionRoundGame
  );

  const competitionRoundInfo = useSelector(
    (state) => state.competitionRoundInfo
  );

  const { loading, error, roundGame } = competitionRoundGame;

  const competitionStats = useSelector((state) => state.competitionStats);
  const { loading: loadingStats, error: errorStats, stats } = competitionStats;

  const {
    loading: loadingRoundInfo,
    error: errorRoundInfo,
    roundInfo,
  } = competitionRoundInfo;

  const roundRank = useSelector((state) => state.roundRank);
  const {
    loading: loadingRoundRank,
    error: errorRoundRank,
    ranking,
  } = roundRank;

  const statsTypeOption = [
    {
      label: "득점 평균",
      value: "score",
    },
    {
      label: "어시스트 평균",
      value: "assist",
    },
    {
      label: "리바운드 평균",
      value: "rebound",
    },
    {
      label: "블락 평균",
      value: "block",
    },
    {
      label: "스틸 평균",
      value: "steal",
    },
    {
      label: "자유투 확률",
      value: "freeThrow",
    },
    {
      label: "야투율",
      value: "fieldGoal",
    },
    {
      label: "3점슛 확률",
      value: "threePointThrow",
    },
  ];

  const statsTypeHandler = (selected) => {
    setStatsType(selected.value);
  };

  const getStatsByType = (type, isFirstPlace) => {
    if (type === "score") {
      if (isFirstPlace) {
        return (stats && stats.score).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.score).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "assist") {
      if (isFirstPlace) {
        return (stats && stats.assist).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.assist).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "rebound") {
      if (isFirstPlace) {
        return (stats && stats.rebound).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.rebound).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "block") {
      if (isFirstPlace) {
        return (stats && stats.block).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.block).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "steal") {
      if (isFirstPlace) {
        return (stats && stats.steal).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.steal).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "freeThrow") {
      if (isFirstPlace) {
        return (stats && stats.freeThrow).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.freeThrow).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "fieldGoal") {
      if (isFirstPlace) {
        return (stats && stats.fieldGoal).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.fieldGoal).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "threePointThrow") {
      if (isFirstPlace) {
        return (stats && stats.threePointThrow).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.threePointThrow).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    }
  };

  const headerNameToKorean = (name) => {
    switch (name) {
      case "rank":
        return "순위";
      case "teamName":
        return "소속 팀";
      case "name":
        return "선수명";
      case "image":
        return "선수";
      case "avg":
        if (statsType === "rebound") {
          return "평균(OR-DR)";
        } else {
          return "평균";
        }
      case "acc":
        if (
          statsType === "freeThrow" ||
          statsType === "fieldGoal" ||
          statsType === "threePointThrow"
        ) {
          return "성공-시도";
        } else {
          return "누적";
        }
      case "gameCount":
        return "경기 수";
      case "total":
        return "합계";
      case "throwRate":
        return "성공률";
      case "playerId":
        return null;
      default:
        return name;
    }
  };

  const firstPlaceHeader = (stat) => {
    const header = [];
    for (const [key, value] of Object.entries(stat)) {
      if (key === "gameCount") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "avg") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "acc") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "total") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "throwRate") {
        header.push([headerNameToKorean(key), `${value}%`]);
      }
    }

    return header;
  };

  const tableHeader = (stats) => {
    const headerNames = [];
    for (const property in stats[0]) {
      if (property === "name") {
        continue;
      }
      if (property === "playerId") {
        continue;
      }
      headerNames.push(headerNameToKorean(property));
    }
    return headerNames.map((name) => (
      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
        <div className="hideextra">{name}</div>
      </th>
    ));
  };

  const tableBody = (stat) => {
    const renderResult = [];
    for (const property in stat) {
      if (property === "throwRate") {
        renderResult.push(
          <td
            style={{
              padding: "8px",
              verticalAlign: "middle",
              border: "1px solid #ddd",
            }}
          >
            <div>{stat[property]} %</div>
          </td>
        );
        continue;
      }

      if (property === "image") {
        renderResult.push(
          <td
            style={{
              padding: "8px",
              position: "relative",
              border: "1px solid #ddd",
            }}
          >
            <div className="competition__ranking__player__container">
              <Image
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                }}
                src={
                  stat[property] === ""
                    ? "/player_default_img.png"
                    : stat[property]
                }
                fluid
                rounded
              />
              <span
                style={{
                  marginTop: "8px",
                  textAlign: "center",
                  marginLeft: "12px",
                }}
              >
                <Link to={`/player/${stat.playerId}`}>{stat.name}</Link>
              </span>
            </div>
          </td>
        );

        continue;
      }

      if (property === "name") {
        continue;
      }

      if (property === "playerId") {
        continue;
      }

      renderResult.push(
        <td
          style={{
            padding: "8px",
            verticalAlign: "middle",
            border: "1px solid #ddd",
          }}
        >
          <div>{stat[property]}</div>
        </td>
      );
    }
    return renderResult;
  };

  useEffect(() => {
    viewLocalSetup(competitionId);
    dispatch(competitionGameList(competitionId));
    dispatch(competitionRoundGameList(competitionId, round));
    dispatch({ type: COMPETITION_STATS_RESET });
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_INFO_RESET });
    dispatch(listLeagueRound(competitionId));
    increaseView(competitionId, "competition");
  }, [dispatch, competitionId, round]);

  useEffect(() => {
    if (roundInfo) {
      dispatch(
        competitionStatsAction(
          competitionId,
          roundInfo.round[round - 1][1],
          roundInfo.round[round - 1][0],
          true
        )
      );
      dispatch(
        competitionRoundRank(competitionId, roundInfo.round[round - 1][1])
      );
    }
  }, [dispatch, roundInfo]);

  const i = 0;

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {roundGame && roundGame.length !== 0 ? (
            <Row>
              <h2
                style={{
                  color: "white",
                  "background-color": "#031b31",
                  "padding-top": "20px",
                  "padding-bottom": "20px",
                  "margin-top": "-16px",
                }}
              >
                {roundInfo && roundInfo.round[round - 1][1] < 2
                  ? roundInfo && roundInfo.round[round - 1][1] + "라운드 기록"
                  : roundInfo && roundInfo.round[round - 1][1] + " 경기 결과"}
              </h2>
              {ranking && (<>
                {roundInfo && roundInfo.round[round - 1][1].includes("/") ? (
                  <div className="text-center">
                    <Row>
                      <Col>
                        <Table
                          bordered
                          responsive
                          className="table-sm text-center mt-3 mx-auto"
                          style={{ maxWidth: "600px" }}
                        >
                          <thead>
                            <tr className="ranking__table__header">
                              <th colSpan="7">
                                <h5
                                  className="ranking__table__text"
                                  style={{
                                    color: "white",
                                    marginTop: "8px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {competitionRoundInfo &&
                                    competitionRoundInfo.roundInfo &&
                                    competitionRoundInfo.roundInfo.name}{" "}
                                  <br />
                                  {outputGroupArray[0]} 순위
                                </h5>
                              </th>
                            </tr>
                            <tr>
                              <th>순위</th>
                              <th>팀명</th>
                              <th>승</th>
                              <th>패</th>
                              <th>득점</th>
                              <th>실점</th>
                              <th>득실</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ranking &&
                              ranking.group1Rank.map((rank, i) => (
                                <tr>
                                  <td>{rank.rank}위</td>
                                  <td>{rank.teamName}</td>
                                  <td>{rank.wins}승</td>
                                  <td>{rank.losses}패</td>
                                  <td>{rank.scoreFor}</td>
                                  <td>{rank.scoreAgainst}</td>
                                  <td>
                                    {rank.goalDifference > 0 && "+"}
                                    {rank.goalDifference}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                      <Col>
                        <Table
                          bordered
                          responsive
                          className="table-sm text-center mt-3 mx-auto"
                          style={{ maxWidth: "600px" }}
                        >
                          <thead>
                            <tr className="ranking__table__header">
                              <th colSpan="7">
                                <h5
                                  className="ranking__table__text"
                                  style={{
                                    color: "white",
                                    marginTop: "8px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {competitionRoundInfo &&
                                    competitionRoundInfo.roundInfo &&
                                    competitionRoundInfo.roundInfo.name}{" "}
                                  <br />
                                  {outputGroupArray[1]} 순위
                                </h5>
                              </th>
                            </tr>
                            <tr>
                              <th>순위</th>
                              <th>팀명</th>
                              <th>승</th>
                              <th>패</th>
                              <th>득점</th>
                              <th>실점</th>
                              <th>득실</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ranking &&
                              ranking.group2Rank.map((rank, i) => (
                                <tr>
                                  <td>{rank.rank}위</td>
                                  <td>{rank.teamName}</td>
                                  <td>{rank.wins}승</td>
                                  <td>{rank.losses}패</td>
                                  <td>{rank.scoreFor}</td>
                                  <td>{rank.scoreAgainst}</td>
                                  <td>
                                    {rank.goalDifference > 0 && "+"}
                                    {rank.goalDifference}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    {/* <Table
                    bordered
                    responsive
                    className="table-sm text-center mt-3 mx-auto"
                    style={{ maxWidth: "600px" }}
                  >
                    <thead>
                      <tr className="ranking__table__header">
                        <th colSpan="7">
                          <h5
                            className="ranking__table__text"
                            style={{
                              color: "white",
                              marginTop: "8px",
                              marginLeft: "8px",
                            }}
                          >
                            {competitionRoundInfo &&
                              competitionRoundInfo.roundInfo &&
                              competitionRoundInfo.roundInfo.name}{" "}
                            <br />
                            전체 순위
                          </h5>
                        </th>
                      </tr>
                      <tr>
                        <th>순위</th>
                        <th>팀명</th>
                        <th>승</th>
                        <th>패</th>
                        <th>득점</th>
                        <th>실점</th>
                        <th>득실</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ranking &&
                      ranking.finalResult &&
                      ranking.finalResult.length !== 0 ? (
                        ranking.finalResult.map((rank, i) => (
                          <tr>
                            <td>{rank.rank}위</td>
                            <td>{rank.teamName}</td>
                            <td>{rank.wins}승</td>
                            <td>{rank.losses}패</td>
                            <td>{rank.scoreFor}</td>
                            <td>{rank.scoreAgainst}</td>
                            <td>
                              {rank.goalDifference > 0 && "+"}
                              {rank.goalDifference}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <h4 className="text-center">
                              조별 경기가 끝난 후, 업데이트 됩니다.
                            </h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table> */}
                  </div>
                ) : (
                  <Table
                    bordered
                    responsive
                    className="table-sm text-center mt-3 mx-auto"
                    style={{ maxWidth: "600px" }}
                  >
                    {!(competitionId === "6576baac226b480dfa455493" && round === "1") && (<>
                      <thead>
                        <tr className="ranking__table__header">
                          <th colSpan="7">
                            <h5
                              className="ranking__table__text"
                              style={{
                                color: "white",
                                marginTop: "8px",
                                marginLeft: "8px",
                              }}
                            >
                              {competitionRoundInfo &&
                                competitionRoundInfo.roundInfo &&
                                competitionRoundInfo.roundInfo.name}{" "}
                              <br />
                              {roundInfo &&
                                roundInfo.round[round - 1][1] + " " + "순위"}
                            </h5>
                          </th>
                        </tr>
                        <tr>
                          <th>순위</th>
                          <th>팀명</th>
                          <th>승</th>
                          <th>패</th>
                          <th>득점</th>
                          <th>실점</th>
                          <th>득실</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ranking &&
                          !ranking.group1Rank &&
                          ranking.map((rank, i) => (
                            <tr key={i}>
                              <td>{rank.rank}위</td>
                              <td>{rank.teamName}</td>
                              <td>{rank.wins}승</td>
                              <td>{rank.losses}패</td>
                              <td>{rank.scoreFor}</td>
                              <td>{rank.scoreAgainst}</td>
                              <td>
                                {rank.goalDifference > 0 && "+"}
                                {rank.goalDifference}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                    )}
                  </Table>
                )}
              </>)}
              <div style={{ "padding-left": "0px", "padding-right": "0px" }}>
                {/* <p>경기일 : {roundInfo && roundInfo.dates[0].substring(0, 10)}</p> */}
                <Table responsive className="table-sm text-center mt-3">
                  <thead>
                    <tr className="table__header">
                      <th
                        style={{
                          "padding-left": "4px",
                          "padding-right": "4px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBasketball}
                          style={{
                            fontSize: "13px",
                            color: "white",
                          }}
                        />
                      </th>
                      <th>경기명</th>
                      <th>HOME</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>AWAY</th>
                      <span className="mobile__hide">
                        <th style={{ "background-color": "#031b31" }}></th>
                      </span>
                    </tr>
                  </thead>
                  <tbody>
                    {roundGame.map((game, i) => (
                      <tr key={i}>
                        <GameSchedule key={i} id={game} index={i} />
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-center mt-5 mb-5">
              <h2 className="mt-5 mb-5 text-center">
                경기 결과가 곧 업데이트 됩니다.
              </h2>
              <Image
                src={`/time4_logo_message.png`}
                style={{ width: "26rem" }}
                className="text-center mt-5 mb-5"
              />
            </Row>
          )}
        </>
      )}
      <br />
      <>
        {loadingStats && <Loader />}
        {stats && (competition.roundStatStandard.length > 0) && (
          <>
            <div
              className="result__statics__header"
              style={{
                marginLeft: "-12px",
                marginRight: "-12px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <h2
                style={{
                  "font-weight": "900",
                  color: "white",
                  "text-align": "center",
                }}
              >
                대회 통계
              </h2>
              <p className="text-center">
                조회하고자 하는 부문을 선택해주세요.
              </p>
              <p style={{ fontSize: "0.8rem", "text-align": "center" }}>
                슛 통계 기준 : 2점 성공-
                {competition && competition.roundStatStandard[round - 1][0]}번
                이상{" "}
                {!(competition && competition.roundStatStandard[1] === 0) &&
                  `| 3점 성공-${competition && competition.roundStatStandard[round - 1][1]
                  }번 이상 `}
                | 자유투 성공-
                {competition && competition.roundStatStandard[round - 1][2]}번
                이상 | 이외 통계는 경기 수-
                {competition && competition.roundStatStandard[round - 1][3]}경기
                이상
              </p>
            </div>
            <Row style={{ "margin-left": "20%", "margin-right": "20%" }}>
              <Select
                options={
                  competition &&
                    competition.roundStatStandard[round - 1][1] === 0
                    ? statsTypeOption.filter(
                      (option) => option.value !== "threePointThrow"
                    )
                    : statsTypeOption
                }
                onChange={statsTypeHandler}
                defaultValue={statsTypeOption[0]}
                isSearchable={false}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#031B31",
                    border: "1px solid #031B31", // Set the border color
                    borderRadius: "4px", // Add border radius for a rounded look
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#FFFFFF", // Set the text color
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#031B31"
                      : "transparent", // Set the option background color
                    color: state.isSelected ? "#FFFFFF" : "#333", // Set the option text color
                  }),
                }}
              />
            </Row>

            {stats && getStatsByType(statsType, true).length == 0 && (
              <h5 className="mb-4 text-center mt-5">
                ❌ 해당 부문은 기준에 적합한 선수가 없습니다. ❌
              </h5>
            )}
            {/* 1등 표시 */}
            {stats && competition.roundStatStandard.length > 0 &&
              getStatsByType(statsType, true).map((stat, index) => (
                <>
                  <Row
                    className="justify-content-center mx-1 mt-5 pt-4 pb-4 ranking__first"
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                    }}
                  >
                    <h1 className="mb-4 text-center">
                      {statsTypeOption.find((x) => x.value === statsType).label}{" "}
                      부문 {stat.rank} 🏆
                    </h1>
                    <Col className="text-end">
                      <div>
                        <Image
                          src={
                            stat.image ? stat.image : "/player_default_img.png"
                          }
                          className="first-ranking-player-image"
                        />
                      </div>
                    </Col>
                    <Col className="justify-content-center align-self-center">
                      <h3 className="mt-3 mb-3" style={{ color: "#FFA500" }}>
                        {firstPlaceHeader(stat).map((header) => (
                          <>
                            {header[0]} : {header[1]}
                            <br />
                          </>
                        ))}
                      </h3>
                      <hr
                        className="first-ranking-player-hr"
                        style={{ color: "white" }}
                      />
                      <h3>
                        <Link
                          to={`/player/${stat.playerId}`}
                          style={{ color: "white" }}
                        >
                          {stat.name}
                        </Link>
                      </h3>
                      <h4>{stat.teamName}</h4>
                    </Col>
                  </Row>
                </>
              ))}
            <hr />
            {/* 2등부터 나열 */}
            <Table
              responsive
              borderless="true"
              className="table-sm text-center"
            >
              <thead>
                <tr className="table__header">
                  {stats && tableHeader(getStatsByType(statsType, true))}
                </tr>
              </thead>
              <tbody>
                {stats &&
                  getStatsByType(statsType, false).map((stat, index) => (
                    <tr key={index}>{tableBody(stat)}</tr>
                  ))}
              </tbody>
            </Table>
          </>
        )}
      </>
    </>
  );
};

export default LeagueRoundDetailScreen;
