import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { listPickupCompetitionDetail } from "../actions/pickupActions";
import { PICKUP_COMPETITION_DETAIL_RESET } from "../constants/pickupConstants";
import { updatePickup } from "../actions/pickupActions";
import axios from "axios";
import { accessControl } from "../utils/access";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";


// const PickupGameTeamMakerScreen = () => {
//   const MovableItem = ({
//     name,
//     index,
//     currentColumnName,
//     moveCardHandler,
//     setItems
//   }) => {
//     const changeItemColumn = (currentItem, columnName) => {
//       setItems((prevState) => {
//         return prevState.map((e) => {
//           return {
//             ...e,
//             column: e.name === currentItem.name ? columnName : e.column
//           };
//         });
//       });
//     };

//     const ref = useRef(null);

//     const [, drop] = useDrop({
//       accept: "card",
//       hover(item, monitor) {
//         if (!ref.current) {
//           return;
//         }

//         const dragIndex = item.index;
//         const hoverIndex = index;

//         if (dragIndex === hoverIndex) {
//           return;
//         }

//         const hoverBoundingRect = ref.current.getBoundingClientRect();
//         const hoverMiddleY =
//           (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
//         const clientOffset = monitor.getClientOffset();
//         const hoverClientY = clientOffset.y - hoverBoundingRect.top;

//         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
//           return;
//         }

//         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
//           return;
//         }

//         moveCardHandler(dragIndex, hoverIndex);
//         item.index = hoverIndex;
//       }
//     });

//     const [{ isDragging }, drag] = useDrag({
//       type: "card",
//       item: { name, index, currentColumnName },
//       collect: (monitor) => {
//         const dropResult = monitor.getDropResult();

//         if (dropResult) {
//           const { name } = dropResult;
//           switch (name) {
//             case "column1":
//               changeItemColumn({ name }, "column1");
//               break;
//             case "column2":
//               changeItemColumn({ name }, "column2");
//               break;
//             case "column3":
//               changeItemColumn({ name }, "column3");
//               break;
//             default:
//               break;
//           }
//         }
//       },
//       collect: (monitor) => ({
//         isDragging: monitor.isDragging()
//       })
//     });

//     const opacity = isDragging ? 0.4 : 1;

//     drag(drop(ref));

//     return (
//       <div ref={ref} className="movable-item" style={{ opacity }}>
//         {name}
//       </div>
//     );

//   }


//   const Column = ({ children, className, title}) => {
//     const [{ isOver, canDrop }, drop] = useDrop({
//       accept: "card",
//       drop: () => ({ name: title }),
//       collect: (monitor) => ({
//         isOver: monitor.isOver(),
//         canDrop: monitor.canDrop()
//       }),

//       canDrop: () => { return ( true ); }
//     });
//     const getBackgroundColor = () => {
//       if (isOver) {
//         if (canDrop) {
//           return "rgb(188,251,255)";
//         } else if (!canDrop) {
//           return "rgb(255,188,188)";
//         }
//       } else {
//         return "";
//       }
//     };
  
//     return (
//       <div
//         ref={drop}
//         className={className}
//         style={{ backgroundColor: getBackgroundColor() }}
//       >
//         <p>{title}</p>
//         {children}
//       </div>
//     );
//   };

//   const tasks = [
//     {id: 1, name: 'Item 1', column: "column1"},
//     {id: 2, name: 'Item 2', column: "column2"},
//     {id: 3, name: 'Item 3', column: "column3"},
//   ];
//   const [items, setItems] = useState(tasks);

//   const moveCardHandler = (dragIndex, hoverIndex) => {
//     const dragItem = items[dragIndex];

//     alert("!");
//     if (dragItem) {
//       setItems((prevState) => {
//         const coppiedStateArray = [...prevState];

//         // remove item by "hoverIndex" and put "dragItem" instead
//         const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

//         // remove item by "dragIndex" and put "prevItem" instead
//         coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

//         return coppiedStateArray;
//       });
//     }
//   };
//   const returnItemsForColumn = (columnName) => {
//     return items
//       .filter((item) => item.column === columnName)
//       .map((item, index) => (
//         <MovableItem
//           key={item.id}
//           name={item.name}
//           currentColumnName={item.column}
//           setItems={setItems}
//           index={index}
//           moveCardHandler={moveCardHandler}
//         />
//       ));
//   };
  
//   return (
//     <>
//     <h1>asdf</h1>
//     {items.map((item) => (
//       <div key={item.id}>{item.column}</div>
//     ))}
//     <div className="container">
//     <DndProvider backend={HTML5Backend}>

//     <h2>asd</h2>
//       <Column title="column1" className="column">
//         {returnItemsForColumn("column1")}
//       </Column>
//       <Column title="column2" className="column">
//         {returnItemsForColumn("column2")}
//       </Column>
//       <Column title="column3" className="column">
//         {returnItemsForColumn("column3")}
//       </Column>
//     </DndProvider>

//     </div>
//     </>
//   )

// };

const PickupGameTeamMakerScreen = ({ match, history }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const pickupCompetitionDetail = useSelector((state) => state.pickupCompetitionDetail);
  const { loading, error, pickupCompetition } = pickupCompetitionDetail;


  const [teamCnt, setTeamCnt] = useState(0);
  const [teamCntRenderer, setTeamCntRenderer] = useState(0);
  const [applyQueueOption, setApplyQueueOption] = useState([]);
  const [teamPlayerId, setTeamPlayerId] = useState([]);

  const handleTeamPlayerChange = (selected, i) => {
    let copy = [...teamPlayerId];
    if (selected.length >= 1) {
      copy[i] = selected;
      setTeamPlayerId(copy);
    } else {
      copy[i] = [];
      setTeamPlayerId(copy);
    }
  };

  const teamMakerRenderer = (e) => {
    setTeamCntRenderer(teamCnt);
    const initArray = [];
    for (let i = 0; i < teamCnt; i++) {
      initArray.push([]);
    }
    setTeamPlayerId(initArray);
  };

  const applyQueueOptionGenerator = (applyQueue) => {
    const option = [];
    applyQueue.map(async (id) => {
      let res = await axios.get(`https://api.time4.co.kr/api/player-profile/name/${id}`);
      let data = res.data;
      option.push({ value: id, label: data });
    });
    return option;
  };

  let teamName;

  const teamMaker = () => {
    const maker = [];
    for (let i = 0; i < teamCntRenderer; i++) {
      maker.push(
        <div>
          <h2>팀 {i + 1}</h2>
          <input
            type="text"
            id={`teamName${i}`}
            value={teamName}
          />
          <Select
              options={applyQueueOption}
              closeMenuOnSelect={false}
              isSearchable
              isMulti
              onChange={(e) => handleTeamPlayerChange(e, i)}
              defaultValue={teamPlayerId[i]}
              //components={{ MultiValueRemove }}
            />
        </div>
      );
    }
    return maker;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const res = document.getElementById("teamName0").value;
    const group = [];
    for (let i = 0; i < teamCnt; i++) {
      const members = [];
      teamPlayerId[i].map((id) => members.push(id.value));
      group.push({
        groupName: document.getElementById(`teamName${i}`).value,
        members: members
      })
    }

    dispatch(updatePickup({
      _id: id,
      name: pickupCompetition.name,
      host: pickupCompetition.host,
      hostId: pickupCompetition.hostId,
      location1: pickupCompetition.location1,
      location2: pickupCompetition.location2,
      place: pickupCompetition.place,
      gameDate: pickupCompetition.gameDate,
      isRecruiting: pickupCompetition.isRecruiting,
      applyQueue: pickupCompetition.applyQueue,
      notice: pickupCompetition.notice,
      games: pickupCompetition.games,
      group: group
    }))
  };

  useEffect(() => {
    accessControl([200], history);
    dispatch({ type: PICKUP_COMPETITION_DETAIL_RESET })
    dispatch(listPickupCompetitionDetail(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!pickupCompetition) {
      dispatch(listPickupCompetitionDetail(id));
    } else {
      setApplyQueueOption(applyQueueOptionGenerator(pickupCompetition.applyQueue));
    }
  }, [dispatch, pickupCompetition]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div>
            <h1>픽업게임 팀 편성</h1>
            <h2>팀 수</h2>
            <input
              type="number"
              onChange={(e) => setTeamCnt(e.target.value)}
              value={teamCnt}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={teamMakerRenderer}
            > 팀 편성 </Button>
          </div>
          <div>
            {teamMaker()}
          </div>
          <Button
            className="submit-button mt-2"
            onClick={submitHandler}
            style={{ width: "100%" }}
          >
            편성 완료
          </Button>
        </>
      )}

    </>
  );

}


export default PickupGameTeamMakerScreen;