import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Row } from "react-bootstrap";
import { writeNotice } from "../actions/noticeActions";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";

const NoticeInputScreen = ({ history }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(writeNotice(title, content));
  };

  const Editor = ({ setContent, content, setImage }) => {
    const [flag, setFlag] = useState(false);

    const customUploadAdapter = (loader) => {
      return {
        upload() {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            loader.file
              .then((file) => {
                data.append("name", file.name);
                data.append("image", file);
                const config = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                };
                axios
                  .post(`${API_SERVER_URL}/api/upload`, data, config)
                  .then((res) => {
                    if (!flag) {
                      setFlag(true);
                      setImage(res.data);
                    }
                    resolve({ default: res.data });
                  });
              })
              .catch((err) => reject(err));
          });
        },
      };
    };
    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return customUploadAdapter(loader);
      };
    }

    return (
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        data=""
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
        }}
      />
    );
  };

  useEffect(() => {
    accessControl([], history);
  }, [history]);

  return (
    <div className="hideextra" style={{ "min-width": "5px" }}>
      <div className="form-wrapper">
        <h2 className="mx-1">새 공지 작성</h2>
        <Row>
          <div style={{ "text-align": "right" }}>
            <Button
              className="submit-button mt-2"
              onClick={submitHandler}
              style={{ width: "100px" }}
            >
              등록하기
            </Button>
          </div>
          <input
            className="title-input mt-2 mb-3"
            type="text"
            placeholder="제목을 입력하세요"
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            style={{
              width: "100%",
              height: "25px",
              "border-top": "none",
              "border-left": "none",
              "border-right": "none",
              "border-bottom": "3px solid #011241",
            }}
          />
        </Row>
        {Editor({ setContent, content, setImage })}
      </div>
    </div>
  );
};

export default NoticeInputScreen;
