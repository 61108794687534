import {
  PICKUP_COMPETITION_LIST_REQUEST,
  PICKUP_COMPETITION_LIST_SUCCESS,
  PICKUP_COMPETITION_LIST_FAIL,
  PICKUP_COMPETITION_LIST_RESET,
  PICKUP_COMPETITION_DETAIL_REQUEST,
  PICKUP_COMPETITION_DETAIL_SUCCESS,
  PICKUP_COMPETITION_DETAIL_FAIL,
  PICKUP_COMPETITION_DETAIL_RESET,
  PICKUP_COMPETITION_INPUT_REQUEST,
  PICKUP_COMPETITION_INPUT_SUCCESS,
  PICKUP_COMPETITION_INPUT_FAIL,
  PICKUP_COMPETITION_INPUT_RESET,
  PICKUP_COMPETITION_UPDATE_REQUEST,
  PICKUP_COMPETITION_UPDATE_SUCCESS,
  PICKUP_COMPETITION_UPDATE_FAIL,
  PICKUP_COMPETITION_UPDATE_RESET,
  PICKUP_COMPETITION_HOST_LIST_REQUEST,
  PICKUP_COMPETITION_HOST_LIST_SUCCESS,
  PICKUP_COMPETITION_HOST_LIST_FAIL,
  PICKUP_COMPETITION_HOST_LIST_RESET,
  PICKUP_COMPETITION_STATS_REQUEST,
  PICKUP_COMPETITION_STATS_SUCCESS,
  PICKUP_COMPETITION_STATS_FAIL,
  PICKUP_COMPETITION_STATS_RESET,
  PICKUP_GAME_INPUT_REQUEST,
  PICKUP_GAME_INPUT_SUCCESS,
  PICKUP_GAME_INPUT_FAIL,
  PICKUP_GAME_INPUT_RESET,
  PICKUP_GAME_DETAIL_REQUEST,
  PICKUP_GAME_DETAIL_SUCCESS,
  PICKUP_GAME_DETAIL_FAIL,
  PICKUP_GAME_DETAIL_RESET,
  PICKUP_GAME_LIST_REQUEST,
  PICKUP_GAME_LIST_SUCCESS,
  PICKUP_GAME_LIST_FAIL,
  PICKUP_GAME_LIST_RESET,
  PICKUP_GAME_RESULT_REQUEST,
  PICKUP_GAME_RESULT_SUCCESS,
  PICKUP_GAME_RESULT_FAIL,
  PICKUP_GAME_RESULT_RESET,
  PICKUP_PLAYER_HOST_LIST_REQUEST,
  PICKUP_PLAYER_HOST_LIST_SUCCESS,
  PICKUP_PLAYER_HOST_LIST_FAIL,
  PICKUP_PLAYER_HOST_LIST_RESET,
  PICKUP_PLAYER_HOST_RECORD_REQUEST,
  PICKUP_PLAYER_HOST_RECORD_SUCCESS,
  PICKUP_PLAYER_HOST_RECORD_FAIL,
  PICKUP_PLAYER_HOST_RECORD_RESET,
  PICKUP_LIST_REQUEST,
  PICKUP_LIST_SUCCESS,
  PICKUP_LIST_FAIL,
  PICKUP_DETAIL_REQUEST,
  PICKUP_DETAIL_SUCCESS,
  PICKUP_DETAIL_FAIL,
  PICKUP_CREATE_REQUEST,
  PICKUP_CREATE_SUCCESS,
  PICKUP_CREATE_FAIL,
  PICKUP_UPDATE_REQUEST,
  PICKUP_UPDATE_SUCCESS,
  PICKUP_UPDATE_FAIL,
} from "../constants/pickupConstants";

export const pickupCompetitionListReducer = (state = { pickupCompetitions: [] }, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_LIST_REQUEST:
      return { loading: true };
    case PICKUP_COMPETITION_LIST_SUCCESS:
      return {
        loading: false,
        pickupCompetitions: action.payload.pickupCompetition,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PICKUP_COMPETITION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_COMPETITION_LIST_RESET:
      return { pickupCompetitions: [] };
    default:
      return state;
  }
};

export const pickupCompetitionDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_DETAIL_REQUEST:
      return { loading: true, ...state};
    case PICKUP_COMPETITION_DETAIL_SUCCESS:
      return { loading: false, pickupCompetition: action.payload };
    case PICKUP_COMPETITION_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_COMPETITION_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const pickupGameListReducer = (state = { pickupCompetition: [] }, action) => {
  switch (action.type) {
    case PICKUP_GAME_LIST_REQUEST:
      return { loading: true, ...state };
    case PICKUP_GAME_LIST_SUCCESS:
      return {
        loading: false,
        pickupCompetition: [...state.pickupCompetition, action.payload].sort((a, b) => {
          return a.gameNum - b.gameNum;
        }) 
      };
    case PICKUP_GAME_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_GAME_LIST_RESET:
      return { pickupCompetition: [] };
    default:
      return state;
  }
};

export const pickupGameResultReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case PICKUP_GAME_RESULT_REQUEST:
      return { loading: true, ...state };
    case PICKUP_GAME_RESULT_SUCCESS:
      return { loading: false, result: action.payload };
    case PICKUP_GAME_RESULT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupCompetitionInputReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_INPUT_REQUEST:
      return { loading: true };
    case PICKUP_COMPETITION_INPUT_SUCCESS:
      return { loading: false };
    case PICKUP_COMPETITION_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_COMPETITION_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const pickupCompetitionUpdateReducer = (state = { pickupCompetition: {} }, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_UPDATE_REQUEST:
      return { loading: true };
    case PICKUP_COMPETITION_UPDATE_SUCCESS:
      return { loading: false, success: true, pickupCompetition: action.payload };
    case PICKUP_COMPETITION_UPDATE_FAIL:
      alert("[오류] 실패하였습니다.");
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupCompetitionHostListReducer = (state = { pickupCompetitionsHost: [] }, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_HOST_LIST_REQUEST:
      return { loading: true };
    case PICKUP_COMPETITION_HOST_LIST_SUCCESS:
      return {
        loading: false,
        pickupCompetitionsHost: action.payload
      };
    case PICKUP_COMPETITION_HOST_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_COMPETITION_HOST_LIST_RESET:
      return { pickupCompetitionsHost: [] };
    default:
      return state;
  }
};

export const pickupGameInputReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_GAME_INPUT_REQUEST:
      return { loading: true };
    case PICKUP_GAME_INPUT_SUCCESS:
      return { loading: false };
    case PICKUP_GAME_INPUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupListReducer = (state = { pickups: [] }, action) => {
  switch (action.type) {
    case PICKUP_LIST_REQUEST:
      return { loading: true };
    case PICKUP_LIST_SUCCESS:
      return {
        loading: false,
        pickups: action.payload,
      };
    case PICKUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupGameDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_GAME_DETAIL_REQUEST:
      return { loading: true, ...state };
    case PICKUP_GAME_DETAIL_SUCCESS:
      return { loading: false, pickupGame: action.payload };
    case PICKUP_GAME_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_CREATE_REQUEST:
      return { loading: true };
    case PICKUP_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PICKUP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_UPDATE_REQUEST:
      return { loading: true };
    case PICKUP_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PICKUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pickupPlayerHostListReducer = (
  state = { pickupHosts: [] },
  action
) => {
  switch (action.type) {
    case PICKUP_PLAYER_HOST_LIST_REQUEST:
      return { ...state, loading: true };
    case PICKUP_PLAYER_HOST_LIST_SUCCESS:
      return { loading: false, pickupHosts: action.payload };
    case PICKUP_PLAYER_HOST_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_PLAYER_HOST_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const pickupPlayerHostRecordReducer = (state = { hostRecord: [] }, action) => {
  switch (action.type) {
    case PICKUP_PLAYER_HOST_RECORD_REQUEST:
      return { ...state, loading: true };
    case PICKUP_PLAYER_HOST_RECORD_SUCCESS:
      return {
        loading: false,
        hostRecord: [...state.hostRecord, action.payload],
      };
    case PICKUP_PLAYER_HOST_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case PICKUP_PLAYER_HOST_RECORD_RESET:
      return { hostRecord: [] };
    default:
      return state;
  }
};

export const pickupCompetitionStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case PICKUP_COMPETITION_STATS_REQUEST:
      return { loading: true };
    case PICKUP_COMPETITION_STATS_SUCCESS:
      return { loading: false, stats: action.payload };
    case PICKUP_COMPETITION_STATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}