import React from "react";
import { Link } from "react-router-dom";

const PlayerSeasonTeam = ({ record }) => {
  return (
    <>
        <>
          <td
            style={{
              position: "sticky",
              left: "0",
              "z-index": "1",
              background: "white",
              padding: "6px",
            }}
          >
            <div className="hideextra" style={{ "min-width": "50px" }}>
              <Link to={`/player/${record && record.playerId}`}>{record && record.name}</Link>
              {/* <Link to={`/admin/user/${record && record.playerId}`}>{record && record.name}</Link> */}
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div className="hideextra" style={{ "min-width": "30px" }}>
              {record && record.gameCount}
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "30px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.score / record.gameCount).toFixed(1)}
              <br />({record && record.score})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.fieldGoal[0] === 0
                ? 0
                : record &&
                  (record.fieldGoal[1] / record.gameCount).toFixed(1) +
                    " - " +
                    (record.fieldGoal[0] / record.gameCount).toFixed(1)}
              <br />({record && record.fieldGoal[1]} -{" "}
              {record && record.fieldGoal[0]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "30px", "line-height": "120%" }}
            >
              {record && record.fieldGoal[1] === 0
                ? 0
                : (
                    (record &&
                      record.fieldGoal[1] / record.fieldGoal[0]) * 100
                  ).toFixed(1)}
              %
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.threePointThrow[0] === 0
                ? 0
                : record &&
                  (record.threePointThrow[1] / record.gameCount).toFixed(
                    1
                  ) +
                    " - " +
                    (
                      record.threePointThrow[0] / record.gameCount
                    ).toFixed(1)}
              <br />({record && record.threePointThrow[1]} -{" "}
              {record && record.threePointThrow[0]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "30px", "line-height": "120%" }}
            >
              {record && record.threePointThrow[1] === 0
                ? 0
                : (
                    (record &&
                      record.threePointThrow[1] /
                        record.threePointThrow[0]) * 100
                  ).toFixed(1)}
              %
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.freeThrow[0] === 0
                ? 0
                : record &&
                  (record.freeThrow[1] / record.gameCount).toFixed(1) +
                    " - " +
                    (record.freeThrow[0] / record.gameCount).toFixed(1)}
              <br />({record && record.freeThrow[1]} -{" "}
              {record && record.freeThrow[0]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "35px", "line-height": "120%" }}
            >
              {record && record.freeThrow[1] === 0
                ? 0
                : (
                    (record &&
                      record.freeThrow[1] / record.freeThrow[0]) * 100
                  ).toFixed(1)}
              %
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.rebound[0] / record.gameCount).toFixed(1)}
              <br />({record && record.rebound[0]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.rebound[1] / record.gameCount).toFixed(1)}
              <br />({record && record.rebound[1]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "35px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.steal / record.gameCount).toFixed(1)}
              <br />({record && record.steal})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "35px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.block / record.gameCount).toFixed(1)}
              <br />({record && record.block})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "35px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.turnOver / record.gameCount).toFixed(1)}
              <br />({record && record.turnOver})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "35px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.foul[0] / record.gameCount).toFixed(1)}
              <br />({record && record.foul[0]})
            </div>
          </td>
          <td
            style={{
              "z-index": "-2",
              position: "relative",
              "background-color": "white",
              padding: "6px",
            }}
          >
            <div
              className="hideextra"
              style={{ "min-width": "40px", "line-height": "120%" }}
            >
              {record && record.gameCount === 0
                ? 0
                : record &&
                  (record.assist / record.gameCount).toFixed(1)}
              <br />({record && record.assist})
            </div>
          </td>
        </>
      
    </>
  );
};

export default PlayerSeasonTeam;
