export const NOTICE_LIST_REQUEST = "NOTICE_LIST_REQUEST";
export const NOTICE_LIST_SUCCESS = "NOTICE_LIST_SUCCESS";
export const NOTICE_LIST_FAIL = "NOTICE_LIST_FAIL";
export const NOTICE_LIST_RESET = "NOTICE_LIST_RESET";

export const NOTICE_DETAIL_REQUEST = "NOTICE_DETAIL_REQUEST";
export const NOTICE_DETAIL_SUCCESS = "NOTICE_DETAIL_SUCCESS";
export const NOTICE_DETAIL_FAIL = "NOTICE_DETAIL_FAIL";

export const NOTICE_INPUT_REQUEST = "NOTICE_INPUT_REQUEST";
export const NOTICE_INPUT_SUCCESS = "NOTICE_INPUT_SUCCESS";
export const NOTICE_INPUT_FAIL = "NOTICE_INPUT_FAIL";
export const NOTICE_INPUT_RESET = "NOTICE_INPUT_RESET";

export const NOTICE_UPDATE_REQUEST = "NOTICE_UPDATE_REQUEST";
export const NOTICE_UPDATE_SUCCESS = "NOTICE_UPDATE_SUCCESS";
export const NOTICE_UPDATE_FAIL = "NOTICE_UPDATE_FAIL";
export const NOTICE_UPDATE_RESET = "NOTICE_UPDATE_RESET";

export const NOTICE_DELETE_REQUEST = "NOTICE_DELETE_REQUEST";
export const NOTICE_DELETE_SUCCESS = "NOTICE_DELETE_SUCCESS";
export const NOTICE_DELETE_FAIL = "NOTICE_DELETE_FAIL";

export const COMPETITION_NOTICE_LIST_REQUEST =
  "COMPETITION_NOTICE_LIST_REQUEST";
export const COMPETITION_NOTICE_LIST_SUCCESS =
  "COMPETITION_NOTICE_LIST_SUCCESS";
export const COMPETITION_NOTICE_LIST_FAIL = "COMPETITION_NOTICE_LIST_FAIL";
export const COMPETITION_NOTICE_LIST_RESET = "COMPETITION_NOTICE_LIST_RESET";

export const COMPETITION_NOTICE_DETAIL_REQUEST =
  "COMPETITION_NOTICE_DETAIL_REQUEST";
export const COMPETITION_NOTICE_DETAIL_SUCCESS =
  "COMPETITION_NOTICE_DETAIL_SUCCESS";
export const COMPETITION_NOTICE_DETAIL_FAIL = "COMPETITION_NOTICE_DETAIL_FAIL";

export const COMPETITION_NOTICE_INPUT_REQUEST =
  "COMPETITION_NOTICE_INPUT_REQUEST";
export const COMPETITION_NOTICE_INPUT_SUCCESS =
  "COMPETITION_NOTICE_INPUT_SUCCESS";
export const COMPETITION_NOTICE_INPUT_FAIL = "COMPETITION_NOTICE_INPUT_FAIL";
export const COMPETITION_NOTICE_INPUT_RESET = "COMPETITION_NOTICE_INPUT_RESET";

export const COMPETITION_NOTICE_UPDATE_REQUEST =
  "COMPETITION_NOTICE_UPDATE_REQUEST";
export const COMPETITION_NOTICE_UPDATE_SUCCESS =
  "COMPETITION_NOTICE_UPDATE_SUCCESS";
export const COMPETITION_NOTICE_UPDATE_FAIL = "COMPETITION_NOTICE_UPDATE_FAIL";
export const COMPETITION_NOTICE_UPDATE_RESET =
  "COMPETITION_NOTICE_UPDATE_RESET";

export const COMPETITION_NOTICE_DELETE_REQUEST =
  "COMPETITION_NOTICE_DELETE_REQUEST";
export const COMPETITION_NOTICE_DELETE_SUCCESS =
  "COMPETITION_NOTICE_DELETE_SUCCESS";
export const COMPETITION_NOTICE_DELETE_FAIL = "COMPETITION_NOTICE_DELETE_FAIL";