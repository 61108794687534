import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { writePost } from "../actions/communityActions";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { detailCompetitionInfo } from "../actions/competitionActions";
import { writeGameArticle } from "../actions/gameArticleActions";

const ArticleWriteScreen = ({ match, history }) => {
  const gameId = match.params.id;
  
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const competitionDetailInfo = useSelector(
    (state) => state.competitionDetailInfo
  );
  const { loading, error, competitionDetail } = competitionDetailInfo;

  const submitGameArticle = () => {
    dispatch(writeGameArticle(content, gameId));

    alert("등록되었습니다.")
    history.push(`/videodetail/${gameId}`);
  };

  useEffect(() => {
    dispatch(detailCompetitionInfo(gameId));
  }, [dispatch, gameId]);

  return (
    <div className="hideextra" style={{ "min-width": "5px" }}>
      <div className="form-wrapper">
        <h4 className="mx-1">[{competitionDetail?.gameName}] 후기 작성</h4>
        <CKEditor
          editor={ClassicEditor}
          // config={ {plugins: [ Image ]}}
          data=""
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
        />
        <Button
          className="submit-button mt-2"
          onClick={submitGameArticle}
          style={{ width: "100px" }}
        >
          등록하기
        </Button>
      </div>
    </div>
  );
};

export default ArticleWriteScreen;
