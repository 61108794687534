import axios from "axios";
import { API_SERVER_URL } from "./actionUrl";
import {
  COMPETITION_APPLY_LIST_REQUEST,
  COMPETITION_APPLY_LIST_SUCCESS,
  COMPETITION_APPLY_LIST_FAIL,
  COMPETITION_APPLY_DETAIL_REQUEST,
  COMPETITION_APPLY_DETAIL_SUCCESS,
  COMPETITION_APPLY_DETAIL_FAIL,
  COMPETITION_APPLY_INPUT_REQUEST,
  COMPETITION_APPLY_INPUT_SUCCESS,
  COMPETITION_APPLY_INPUT_FAIL,
  COMPETITION_APPLY_UPDATE_REQUEST,
  COMPETITION_APPLY_UPDATE_SUCCESS,
  COMPETITION_APPLY_UPDATE_FAIL,
} from "../constants/competitionApplyConstants";

export const listApplies = (competitionId = "", applyPassword = "") => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_APPLY_LIST_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/competition-apply?competitionId=${competitionId}&applyPassword=${applyPassword}`);

    dispatch({ type: COMPETITION_APPLY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_APPLY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const detailApply = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_APPLY_DETAIL_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/competition-apply/${id}`);

    dispatch({ type: COMPETITION_APPLY_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_APPLY_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const inputApply = (apply) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_APPLY_INPUT_REQUEST });

    const { data } = await axios.post(`${API_SERVER_URL}/api/competition-apply`, apply);

    dispatch({ type: COMPETITION_APPLY_INPUT_SUCCESS, payload: data });

    alert("신청이 완료되었습니다.");

    window.location.reload();
  } catch (error) {
    dispatch({
      type: COMPETITION_APPLY_INPUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    return error.response.status;
  }
}

export const updateApply = (apply) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_APPLY_UPDATE_REQUEST });

    const { data } = await axios.put(`${API_SERVER_URL}/api/competition-apply/${apply._id}`, apply);

    dispatch({ type: COMPETITION_APPLY_UPDATE_SUCCESS, payload: data });

    alert("신청서 수정이 완료되었습니다.");

    window.location.reload();
  } catch (error) {
    dispatch({
      type: COMPETITION_APPLY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}