import React, { useState, useEffect } from "react";
import { Table, Row, Image, Button } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listPickupCompetitions } from "../actions/pickupActions";

const PickupGameBookingListScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;
  const location2 = "";

  const [location1, setLocation1] = useState(1);

  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleSeoul = () => {
    setLocation1(0);
  };
  const handleGyeonggi = () => {
    setLocation1(1);
  };
  const handleGangwon = () => {
    setLocation1(2);
  };
  const handleChungCheong = () => {
    setLocation1(3);
  };
  const handleJeonRa = () => {
    setLocation1(4);
  };
  const handleGyeongSang = () => {
    setLocation1(5);
  };

  const isPickupFull = (pickup) => {
    return pickup.recruitCount <= pickup.applyQueue.length;
  }
  const pickupCompetitionList = useSelector((state) => state.pickupCompetitionList);
  const { loading, error, pickupCompetitions, page, pages } = pickupCompetitionList;

  useEffect(() => {
    dispatch({ type: "PICKUP_COMPETITION_LIST_RESET" })
    dispatch(listPickupCompetitions(keyword, pageNumber, location1, location2));
  }, [dispatch, keyword, pageNumber, location1, location2]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="menu__header" style={{ marginBottom: "15px" }}>
            <h2
              className="mt-3 mb-3"
              style={{ color: "white", "font-weight": 700 }}
            >
              픽업게임 부킹
            </h2>
            <p style={{ color: "white" }}>조회하고자 하는 지역을 클릭하세요</p>
          </div>
          <div className="pickup__location__menu">
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",

              }}
              variant={location1 === 0 ? "warning" : "primary"}
              onClick={handleSeoul}
            >
              서울
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",
              }}
              variant={location1 === 1 ? "warning" : "primary"}
              onClick={handleGyeonggi}
            >
              경기도
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",
              }}
              variant={location1 === 2 ? "warning" : "primary"}
              onClick={handleGangwon}
            >
              강원도
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",
              }}
              variant={location1 === 3 ? "warning" : "primary"}
              onClick={handleChungCheong}
            >
              충청도
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",
              }}
              variant={location1 === 4 ? "warning" : "primary"}
              onClick={handleJeonRa}
            >
              전라도
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                padding: "8px 60px 8px 60px",
                fontWeight: "900",
                color: "white",
                marginTop: "10px",
              }}
              variant={location1 === 5 ? "warning" : "primary"}
              onClick={handleGyeongSang}
            >
              경상도
            </Button>
          </div>
          <Table striped hover responsive className="table-sm text-center mt-3">
            <thead>
              <tr
                style={{
                  color: "white",
                  "background-color": "#031b31",
                }}
              >
                <th>제목</th>
                <th>주최</th>
                <th>지역</th>
                <th>장소</th>
                <th>날짜</th>
              </tr>
            </thead>
            <tbody>
              {pickupCompetitions &&
                pickupCompetitions
                  .sort(
                    (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
                  )
                  .map((pickupCompetition) => (
                    <tr key={pickupCompetition._id}>
                      <td style={{ textAlign: "left", width: "300px" }}>
                        <div>
                          <Link
                            style={{ display: "flex", alignItems: "center" }}
                            to={`/pickupgame/booking/${pickupCompetition._id}`}>
                            <div
                              style={{
                                backgroundColor: isPickupFull(pickupCompetition) ? "#424242" : "#ff0000",
                                color: "#ffffff",
                                fontSize: "0.8rem",
                                padding: "2px 5px",
                                marginRight: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {isPickupFull(pickupCompetition) ? "종료" : "모집중"}
                            </div>
                            {pickupCompetition.name}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ "min-width": "130px" }}
                        >
                          <Link to={`/pickupgame/booking/${pickupCompetition._id}`}>
                            {pickupCompetition.hostNickname}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ "min-width": "130px" }}
                        >
                          {pickupCompetition.location2}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ "min-width": "130px" }}
                        >
                          {pickupCompetition.place}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ "min-width": "130px" }}
                        >
                          {pickupCompetition.gameDate.substring(0, 10)}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          {userInfo && userInfo.isAdmin && (
            <Row>
              <Link
                to={`/pickupgame/recruit`}
                style={{
                  "text-align": "right",
                  "font-size": "14px",
                  "margin-left": "-10px",
                }}
                className="mt-3 mb-2"
              >
                모집하기
              </Link>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default PickupGameBookingListScreen;
