// 0 : 일반회원
// 1 : 유료회원
// 100 : 기록원1 (대회 생성, 팀 등록, 선수 가입력, 오더지)
// 101 : 기록원2 (경기 입력만 가능)
// 99 : time4 직원 모드 (대회 생성, 팀 등록, 선수 가입력, 선수 수정, 선수 삭제, 오더지)
// 200 : 픽업게임
// 300 : 협회 관계자 (대회 생성, 경기 생성)
// 400 : 원장
// 401 : 강사

const accessControl = (accessList, history) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (!userInfo) {
    alert('접근 권한이 없습니다.');
    history.push('/');
    return false;
  }
  if (userInfo.isAdmin || accessList.includes(userInfo.tier)) {
    return true;
  } else {
    alert('접근 권한이 없습니다.');
    history.push('/');
    return false;
  }
}

export {
  accessControl,
}