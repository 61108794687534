import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import axios from "axios";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_SERVER_URL } from "../actions/actionUrl";

const Footer = () => {
  return (
    <footer>
      <Container
        style={{
          "margin-top": "70px",
          "margin-bottom": "30px",
        }}
      >
        <Row style={{ "background-color": "#031B31" }}>
          <Col
            className="text-center footer__on__pc1 footer__on__mobile1 pt-2 pb-2"
            style={{
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={faCircleExclamation} />{" "}
            <span className="footer__text">
              정보의 오류, 업데이트가 필요하면 언제든지 연락주세요.
            </span>
            <br className="mobile__footer__br" />
            <div className="mobile__footer__box"></div>
            <span className="footer__email">Email | koreatime4@gmail.com</span>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center footer__on__pc footer__on__mobile__2"
            style={{
              color: "gray",
            }}
          >
            경기 기록, 선수 정보 관련 문의 :{" "}
            <a href="https://pf.kakao.com/_axcXuG?from=qr">
              카카오채널 TIME4
              <Image src={`/qrcode_50.png`} className="mb-1" />
            </a>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col
            className="text-center footer__on__pc footer__on__mobile"
            style={{
              color: "gray",
            }}
          >
            (15596) 경기도 안산시 상록구 해양3로 15, 1002호 (사동)
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center footer__on__pc footer__on__mobile"
            style={{
              color: "gray",
            }}
          >
            대표 윤대호 | 사업자등록번호 162-81-02789 | Email :
            koreatime4@gmail.com
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3 copyright__on__mobile">
            Copyright&copy; TIME4 All Rights Reserved
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
