import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_SERVER_URL } from "../actions/actionUrl";

const PlayerName = ({ id }) => {
  let [name, setName] = useState("");

  async function getName() {
    let res = await axios.get(
      `${API_SERVER_URL}/api/player-profile/name/${id}`
    );
    let data = res.data;
    setName(data);
  }

  getName();

  return name;
};

export default PlayerName;
