import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  deleteCompetitionNotice,
  readCompetitionNotice,
  updateCompetitionNotice
} from "../actions/noticeActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { getUserTeam } from "../actions/userActions";
import { getOwnAssociation } from "../actions/associationActions";

const CompetitionNoticeDetailScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const competitionNoticeRead = useSelector((state) => state.competitionNoticeRead);

  const { loading, error, competitionNotice } = competitionNoticeRead;

  const userTeam = useSelector((state) => state.userTeam);
  const {
    loading: loadingUserTeam,
    error: errorUserTeam,
    userTeams,
  } = userTeam;

  const [selectedTeam, setSelectedTeam] = useState("");

  async function increaseView(id) {
    axios.put(
      `${API_SERVER_URL}/api/competition-notice/increase-view/${id}`
    );
  }

  const associationOwn = useSelector((state) => state.associationOwn);
  const { association } = associationOwn;

  useEffect(() => {
    if (competitionNotice) {
      dispatch(getOwnAssociation(competitionNotice?.hostId));
    }
  }, [dispatch, competitionNotice?.hostId]);

  useEffect(() => {
    dispatch(getUserTeam(userId));
  }, [dispatch, userId]);

  const teamOption = [];

  userTeams &&
    userTeams.map((team) => {
      teamOption.push({
        value: team._id,
        label: team.name,
      });
    });

  useEffect(() => {
    dispatch(readCompetitionNotice(id));
    increaseView(id);
  }, [dispatch, id]);

  const applyHandler = () => {
    if (!selectedTeam) {
      alert("팀을 선택해주세요.");
      return;
    }
    if (competitionNotice?.applyQueue.includes(selectedTeam)) {
      alert("이미 참가한 팀입니다.");
      return;
    }
    if (competitionNotice?.applyQueue.length >= competitionNotice?.recruitCount) {
      const confirm = window.confirm("모집 인원이 초과되어 대기팀으로 신청하게 됩니다. 대기 팀 신청하시겠습니까?");

      if (!confirm) {
        return;
      }
    }
    let applyQueue = competitionNotice.applyQueue;
    if (!applyQueue.includes(selectedTeam)) {
      applyQueue.push(selectedTeam);

      dispatch(
        updateCompetitionNotice({
          ...competitionNotice,
          applyQueue: applyQueue,
        })
      );
    }

    alert("신청되었습니다.");

    window.location.reload();
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      dispatch(deleteCompetitionNotice(id));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="post__main">
            <h3 style={{ color: "white" }}>
              {competitionNotice &&
                competitionNotice.competitionName}
            </h3>
            <p>
              작성일 {competitionNotice &&
                competitionNotice.date.substring(0, 10)}{" "}
              | 조회{" "}
              {competitionNotice &&
                competitionNotice.view}
            </p>
            <hr
              style={{
                backgroundColor: "white",
                marginLeft: "-16px",
                marginRight: "-16px",
              }}
            />
            <p>
              {/* {competitionNotice && competitionNotice.competitionInfo && competitionNotice.competitionInfo.name}{" "} */}
              <br />
              주최 : {association && association.name} <br />
              대회 일자 : {competitionNotice && competitionNotice.competitionDate.substring(0, 10)}{" "} <br />
              모집 팀 수 : {competitionNotice && competitionNotice.recruitCount}{" "} <br />
              모집된 팀 수 : {competitionNotice && competitionNotice.applyQueue.length}{" "}
              <br />
            </p>
          </div>
          <div className="post__content">
            {ReactHtmlParser(competitionNotice && competitionNotice.content)}
          </div>
          <div className="post__content">
            <h4>참가 신청</h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "16px" }}>신청할 팀을 선택해주세요 :</p>
              <Select
                options={teamOption}
                isSearchable
                onChange={(e) => setSelectedTeam(e.value)}
              />
              <Button
                variant="primary"
                onClick={applyHandler}
                style={{ marginLeft: "16px" }}
              >
                참가 신청
              </Button>
            </div>
          </div>
          <Row className="text-center mt-5">
            <Col>
              <Link to="/">
                <Button>메인으로</Button>
              </Link>
            </Col>
            <Col>
              <Link to="/competition-notice/list">
                <Button>공지 목록</Button>
              </Link>
            </Col>
            {userInfo && userInfo.isAdmin && (
              <>
                <Col>
                  <Link
                    to={`/competition-notice/edit/${competitionNotice &&
                      competitionNotice._id
                      }`}
                  >
                    <Button variant="success">수정하기</Button>
                  </Link>
                </Col>
                <Col>
                  <Button variant="danger" onClick={deleteHandler}>
                    삭제하기
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default CompetitionNoticeDetailScreen;
