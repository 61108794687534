import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { isPlayerDuplicate, playerInput } from "../actions/playerActions";
import { listTeams } from "../actions/teamActions";
import Select from "react-select";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const TempPlayerInputScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [height, setHeight] = useState("");
  const [footSize, setFootSize] = useState("");
  const [wingspan, setWingspan] = useState("");
  const [sargent, setSargent] = useState("");
  const [positionState, setPositionState] = useState(new Array(5).fill(false));
  const [jerseyNum, setJerseyNum] = useState("");
  const [teamId, setTeamId] = useState("");
  const [birthday, setBirthday] = useState("");

  const cropperRef = useRef(null);
  const [inputImage, setInputImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas());
  };

  const dispatch = useDispatch();

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  useEffect(() => {
    accessControl([99, 100], history);
    dispatch(listTeams());
  }, [dispatch]);

  const imageContainerStyle = {
    position: 'relative',
    display: 'inline-block'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'red',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '18px'
  };

  // 팀 리스트 선택
  const options = [];

  teams &&
    teams.map((team) =>
      options.push({
        value: team._id,
        label: team.name,
      })
    );

  //const positionList = ["PG", "SG", "SF", "PF", "C"];
  const positionList = [
    {
      value: "PG",
      label: "PG (1번)",
    },
    {
      value: "SG",
      label: "SG (2번)",
    },
    {
      value: "SF",
      label: "SF (3번)",
    },
    {
      value: "PF",
      label: "PF (4번)",
    },
    {
      value: "C",
      label: "C (5번)",
    },
  ];

  const birthday6to8 = (birthday) => {
    if (birthday.length === 6) {
      if (birthday.substring(0, 2) > Number(new Date().getFullYear().toString().substring(2, 4))) {
        return "19" + birthday;
      } else {
        return "20" + birthday;
      }
    }
    return birthday;
  };

  const handleTeamChange = (selected) => {
    setTeamId(selected.value);
  };

  const handlePositionChange = (pos) => {
    const updatedPositionState = positionState.map((item, index) =>
      index === pos ? !item : item
    );

    setPositionState(updatedPositionState);
  }

  // 선수 이미지 등록
  const [uploading, setUploading] = useState(false);

  const playerDuplicate = useSelector((state) => state.playerDuplicate);
  const {
    loading: loadingDuplicate,
    error: errorDuplicate,
    isDuplicate,
  } = playerDuplicate;

  const duplicateHandler = (name, birthday) => {
    dispatch(isPlayerDuplicate(name, birthday6to8(birthday.split(' ').join(''))));
  };

  const adminPlayerInput = useSelector((state) => state.adminPlayerInput);
  const { loading, error } = adminPlayerInput;

  const submitHandler = (e) => {
    e.preventDefault();
    const position = positionState.map((item, index) => item && positionList[index].value).filter(item => item);
    const teamJerseyNum = teamId !== "" ? [
      {
        teamId: teamId,
        jerseyNum: jerseyNum,
        position: position,
      },
    ] : [];

    const formData = new FormData();

    if (croppedImage !== null) {
      croppedImage.toBlob(async (blob) => {
        formData.append("image", blob, "image.jpg");

        setUploading(true);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const { data } = await axios.post(
            `${API_SERVER_URL}/api/upload`,
            formData,
            config
          );

          setUploading(false);

          dispatch(
            playerInput({
              name,
              image: data,
              birthday: birthday6to8(birthday.split(' ').join('')),
              height,
              footSize,
              wingspan,
              sargent,
              // position,
              // jerseyNum,
              teamJerseyNum,
              teamId: teamId !== "" ? [teamId] : [],
            })
          );
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      }, "image/jpeg");
    } else {
      dispatch(
        playerInput({
          name,
          image: "",
          birthday: birthday6to8(birthday.split(' ').join('')),
          height,
          footSize,
          wingspan,
          sargent,
          // position,
          // jerseyNum,
          teamJerseyNum,
          teamId: teamId !== "" ? [teamId] : [],
        })
      );
    }
  };

  return (
    <FormContainer>
      <h1>선수 가입력</h1>
      <p className="mt-2">
        모든 정보가 없어도 선수 등록이 가능합니다. <br />
        없는 정보는 비운 상태로 선수 등록을 해주세요.
      </p>
      <p style={{ color: "red" }}>
        [선수명 - 생년월일] 입력 후, 반드시 중복 확인 버튼을 눌러주세요.
      </p>
      {error && (
        <Message variant="danger">
          선수 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      {loadingTeam ? (
        <Loader />
      ) : errorTeam ? (
        <Message variant="danger">
          [오류] 팀 정보를 불러오지 못했습니다. 개발자에게 문의해주세요.
        </Message>
      ) : (
        <Form onSubmit={submitHandler} className="mt-3">
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="name">
                <Form.Label>선수명</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="선수명"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="birthday">
                <Form.Label>생년월일</Form.Label>
                <Form.Control
                  type="birthday"
                  placeholder="예) 920101"
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Button
                onClick={() => duplicateHandler(name, birthday)}
                variant="primary"
                style={{ "margin-top": "30px" }}
              >
                중복 확인
              </Button>
            </Col>
          </Row>
          {loadingDuplicate ? (
            <Loader />
          ) : errorDuplicate ? (
            <Message variant="danger">선수 중복 확인에 실패하였습니다.</Message>
          ) : (
            isDuplicate &&
            isDuplicate.isDuplicate === false && (
              <Message>선수 등록 가능합니다.</Message>
            )
          )}
          <Form.Group controlId="image" className="mt-3">
            <Form.Label>이미지</Form.Label>
            <Form.Group controlId="crop" className="mt-3">
              <input type="file" accept="image/*" onChange={(e) => setInputImage(URL.createObjectURL(e.target.files[0]))} />
              <Cropper
                src={inputImage}
                viewMode={1}
                crop={onCrop}
                ref={cropperRef}
                zoomable={false}
                initialAspectRatio={16 / 16}
              />
            </Form.Group>
            <div style={imageContainerStyle}>
              {image !== "" && <>
              <img src={image} alt="image" style={{ width: '100%' }}/>
              <button style={closeButtonStyle} onClick={deleteImageHandler}>X</button>
              </>}
              {image === "" && croppedImage === null &&
                <>
              <img src={"/player_default_img.png"} alt="image" style={{ width: '100%' }}/>
              </>}
            </div>
          </Form.Group>
          {uploading && <Loader />}
          <Form.Group controlId="teamId" className="mt-3">
            <Form.Label>소속 팀</Form.Label>
            <Select
              options={options}
              isSearchable
              onChange={handleTeamChange}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId="height" className="mt-3">
                <Form.Label>신장</Form.Label>
                <Form.Control
                  type="height"
                  placeholder="ex) 175"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="footSize" className="mt-3">
                <Form.Label>발크기</Form.Label>
                <Form.Control
                  type="footSize"
                  placeholder="ex) 275"
                  value={footSize}
                  onChange={(e) => setFootSize(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="wingspan" className="mt-3">
                <Form.Label>윙스팬</Form.Label>
                <Form.Control
                  type="wingspan"
                  placeholder="ex) 200"
                  value={wingspan}
                  onChange={(e) => setWingspan(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="sargent" className="mt-3">
                <Form.Label>서전트</Form.Label>
                <Form.Control
                  type="sargent"
                  placeholder="ex) 60"
                  value={sargent}
                  onChange={(e) => setSargent(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
            <Form.Label>포지션</Form.Label>
              {positionList.map((position, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    label={position.label}
                    id={`custom-checkbox-${index}`}
                    name={position}
                    value={position}
                    checked={positionState[index]}
                    onChange={() => handlePositionChange(index)}
                  />
                )
              })}
            </Col>
            <Col>
              <Form.Group controlId="jerseyNum" className="mt-3">
                <Form.Label>등번호</Form.Label>
                <Form.Control
                  type="jerseyNum"
                  placeholder="등번호"
                  value={jerseyNum}
                  onChange={(e) => setJerseyNum(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            style={{ width: "100%" }}
          >
            선수 등록
          </Button>
        </Form>
      )}
    </FormContainer>
  );
};

export default TempPlayerInputScreen;
