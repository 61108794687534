import {
  COMMUNITY_LIST_REQUEST,
  COMMUNITY_LIST_SUCCESS,
  COMMUNITY_LIST_FAIL,
  COMMUNITY_LIST_RESET,
  COMMUNITY_READ_REQUEST,
  COMMUNITY_READ_SUCCESS,
  COMMUNITY_READ_FAIL,
  COMMUNITY_WRITE_REQUEST,
  COMMUNITY_WRITE_SUCCESS,
  COMMUNITY_WRITE_FAIL,
  COMMUNITY_UPDATE_REQUEST,
  COMMUNITY_UPDATE_SUCCESS,
  COMMUNITY_UPDATE_FAIL,
  COMMUNITY_DELETE_REQUEST,
  COMMUNITY_DELETE_SUCCESS,

} from "../constants/communityConstants";

export const communityListReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case COMMUNITY_LIST_REQUEST:
      return { loading: true };
    case COMMUNITY_LIST_SUCCESS:
      return {
        loading: false,
        posts: action.payload.community,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case COMMUNITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMMUNITY_LIST_RESET:
      return { posts: [] };
    default:
      return state;
  }
};

export const communityReadReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case COMMUNITY_READ_REQUEST:
      return { ...state, loading: true };
    case COMMUNITY_READ_SUCCESS:
      return { loading: false, post: action.payload };
    case COMMUNITY_READ_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const communityWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMUNITY_WRITE_REQUEST:
      return { loading: true };
    case COMMUNITY_WRITE_SUCCESS:
      return { loading: false };
    case COMMUNITY_WRITE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const communityUpdateReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case COMMUNITY_UPDATE_REQUEST:
      return { loading: true };
    case COMMUNITY_UPDATE_SUCCESS:
      return { loading: false, success: true, post: action.payload };
    case COMMUNITY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const communityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMUNITY_DELETE_REQUEST:
      return { loading: true };
    case COMMUNITY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COMMUNITY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}