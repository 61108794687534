import React, { useState } from "react";
import Select from "react-select";
import optionsType from "../static/optionsType";

const TypeBox = ({ history, pageType }) => {
  const onChangeHandler = (selected) => {
    history.push(`/${pageType}/search/${selected.value}/page/1`);
  };

  return (
    <div>
      <Select
        options={optionsType}
        isSearchable={false}
        placeholder="종별 선택"
        onChange={onChangeHandler}
        inputProps={{ readOnly: true }}
        styles={{
          control: (provided) => ({
            ...provided,
            "border-radius": "50px",
            "border-style": "solid",
            "border-color": "#BABABA",
            "border-width": "1px",
            width: "20%",
            minWidth: "120px",
          }),
        }}
      />
    </div>
  );
};

export default TypeBox;
