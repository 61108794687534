import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listCompetitionInfo } from "../actions/competitionActions";
import Loader from "./Loader";
import Message from "./Message";
import { deleteGame } from "../actions/competitionActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { pickupGameListRecord, pickupGameRecord } from "../actions/pickupActions";

const PickupGameSchedule = ({ id, index }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const pickupGameList = useSelector((state) => state.pickupGameList);
  const { loading, error, pickupCompetition } = pickupGameList;

  const deleteHandler = (id) => {
    if (window.confirm("정말로 경기를 지우겠습니까?")) {
      dispatch(deleteGame(id));
    }
  };

  const lose = {
    color: "red",
  };

  const win = {
    color: "blue",
  };

  const imgStyle = {
    maxHeight: 48,
    maxWidth: 48,
  };
  
  useEffect(() => {
    dispatch(pickupGameListRecord(id));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <td
            className="align-middle"
            style={{
              "font-weight": "800",
              "padding-left": "0px",
              "padding-right": "0px",
            }}
          >
            <div style={{ "padding-bottom": "12px" }}>{index + 1}</div>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "5px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            <div className="hideextra" style={{ "min-width": "40px" }}>
              <h5 className="text__competition__mobile__h5">
                {pickupCompetition[index] && pickupCompetition[index]?.gameName}
              </h5>
              <span className="on__mobile__detail__btn__appear">
                <div className="mt-2">
                  {" "}
                  <Link
                    to={`/gamerecord/detail/${
                      pickupCompetition[index] && pickupCompetition[index]?._id
                    }`}
                  >
                    <button
                      className="result__btn align-middle"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <span className="btn__mobile__font">결과보기</span>
                    </button>
                  </Link>
                </div>
              </span>
            </div>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "0px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            {pickupCompetition[index] &&
            pickupCompetition[index]?.vsTeam[0] === "" ? (
              <Image
                src={"/dncurry_logo.png"}
                style={imgStyle}
                alt={"/dncurry_logo.png"}
                fluid
                className="mx-3"
              />
            ) : (
              <Image
                src={"/dncurry_logo.png"}
                style={imgStyle}
                alt={"/dncurry_logo.png"}
                fluid
                className="mx-3"
              />
            )}
            <br />
            <span className="mobile__team__name">
              {pickupCompetition[index] && pickupCompetition[index]?.vsTeam[0]}
            </span>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "0px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            <h3 className="competition__info__mobile">
              {pickupCompetition[index] && pickupCompetition[index]?.gameResult[0]}
              <br />
              <span
                className="competition__wl__mobile align-middle"
                style={
                  pickupCompetition[index] &&
                  pickupCompetition[index]?.gameResult[1] >
                    pickupCompetition[index]?.gameResult[0]
                    ? lose
                    : win
                }
              >
                {pickupCompetition[index] &&
                pickupCompetition[index]?.gameResult[0] ===
                  pickupCompetition[index]?.gameResult[1]
                  ? "무"
                  : pickupCompetition[index] &&
                    pickupCompetition[index]?.gameResult[1] >
                      pickupCompetition[index]?.gameResult[0]
                  ? "패"
                  : "승"}
              </span>
            </h3>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "10px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            <h3>VS</h3>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "0px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            <h3 className="competition__info__mobile">
              {pickupCompetition[index] && pickupCompetition[index]?.gameResult[1]}
              <br />
              <span
                className="competition__wl__mobile"
                style={
                  pickupCompetition[index] &&
                  pickupCompetition[index]?.gameResult[0] >
                    pickupCompetition[index]?.gameResult[1]
                    ? lose
                    : win
                }
              >
                {pickupCompetition[index] &&
                pickupCompetition[index]?.gameResult[0] ===
                  pickupCompetition[index]?.gameResult[1]
                  ? "무"
                  : pickupCompetition[index] &&
                    pickupCompetition[index]?.gameResult[0] >
                      pickupCompetition[index]?.gameResult[1]
                  ? "패"
                  : "승"}
              </span>
            </h3>
          </td>
          <td
            className="align-middle"
            style={{
              padding: "0px",
              "padding-top": "10px",
              "padding-bottom": "10px",
            }}
          >
            {pickupCompetition[index] &&
            pickupCompetition[index]?.vsTeam[1] === "" ? (
              <Image
                src={"/dncurry_logo.png"}
                style={imgStyle}
                alt={"/dncurry_logo.png"}
                fluid
                className="mx-3"
              />
            ) : (
              <Image
                src={"/dncurry_logo.png"}
                style={imgStyle}
                alt={"/dncurry_logo.png"}
                fluid
                className="mx-3"
              />
            )}
            <br />
            <span className="mobile__team__name">
              {pickupCompetition[index] && pickupCompetition[index]?.vsTeam[1]}
            </span>
          </td>
          <span className="on__pc__detail__btn__appear">
            <td className="align-middle" style={{ padding: "0px" }}>
              {" "}
              <div style={{ display: "flex" }} className="mt-3">
                <Link
                  to={`/pickupgame/game-record/detail/${
                    pickupCompetition[index] && pickupCompetition[index]?._id
                  }`}
                >
                  <Button
                    variant="primary"
                    style={{
                      "padding-left": "30px",
                      "padding-right": "30px",
                      "border-radius": "25px",
                    }}
                  >
                    결과 보기
                  </Button>
                </Link>
              </div>
            </td>
          </span>
        </>
      )}
    </>
  );
};

export default PickupGameSchedule;
