import React from 'react';

export default () => <div className="terms__box">
    <div className="terms__content">
        이용약관
        <br/>
        <br/> 제 1조 목적 이 약관은 TIME4(이하 타임4)가 운영하는
        웹사이트(www.time4.co.kr)에서 제공하는 인터넷 관련 서비스를 타임4
        계정으로 가입하여 이용함에 있어 사이트와 이용자의 권리, 의무 및
        책임사항을 규정함을 목적으로 합니다.
        <br/> 제 2조 정의 타임4(www.time4.co.kr)는 재화 또는 용역(이하 “재화
        등”이라 합니다)을 이용자에게 제공하기 위하여 컴퓨터 등 정보 통신설비를
        이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며
        아울러 타임4, 타임4 웹사이트를 운영하는 사업자의 의미로도 사용합니다.
        <br/> 이용자란 타임4에 접속하여 이 약관에 따라 "서비스"를 이용하는
        자를 통칭합니다.
        <br/>
        회원이라 함은 이용자 중 타임4 계정에 등록하고 타임4에 개인정보를
        제공하여 회원등록을 한 자로서, 타임4의 정보를 지속적으로 제공받으며
        "서비스"를 계속적으로 이용할 수 있는 자를 말합니다.
        <br/> 아이디(ID)라 함은 "회원"의 식별과 "서비스" 이용을 위하여
        "회원"이 정하고 타임4이 승인하는 문자와 숫자의 조합을 의미합니다.
        <br/> 비밀번호라 함은 "회원"이 부여 받은 아이디(ID)와 일치되는
        "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는
        숫자의 조합을 의미합니다.
        <br/> 유료서비스라 함은 타임4이 유료로 제공하는 각종
        온라인디지털콘텐츠(각종 정보콘텐츠, 통계서비스, 동영상, 리포트, 아이템
        및 기타 유료 콘텐츠를 포함) 및 제반 서비스를 의미합니다.
        <br/> 선불전자지급수단이라 함은 원볼포인트 등 타임4이 발행 당시 미리
        "회원"에게 공지한 전자금융거래법상 "선불전자지급수단"을 말합니다.
        <br/> 게시물이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스"상에
        게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진,
        동영상 및 각종 파일과 링크 등을 의미합니다. 제 3조 약관등의 명시와
        설명 및 개정 타임4은 이 약관의 내용과 상호 및 대표자 성명, 영업소
        소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
        전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호,
        개인정보관리책임자 등을 이용자가 알 수 있도록 서비스 화면에
        게시합니다.
        <br/> 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할
        수 있습니다.
        <br/> 타임4은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는
        내용 중 청약철회·배송책임·환불조건 등과 같은 중요한 내용을 이용자가
        이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의
        확인을 구합니다.
        <br/> 타임4은 전자상거래 등에서의 소비자보호에 관한 법률, 약관의
        규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 및
        정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등
        관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        <br/> 타임4은 이 약관을 개정할 경우에는 적용일자 및 개정사유를
        명시하여 현행약관과 함께 타임4 웹사이트의 초기화면에 그 적용일자 7일
        이전부터 적용일자 전일까지 공지합니다.
        <br/> 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한
        30일 이상의 사전 유예기간을 두고 공지합니다.
        <br/> 이 경우 타임4은 개정 전 내용과 개정 후 내용을 명확하게 비교하여
        이용자가 알기 쉽도록 표시합니다.
        <br/> 타임4은 약관을 개정할 경우에는 그 개정약관은 적용일자 이후에
        체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
        개정전의 약관조항이 그대로 적용됩니다.
        <br/> 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를
        원하는 뜻을 제4항에 의한 개정약관의 공지기간 내에 타임4에 요청하여
        타임4의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
        <br/> 타임4이 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게
        30일 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는
        뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
        의사표시를 하지 아니한 경우 회원이 개정 약관에 동의한 것으로 봅니다.
        <br/> 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의
        내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다.
        <br/>
        다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는
        이용계약을 해지할 수 있습니다.
        <br/> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
        전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한
        법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및
        관계법령 또는 상관례에 따릅니다.
        <br/> 제 4조 서비스의 제공 및 변경 타임4은 다음과 같은 업무를
        수행합니다.
        <br/> 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결과 용역의
        배송 인터넷 서비스 및 솔루션 제공 인맥, 친목을 위한 사회 관계망 서비스
        회사가 추가 개발하거나 다른 회사와의 제휴계약을 통해 회원에게 제공하는
        일체의 서비스 기타 타임4이 정하는 업무 타임4은 특별한 사유가 없는 한
        연중무휴, 1일 24시간 서비스를 제공한다.
        <br/> 다만, "서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는
        경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
        해당 서비스 초기화면에 게시하여야 합니다.
        <br/> 타임4은 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
        제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.
        <br/> 타임4은 무료로 제공되는 "서비스"의 일부 또는 전부를 회사의 정책
        및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에
        특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.
        <br/>
        타임4은 "유료서비스"를 제공할 경우 타임4에 이용요금에 대해서
        게시하여야 합니다.
        <br/> 타임4은 "유료서비스" 이용금액을 "서비스"의 종류 및 기간에 따라
        예고 없이 변경할 수 있습니다.
        <br/> 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지
        않습니다.
        <br/> "유료서비스" 신청후 "회원" 사정에 의해 서비스를 취소할 경우,
        정부가 고시하는 "디지털콘텐츠 이용자보호지침"에 따라 타임4은 본 지침이
        제시하는 범위 내에서 환불 수수료를 부과할 수 있습니다.
        <br/> 제 5조 회원가입 및 이용계약의 체결 타임4 계정에 등록된 이용자는
        타임4이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에
        동의한다는 의사표시를 함으로서 회원 가입을 신청합니다.
        <br/>
        타임4은 이용자의 가입신청에 대하여 "서비스" 이용을 승낙함을 원칙으로
        합니다.
        <br/> 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
        않거나 사후에 이용계약을 해지할 수 있습니다.
        <br/> 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
        있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
        <br/> 실명이 아니거나 타인의 명의를 이용한 경우 허위의 정보를
        기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 14세 미만 아동이
        법정대리인(부모 등)의 동의를 얻지 아니한 경우 이용자의 귀책사유로
        인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는
        경우 회원가입 신청에 있어 회사는 전문기관을 통한 실명확인 및
        본인인증을 요청할 수 있습니다.
        <br/> 회사는 서비스 관련설비의 여유가 없거나, 기술상 또는 업무상
        문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        <br/> 본 조 제2항과 제4항에 따라 회원가입 신청의 승낙을 하지
        아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입 신청자에게
        알리도록 합니다.
        <br/> 이용 계약의 성립 시기는 타임4의 가입완료 또는 결제완료 등의
        신청절차 상에서 표시한 시점으로 합니다.
        <br/> 타임4은 회원에 대해 "서비스" 운영 정책에 따라 등급별로 구분하여
        이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수
        있습니다.
        <br/> "회원"이 "유료서비스"를 이용하기 위해서는 이 약관에 동의 후 각
        "서비스"에 따른 이용조건에 따라 이용요금을 지급하여야 합니다.
        <br/> 제 6조 서비스 이용의 제한 및 중지 타임4은 컴퓨터 등
        정보통신설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한
        경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
        <br/> 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
        정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
        <br/> 타임4은 "회원"이 이 약관의 의무를 위반하거나 "서비스"의
        정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로
        "서비스" 이용을 단계적으로 제한할 수 있습니다.
        <br/> 타임4은 전항에도 불구하고, 주민등록법을 위반한 명의도용 및
        결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의
        제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹,
        악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한
        경우에는 즉시 영구이용정지를 할 수 있습니다.
        <br/> 본 항에 따른 영구이용 정지시 "서비스" 이용을 통해 획득한 혜택이
        모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
        <br/>
        타임4은 본 조의 이용제한 범위와 제한조건 및 세부내용은 개별 "서비스"
        상의 운영정책이 정하는 바에 따르되, "회사"에서 제공하는 팀과 리그의
        "서비스" 및 제 2조 9항의 "사이트"들은 부분적인 독립 운영정책과
        이용약관을 따릅니다.
        <br/> 본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는
        경우에는 타임4은 제 8조에 따라 통지합니다.
        <br/> 제 7조 회원정보의 변경 "회원"은 개인정보 관리화면을 통하여
        언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
        <br/> 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이
        불가능합니다.
        <br/> "회원"은 회원가입 신청시 기재한 사항이 변경되었을 경우,
        온라인으로 수정을 하거나 전자우편 및 기타 방법으로 그 변경사항을
        알려야 합니다.
        <br/> 제 2항의 변경사항을 타임4에 알리지 않아 발생한 불이익에 대하여
        회사는 책임지지 않습니다.
        <br/> 제 8조 회원에 대한 통지 타임4이 회원에 대한 통지를 하는 경우,
        회원이 타임4과 미리 약정하여 지정한 전자우편주소, 전자쪽지 등으로 할
        수 있습니다.
        <br/>
        타임4은 불특정 다수 회원에 대한 통지의 경우 1주일이상 게시판에
        게시함으로써 개별 통지에 갈음할 수 있습니다.
        <br/> 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
        대하여는 개별통지를 합니다.
        <br/>
        타임4은 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대해서
        전자메일이나 서신우편 등의 방법으로 회원에게 제공할 수 있으며, 회원은
        원하지 않을 경우 가입신청 메뉴와 회원정보수정 메뉴에서 정보수신거부를
        할 수 있습니다.
        <br/> 단, 서비스의 종료·중단, 기타 서비스 내용에 중대한 변경 등의
        사유가 있을 경우에는 회원의 수신거부의사에도 불구하고 해당 내용을
        제공할 수 있습니다.
        <br/> 제 9조 회원의 청약철회와 계약해제, 해지 등 "회원"은 언제든지
        서비스 초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약
        해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를
        즉시 처리하여야 합니다.
        <br/> "회원"이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라
        회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든
        데이터는 소멸됩니다.
        <br/> "회원"이 계약을 해지하는 경우, 게시판에 등록된 게시물, 댓글등이
        등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.
        <br/> 타임4과 재화 등의 구매에 관한 계약을 체결한 이용자는 이용계약이
        성립한 날부터 7일 이내에 청약을 철회할 수 있습니다.
        <br/> "회원"은 재화 등을 배송 받은 경우 다음 각호의 1에 해당하는
        경우에는 반품 및 교환, 청약철회를 할 수 없습니다.
        <br/> "회원"에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우
        (다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는
        청약철회를 할 수 있습니다) "회원"의 사용 또는 일부 소비에 의하여 재화
        등의 가치가 현저히 감소한 경우 시간의 경과에 의하여 재판매가 곤란할
        정도로 재화 등의 가치가 현저히 감소한 경우 같은 성능을 지닌 재화 등
        복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우 청약의
        철회가 불가능한 "유료서비스"에 대한 사실을 표시사항에 포함한 경우
        시용상품을 제공한 경우 한시적 또는 일부이용 등의 방법을 제공한 경우
        "회원"은 제 4항 및 제 5항의 규정에 불구하고 재화 등의 내용이 표시·광고
        내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 재화 등을
        공급받은 날부터3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일
        이내에 청약철회 등을 할 수 있습니다.
        <br/> 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은
        "회원"이 부담합니다.
        <br/> 타임4은 "회원"에게 청약철회 등을 이유로 위약금 또는
        손해배상금을 청구하지 않습니다.
        <br/> 제 10조 회원의 계약해제, 해지의 효과 타임4은 "회원"에게
        계약해제, 해지의 의사표시에 대하여 회신한 날로부터 3영업일 이내에
        대금의 결제와 동일한 방법으로 이를 환급하여야 하며, 동일한 방법으로
        환불이 불가능할 때에는 이를 사전에 고지하여야 합니다.
        <br/> 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터
        3영업일 이내에 이를 환급하도록 합니다.
        <br/> 타임4이 제1항에 따라 환급할 경우에 "회원"이 유료서비스
        이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환급할 수 있습니다.
        <br/> 타임4은 위 대금을 환급함에 있어서 "회원"이 신용카드 또는
        전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이
        당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지
        또는 취소하도록 요청합니다.
        <br/> 다만, 제1항 단서 및 제2항의 경우에는 그러하지 아니할 수
        있습니다.
        <br/> 타임4은 "유료서비스 등의 대금을 지급 받은 자" 또는 "회원과
        유료서비스 이용계약을 체결한 자"가 동일인이 아닌 경우에 각자는
        청약철회 또는 계약해제, 해지로 인한 대금환급과 관련한 의무의 이행에
        있어서 연대하여 책임을 집니다.
        <br/> 제 11조 선불전자지급수단의 발행 및 관리, 유효기간 타임4은
        "회원"으로부터 아이디의 분실 또는 도난 등의 통지를 받기 전에 발생하는
        "선불전자지급수단"에 저장된 금액에 대한 손해에 대하여는 책임지지
        않습니다.
        <br/> "회원"이 보유 중인 "선불전자지급수단"은 유료 상품 결제시 사용할
        수 있으며, 현금으로 출금되지 않습니다.
        <br/> "회원"은 타임4에서 정한 기한 및 이용방법에 따라
        "선불전자지급수단"을 이용할 수 있으며, 그 구체적 사항을 서비스
        페이지를 통해 공지합니다.
        <br/> 타임4은 "회원"에 대하여 "선불전자지급수단"에 대한 유효기간을
        설정할 수 있으며, "회원"은 타임4이 정한 유효기간 내에서만
        "선불전자지급수단"을 사용할 수 있습니다.
        <br/> 타임4은 서비스 페이지 등을 통하여 전항의 유효기간 설정 여부 및
        그 기간을 공지합니다.
        <br/> 제 12조 정보의 제공 및 광고의 게재 타임4은 "회원"이 "서비스"
        이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의
        방법으로 회원에게 제공할 수 있습니다.
        <br/> 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한
        답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수
        있습니다.
        <br/>
        제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는
        "회원"의 사전 동의를 받아서 전송합니다.
        <br/> 다만, 회원의 거래관련 정보 및 고객문의 등에 대한 회신에
        있어서는 제외됩니다.
        <br/> 타임4은 "서비스"의 운영과 관련하여 서비스화면, 홈페이지,
        전자우편 등에 광고를 게재할 수 있습니다.
        <br/> 광고가 게재된 전자우편을 수신한 회원은 수신거절을 할 수
        있습니다.
        <br/> 이용자는 타임4이 제공하는 "서비스"와 관련하여 게시물 또는 기타
        정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
        <br/> 제 13조 게시물의 관리 "회원"의 "게시물"이 정보통신망법 및
        저작권법등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이
        정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수
        있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.
        <br/> 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
        인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는
        관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.
        <br/> 제 14조 저작권의 귀속 및 이용제한 타임4을 통해 "서비스"되는
        서비스의 저작권과 저작물 및 지적재산권은 타임4에 귀속됩니다.
        <br/> 단, 회원의 게시물 및 제휴 계약에 따라 제공되는 저작물 등은
        제외합니다.
        <br/> 타임4은 "서비스"와 관련하여 "회원"에게 이용조건에 따라 계정,
        아이디, 콘텐츠, 포인트 등을 이용할 수 있는 이용권만을 부여하며,
        "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
        <br/>
        이용자는 타임4을 이용함으로써 얻는 정보 중 지식재산권이 귀속된 정보를
        사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리
        목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br/> 타임4이 "서비스" 내에 게시하는 게시물은 검색결과 내지 "서비스"
        및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위
        내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.
        <br/> 이 경우, 회사는 저작권법 규정을 준수하며, "회원"은 언제든지
        고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제,
        수정, 비공개 등의 조치를 취할 수 있습니다.
        <br/> 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우
        당해 이용자에게 통보합니다.
        <br/> 제 16조 개인정보보호의 의무 타임4은 "정보통신망법" 등 관계
        법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다.
        <br/> 개인정보의 보호 및 사용에 대해서는 관련법 및 타임4의
        개인정보처리방침이 적용됩니다.
        <br/> 다만, 회사가 운영하는 사이트 이외의 링크된 사이트에서는 회사의
        개인정보처리방침이 적용되지 않습니다.
        <br/> 제 17조 의무 타임4은 법령과 이 약관이 금지하거나 공서양속에
        반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고
        안정적으로 재화, 용역을 제공하는데 최선을 다하여야 합니다.
        <br/> 타임4은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록
        "회원"의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야
        합니다.
        <br/>
        타임4은 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이
        정당하다고 인정할 경우에는 이를 처리하고 "회원"이 제기한 의견이나
        불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 처리과정
        및 결과를 전달합니다.
        <br/> 타임4은 "회원"이 수신 동의를 하지 않은 영리 목적의 광고성
        전자우편, SMS 문자메시지 등을 발송하지 아니합니다.
        <br/> 제 18조 회원의 아이디 및 비밀번호에 대한 의무 아이디(ID)와
        비밀번호에 관한 관리책임은 "회원" 본인에게 있으며, 이를 제3자에게
        이용하도록 해서는 안됩니다.
        <br/> 타임4은 "회원"의 아이디가 개인정보 유출 우려가 있거나, 반사회적
        또는 미풍양속에 어긋나거나 타임4의 운영자로 오인한 우려가 있는 경우,
        해당 아이디의 이용을 제한할 수 있습니다.
        <br/> "회원"이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고
        있음을 인지한 경우에는 바로 타임4에 통보하고 안내에 따라야 합니다.
        <br/> 제3항의 경우에 해당 "회원"이 타임4에 그 사실을 통지하지 않거나,
        안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
        <br/> 제 19조 이용자의 의무 이용자는 다음 행위를 하여서는 안됩니다.
        <br/> 신청 또는 변경시 허위 내용의 등록 타인의 정보 도용 타임4에
        게시된 정보의 변경 타임4이 정한 정보 이외의 정보 (컴퓨터 프로그램
        등)의 송신 또는 게시 타임4 기타 제3자의 저작권 등 지식재산권에 대한
        침해 타임4 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 외설
        또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 타임4에
        공개 또는 게시하는 행위 타임4의 동의 없이 영리를 목적으로 "서비스"를
        사용하는 행위 정보통신망법 등 관련 법령에 의하여 그 전송 또는 게시가
        금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위 다른
        "회원"의 개인정보를 그 동의없이 수집, 저장, 공개하는 행위 타임4이
        제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일,
        디스어셈블 하는 행위 기타 불법적이거나 부당한 행위 제 20조 연결
        웹사이트와 피연결 웹사이트간의 관계 타임4과 하위 웹사이트가
        하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함
        됨) 방식 등으로 연결된 경우, 전자를 연결 웹사이트라고 하고 후자를
        피연결 웹사이트라고 합니다.
        <br/>
        타임4은 피연결 웹사이트가 독자적으로 제공하는 재화 등에 의해 이용자와
        행하는 거래에 대해서는 그 거래에 대한 보증책임을 지지 않는다는 내용을
        초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에
        대한 보증책임을 지지 않습니다.
        <br/> 제 21조 책임제한 타임4은 천재지변 또는 이에 준하는 불가항력으로
        인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이
        면제됩니다.
        <br/> 타임4은 "회원"의 귀책사유로 인한 "서비스" "유료서비스" 이용의
        장애에 대하여는 책임을 지지 않습니다.
        <br/> 타임4은 "회원"이 "서비스" "유료서비스"와 관련하여 게재한 정보,
        자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
        <br/> 타임4은 "회원"간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로
        하여 거래 등을 한 경우에는 책임이 면제됩니다.
        <br/> 타임4은 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
        규정이 없는 한 책임을 지지 않습니다.
        <br/> 제 22조 재판권 및 준거법 타임4과 이용자간에 발생한 전자거래
        분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
        경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
        <br/> 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
        거주자의 경우에는 민사소송법상의 관할 법원에 제기합니다.
        <br/> 타임4과 이용자간에 제기된 전자상거래 소송에는 대한민국법을
        적용합니다.
        <br/> 제 23조 아이템 환불 고지 아이템의 사용 내역 확인 개인이 구매한
        아이템의 사용 내역은 [마이페이지]에서 확인이 가능합니다.
        <br/> 팀이 구매한 아이템의 사용 내역은 [팀페이지]에서 확인이
        가능합니다.
        <br/>
        <br/> 환불 정책
        <br/> 2-1.
        <br/> 회원이 결제한 아이템의 환불을 요청하는 경우 이를 환불받을 수
        있습니다.
        <br/> 구매 완료일로부터 14일 이내인 경우에 한하여 환불 요청이
        가능합니다.
        <br/> 환불 요청이 있을 경우 회사는 회원의 아이템을 회수 또는 삭제하고
        삭제한 날로부터 3~7영업일 이내에 환불 조치합니다.
        <br/> 부분 환불 요청 시 실 결제 금액에서 사용한 횟수만큼 정상가 차감
        후 환불되며, 이로 인해 환불 가능 금액이 없을 수 있습니다.
        <br/> 2-2.
        <br/> 위 1항 1조에 의거하여 환불을 원하는 회원은 회사가 정한 절차를
        통해 직접 환불을 신청하여야 합니다.
        <br/> 회사는 접수된 환불 건에 대한 심사를 통해 환불 여부를 결정하고,
        환불이 결정된 경우 회원에게 환불 금액을 지급합니다.
        <br/> 2-3.
        <br/> 회사가 회원에게 무상으로 지급한 아이템은 환불 대상에서
        제외됩니다.
        <br/> 2-4.
        <br/> 다음과 같은 사유에 해당하는 경우 회사는 환불을 거부할 수
        있습니다.
        <br/> - 회원의 계정이 휴면 상태가 된 경우 (마지막 접속 일로부터 1년간
        해당 접속이 없는 경우) - 회원의 계정이 제재 대상이 되어 접속이
        불가능한 경우 - 비정상적인 방법을 통해 아이템을 습득한 경우 - 아이템의
        사용처가 회사의 이용약관 및 운영정책에 위반되는 경우 - 결제를 통해
        구매한 아이템이 아닌 이벤트 보상 등으로 무료로 지급받은 경우 - 각종
        프로모션 등으로 할인이 적용된 상품의 경우 - 이벤트로 받는 현물이
        사용된 경우 : 현물이 소비자의 잘못으로 물건이 멸실(물건의 기능을 할 수
        없을 정도로 전부 파괴된 상태) 되거나 훼손된 경우 : 소비자가 사용해서
        물건의 가치가 뚜렷하게 떨어진 경우 : 시간이 지나 다시 판매하기 곤란할
        정도로 물건의 가치가 뚜렷하게 떨어진 경우 : 복제가 가능한 물건의
        포장을 훼손한 경우 환불 방법 3-1.
        <br/>
        환불 요청은 반드시 결제한 계정으로 문의하셔야 합니다.
        <br/> 3-2.
        <br/> 회원이 직접 구매하여 보유하고 있는 아이템이 결제한 금액과
        동일하다면 승인 취소 방식의 환불을 원칙으로 하고 있습니다.
        <br/> 단, 회원이 후불제 결제 수단 (휴대폰 결제, 신용카드 등)을
        사용하여 서비스 요금을 결제한 경우, 회사는 해당 회원의 이용 요금
        납부를 확인한 날로부터 7영업일 이내에 환불을 진행합니다.
        <br/> 이때 요금 납부에 대한 증명은 회원에게 책임이 있습니다.
        <br/> 회사는 회원이 후불제 결제 수단의 요금 납부를 증명하는 사실이
        확인되는 즉시 환불 처리를 진행합니다.
        <br/> 3-3.
        <br/> 회사가 회원에게 지급한 무료 아이템의 경우에는 서비스 제공
        기간을 보증하지 않습니다.
        <br/> 3-4.
        <br/> 결제 시스템 오류로 인해 정상적으로 아이템을 지급받지 못했다면
        승인 취소 방식으로 환불을 받을 수 있습니다.
        <br/> 3-5.
        <br/> 회원이 사이트에서 구매하는 유료 콘텐츠는 청약 철회가 가능한
        콘텐츠와 청약 철회가 제한되는 콘텐츠로 구분되어 판매됩니다.
        <br/> 청약 철회가 가능한 콘텐츠에 대해서는 구매일로부터 14일 이내에
        청약을 철회할 수 있으며, 사용한 콘텐츠에 대해서는 청약 철회가
        제한됩니다.
        <br/> 3-6.
        <br/> 환불 요청 시에는 다음과 같은 서류를 제출해야 하며, 이미 환불
        처리가 진행된 건에 대해서는 환불 취소가 불가하여, 공제된 수수료에 대한
        원복 또한 가능하지 않습니다.
        <br/> ※ 환불요청방법 아래의 필수 내용과 서류를 메일로 발송 [필수] -
        본인 확인 문서 (주민등록번호 뒷자리, 주소를 가린 주민등록증 사본) -
        본인 명의의 환불받으실 통장 사본 (은행명, 계좌번호, 인터넷 뱅킹 통장
        사본) - 타임4 아이디, 성함, 연락처, 환불 사유 [후불제 충전
        수단(휴대폰, 신용카드)으로 결제한 경우] - 요금 납부 영수증 ※ 메일 주소
        : koreatime4@gmail.com
        <br/> 3-7.
        <br/> 회사는 결제 및 환불을 악용하는 것을 방지하기 위해 무료로
        제공받은 아이템(이벤트 지급)은 환불하지 않습니다.
        <br/>
        3-8.
        <br/> 개인정보 (주민등록번호, 회원 ID, 비밀번호, 결제정보 등)의
        도용과 결제 사기 등으로 인한 조사가 필요한 경우에는 해당 조사가
        완료되는 시점까지 해당 회원과 관계된 계정 역시 환불이 불가능합니다.
        <br/> 이 경우 개인 정보 열람 요청은 관련 법에 따라 수사기관의 수사
        자료 제공 절차에 의해 해당 수사기관에만 제공되며, 개별적인 열람은
        가능하지 않습니다.
        <br/>
        손해배상
        <br/> 4-1.
        <br/> 회사는 회원이 구매한 아이템이 손상, 훼손, 삭제되어 아이템
        이용에 손해를 입은 회원에게 해당 아이템을 복원하는 형태로 보상합니다.
        <br/> 다만, 회사의 고의적인 과실 없이 회원에게 발생한 모든 손해에
        대해서는 책임을 지지 않습니다.
        <br/> 4-2.
        <br/> 회원이 타임4의 이용약관 및 각 서비스의 이용약관을 위반하여 이용
        제한 또는 이용해지된 경우에는, 회원이 보유한 아이템의 사용권은
        상실되며 회사는 해당 아이템에 대한 구매대금 반환할 책임을 부담하지
        않습니다.
        <br/> 4-3.
        <br/> 회사는 타임4 각 서비스의 변경 또는 중지로 인하여 특정 아이템이
        소멸될 경우에는 사전에 7일 이상 홈페이지 (www.time4.co.kr)에 공지하고,
        회사가 정한 보상 방법에 따라 회원에게 손해에 대해 배상하여야 합니다.
        부칙 이 약관은 2023년 1월 1일부터 적용됩니다.
    </div>
</div>;