import React, { useState, useEffect } from "react";
import {
  competitionGameList,
  competitionStatsAction,
} from "../actions/competitionActions";
import {
  Table,
  Card,
  Row,
  Col,
  ListGroup,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listLeagueRound } from "../actions/competitionActions";
import { COMPETITION_ROUNDGAME_RESET } from "../constants/competitionConstants";
import TeamName1 from "../components/TeamName1";
import Select from "react-select";
import { viewLocalSetup, increaseView } from "../utils/viewSetup";

const DivisionRoundListScreen = ({ match }) => {
  const id = match.params.id;

  const [statsType, setStatsType] = useState("score");

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const setup = localStorage.getItem(`${id}setup`);

  const competitionRoundInfo = useSelector(
    (state) => state.competitionRoundInfo
  );
  const { loading, error, roundInfo } = competitionRoundInfo;

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competition,
  } = competitionGame;

  const [isLoading, setIsLoading] = useState(true);
  const competitionStats = useSelector((state) => state.competitionStats);
  const { loading: loadingStats, error: errorStats, stats } = competitionStats;

  const statsTypeOption = [
    {
      label: "득점 평균",
      value: "score",
    },
    {
      label: "어시스트 평균",
      value: "assist",
    },
    {
      label: "리바운드 평균",
      value: "rebound",
    },
    {
      label: "블락 평균",
      value: "block",
    },
    {
      label: "스틸 평균",
      value: "steal",
    },
    {
      label: "자유투 확률",
      value: "freeThrow",
    },
    {
      label: "야투율",
      value: "fieldGoal",
    },
    {
      label: "3점슛 확률",
      value: "threePointThrow",
    },
  ];

  const statsTypeHandler = (selected) => {
    setStatsType(selected.value);
  };

  const getStatsByType = (type, isFirstPlace) => {
    if (type === "score") {
      if (isFirstPlace) {
        return (stats && stats.score).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.score).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "assist") {
      if (isFirstPlace) {
        return (stats && stats.assist).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.assist).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "rebound") {
      if (isFirstPlace) {
        return (stats && stats.rebound).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.rebound).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "block") {
      if (isFirstPlace) {
        return (stats && stats.block).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.block).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "steal") {
      if (isFirstPlace) {
        return (stats && stats.steal).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.steal).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "freeThrow") {
      if (isFirstPlace) {
        return (stats && stats.freeThrow).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.freeThrow).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "fieldGoal") {
      if (isFirstPlace) {
        return (stats && stats.fieldGoal).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.fieldGoal).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    } else if (type === "threePointThrow") {
      if (isFirstPlace) {
        return (stats && stats.threePointThrow).filter((stat) =>
          stat.rank.includes("1위")
        );
      } else {
        return (stats && stats.threePointThrow).filter(
          (stat) => !stat.rank.includes("1위")
        );
      }
    }
  };

  const headerNameToKorean = (name) => {
    switch (name) {
      case "rank":
        return "순위";
      case "teamName":
        return "소속 팀";
      case "name":
        return "선수명";
      case "image":
        return "선수";
      case "avg":
        if (statsType === "rebound") {
          return "평균(OR-DR)";
        } else {
          return "평균";
        }
      case "acc":
        if (
          statsType === "freeThrow" ||
          statsType === "fieldGoal" ||
          statsType === "threePointThrow"
        ) {
          return "성공-시도";
        } else {
          return "누적";
        }
      case "gameCount":
        return "경기 수";
      case "total":
        return "합계";
      case "throwRate":
        return "성공률";
      case "playerId":
        return null;
      default:
        return name;
    }
  };

  const firstPlaceHeader = (stat) => {
    const header = [];
    for (const [key, value] of Object.entries(stat)) {
      if (key === "gameCount") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "avg") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "acc") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "total") {
        header.push([headerNameToKorean(key), value]);
      } else if (key === "throwRate") {
        header.push([headerNameToKorean(key), `${value}%`]);
      }
    }

    return header;
  };

  const tableHeader = (stats) => {
    const headerNames = [];
    for (const property in stats[0]) {
      if (property === "name") {
        continue;
      }
      if (property === "playerId") {
        continue;
      }
      headerNames.push(headerNameToKorean(property));
    }
    return headerNames.map((name) => (
      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
        <div className="hideextra">{name}</div>
      </th>
    ));
  };

  const tableBody = (stat) => {
    const renderResult = [];
    for (const property in stat) {
      if (property === "throwRate") {
        renderResult.push(
          <td
            style={{
              padding: "8px",
              verticalAlign: "middle",
              border: "1px solid #ddd",
            }}
          >
            <div>{stat[property]} %</div>
          </td>
        );
        continue;
      }

      if (property === "image") {
        renderResult.push(
          <td
            style={{
              padding: "8px",
              position: "relative",
              border: "1px solid #ddd",
            }}
          >
            <div className="competition__ranking__player__container">
              <Image
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                }}
                src={
                  stat[property] === ""
                    ? "/player_default_img.png"
                    : stat[property]
                }
                fluid
                rounded
              />
              <span
                style={{
                  marginTop: "8px",
                  textAlign: "center",
                  marginLeft: "12px",
                }}
              >
                <Link to={`/player/${stat.playerId}`}>{stat.name}</Link>
              </span>
            </div>
          </td>
        );

        continue;
      }

      if (property === "name") {
        continue;
      }

      if (property === "playerId") {
        continue;
      }

      renderResult.push(
        <td
          style={{
            padding: "8px",
            verticalAlign: "middle",
            border: "1px solid #ddd",
          }}
        >
          <div>{stat[property]}</div>
        </td>
      );
    }
    return renderResult;
  };

  useEffect(() => {
    viewLocalSetup(id);
    dispatch(competitionGameList(id));
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
    dispatch(listLeagueRound(id));

    dispatch(competitionStatsAction(id));
    increaseView(id, "competition");
  }, [dispatch, id]);

  return (
    <>
      <h4 className="mt-4 text-center">{roundInfo && roundInfo.name}</h4>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <p className="text-center mt-3 mb-2">
            주최 : {roundInfo && roundInfo.host}
          </p>
          {loadingCompetition ? (
            <Loader />
          ) : errorCompetition ? (
            <Message variant="danger">
              정보를 불러오는데 실패하였습니다.
            </Message>
          ) : (
            competition &&
            competition.group.length !== 0 && (
              <>
                <h5>예선 조편성</h5>
                <Row>
                  {competition &&
                    competition.group.map((gp) => (
                      <Col key={gp._id} xs={6} sm={6} md={6} lg={4} xl={3}>
                        <Card className="mb-3">
                          <ListGroup>
                            <Card.Header tag="h4">
                              {gp && gp.groupName}조
                            </Card.Header>
                            {gp &&
                              gp.teamId.map((team) => (
                                <>
                                  <ListGroup.Item>
                                    <TeamName1 id={team} />
                                  </ListGroup.Item>
                                </>
                              ))}
                          </ListGroup>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </>
            )
          )}
          {competition && competition.games.length !== 0 ? (
            <>
              <Table
                hover
                responsive
                className="table-sm text-center mt-3"
                style={{ border: "1px solid #D2D2D2" }}
              >
                <tbody>
                  {roundInfo &&
                    roundInfo.round.length > 0 &&
                    roundInfo.round
                      .sort((a, b) => a[0] - b[0])
                      .map((r, index) => (
                        <tr key={index}>
                          <td
                            className="align-middle"
                            style={{
                              "padding-left": "4px",
                              "padding-right": "4px",
                            }}
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "80px" }}
                            >
                              {roundInfo.round[index][2] ===
                              roundInfo.round[index][3] ? (
                                <>
                                  {roundInfo.round[index][2].substring(0, 10)}
                                </>
                              ) : (
                                <>
                                  {roundInfo.round[index][2].substring(0, 10)} ~{" "}
                                  {roundInfo.round[index][3].substring(5, 10)}
                                </>
                              )}
                            </div>
                          </td>
                          <td
                            className="align-middle"
                            style={{
                              "min-width": "50px",
                              "font-weight": "900",
                              color: "black",
                              "padding-left": "4px",
                              "padding-right": "4px",
                            }}
                          >
                            {roundInfo &&
                              (r[1].length <= 1 ? r[1] + " 라운드" : r[1])}
                          </td>
                          <td
                            style={{
                              "padding-left": "4px",
                              "padding-right": "4px",
                            }}
                          >
                            <Link
                              to={`/divisionround/${id}/${roundInfo.round[index][0]}`}
                            >
                              <button className="result__btn align-middle">
                                자세히 보기
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              {userInfo && userInfo.isAdmin && (
                <div className="text-center">
                  <Link
                    to={`/competition-notice/list/${
                      competition && competition._id
                    }`}
                  >
                    <Button variant="success">대회 공지 관리</Button>
                  </Link>
                </div>
              )}
              {competition && competition.image[0] !== "" && (
                <>
                  <Row className="mt-3">
                    <Col md={6} className="mt-3">
                      <Image
                        src={competition && competition.image[0]}
                        alt={competition && competition.image[0]}
                        fluid
                      />
                    </Col>
                    {competition && competition.image[1] !== "" && (
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[1]}
                          alt={competition && competition.image[1]}
                          fluid
                        />
                      </Col>
                    )}
                  </Row>
                  {competition && competition.image[2] !== "" && (
                    <Row>
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[2]}
                          alt={competition && competition.image[2]}
                          fluid
                        />
                      </Col>
                      {competition && competition.image[3] !== "" && (
                        <Col md={6} className="mt-3">
                          <Image
                            src={competition && competition.image[3]}
                            alt={competition && competition.image[3]}
                            fluid
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {competition && competition.image[4] !== "" && (
                    <Row>
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[4]}
                          alt={competition && competition.image[4]}
                          fluid
                        />
                      </Col>
                      {competition && competition.image[5] !== "" && (
                        <Col md={6} className="mt-3">
                          <Image
                            src={competition && competition.image[5]}
                            alt={competition && competition.image[5]}
                            fluid
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                </>
              )}
            </>
          ) : (
            <Row className="justify-content-center mt-5 mb-5">
              <h2 className="mt-5 text-center">경기 시작 전입니다.</h2>
              <h4 className="text-center mt-3 mb-3">
                예정일 :{" "}
                {competition &&
                  competition.startDate &&
                  competition.startDate.substring(0, 10)}
              </h4>
              <Image
                src={`/time4_logo_message.png`}
                style={{ width: "20rem" }}
                className="text-center mt-5"
              />
              {competition && competition.image[0] !== "" && (
                <>
                  <Row className="mt-3">
                    <Col md={6} className="mt-3">
                      <Image
                        src={competition && competition.image[0]}
                        alt={competition && competition.image[0]}
                        fluid
                      />
                    </Col>
                    {competition && competition.image[1] !== "" && (
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[1]}
                          alt={competition && competition.image[1]}
                          fluid
                        />
                      </Col>
                    )}
                  </Row>
                  {competition && competition.image[2] !== "" && (
                    <Row>
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[2]}
                          alt={competition && competition.image[2]}
                          fluid
                        />
                      </Col>
                      {competition && competition.image[3] !== "" && (
                        <Col md={6} className="mt-3">
                          <Image
                            src={competition && competition.image[3]}
                            alt={competition && competition.image[3]}
                            fluid
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {competition && competition.image[4] !== "" && (
                    <Row>
                      <Col md={6} className="mt-3">
                        <Image
                          src={competition && competition.image[4]}
                          alt={competition && competition.image[4]}
                          fluid
                        />
                      </Col>
                      {competition && competition.image[5] !== "" && (
                        <Col md={6} className="mt-3">
                          <Image
                            src={competition && competition.image[5]}
                            alt={competition && competition.image[5]}
                            fluid
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                </>
              )}
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default DivisionRoundListScreen;
