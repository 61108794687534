import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listPlayerDetails } from "../actions/playerActions";
import { listPlayerCompetition } from "../actions/playerActions";
import TeamInfo from "../components/TeamInfo";
import Prize from "../components/Prize";
import {
  PLAYER_SEASON_RECORD_RESET,
  PLAYER_COMPETITION_LIST_RESET,
} from "../constants/playerConstants";
import PlayerDetailSeasonTeam from "../components/PlayerDetailSeasonTeam";
import PlayerDetailCompetition from "../components/PlayerDetailCompetition";
import Select from "react-select";
import Searcher from "../components/Searcher";

const PersonalRecordScreen = ({ match }) => {
  const playerId = match.params.id;

  const [year, setYear] = useState("2024");

  const dispatch = useDispatch();

  const playerDetails = useSelector((state) => state.playerDetails);
  const { loading, error, player } = playerDetails;

  const playerCompetitionList = useSelector(
    (state) => state.playerCompetitionList
  );
  const {
    loading: competitionListLoading,
    error: competitionListError,
    competitions,
  } = playerCompetitionList;

  const handleSeasonChange = (selected) => {
    setYear(selected.value);
  };

  const options = [
    {
      value: 2022,
      label: "시즌 2022",
    },
    {
      value: 2023,
      label: "시즌 2023",
    },
    {
      value: 2024,
      label: "시즌 2024",
    }
  ];

  useEffect(() => {
    dispatch({ type: PLAYER_SEASON_RECORD_RESET });
    dispatch({ type: PLAYER_COMPETITION_LIST_RESET });
    dispatch(listPlayerDetails(playerId));
    dispatch(listPlayerCompetition({ _id: playerId, year: year, isThree: false }));
  }, [dispatch, playerId, year]);

  return (
    <>
      {loading || competitionListLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Searcher searchType={"personallistrecord"} />
          <Row className="justify-content-center">
            <h1
              style={{
                color: "white",
                "background-color": "#031b31",
                "padding-top": "20px",
                "padding-bottom": "20px",
                "padding-left": "30px",
                "margin-top": "-16px",
              }}
            >
              선수 정보
            </h1>
            <>
              <span className="on__pc__info">
                <div
                  xs
                  lg="5"
                  className="text-center"
                  style={{
                    "background-color": "#013B70",
                    "margin-top": "50px",
                    "padding-bottom": "30px",
                    "border-radius": "40px 40px 10px 10px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div className="on__pc__info">
                    {player.image === "" ? (
                      <Col xs lg="5" className="text-center">
                        <Card.Img
                          top
                          style={{
                            width: "22rem",
                            border: "0px solid rgba(0, 0, 0, 0)",
                            "border-radius": "25px 25px 25px 25px",
                            "text-align": "center",
                          }}
                          alt={player.name}
                          src={"/player_default_img.png"}
                          fluid
                        ></Card.Img>
                      </Col>
                    ) : (
                      <Card.Img
                        top
                        style={{
                          width: "24rem",
                          border: "0px solid rgba(0, 0, 0, 0)",
                          "margin-top": "60px",
                          "text-align": "right",
                          filter: "drop-shadow(24px 24px 0px #031B31)",
                        }}
                        alt={player.name}
                        src={player.image}
                        fluid
                        className="mb-5"
                      ></Card.Img>
                    )}
                  </div>
                  <div
                    className="on__pc__info"
                    style={{ "min-width": "300px" }}
                  >
                    <Card.Title>
                      <br />
                      <h1
                        style={{ color: "white", "text-align": "left" }}
                        className="mb-3"
                      >
                        <br />
                        <span style={{ "font-size": "2.5rem" }}>
                          {player.name}{" "}
                        </span>
                        <span
                          style={{
                            "font-size": "1.2rem",
                            color: "white",
                            opacity: "0.5",
                          }}
                        >
                          NO.{player.jerseyNum}
                        </span>
                      </h1>
                    </Card.Title>
                    <Card.Text>
                      <Row>
                        <Col style={{ "text-align": "left" }}>
                          <p className="player__detail__p__title">생년월일</p>
                          <p className="player__detail__p__title">포지션</p>
                          <p className="player__detail__p__title">신장</p>
                          <p className="player__detail__p__title">발사이즈</p>
                          <p className="player__detail__p__title">윙스팬</p>
                          <p className="player__detail__p__title">서전트</p>
                        </Col>
                        <Col style={{ "text-align": "right" }}>
                          <p className="player__detail__p">
                            {player.birthday
                              ? player.birthday.substr(0, 4) +
                                "-" +
                                player.birthday.substr(4, 2) +
                                "-" +
                                player.birthday.substr(6, 2)
                              : "-"}
                          </p>
                          <p className="player__detail__p">
                            {player.teamJerseyNum && player.teamJerseyNum[0] &&
                            player.teamJerseyNum[0].position &&
                            player.teamJerseyNum[0].position.length === 0
                              ? "-"
                              : player && player.position && player.position.join(" / ")}
                          </p>
                          <p className="player__detail__p">
                            {player.height ? player.height + "cm" : "-"}
                          </p>
                          <p className="player__detail__p">
                            {player.footSize ? player.footSize + "mm" : "-"}
                          </p>
                          <p className="player__detail__p">
                            {player.wingspan ? player.wingspan + "cm" : "-"}
                          </p>
                          <p className="player__detail__p">
                            {player.sargent ? player.sargent + "cm" : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Card.Text>
                  </div>
                  {player.teamId && player.teamId.length > 0 && (
                    <div className="text-center pt-5 mt-3 on__pc__info">
                      <Card.Title>
                        <TeamInfo
                          key={player.teamJerseyNum[0]?.teamId}
                          id={player.teamJerseyNum[0]?.teamId}
                        />
                      </Card.Title>
                    </div>
                  )}
                </div>
              </span>
              {/* mobile */}
              <div className="text-center on__mobile__image">
                {player.image === "" ? (
                  <Col xs lg="5" className="text-center">
                    <Card.Img
                      top
                      style={{
                        width: "20rem",
                        border: "0px solid rgba(0, 0, 0, 0)",
                        "border-radius": "25px 25px 25px 25px",
                        "text-align": "center",
                      }}
                      alt={player.name}
                      src={"/player_default_img.png"}
                      fluid
                    ></Card.Img>
                  </Col>
                ) : (
                  <Card.Img
                    top
                    style={{
                      width: "100%",
                      border: "0px solid rgba(0, 0, 0, 0)",
                      "text-align": "center",
                    }}
                    alt={player.name}
                    src={player.image}
                    fluid
                    className="mt-3"
                  ></Card.Img>
                )}
              </div>
            </>

            <span className="on__mobile__info">
              <Col xs lg="5" className="mt-2">
                <Card
                  className="card__for__mobile"
                  style={{
                    border: "0",
                    "background-color": "#013B70",
                    color: "white",
                    "margin-top": "-12px",
                  }}
                >
                  <Card.Body>
                    <br />
                    <Row className="mb-3">
                      <Col className="text-center">
                        {player.teamId && player.teamId.length > 0 && (
                          <div
                            className="allStyle"
                            style={{ "margin-left": "0px" }}
                          >
                            <TeamInfo
                              key={player.teamId}
                              id={player.teamId && player.teamId[0]}
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        style={{ textAlign: "right", "margin-right": "20px" }}
                      >
                        <h1>
                          <br />
                          <span
                            className="player__detail__h1"
                            style={{ color: "white" }}
                          >
                            {player.name}{" "}
                          </span>
                        </h1>
                        <h1
                          style={{
                            "font-size": "1.2rem",
                            color: "rgba(255, 255, 255, 0.8)",
                            opacity: "0.5",
                          }}
                        >
                          NO.{player.jerseyNum}
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          생년월일
                        </p>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          포지션
                        </p>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          신장
                        </p>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          발사이즈
                        </p>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          윙스팬
                        </p>
                        <p
                          className="player__detail__p__title"
                          style={{ "margin-bottom": "8px" }}
                        >
                          서전트
                        </p>
                      </Col>
                      <Col style={{ "text-align": "right" }}>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.birthday
                            ? player.birthday.substr(0, 4) +
                              "-" +
                              player.birthday.substr(4, 2) +
                              "-" +
                              player.birthday.substr(6, 2)
                            : "-"}
                        </p>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.position === "" ? "N/A" : player.position}
                        </p>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.height}cm
                        </p>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.footSize}mm
                        </p>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.wingspan}cm
                        </p>
                        <p
                          className="player__detail__p"
                          style={{ "margin-bottom": "8px" }}
                        >
                          {player.sargent}cm
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </span>
          </Row>
          {player.prize && player.prize.length != 0 ? (
            <Prize prize={player.prize} />
          ) : (
            ""
          )}
          <Row>
            <h3 className="mt-5 mb-2">선수 기록</h3>
            <Select
              options={options}
              defaultValue={{
                label: "시즌 " + year,
                value: year,
              }}
              onChange={handleSeasonChange}
              placeholder="조회하고자 하는 시즌을 선택해주세요"
              className="mb-3"
            />
            <PlayerDetailSeasonTeam id={playerId} year={year} isThree={false} />
            <hr />
          </Row>

          <h3 className="mt-4 mb-2">{year} 대회 기록</h3>
          <Row>
            {competitions &&
              competitions.map((competition, index) => (
                <>
                  <PlayerDetailCompetition
                    id={playerId}
                    i={index}
                    competitionId={competition}
                  />
                </>
              ))}
          </Row>
        </>
      )}
    </>
  );
};

export default PersonalRecordScreen;
