export const COMPETITION_LIST_REQUEST = "COMPETITION_LIST_REQUEST";
export const COMPETITION_LIST_SUCCESS = "COMPETITION_LIST_SUCCESS";
export const COMPETITION_LIST_FAIL = "COMPETITION_LIST_FAIL";
export const COMPETITION_LIST_RESET = "COMPETITION_LIST_RESET";

export const COMPETITION_GAME_REQUEST = "COMPETITION_GAME_REQUEST";
export const COMPETITION_GAME_SUCCESS = "COMPETITION_GAME_SUCCESS";
export const COMPETITION_GAME_FAIL = "COMPETITION_GAME_FAIL";
export const COMPETITION_GAME_RESET = "COMPETITION_GAME_RESET";

export const COMPETITION_INFO_REQUEST = "COMPETITION_INFO_REQUEST";
export const COMPETITION_INFO_SUCCESS = "COMPETITION_INFO_SUCCESS";
export const COMPETITION_INFO_FAIL = "COMPETITION_INFO_FAIL";
export const COMPETITION_INFO_RESET = "COMPETITION_INFO_RESET";

export const COMPETITION_DETAIL_INFO_REQUEST =
  "COMPETITION_DETAIL_INFO_REQUEST";
export const COMPETITION_DETAIL_INFO_SUCCESS =
  "COMPETITION_DETAIL_INFO_SUCCESS";
export const COMPETITION_DETAIL_INFO_FAIL = "COMPETITION_DETAIL_INFO_FAIL";
export const COMPETITION_DETAIL_INFO_RESET = "COMPETITION_DETAIL_INFO_RESET";

export const COMPETITION_RESULT_REQUEST = "COMPETITION_RESULT_REQUEST";
export const COMPETITION_RESULT_SUCCESS = "COMPETITION_RESULT_SUCCESS";
export const COMPETITION_RESULT_FAIL = "COMPETITION_RESULT_FAIL";

export const COMPETITION_INPUT_REQUEST = "COMPETITION_INPUT_REQUEST";
export const COMPETITION_INPUT_SUCCESS = "COMPETITION_INPUT_SUCCESS";
export const COMPETITION_INPUT_FAIL = "COMPETITION_INPUT_FAIL";

export const COMPETITION_ASSOCIATION_INPUT_REQUEST = "COMPETITION_ASSOCIATION_INPUT_REQUEST";
export const COMPETITION_ASSOCIATION_INPUT_SUCCESS = "COMPETITION_ASSOCIATION_INPUT_SUCCESS";
export const COMPETITION_ASSOCIATION_INPUT_FAIL = "COMPETITION_ASSOCIATION_INPUT_FAIL";

export const COMPETITION_GAME_INPUT_REQUEST = "COMPETITION_GAME_INPUT_REQUEST";
export const COMPETITION_GAME_INPUT_SUCCESS = "COMPETITION_GAME_INPUT_SUCCESS";
export const COMPETITION_GAME_INPUT_FAIL = "COMPETITION_GAME_INPUT_FAIL";

export const COMPETITION_GAME_DELETE_REQUEST =
  "COMPETITION_GAME_DELETE_REQUEST";
export const COMPETITION_GAME_DELETE_SUCCESS =
  "COMPETITION_GAME_DELETE_SUCCESS";
export const COMPETITION_GAME_DELETE_FAIL = "COMPETITION_GAME_DELETE_FAIL";

export const COMPETITION_GAME_UPDATE_REQUEST =
  "COMPETITION_GAME_UPDATE_REQUEST";
export const COMPETITION_GAME_UPDATE_SUCCESS =
  "COMPETITION_GAME_UPDATE_SUCCESS";
export const COMPETITION_GAME_UPDATE_FAIL = "COMPETITION_GAME_UPDATE_FAIL";
export const COMPETITION_GAME_UPDATE_RESET = "COMPETITION_GAME_UPDATE_RESET";

export const COMPETITION_ROUNDINFO_REQUEST = "COMPETITION_ROUNDINFO_REQUEST";
export const COMPETITION_ROUNDINFO_SUCCESS = "COMPETITION_ROUNDINFO_SUCCESS";
export const COMPETITION_ROUNDINFO_FAIL = "COMPETITION_ROUNDINFO_FAIL";
export const COMPETITION_ROUNDINFO_RESET = "COMPETITION_ROUNDINFO_RESET";

export const COMPETITION_ROUNDGAME_REQUEST = "COMPETITION_ROUNDGAME_REQUEST";
export const COMPETITION_ROUNDGAME_SUCCESS = "COMPETITION_ROUNDGAME_SUCCESS";
export const COMPETITION_ROUNDGAME_FAIL = "COMPETITION_ROUNDGAME_FAIL";
export const COMPETITION_ROUNDGAME_RESET = "COMPETITION_ROUNDGAME_RESET";

export const COMPETITION_PARTICIPANTS_REQUEST =
  "COMPETITION_PARTICIPANTS_REQUEST";
export const COMPETITION_PARTICIPANTS_SUCCESS =
  "COMPETITION_PARTICIPANTS_SUCCESS";
export const COMPETITION_PARTICIPANTS_FAIL = "COMPETITION_PARTICIPANTS_FAIL";

export const COMPETITION_UPDATE_REQUEST = "COMPETITION_UPDATE_REQUEST";
export const COMPETITION_UPDATE_SUCCESS = "COMPETITION_UPDATE_SUCCESS";
export const COMPETITION_UPDATE_FAIL = "COMPETITION_UPDATE_FAIL";
export const COMPETITION_UPDATE_RESET = "COMPETITION_UPDATE_RESET";

export const COMPETITION_GAME_ORDER_REQUEST = "COMPETITION_GAME_ORDER_REQUEST";
export const COMPETITION_GAME_ORDER_SUCCESS = "COMPETITION_GAME_ORDER_SUCCESS";
export const COMPETITION_GAME_ORDER_FAIL = "COMPETITION_GAME_ORDER_FAIL";

export const COMPETITION_STATS_REQUEST = "COMPETITION_STATS_REQUEST";
export const COMPETITION_STATS_SUCCESS = "COMPETITION_STATS_SUCCESS";
export const COMPETITION_STATS_FAIL = "COMPETITION_STATS_FAIL";
export const COMPETITION_STATS_RESET = "COMPETITION_STATS_RESET";

export const COMPETITION_ROUND_RANK_REQUEST = "COMPETITION_ROUND_RANK_REQUEST";
export const COMPETITION_ROUND_RANK_SUCCESS = "COMPETITION_ROUND_RANK_SUCCESS";
export const COMPETITION_ROUND_RANK_FAIL = "COMPETITION_ROUND_RANK_FAIL";
export const COMPETITION_ROUND_RANK_RESET = "COMPETITION_ROUND_RANK_RESET";

export const TEAM_GAME_LIST_REQUEST = "TEAM_GAME_LIST_REQUEST";
export const TEAM_GAME_LIST_SUCCESS = "TEAM_GAME_LIST_SUCCESS";
export const TEAM_GAME_LIST_FAIL = "TEAM_GAME_LIST_FAIL";
export const TEAM_GAME_LIST_RESET = "TEAM_GAME_LIST_RESET";

export const SCHEDULED_GAME_LIST_REQUEST = "SCHEDULED_GAME_LIST_REQUEST";
export const SCHEDULED_GAME_LIST_SUCCESS = "SCHEDULED_GAME_LIST_SUCCESS";
export const SCHEDULED_GAME_LIST_FAIL = "SCHEDULED_GAME_LIST_FAIL";
export const SCHEDULED_GAME_LIST_RESET = "SCHEDULED_GAME_LIST_RESET";
