import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { login } from "../actions/userActions";

const LoginScreen = ({ location, history }) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(userId, password));
  };

  return (
    <>
      <FormContainer>
        <h2>로그인</h2>
        <p className="mt-3 mx-1 login__mobile__description">
          로그인하여, 회원만의 특별한 정보와 기능들을 이용해보세요!
        </p>
        {error && (
          <Message variant="danger">아이디 또는 비밀번호가 틀립니다.</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler} className="mt-5">
          <Form.Group controlId="userId">
            <Form.Label>아이디</Form.Label>
            <Form.Control
              type="userId"
              placeholder="아이디 입력"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="password" className="mt-3">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="비밀번호 입력"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
        <Row
          className="py-3"
          style={{ "padding-left": "12px", "padding-right": "12px" }}
        >
          <Button
            type="submit"
            variant="primary"
            className="mt-2"
            onClick={submitHandler}
          >
            로그인
          </Button>
          <Col className="mt-3 text-center">
            TIME4에 처음이신가요?{" "}
            <Link
              to={redirect ? `/register?redirect=${redirect}` : "/register"}
            >
              회원가입
            </Link>
          <br />
          <br />
          아이디/비밀번호를 잊어버리셨나요?{" "}
          <Link
            to={"/id-find"}
          >
            아이디/비밀번호 찾기
          </Link>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

export default LoginScreen;
