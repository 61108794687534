import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { playerSeasonRecord } from "../actions/playerActions";
import { PLAYER_SEASON_RECORD_RESET } from "../constants/playerConstants";
import Loader from "./Loader";
import Message from "./Message";

const PlayerDetailSeasonTeam = ({ id, year, teamId, isThree }) => {
  const dispatch = useDispatch();
  const i = 0;

  const playerSeason = useSelector((state) => state.playerSeason);
  const { loading, error, record } = playerSeason;

  useEffect(() => {
    dispatch({ type: PLAYER_SEASON_RECORD_RESET });
    dispatch(playerSeasonRecord({ _id: id, year: year, teamId: teamId, isThree: isThree }));
  }, [dispatch, id, year, teamId, isThree]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h4>{record[i] && record[i].teamName} {year}시즌 기록</h4>
          <Table
            striped
            hover
            responsive
            className="table-sm text-center table__padding__sm"
          >
            <thead>
              <tr className="table__header">
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    경기수
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "40px" }}>
                    득점
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "100px" }}>
                    2점 <br />
                    성공-시도
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "40px" }}>
                    2점 <br />
                    성공률
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "100px" }}>
                    3점 <br />
                    성공-시도
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "30px" }}>
                    3점 <br />
                    성공률
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "100px" }}>
                    자유투 <br />
                    성공-시도
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "35px" }}>
                    자유투
                    <br /> 성공률
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "40px" }}>
                    공격 <br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "40px" }}>
                    수비 <br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "35px" }}>
                    스틸
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "35px" }}>
                    블락
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "35px" }}>
                    턴오버
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "35px" }}>
                    파울
                  </div>
                </th>
                <th>
                  <div className="hideextra" style={{ "min-width": "40px" }}>
                    어시스트
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ "line-height": "120%" }}>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div className="hideextra" style={{ "min-width": "30px" }}>
                    {record[i] && record[i].gameCount}
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "30px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].score / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].score})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].fieldGoal[0] === 0
                      ? 0
                      : record[i] &&
                        (record[i].fieldGoal[1] / record[i].gameCount).toFixed(
                          1
                        ) +
                          " - " +
                          (
                            record[i].fieldGoal[0] / record[i].gameCount
                          ).toFixed(1)}
                    <br />({record[i] && record[i].fieldGoal[1]} -
                    {record[i] && record[i].fieldGoal[0]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "30px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].fieldGoal[1] === 0
                      ? 0
                      : (
                          (record[i] &&
                            record[i].fieldGoal[1] / record[i].fieldGoal[0]) *
                          100
                        ).toFixed(1)}
                    %
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].threePointThrow[0] === 0
                      ? 0
                      : record[i] &&
                        (
                          record[i].threePointThrow[1] / record[i].gameCount
                        ).toFixed(1) +
                          " - " +
                          (
                            record[i].threePointThrow[0] / record[i].gameCount
                          ).toFixed(1)}
                    <br />({record[i] && record[i].threePointThrow[1]} -{" "}
                    {record[i] && record[i].threePointThrow[0]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "30px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].threePointThrow[1] === 0
                      ? 0
                      : (
                          (record[i] &&
                            record[i].threePointThrow[1] /
                              record[i].threePointThrow[0]) * 100
                        ).toFixed(1)}
                    %
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].freeThrow[0] === 0
                      ? 0
                      : record[i] &&
                        (record[i].freeThrow[1] / record[i].gameCount).toFixed(
                          1
                        ) +
                          " - " +
                          (
                            record[i].freeThrow[0] / record[i].gameCount
                          ).toFixed(1)}
                    <br />({record[i] && record[i].freeThrow[1]} -{" "}
                    {record[i] && record[i].freeThrow[0]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "35px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].freeThrow[1] === 0
                      ? 0
                      : (
                          (record[i] &&
                            record[i].freeThrow[1] / record[i].freeThrow[0]) *
                          100
                        ).toFixed(1)}
                    %
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].rebound[0] / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].rebound[0]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].rebound[1] / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].rebound[1]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "35px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].steal / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].steal})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "35px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].block / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].block})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "35px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].turnOver / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].turnOver})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "35px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].foul[0] / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].foul[0]})
                  </div>
                </td>
                <td
                  style={{
                    "z-index": "-2",
                    position: "relative",
                    "background-color": "white",
                    padding: "6px",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{ "min-width": "40px", "line-height": "120%" }}
                  >
                    {record[i] && record[i].gameCount === 0
                      ? 0
                      : record[i] &&
                        (record[i].assist / record[i].gameCount).toFixed(1)}
                    <br />({record[i] && record[i].assist})
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default PlayerDetailSeasonTeam;
