import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { Form, Button, Image } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import {
  competitionGameList,
  listCompetition,
  updateCompetition,
} from "../actions/competitionActions";
import { accessControl } from "../utils/access";

const ModifyCompetitionImageScreen = ({ history }) => {
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  
  const [originalImage, setOriginalImage] = useState("");
  const [originalImage1, setOriginalImage1] = useState("");
  const [originalImage2, setOriginalImage2] = useState("");
  const [originalImage3, setOriginalImage3] = useState("");
  const [originalImage4, setOriginalImage4] = useState("");
  const [originalImage5, setOriginalImage5] = useState("");

  const [competitionId, setCompetitionId] = useState("");
  const [uploading, setUploading] = useState(false);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandler1 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage1(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandler2 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage2(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandler3 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage3(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandler4 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage4(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandler5 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage5(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const dispatch = useDispatch();

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const competitionGame = useSelector((state) => state.competitionGame);
  const { loading, error, competition } = competitionGame;

  const competitionUpdate = useSelector((state) => state.competitionUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = competitionUpdate;

  useEffect(() => {
    accessControl([99, 100], history)
    dispatch(listCompetition());
    setImage(competition && competition.image[0]);
    setImage1(competition && competition.image[1]);
    setImage2(competition && competition.image[2]);
    setImage3(competition && competition.image[3]);
    setImage4(competition && competition.image[4]);
    setImage5(competition && competition.image[5]);
    setOriginalImage(competition && competition.image[0]);
    setOriginalImage1(competition && competition.image[1]);
    setOriginalImage2(competition && competition.image[2]);
    setOriginalImage3(competition && competition.image[3]);
    setOriginalImage4(competition && competition.image[4]);
    setOriginalImage5(competition && competition.image[5]);
  }, [dispatch, competition]);

  const options = [];

  competitions &&
    competitions.map((competition) =>
      options.push({
        value: competition._id,
        label: competition.name,
      })
    );

  const handleCompetitionChange = (selected) => {
    setCompetitionId(selected.value);
    dispatch(competitionGameList(selected.value));
  };

  const deleteFileHandler = async (key) => {
    try {
      await axios.delete(`${API_SERVER_URL}/api/upload/${key}`);
    }
    catch (error) {
      console.error(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let images = [image, image1, image2, image3, image4, image5];
    let originalImages = [
      originalImage,
      originalImage1,
      originalImage2,
      originalImage3,
      originalImage4,
      originalImage5,
    ];
    
    // for (let i = 0; i < images.length; i++) {
    //   if ((originalImages[i] !== "") && ((images[i] === "") || (images[i] !== originalImages[i]))) {
    //     let key = originalImages[i].match(/\/([^\/]+)$/)[1]
    //     deleteFileHandler(key);
    //   }
    // }
    
    dispatch(
      updateCompetition({
        _id: competitionId,
        name: competition.name,
        host: competition.host,
        place: competition.place,
        isThree: competition.isThree,
        category: competition.category,
        teamType: competition.teamType,
        startDate: competition.startDate,
        endDate: competition.endDate,
        division: competition.division,
        isRecruiting: competition.isRecruiting,
        statStandard: competition.statStandard,
        roundStatStandard: competition.roundStatStandard,
        additionalInfo: competition.additionalInfo,
        image: images,
      })
    );
  };

  return (
    <FormContainer>
      <h1 className="mt-2">대회 이미지 관리</h1>
      <p className="mt-4">정보가 불러와지지 않는 경우, 새로고침을 해주세요.</p>
      {error && (
        <Message variant="danger">
          이미지 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loadingCompetition ? (
        <Loader />
      ) : errorCompetition ? (
        <Message variant="danger">
          [오류] 정보를 불러오지 못했습니다. 개발자에게 문의해주세요.
        </Message>
      ) : (
        <Form onSubmit={submitHandler} className="mt-3">
          <Form.Group controlId="teamId" className="mt-3">
            <Form.Label>대회</Form.Label>
            <Select
              options={options}
              isSearchable
              onChange={handleCompetitionChange}
              defaultValue={{
                label: competition && competition.name,
                value: competition && competition._id,
              }}
            />
          </Form.Group>
          <Form.Group controlId="image" className="mt-3">
            <Form.Label>이미지 - 1</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image !== "" && <Image src={image} alt="image" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image}
              onChange={(e) => setImage(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler}
          ></Form.Control>
          {uploading && <Loader />}

          <Form.Group controlId="image1" className="mt-3">
            <Form.Label>이미지 - 2</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image1 !== "" && <Image src={image1} alt="image-1" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image1}
              onChange={(e) => setImage1(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler1}
          ></Form.Control>
          {uploading && <Loader />}

          <Form.Group controlId="image2" className="mt-3">
            <Form.Label>이미지 - 3</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image2 !== "" && <Image src={image2} alt="image-2" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image2}
              onChange={(e) => setImage2(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler2}
          ></Form.Control>
          {uploading && <Loader />}

          <Form.Group controlId="image3" className="mt-3">
            <Form.Label>이미지 - 4</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image3 !== "" && <Image src={image3} alt="image-3" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image3}
              onChange={(e) => setImage3(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler3}
          ></Form.Control>

          <Form.Group controlId="image4" className="mt-3">
            <Form.Label>이미지 - 5</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image4 !== "" && <Image src={image4} alt="image-4" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image4}
              onChange={(e) => setImage4(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler4}
          ></Form.Control>

          <Form.Group controlId="image5" className="mt-3">
            <Form.Label>이미지 - 6</Form.Label>
            <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
            {image5 !== "" && <Image src={image5} alt="image-5" fluid />}
            <Form.Control
              type="text"
              placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
              value={image5}
              onChange={(e) => setImage5(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Control
            type="file"
            id="image-file"
            label="이미지 선택"
            custom="true"
            onChange={uploadFileHandler5}
          ></Form.Control>
          {uploading && <Loader />}

          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            style={{ width: "100%" }}
          >
            정보 업데이트
          </Button>
        </Form>
      )}
    </FormContainer>
  );
};

export default ModifyCompetitionImageScreen;
