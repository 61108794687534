const optionsType = [
  {
    value: "유소년",
    label: "유소년",
  },
  {
    value: "일반부",
    label: "일반부",
  },
  {
    value: "마스터즈",
    label: "마스터즈",
  },
  {
    value: "직장인",
    label: "직장인",
  },
  {
    value: "엘리트 초",
    label: "엘리트 초",
  },
  {
    value: "엘리트 중",
    label: "엘리트 중",
  },
  {
    value: "엘리트 고",
    label: "엘리트 고",
  },
  {
    value: "엘리트 대",
    label: "엘리트 대",
  },
  {
    value: "엘리트 실업",
    label: "엘리트 실업",
  },
  {
    value: "생활 중등부",
    label: "생활 중등부",
  },
  {
    value: "생활 고등부",
    label: "생활 고등부",
  },
  {
    value: "생활 대학부",
    label: "생활 대학부",
  },
  {
    value: "비등록부",
    label: "비등록부",
  },
  {
    value: "학교스포츠클럽(남초)",
    label: "학교스포츠클럽(남초)",
  },
  {
    value: "학교스포츠클럽(남중)",
    label: "학교스포츠클럽(남중)",
  },
  {
    value: "학교스포츠클럽(남고)",
    label: "학교스포츠클럽(남고)",
  },
  {
    value: "학교스포츠클럽(여초)",
    label: "학교스포츠클럽(여초)",
  },
  {
    value: "학교스포츠클럽(여중)",
    label: "학교스포츠클럽(여중)",
  },
  {
    value: "학교스포츠클럽(여고)",
    label: "학교스포츠클럽(여고)",
  },
  {
    value: "TOP농구교실",
    label: "TOP농구교실",
  },
  {
    value: "농구연구소",
    label: "농구연구소",
  }
];

export default optionsType;