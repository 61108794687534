import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({ pages, page, isAdmin = false, keyword = "", pageType }) => {
  const [currentPageNum, setCurrentPageNum] = useState(0);

  function changePageNum(x) {
    setCurrentPageNum(x);
  }

  return (
    pages > 1 && (
      <>
        <Pagination>
          <LinkContainer
            to={
              !isAdmin
                ? keyword
                  ? `/${pageType}/search/${keyword}/page/1`
                  : `/${pageType}/page/1`
                : `/admin/productlist/1`
            }
          >
            <button className="pagination__custom__button">«</button>
          </LinkContainer>
          {[...Array(pages).keys()].map(
            (x) =>
              page - 5 < x &&
              x < page + 4 && (
                <>
                  <LinkContainer
                    key={x + 1}
                    to={
                      !isAdmin
                        ? keyword
                          ? `/${pageType}/search/${keyword}/page/${x + 1}`
                          : `/${pageType}/page/${x + 1}`
                        : `/admin/productlist/${x + 1}`
                    }
                  >
                    <Pagination.Item
                      active={x + 1 === page}
                      activeLabel={false}
                      onClick={(e) => changePageNum(e.target.text)}
                    >
                      {x + 1}
                    </Pagination.Item>
                  </LinkContainer>
                </>
              )
          )}
          <LinkContainer
            to={
              !isAdmin
                ? keyword
                  ? `/${pageType}/search/${keyword}/page/${pages}`
                  : `/${pageType}/page/${pages}`
                : `/admin/productlist/${pages}`
            }
          >
            <button className="pagination__custom__button">»</button>
          </LinkContainer>
        </Pagination>
      </>
    )
  );
};

export default Paginate;
