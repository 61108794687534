import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { addCompetitionGameAssociation, competitionInput } from "../actions/competitionActions";
import { listTeams, teamDetailsInfo } from "../actions/teamActions";
import { accessControl } from "../utils/access";
import optionsType from "../static/optionsType";
import { readCompetitionNotice } from "../actions/noticeActions";

const CompetitionGroupGameScreen = ({ history, match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [place, setPlace] = useState("");
  const [teamTypeObject, setTeamTypeObject] = useState("");
  const isLeague = false;
  const isOfficial = true;
  const [division, setDivision] = useState("일반");
  // 경기 시작 하이픈 자동 생성
  const [startDate, setStartDate] = useState("");
  const startRef = useRef();

  const [teamIdA, setTeamIdA] = useState([]);
  const [teamIdB, setTeamIdB] = useState([]);
  const [teamIdC, setTeamIdC] = useState([]);
  const [teamIdD, setTeamIdD] = useState([]);
  const [teamIdE, setTeamIdE] = useState([]);
  const [teamIdF, setTeamIdF] = useState([]);
  const [teamIdG, setTeamIdG] = useState([]);
  const [teamIdH, setTeamIdH] = useState([]);

  const [optionsTeam, setOptionsTeam] = useState([]);
  const [originalOptionsTeam, setOriginalOptionsTeam] = useState([]);

  const [groupCount, setGroupCount] = useState(0);
  const [groupCountRenderer, setGroupCountRenderer] = useState(0);
  const [applyQueueOption, setApplyQueueOption] = useState([]);
  const [teamMemberOption, setTeamMemberOption] = useState([]);
  const [teamPlayerId, setTeamPlayerId] = useState([]);
  const [teamIndex, setTeamIndex] = useState(0);
  const [teamInfo, setTeamInfo] = useState([]);
  const [groupTeam, setGroupTeam] = useState([]);
  const [isGrouped, setIsGrouped] = useState(false);
  const [allGame, setAllGame] = useState([]);
  const [allGameCnt, setAllGameCnt] = useState(0);

  const [gameOrderList, setGameOrderList] = useState([]);
  const [showedGameOrderList, setShowedGameOrderList] = useState([]);

  let teamName;

  const group = [];

  const competitionNoticeRead = useSelector((state) => state.competitionNoticeRead);

  const {
    loading: loadingCompetitionNotice,
    error: errorCompetitionNotice,
    competitionNotice
  } = competitionNoticeRead;

  useEffect(() => {
    dispatch(readCompetitionNotice(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (competitionNotice) {
      setName(competitionNotice.competitionName);
      setHost(competitionNotice.associationName);

      const tempOptionsTeam = competitionNotice?.acceptList.map(item => ({
        value: item,
        label: competitionNotice.applyQueueInfo.find(team => team.teamId === item).teamName
      }));

      setOptionsTeam(tempOptionsTeam);
      setOriginalOptionsTeam(tempOptionsTeam);
    }
  }, [competitionNotice]);

  const alphabetMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H"
  }


  const groupMaker = () => {
    const maker = [];
    for (let i = 0; i < groupCountRenderer; i++) {
      maker.push(
        <div key={i} className="group-section">
          <h4>조명 ({i + 1}) :</h4>
          <div className="group-input">
            <input
              type="text"
              id={`groupName${i}`}
              defaultValue={`${alphabetMap[i]}조`}
              value={teamName}
            />
          </div>
          <p>팀 선택</p>
          <Select
            options={optionsTeam}
            closeMenuOnSelect={false}
            isSearchable
            isMulti
            onChange={(e) => handleTeamChange(e, i)}
            defaultValue={groupTeam[i]}
          />
          <hr className="group-divider" />
        </div>
      );
    }
    return maker;
  };

  const groupMakerRenderer = (e) => {
    setGroupTeam(new Array(Number(groupCount)).fill([]));
    setGroupCountRenderer(groupCount);
    const initArray = [];
    for (let i = 0; i < groupCount; i++) {
      initArray.push([]);
    }
    setTeamPlayerId(initArray);

  };

  const groupCountInputHandler = (e) => {
    setGroupCount(e.target.value);

    const number = parseInt(e.target.value);
    const newArray = Array.from({ length: number }, (_, index) => index + 1);
    setTeamMemberOption(newArray);
    setTeamInfo(new Array(number).fill(""));
  };

  const handleTeamChange = (e, i) => {
    //only value of e is selected team
    const selectedTeams = e.map((team) => team.value);

    const copy = [...groupTeam];
    copy[i] = selectedTeams;

    setGroupTeam(copy);

    const newOptionsTeam = originalOptionsTeam?.filter((team) => {
      return !copy.flat().includes(team.value);
    });

    setOptionsTeam(newOptionsTeam);
  };


  useEffect(() => {
    accessControl([99, 100, 300], history);
  }, [dispatch]);

  const handleAllGame = () => {
    setIsGrouped(true);

    const gameOrder = [];
    const initGameOrder = [];
    const showedGameOrder = [];

    for (let i = 0; i < groupCount; i++) {
      gameOrder.push({
        groupName: "",
        allGame: []
      });

      initGameOrder.push({
        groupName: "",
        allGame: []
      });
    }

    let tempAllGameCnt = 0;

    console.log(groupTeam)

    for (let i = 0; i < groupTeam.length; i++) {
      console.log(groupTeam[i])
      gameOrder[i].groupName = document.getElementById(`groupName${i}`).value;
      initGameOrder[i].groupName = document.getElementById(`groupName${i}`).value;
      // console.log(gameOrder)

      const tempAllGame = [];
      const tempInitGame = [];
      for (let j = 0; j < groupTeam[i].length; j++) {
        for (let k = j + 1; k < groupTeam[i].length; k++) {
          tempAllGame.push([groupTeam[i][j], groupTeam[i][k]]);
          tempInitGame.push(["", ""]);
          showedGameOrder.push(["", ""]);
          tempAllGameCnt++;
        }
      }

      gameOrder[i].allGame = tempAllGame;
      initGameOrder[i].allGame = tempInitGame;
    }
    setAllGame(gameOrder);
    setAllGameCnt(tempAllGameCnt);
    setShowedGameOrderList(showedGameOrder);
    setGameOrderList(initGameOrder);
  };


  const divisionOption = [
    {
      value: 0,
      label: "일반",
    },
    {
      value: 1,
      label: "디비전1",
    },
    {
      value: 2,
      label: "디비전2",
    },
    {
      value: 3,
      label: "디비전3",
    },
    {
      value: 4,
      label: "디비전4",
    }
  ];

  const teamIdtoName = (teamId) => {
    const teamName = originalOptionsTeam?.find(team => team.value === teamId);

    if (teamName) {
      return teamName.label;
    } else {
      return "";
    }
  }

  const competitionGameAssociationInput = useSelector(
    (state) => state.competitionGameAssociationInput
  );
  const { loading, error } = competitionGameAssociationInput;

  const submitHandler = () => {
    dispatch(addCompetitionGameAssociation(gameOrderList, id));
  };

  const gameOrderHandler = (game, allGameIndex, itemIndex) => {
    const copy = [...gameOrderList];
    const index = copy[allGameIndex].allGame.findIndex(item => item[0] === "");
    copy[allGameIndex].allGame[index] = game;
    setGameOrderList(copy);

    const copyShowed = [...showedGameOrderList];
    for (let i = 0; i < copyShowed.length; i++) {
      if (copyShowed[i][0] === "") {
        copyShowed[i] = game;
        break;
      }
    }
    setShowedGameOrderList(copyShowed);
  }

  const gameOrderInitHandler = (allGameIndex) => {
    // const copy = [...gameOrderList];
    // copy[allGameIndex].allGame = new Array(allGame[allGameIndex].allGame.length).fill(["", ""]);
    // setGameOrderList(copy);

    const copy = [...gameOrderList];
    for (let i = 0; i < copy.length; i++) {
      copy[i].allGame = new Array(allGame[i].allGame.length).fill(["", ""]);
    }
    setGameOrderList(copy);

    const copyShowed = [...showedGameOrderList];
    for (let i = 0; i < copyShowed.length; i++) {
      copyShowed[i] = ["", ""];
    }
    setShowedGameOrderList(copyShowed);
  }

  return (
    <FormContainer>
      <h1>조 편성</h1>
      {error && (
        <Message variant="danger">
          대회 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form>
        <Form.Group controlId="tempTeam">
          <div>
            <span>조 수 : </span>
            <input
              type="number"
              onChange={groupCountInputHandler}
              value={groupCount}
              style={{ width: "50px" }}
            />
            <Button
              variant="outline-primary"
              style={{ marginLeft: "10px", marginBottom: "5px", marginTop: "5px" }}
              onClick={groupMakerRenderer}
            >조 편성</Button>
          </div>
          <div>
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "10%",
                    }}
                  >순번</th>
                  <th
                    style={{
                      width: "20%",
                    }}
                  >조명</th>
                  <th>팀 선택</th>
                </tr>
              </thead>
              <tbody>
                {groupTeam.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {/* {alphabetMap[index]}조 */}
                      <input
                        type="text"
                        id={`groupName${index}`}
                        defaultValue={`${alphabetMap[index]}조`}
                        value={teamName}
                        style={{ width: "100%" }}
                      />
                      </td>
                    <td>
                      <Select
                        options={optionsTeam}
                        closeMenuOnSelect={false}
                        isSearchable
                        isMulti
                        onChange={(e) => handleTeamChange(e, index)}
                        defaultValue={item}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
          {groupTeam.length > 0 && (
            <Button
              onClick={handleAllGame}
            >대진표 생성</Button>
          )}
        </Form.Group>
        {isGrouped && (
          <Form.Group controlId="allGame" className="mt-5">
            <Form.Label>조별 대진표</Form.Label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                width: "100%"
              }}
            >
              <div
                style={{
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                {allGame.map((item, allGameIndex) => (
                  <div
                    key={allGameIndex}
                    className="game-section"
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "100%"
                      }}
                    >
                      <h3>{item.groupName} :</h3>
                      {item.allGame.map((game, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="game-item"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            disabled={gameOrderList[allGameIndex].allGame.includes(game)}
                            variant="outline-primary"
                            className="game-button"
                            style={{
                              width: "125%",
                              marginBottom: "5px",
                              backgroundColor: gameOrderList[allGameIndex].allGame.includes(game) ? "#ccc" : "inherit",
                              color: gameOrderList[allGameIndex].allGame.includes(game) ? "#555" : "#000",

                            }}
                            onClick={() => gameOrderHandler(game, allGameIndex, itemIndex)}
                          >
                            {teamIdtoName(game[0])}
                            <div style={{ color: "red", fontSize: "12px", display: "inline" }}>
                              {" "}vs{" "}
                            </div>
                            {teamIdtoName(game[1])}
                          </Button>
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        width: "50%",
                        marginLeft: "50px"
                      }}
                    >
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <h3>경기 순서</h3>
                {showedGameOrderList.map((item, index) => (
                  <div key={index} className="game-item">
                    <span>{index + 1}경기 : </span>
                    <div style={{ color: "red", fontSize: "12px", display: "inline" }}>
                      {teamIdtoName(showedGameOrderList[index][0])}
                      vs
                      {teamIdtoName(showedGameOrderList[index][1])}
                    </div>
                  </div>
                ))}
                <Button
                  variant="outline-danger"
                  style={{ width: "100%", marginTop: "10px" }}
                  onClick={gameOrderInitHandler}
                >
                  초기화
                </Button>
              </div>
            </div>
          </Form.Group>
        )}
        <Button
          // type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
          onClick={submitHandler}
        >
          조 편성 완료 / 대진표 저장
        </Button>
      </Form>
    </FormContainer>
  );
};

export default CompetitionGroupGameScreen;