import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import optionsCompetitionTeamType from '../static/optionsCompetitionTeamType';
import { useDispatch, useSelector } from 'react-redux';
import { competitionGameList, listCompetition } from '../actions/competitionActions';
import { detailApply, inputApply, updateApply } from '../actions/competitionApplyActions';

const CompetitionApplyDetailScreen = ({ match }) => {
  const id = match.params.id;
  const isReadOnly = match.params.readOnly === "1" ? true : false;

  const dispatch = useDispatch();

  const [teamName, setTeamName] = useState('');
  const [leaderName, setLeaderName] = useState('');
  const [leaderPhoneNum, setLeaderPhoneNum] = useState('');
  const [playerList, setPlayerList] = useState([]);

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const competitionApplyDetail = useSelector((state) => state.competitionApplyDetail);
  const { loading, error, apply } = competitionApplyDetail;

  const competitionGame = useSelector((state) => state.competitionGame);
  const { competition } = competitionGame;

  useEffect(() => {
    if (apply) {
      dispatch(competitionGameList(apply.competitionId));
    }
  }, [dispatch, apply]);

  const formatPhoneNumber = (phoneNum) => {
    const cleaned = ("" + phoneNum).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNum;
  };

  const formatBirthday = (birthday) => {
    return birthday.slice(0, 6);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (teamName === '') {
      alert('팀명을 입력해주세요.');
      return;
    }
    if (leaderName === '') {
      alert('감독 성명을 입력해주세요.');
      return;
    }
    if (leaderPhoneNum === '') {
      alert('감독 휴대폰 번호를 입력해주세요.');
      return;
    }
    
    const newPlayerList = playerList.filter(player => player.name !== '');

    if (newPlayerList.length === 0) {
      alert('선수를 한 명 이상 입력해주세요.');
      return;
    }

    for (let i = 0; i < newPlayerList.length; i++) {
      if (newPlayerList[i].birthday.length !== 6) {
        alert('생년월일은 6자리로 입력해주세요.');
        return;
      }
    }

    dispatch(updateApply({
      _id: id,
      competitionId: apply.competitionId,
      teamName,
      leaderName,
      leaderPhoneNum,
      playerList: newPlayerList,
      applyPassword: apply.applyPassword,
      applyDate: apply.applyDate,
    }));
  };

  useEffect(() => {
    dispatch({ type: 'COMPETITION_APPLY_DETAIL_RESET' });
    dispatch(detailApply(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (apply) {
      setTeamName(apply.teamName);
      setLeaderName(apply.leaderName);
      setLeaderPhoneNum(apply.leaderPhoneNum);
      setPlayerList(apply.playerList);
    }
  }, [apply]);

  return (
    <Container>
      <div>
        <span style={{ color: 'blue', fontSize: '30px' }}>{competition?.name}</span>
        <span style={{ fontSize: '30px' }}> 참가 신청서</span>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3" style={{ width: '70%' }}>
          <Form.Group as={Col}>
            <Form.Label>팀명</Form.Label>
            <Form.Control
              type="text"
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3" style={{ width: '70%' }}>
          <Form.Group as={Col}>
            <Form.Label>감독 성명</Form.Label>
            <Form.Control
              type="text"
              value={leaderName}
              onChange={e => setLeaderName(e.target.value)}
              readOnly={isReadOnly}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>감독 휴대폰 번호</Form.Label>
            <Form.Control
              type="text"
              value={leaderPhoneNum}
              onChange={e => setLeaderPhoneNum(formatPhoneNumber(e.target.value))}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Row>
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
          style={{ borderColor: "black", maxWidth: "700px" }}
        >
          <thead>
            <tr>
              <th
                style={{ width: '7%' }}
              >
                순번
              </th>
              <th
                style={{ width: '20%' }}
              >
                이름
              </th>
              <th
                style={{ width: '20%' }}
              >
                생년월일(6자리)
              </th>
              <th
                style={{ width: '15%' }}
              >
                등번호
              </th>
            </tr>
          </thead>
          <tbody>
            {apply && apply.playerList && apply.playerList.map((player, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td><Form.Control readOnly={isReadOnly} style={{ height: "30px" }} type="text" value={player.name} /></td>
                <td><Form.Control readOnly={isReadOnly} style={{ height: "30px" }} type="text" value={player.birthday} /></td>
                <td><Form.Control readOnly={isReadOnly} style={{ height: "30px" }} type="text" value={player.jerseyNum} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isReadOnly && (<>
          <Button variant="primary" type="submit">수정하기</Button>
        </>)}
      </Form>
    </Container>
  );
};

export default CompetitionApplyDetailScreen;
