export const PLAYER_LIST_REQUEST = "PLAYER_LIST_REQUEST";
export const PLAYER_LIST_SUCCESS = "PLAYER_LIST_SUCCESS";
export const PLAYER_LIST_FAIL = "PLAYER_LIST_FAIL";
export const PLAYER_LIST_RESET = "PLAYER_LIST_RESET";

export const PLAYER_LIST_CONSONANT_REQUEST = "PLAYER_LIST_CONSONANT_REQUEST";
export const PLAYER_LIST_CONSONANT_SUCCESS = "PLAYER_LIST_CONSONANT_SUCCESS";
export const PLAYER_LIST_CONSONANT_FAIL = "PLAYER_LIST_CONSONANT_FAIL";
export const PLAYER_LIST_CONSONANT_RESET = "PLAYER_LIST_CONSONANT_RESET";

export const PLAYER_LIST_TYPE_REQUEST = "PLAYER_LIST_TYPE_REQUEST";
export const PLAYER_LIST_TYPE_SUCCESS = "PLAYER_LIST_TYPE_SUCCESS";
export const PLAYER_LIST_TYPE_FAIL = "PLAYER_LIST_TYPE_FAIL";
export const PLAYER_LIST_TYPE_RESET = "PLAYER_LIST_TYPE_RESET";

export const PLAYER_DETAIL_REQUEST = "PLAYER_DETAIL_REQUEST";
export const PLAYER_DETAIL_SUCCESS = "PLAYER_DETAIL_SUCCESS";
export const PLAYER_DETAIL_FAIL = "PLAYER_DETAIL_FAIL";
export const PLAYER_DETAIL_RESET = "PLAYER_DETAIL_RESET";

export const PLAYER_SEASON_RECORD_REQUEST = "PLAYER_SEASON_RECORD_REQUEST";
export const PLAYER_SEASON_RECORD_SUCCESS = "PLAYER_SEASON_RECORD_SUCCESS";
export const PLAYER_SEASON_RECORD_FAIL = "PLAYER_SEASON_RECORD_FAIL";
export const PLAYER_SEASON_RECORD_RESET = "PLAYER_SEASON_RECORD_RESET";

export const PLAYER_COMPETITION_RECORD_REQUEST =
  "PLAYER_COMPETITION_RECORD_REQUEST";
export const PLAYER_COMPETITION_RECORD_SUCCESS =
  "PLAYER_COMPETITION_RECORD_SUCCESS";
export const PLAYER_COMPETITION_RECORD_FAIL = "PLAYER_COMPETITION_RECORD_FAIL";
export const PLAYER_COMPETITION_RECORD_RESET =
  "PLAYER_COMPETITION_RECORD_RESET";

export const PLAYER_ALL_RECORD_REQUEST = "PLAYER_ALL_RECORD_REQUEST";
export const PLAYER_ALL_RECORD_SUCCESS = "PLAYER_ALL_RECORD_SUCCESS";
export const PLAYER_ALL_RECORD_FAIL = "PLAYER_ALL_RECORD_FAIL";
export const PLAYER_ALL_RECORD_RESET = "PLAYER_ALL_RECORD_RESET";

export const PLAYER_INPUT_REQUEST = "PLAYER_INPUT_REQUEST";
export const PLAYER_INPUT_SUCCESS = "PLAYER_INPUT_SUCCESS";
export const PLAYER_INPUT_FAIL = "PLAYER_INPUT_FAIL";
export const PLAYER_INPUT_RESET = "PLAYER_INPUT_RESET";

export const PLAYER_UPDATE_REQUEST = "PLAYER_UPDATE_REQUEST";
export const PLAYER_UPDATE_SUCCESS = "PLAYER_UPDATE_SUCCESS";
export const PLAYER_UPDATE_FAIL = "PLAYER_UPDATE_FAIL";
export const PLAYER_UPDATE_RESET = "PLAYER_UPDATE_RESET";

export const PLAYER_DUPLICATE_REQUEST = "PLAYER_DUPLICATE_REQUEST";
export const PLAYER_DUPLICATE_SUCCESS = "PLAYER_DUPLICATE_SUCCESS";
export const PLAYER_DUPLICATE_FAIL = "PLAYER_DUPLICATE_FAIL";
export const PLAYER_DUPLICATE_RESET = "PLAYER_DUPLICATE_RESET";

export const PLAYER_DELETE_REQUEST = "PLAYER_DELETE_REQUEST";
export const PLAYER_DELETE_SUCCESS = "PLAYER_DELETE_SUCCESS";
export const PLAYER_DELETE_FAIL = "PLAYER_DELETE_FAIL";

export const PLAYER_COMPETITION_LIST_REQUEST = "PLAYER_COMPETITION_LIST_REQUEST";
export const PLAYER_COMPETITION_LIST_SUCCESS = "PLAYER_COMPETITION_LIST_SUCCESS";
export const PLAYER_COMPETITION_LIST_FAIL = "PLAYER_COMPETITION_LIST_FAIL";
export const PLAYER_COMPETITION_LIST_RESET = "PLAYER_COMPETITION_LIST_RESET";