import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import {
  listTeams,
} from "../actions/teamActions";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";

const AwardGenerateScreen = ({ history }) => {
  const [team, setTeam] = useState("");
  const [rank, setRank] = useState(1);
  const [grade, setGrade] = useState("");
  const [competitionType, setCompetitionType] = useState("topdiv");

  const [awardLoading, setAwardLoading] = useState(false);
  const [awardError, setAwardError] = useState(false);

  const [gradeDisabled, setGradeDisabled] = useState(true);

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const teamList = useSelector((state) => state.teamList);
  const { loading, error, teams } = teamList;

  const optionsTeam = [];

  teams &&
    teams.map((team) =>
      optionsTeam.push({
        value: team._id,
        label: team.name,
      })
    );

  const optionsCompetition = [
    { value: "topdiv", label: "TOP 디비전" },
    { value: "i", label: "I-League" },
    { value: "c", label: "TOP 챔피언스" },
    { value: "time4div", label: "타임4 디비전" },
  ];

  const optionsRank = [
    { value: 1, label: "우승" },
    { value: 2, label: "준우승" },
    { value: 3, label: "3등" },
  ];

  const optionsGrade = [
    { value: "4학년부", label: "4학년부" },
    { value: "5학년부", label: "5학년부" },
    { value: "6학년부", label: "6학년부" },
    { value: "7학년부", label: "7학년부" },
    { value: "8학년부", label: "8학년부" },
    { value: "9학년부", label: "9학년부" },
    { value: "고등부", label: "고등부" },
    { value: "", label: "직접입력" },
  ];

  const handleGradeChange = (e) => {
    if (e.label === "직접입력") {
      setGradeDisabled(false);
    } else {
      setGradeDisabled(true);
    }
    setGrade(e.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setAwardLoading(true);

    if (competitionType === "") {
      alert("대회를 선택하세요");
      setAwardLoading(false);
      return;
    } else if (team === "") {
      alert("팀을 선택하세요");
      setAwardLoading(false);
      return;
    } else if (rank === "") {
      alert("상장 종류를 선택하세요");
      setAwardLoading(false);
      return;
    } else if (grade === "") {
      alert("학년을 선택하세요");
      setAwardLoading(false);
      return;
    }
    try {
      const { data } = await axios.post(
        `${API_SERVER_URL}/api/award?teamId=${team}&rank=${rank}&grade=${grade}&competitionType=${competitionType}`,
        null,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` }
        },
      )

      window.location.href = data.downloadUrl;
    } catch (error) {
      setAwardError(error);
    } finally {
      setAwardLoading(false);
    }
  };

  useEffect(() => {
    accessControl([], history);
    dispatch(listTeams());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <FormContainer>
            <h1>상장 생성</h1>
            <Form.Label>대회</Form.Label>
            {optionsCompetition.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={option.label}
                name="competition"
                value={option.value}
                defaultChecked={index === 0}
                onChange={(e) => setCompetitionType(e.target.value)}
              />
            ))}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="team">
                <Form.Label>팀</Form.Label>
                <Select
                  options={optionsTeam}
                  onChange={(e) => setTeam(e.value)}
                  placeholder="팀을 선택하세요"
                />
              </Form.Group>
              <Form.Group controlId="rank">
                <Form.Label>상장 종류</Form.Label>
                {optionsRank.map((option, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    label={option.label}
                    name="rank"
                    value={option.value}
                    defaultChecked={index === 0}
                    onChange={(e) => setRank(Number(e.target.value))}
                  />
                ))}
              </Form.Group>
              <Form.Group controlId="grade">
                <Form.Label>학년</Form.Label>
                <Select
                  options={optionsGrade}
                  onChange={handleGradeChange}
                  placeholder="학년을 선택하세요"
                />
              </Form.Group>
              <Form.Group controlId="grade1">
                <Form.Control
                  type="text"
                  placeholder="학년을 입력하세요"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  disabled={gradeDisabled}
                ></Form.Control>
              </Form.Group>
              <Button
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                type="submit"
                variant="primary"
                disabled={awardLoading}
              >
                상장 생성
              </Button>
            </Form>
          </FormContainer>
          <div>
            {awardLoading && <>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <h3>상장 생성 중... 곧 다운로드가 진행됩니다.</h3>
              </div>
              <Loader />
            </>}
            {awardError && (
              <Message variant="danger">{awardError}</Message>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AwardGenerateScreen;