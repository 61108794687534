import React from "react";
import Searcher from "../components/Searcher";

const ThreeOnThreeSearchScreen = () => {
  return (
    <>
      <div className="menu__header">
        <h2
          className="mt-3 mb-3"
          style={{ color: "white", "font-weight": 700 }}
        >
          선수 3대3 개인기록조회
        </h2>
        <p style={{ color: "white" }}>
          조회하고자 하는 선수의 이름을 검색하세요
        </p>
      </div>
      <Searcher searchType={"3on3listrecord"} />
      <p
        className="text-center mb-3 mt-5"
        style={{ "font-weight": 400, color: "#031B31" }}
      >
        검색시 이곳에 선수들이 표시됩니다
      </p>
    </>
  );
};

export default ThreeOnThreeSearchScreen;
