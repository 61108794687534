import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image } from "react-bootstrap";
import { API_SERVER_URL } from "../actions/actionUrl";

const MyPageTeamInfo = ({ id }) => {
  let [teamInfo, setTeamInfo] = useState("");

  const imgStyle = {
    maxHeight: 220,
    maxWidth: 220,
  };

  const imgStyleDefault = {
    maxHeight: 180,
    maxWidth: 180,
  };

  async function getTeamInfo() {
    if (teamInfo) return;
    let res = await axios.get(`${API_SERVER_URL}/api/team/name/${id}`);
    let data = res.data;
    setTeamInfo(data);
  }

  if (!teamInfo) {
    getTeamInfo();
  }

  return (
    <>
      {teamInfo.image === "" ? (
        <Image
          src={"/team_default_img.png"}
          style={imgStyleDefault}
          alt={teamInfo.name}
          fluid
          className="mb-3 imgStyle"
        />
      ) : (
        <Image
          src={teamInfo.image}
          style={imgStyle}
          alt={teamInfo.name}
          fluid
          className="mb-3 imgStyle"
        />
      )}
      <h1 style={{ color: "black" }}>{teamInfo.name}</h1>
    </>
  );
};

export default MyPageTeamInfo;
