import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import {
  PICKUP_COMPETITION_LIST_REQUEST,
  PICKUP_COMPETITION_LIST_SUCCESS,
  PICKUP_COMPETITION_LIST_FAIL,
  PICKUP_COMPETITION_LIST_RESET,
  PICKUP_COMPETITION_DETAIL_REQUEST,
  PICKUP_COMPETITION_DETAIL_SUCCESS,
  PICKUP_COMPETITION_DETAIL_FAIL,
  PICKUP_COMPETITION_DETAIL_RESET,
  PICKUP_COMPETITION_INPUT_REQUEST,
  PICKUP_COMPETITION_INPUT_SUCCESS,
  PICKUP_COMPETITION_INPUT_FAIL,
  PICKUP_COMPETITION_INPUT_RESET,
  PICKUP_COMPETITION_UPDATE_REQUEST,
  PICKUP_COMPETITION_UPDATE_SUCCESS,
  PICKUP_COMPETITION_UPDATE_FAIL,
  PICKUP_PLAYER_HOST_LIST_REQUEST,
  PICKUP_PLAYER_HOST_LIST_SUCCESS,
  PICKUP_PLAYER_HOST_LIST_FAIL,
  PICKUP_PLAYER_HOST_LIST_RESET,
  PICKUP_PLAYER_HOST_RECORD_REQUEST,
  PICKUP_PLAYER_HOST_RECORD_SUCCESS,
  PICKUP_PLAYER_HOST_RECORD_FAIL,
  PICKUP_PLAYER_HOST_RECORD_RESET,
  PICKUP_GAME_INPUT_REQUEST,
  PICKUP_GAME_INPUT_SUCCESS,
  PICKUP_GAME_INPUT_FAIL,
  PICKUP_GAME_INPUT_RESET,
  PICKUP_GAME_DETAIL_REQUEST,
  PICKUP_GAME_DETAIL_SUCCESS,
  PICKUP_GAME_DETAIL_FAIL,
  PICKUP_GAME_DETAIL_RESET,
  PICKUP_GAME_LIST_REQUEST,
  PICKUP_GAME_LIST_SUCCESS,
  PICKUP_GAME_LIST_FAIL,
  PICKUP_GAME_LIST_RESET,
  PICKUP_GAME_RESULT_REQUEST,
  PICKUP_GAME_RESULT_SUCCESS,
  PICKUP_GAME_RESULT_FAIL,
  PICKUP_GAME_RESULT_RESET,
  PICKUP_LIST_REQUEST,
  PICKUP_LIST_SUCCESS,
  PICKUP_LIST_FAIL,
  PICKUP_DETAIL_REQUEST,
  PICKUP_DETAIL_SUCCESS,
  PICKUP_DETAIL_FAIL,
  PICKUP_CREATE_REQUEST,
  PICKUP_CREATE_SUCCESS,
  PICKUP_CREATE_FAIL,
  PICKUP_UPDATE_REQUEST,
  PICKUP_UPDATE_SUCCESS,
  PICKUP_UPDATE_FAIL,
  PICKUP_COMPETITION_HOST_LIST_REQUEST,
  PICKUP_COMPETITION_HOST_LIST_SUCCESS, 
  PICKUP_COMPETITION_HOST_LIST_FAIL,
  PICKUP_COMPETITION_STATS_REQUEST,
  PICKUP_COMPETITION_STATS_SUCCESS, 
  PICKUP_COMPETITION_STATS_FAIL,
} from "../constants/pickupConstants";

export const inputPickupCompetition =
  (
    name,
    hostId,
    location1,
    location2,
    place,
    gameDate,
    notice,
    recruitCount
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: PICKUP_COMPETITION_INPUT_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${API_SERVER_URL}/api/pickup-competition`,
        {
          name,
          hostId,
          location1,
          location2,
          place,
          gameDate,
          notice,
          recruitCount
        },
        config
      );
      dispatch({ type: PICKUP_COMPETITION_INPUT_SUCCESS, payload: data });
      alert("[성공] 대회 등록에 성공하였습니다.");
      window.location.reload();
    } catch (error) {
      dispatch({
        type: PICKUP_COMPETITION_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPickupCompetitions =
  (keyword = "", pageNumber = "", location1 = "", location2 = "", isRecruiting = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PICKUP_COMPETITION_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/pickup-competition?keyword=${keyword}&pageNumber=${pageNumber}&location1=${location1}&location2=${location2}&isRecruiting=${isRecruiting}`
      );
      dispatch({ type: PICKUP_COMPETITION_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PICKUP_COMPETITION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const updatePickupCompetition = (pickupCompetition) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PICKUP_COMPETITION_UPDATE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axios.put(
        `${API_SERVER_URL}/api/pickup-competition/${pickupCompetition._id}`,
        pickupCompetition,
        config
      );
  
      dispatch({
        type: PICKUP_COMPETITION_UPDATE_SUCCESS,
        payload: data,
      });
  
      dispatch({ type: PICKUP_COMPETITION_DETAIL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PICKUP_COMPETITION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPickupCompetitionDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_COMPETITION_DETAIL_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-competition/${id}`
    );
    dispatch({ type: PICKUP_COMPETITION_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_COMPETITION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const pickupGameRecord = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_GAME_DETAIL_REQUEST });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-game/${id}`
    );

    dispatch({
      type: PICKUP_GAME_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PICKUP_GAME_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const pickupGameResultInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_GAME_RESULT_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-game/result/${id}`
    );
    dispatch({ type: PICKUP_GAME_RESULT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_GAME_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const pickupGameListRecord = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_GAME_LIST_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-game/${id}`
    );
    dispatch({ type: PICKUP_GAME_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_GAME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPickupCompetitionsHost = (id) => async (dispatch) => {
    try {
      dispatch({ type: PICKUP_COMPETITION_HOST_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/pickup-competition/host/${id}`
      );
      dispatch({ type: PICKUP_COMPETITION_HOST_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PICKUP_COMPETITION_HOST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const pickupCompetitionGameInput =
  (
    pickupGame,
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: PICKUP_GAME_INPUT_REQUEST });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${API_SERVER_URL}/api/pickup-game`,
          pickupGame,
          config
        );
        dispatch({ type: PICKUP_GAME_INPUT_SUCCESS, payload: data });
        alert("[성공] 경기 결과 등록에 성공하였습니다.");
        window.location.reload();
      } catch (error) {
        dispatch({
          type: PICKUP_GAME_INPUT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const updatePickup = (pickup) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_UPDATE_REQUEST });


    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/pickup-competition/${pickup._id}`,
      pickup,
      config
    );

    dispatch({ type: PICKUP_UPDATE_SUCCESS, payload: data });
    alert("[성공] 팀 편성을 완료하였습니다.");
    window.location.reload();
  } catch (error) {
    alert(error.response && error.response.data.message
      ? error.response.data.message
      : error.message,);
    dispatch({
      type: PICKUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPickupPlayerHost = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_PLAYER_HOST_LIST_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-game/player-host/${id}`
    );
    dispatch({ type: PICKUP_PLAYER_HOST_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_PLAYER_HOST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const recordPickupPlayerHost = (player) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_PLAYER_HOST_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/pickup-game/player-record/host/${player._id}?hostId=${player.hostId}`
    );
    dispatch({ type: PICKUP_PLAYER_HOST_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_PLAYER_HOST_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const pickupCompetitionStatsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_COMPETITION_STATS_REQUEST });
    const pickupCompetition = await axios.get(
      `${API_SERVER_URL}/api/pickup-competition/participants/stats/${id}`
    );

    const { data } = pickupCompetition;
    dispatch({ type: PICKUP_COMPETITION_STATS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PICKUP_COMPETITION_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}