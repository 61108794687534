import {
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_LIST_FAIL,
  NOTICE_LIST_RESET,
  NOTICE_DETAIL_REQUEST,
  NOTICE_DETAIL_SUCCESS,
  NOTICE_DETAIL_FAIL,
  NOTICE_INPUT_REQUEST,
  NOTICE_INPUT_SUCCESS,
  NOTICE_INPUT_FAIL,
  NOTICE_INPUT_RESET,
  NOTICE_UPDATE_REQUEST,
  NOTICE_UPDATE_SUCCESS,
  NOTICE_UPDATE_FAIL,
  NOTICE_UPDATE_RESET,
  NOTICE_DELETE_REQUEST,
  NOTICE_DELETE_SUCCESS,
  NOTICE_DELETE_FAIL,
  COMPETITION_NOTICE_LIST_REQUEST,
  COMPETITION_NOTICE_LIST_SUCCESS,
  COMPETITION_NOTICE_LIST_FAIL,
  COMPETITION_NOTICE_LIST_RESET,
  COMPETITION_NOTICE_DETAIL_REQUEST,
  COMPETITION_NOTICE_DETAIL_SUCCESS,
  COMPETITION_NOTICE_DETAIL_FAIL,
  COMPETITION_NOTICE_INPUT_REQUEST,
  COMPETITION_NOTICE_INPUT_SUCCESS,
  COMPETITION_NOTICE_INPUT_FAIL,
  COMPETITION_NOTICE_INPUT_RESET,
  COMPETITION_NOTICE_UPDATE_REQUEST,
  COMPETITION_NOTICE_UPDATE_SUCCESS,
  COMPETITION_NOTICE_UPDATE_FAIL,
  COMPETITION_NOTICE_UPDATE_RESET,
  COMPETITION_NOTICE_DELETE_REQUEST,
  COMPETITION_NOTICE_DELETE_SUCCESS,
  COMPETITION_NOTICE_DELETE_FAIL,
} from "../constants/noticeConstants";

export const noticeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICE_DELETE_REQUEST:
      return { loading: true };
    case NOTICE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case NOTICE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const noticeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICE_UPDATE_REQUEST:
      return { loading: true };
    case NOTICE_UPDATE_SUCCESS:
      alert("[성공] 정상적으로 업데이트 되었습니다.");
      return { loading: false, success: true, notice: action.payload };
    case NOTICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case NOTICE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const noticeWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICE_INPUT_REQUEST:
      return { loading: true };
    case NOTICE_INPUT_SUCCESS:
      return { loading: false };
    case NOTICE_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case NOTICE_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const noticeReadReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTICE_DETAIL_REQUEST:
      return { loading: true };
    case NOTICE_DETAIL_SUCCESS:
      return { loading: false, notice: action.payload };
    case NOTICE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const noticeListReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case NOTICE_LIST_REQUEST:
      return { loading: true };
    case NOTICE_LIST_SUCCESS:
      return { loading: false, notices: action.payload };
    case NOTICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NOTICE_LIST_RESET:
      return { notices: [] };
    default:
      return state;
  }
};

export const competitionNoticeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_NOTICE_DELETE_REQUEST:
      return { loading: true };
    case COMPETITION_NOTICE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COMPETITION_NOTICE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionNoticeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_NOTICE_UPDATE_REQUEST:
      return { loading: true };
    case COMPETITION_NOTICE_UPDATE_SUCCESS:
      return { loading: false, success: true, competitionNotice: action.payload };
    case COMPETITION_NOTICE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_NOTICE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionNoticeWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_NOTICE_INPUT_REQUEST:
      return { loading: true };
    case COMPETITION_NOTICE_INPUT_SUCCESS:
      return { loading: false };
    case COMPETITION_NOTICE_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_NOTICE_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const competitionNoticeReadReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPETITION_NOTICE_DETAIL_REQUEST:
      return { loading: true };
    case COMPETITION_NOTICE_DETAIL_SUCCESS:
      return { loading: false, competitionNotice: action.payload };
    case COMPETITION_NOTICE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const competitionNoticeListReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case COMPETITION_NOTICE_LIST_REQUEST:
      return { loading: true };
    case COMPETITION_NOTICE_LIST_SUCCESS:
      return {
        loading: false,
        competitionNotices: action.payload.competitionNotice,
        pages: action.pages,
        page: action.page,
      };
    case COMPETITION_NOTICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPETITION_NOTICE_LIST_RESET:
      return { competitionNotices: [] };
    default:
      return state;
  }
}