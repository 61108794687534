import React, { useState } from "react";
import { API_SERVER_URL } from "../actions/actionUrl";
import axios from "axios";

const IDEmailFindScreen = ({ history }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      alert("이메일이 전송되었습니다. 이메일을 확인해주세요.");

      const response = await axios.post(`${API_SERVER_URL}/api/user/reset/email?email=${email}`);
      setMessage(response.data.message);

      history.push("/login");
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <div style={{ textAlign: "center", margin: "50px auto", width: "300px" }}>
      <h2 style={{ marginBottom: "20px" }}>비밀번호 재설정</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="email">이메일</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              padding: "5px",
              width: "100%",
              borderRadius: "3px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            background: "#0D192B",
            color: "#fff",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          비밀번호 재설정 이메일 보내기
        </button>
      </form>
      {message && <div style={{ marginTop: "10px" }}>{message}</div>}
    </div>
  );
};

export default IDEmailFindScreen;
