import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listCompetitionNotice } from "../actions/noticeActions";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllCompetitionNoticeListScreen = () => {
  const dispatch = useDispatch();

  const competitionNoticeList = useSelector(
    (state) => state.competitionNoticeList
  );
  const { loading, error, competitionNotice } = competitionNoticeList;

  useEffect(() => {
    dispatch(listCompetitionNotice());
  }, [dispatch]);

  return (
    <>
      <div className="menu__header">
        <h2
          className="mt-3 mb-3"
          style={{ color: "white", "font-weight": 700 }}
        >
          대회소식
        </h2>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm text-center mt-3">
            <thead>
              <tr className="table__header">
                <th style={{ width: "50px" }}>
                  <FontAwesomeIcon icon={faBasketball} />
                </th>
                <th>제목</th>
                <th className="notice__date__field">작성일</th>
                <th className="notice__view__field">조회</th>
              </tr>
            </thead>
            <tbody>
              {competitionNotice &&
                competitionNotice.result &&
                competitionNotice.result.map((notice, index) => (
                  <tr key={notice.competitionNotice._id}>
                    <td>{competitionNotice.result.length - index}</td>
                    <td>
                      <Link
                        to={`/competition-notice/detail/${notice.competitionNotice._id}`}
                      >
                        {notice.competitionNotice.title}
                      </Link>
                    </td>
                    <td>{notice.competitionNotice.date.substring(2, 10)}</td>
                    <td>{notice.competitionNotice.view}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default AllCompetitionNoticeListScreen;
