import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { writePost } from "../actions/communityActions";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const CommunityWriteScreen = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("자유게시판");

  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(null);

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const submitCommunityPost = () => {
    const author = {
      _id: userInfo._id,
      name: userInfo.name,
    };
    dispatch(writePost(title, content, category, author));
  };

  const categoryHandler = (r, cat) => {
    setRSelected(r);
    setCategory(cat);
  };

  const categoryButton = (r, cat) => {
    return (
      <Button
        color="primary"
        outline
        onClick={(e) => categoryHandler(r, cat)}
        active={rSelected === r}
        style={{
          fontSize: "0.75rem",
          padding: "8px 6px",
          borderRadius: "10px",
          marginRight: "8px",
          marginBottom: "8px",
        }}
      >
        {cat}
      </Button>
    );
  };

  return (
    <div className="hideextra" style={{ "min-width": "5px" }}>
      <div className="form-wrapper">
        <h4 className="mx-1">글쓰기</h4>
        <Row>
          <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>제목</span>
            <input
              type="text"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              style={{ width: "50%", height: "50px" }}
            />
          </div>
          <div>
            <span style={{ marginRight: "10px" }}>글머리</span>
            <ButtonGroup>
              {categoryButton(1, "자유게시판")}
              {userInfo && userInfo.isAdmin && categoryButton(2, "공지사항")}
              {userInfo && userInfo.isAdmin && categoryButton(3, "대회공지")}
            </ButtonGroup>
          </div>
        </Row>
        <CKEditor
          editor={ClassicEditor}
          // config={ {plugins: [ Image ]}}
          data=""
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
        />
        <Button
          className="submit-button mt-2"
          onClick={submitCommunityPost}
          style={{ width: "100px" }}
        >
          등록하기
        </Button>
      </div>
    </div>
  );
};

export default CommunityWriteScreen;
