import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { listCompetition } from '../actions/competitionActions';
const CompetitionApplyLinkScreen = () => {
  const dispatch = useDispatch();

  const [optionsCompetition, setOptionsCompetition] = useState([]);
  const [applyLink, setApplyLink] = useState('');

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  useEffect(() => {
    dispatch(listCompetition());
  }, [dispatch]);

  useEffect(() => {
    if (competitions) {
      let tempOptionsCompetition = [];

      competitions
        .filter(competition => competition.status <= 1)
        .map((competition) =>
          tempOptionsCompetition.push({
            value: competition._id,
            label: competition.name,
          })
        );
      setOptionsCompetition(tempOptionsCompetition);
    }
  }, [competitions]);

  const linkGenerate = (e) => {
    setApplyLink(`https://time4.co.kr/competition-apply/${e.value}`);
  };

  return (
    <Container>
      <div style={{ fontWeight: 'bold'}}>대회 선택</div>
      <div
        style={{
          width: '300px',
          marginBottom: '20px',
          textAlign: 'left',
        }}
      >
      <Select
        options={optionsCompetition}
        onChange={linkGenerate}
      />
      </div>
      <input
        type="text"
        value={applyLink}
        onChange={(e) => setApplyLink(e.target.value)}
        style={{ width: '1000px', marginBottom: '20px' }}
        disabled
      />
    </Container>
  );
};

export default CompetitionApplyLinkScreen;
