import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listNotice } from "../actions/noticeActions";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoticeListScreen = () => {
  const dispatch = useDispatch();

  const noticeList = useSelector((state) => state.noticeList);
  const { loading, error, notices } = noticeList;

  useEffect(() => {
    dispatch(listNotice());
  }, [dispatch]);

  return (
    <>
      <div className="menu__header">
        <h2
          className="mt-3 mb-3"
          style={{ color: "white", "font-weight": 700 }}
        >
          공지사항
        </h2>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm text-center mt-3">
            <thead>
              <tr className="table__header">
                <th style={{ width: "50px" }}>
                  <FontAwesomeIcon icon={faBasketball} />
                </th>
                <th>제목</th>
                <th className="notice__date__field">작성일</th>
              </tr>
            </thead>
            <tbody>
              {notices &&
                notices.notice &&
                notices.notice.map((notice, index) => (
                  <tr key={notice._id}>
                    <td>{notices.notice.length - index}</td>
                    <td>
                      <Link to={`/notice/detail/${notice._id}`}>
                        {notice.title}
                      </Link>
                    </td>
                    <td>{notice.date.substring(0, 10)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default NoticeListScreen;
