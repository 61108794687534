// import React from "react";
// import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { LinkContainer } from "react-router-bootstrap";
// import { logout } from "../actions/userActions";

// const Header = () => {
//   const dispatch = useDispatch();
//   const userLogin = useSelector((state) => state.userLogin);
//   //const { userInfo } = userLogin;
//   //console.log(userInfo);
//   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
//   const logoutHandler = () => {
//     dispatch(logout());
//   };

//   const tierAccess = (accessList) => {
//     if (userInfo) {
//       if (userInfo._id === "643619642e2f6ef00d37f168") return true;
//       if (accessList.includes(userInfo.tier)) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   };

//   return (
//     <header>
//       <Navbar bg="light" variant="black" expand="lg" collapseOnSelect>
//         <Container>
//           <Navbar.Brand href="/">
//             <Image
//               src={`/logo.png`}
//               style={{
//                 border: "0px solid rgba(0, 0, 0, 0)",
//                 width: "7rem",
//                 "margin-right": "10px",
//                 "margin-top": "2px",
//               }}
//               className="mb-1"
//             />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto">
//               <Nav.Link
//                 href="/personallistrecord/page/1"
//                 style={{ marginRight: "10px" }}
//               >
//                 개인기록
//               </Nav.Link>
//               <Nav.Link
//                 href="/3on3listrecord/page/1"
//                 style={{ marginRight: "10px" }}
//               >
//                 개인기록(3대3)
//               </Nav.Link>
//               <Nav.Link
//                 href="/teamrecord/page/1"
//                 style={{ marginRight: "10px" }}
//               >
//                 팀기록
//               </Nav.Link>
//               <Nav.Link
//                 href="/competitionrecord-div/page/1"
//                 style={{ marginRight: "10px" }}
//               >
//                 디비전 기록실
//               </Nav.Link>
//               <Nav.Link
//                 href="/competitionrecord-lab/page/1"
//                 style={{ marginRight: "10px" }}
//               >
//                 농구연구소
//               </Nav.Link>
//               <NavDropdown title="일반 기록실" style={{ marginRight: "10px" }}>
//                 <LinkContainer to="/competitionrecord/page/1">
//                   <NavDropdown.Item>대회기록</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/youthrecord/page/1">
//                   <NavDropdown.Item>대회기록(유소년)</NavDropdown.Item>
//                 </LinkContainer>
//               </NavDropdown>

//               {/* <NavDropdown
//                 title="디비전 기록실"
//                 style={{ marginRight: "10px" }}
//               > */}
//               {/* <LinkContainer to="/personallistrecord/page/1">
//                   <NavDropdown.Item>개인기록</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/teamrecord/page/1">
//                   <NavDropdown.Item>팀기록</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/div1competitionrecord/page/1">
//                   <NavDropdown.Item>디비전1</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/div2competitionrecord/page/1">
//                   <NavDropdown.Item>디비전2</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/div3competitionrecord/page/1">
//                   <NavDropdown.Item>디비전3</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/div4competitionrecord/page/1">
//                   <NavDropdown.Item>디비전4</NavDropdown.Item>
//                 </LinkContainer> */}
//               {/* <LinkContainer to="/competitionrecord-div/page/1">
//                   <NavDropdown.Item>디비전 대회</NavDropdown.Item>
//                 </LinkContainer>
//               </NavDropdown> */}
//               {/* <Nav.Link href="/media">미디어</Nav.Link> */}
//               {/* <Nav.Link href="/ranking">랭킹</Nav.Link> */}
//               <Nav.Link href="/community/category/자유게시판">
//                 커뮤니티
//               </Nav.Link>
//               {/* <Nav.Link href="/competition-notice/page/1">
//                 대회 공지
//               </Nav.Link> */}
//               {/* <NavDropdown title="픽업게임">
//                 <LinkContainer to="/pickupgame/player-record/page/1">
//                   <NavDropdown.Item>개인기록</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/pickupgame/game-record">
//                   <NavDropdown.Item>경기결과</NavDropdown.Item>
//                 </LinkContainer>
//                 <LinkContainer to="/pickupgame/booking">
//                   <NavDropdown.Item>부킹</NavDropdown.Item>
//                 </LinkContainer>
//               </NavDropdown> */}

//               {/* <Nav.Link href="/shop">농구용품</Nav.Link> */}

//               {userInfo ? (
//                 <>
//                   <NavDropdown title={`마이메뉴(${userInfo.name})`} id="username">
//                     <LinkContainer to="/mypage">
//                       <NavDropdown.Item>마이페이지</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/profile">
//                       <NavDropdown.Item>내 정보</NavDropdown.Item>
//                     </LinkContainer>
//                     <NavDropdown.Item onClick={logoutHandler}>
//                       로그아웃
//                     </NavDropdown.Item>
//                   </NavDropdown>
//                   <NavDropdown title={`내 팀 관리`} id="username">
//                     <LinkContainer to={`/myteam`}>
//                       <NavDropdown.Item>팀 페이지</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to={`/playermanage`}>
//                       <NavDropdown.Item>팀 관리</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/new-team">
//                       <NavDropdown.Item>팀 생성</NavDropdown.Item>
//                     </LinkContainer>
//                     {/* <LinkContainer to="/competitionapply">
//                       <NavDropdown.Item>대회 팀 신청</NavDropdown.Item>
//                     </LinkContainer> */}
//                   </NavDropdown>
//                 </>
//               ) : (
//                 <Nav.Link href="/login">
//                   <i className="fas fa-user"></i> 로그인
//                 </Nav.Link>
//               )}

//               {userInfo && userInfo.isAdmin && (
//                 <>
//                   {/* <NavDropdown title="픽업게임 관리자" id="adminmenu">
//                     <LinkContainer to="/pickupgame/recruit">
//                       <NavDropdown.Item>픽업게임 모집</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/pickupgame/gameinput">
//                       <NavDropdown.Item>픽업 경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown> */}
//                   <NavDropdown title="관리자 모드" id="adminmenu">
//                     <LinkContainer to="/admin/gameinput">
//                       <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/tmpteaminput">
//                       <NavDropdown.Item>팀 가입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/tmpplayerinput">
//                       <NavDropdown.Item>선수 가입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/competitioninput">
//                       <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/three-competitioninput">
//                       <NavDropdown.Item>3대3 대회 생성</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/noticeinput">
//                       <NavDropdown.Item>공지 입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/allcompetition">
//                       <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/competition-image">
//                       <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/div-award">
//                       <NavDropdown.Item>디비전 상장 생성</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/admin/record-swap">
//                       <NavDropdown.Item>선수 기록 대치</NavDropdown.Item>
//                     </LinkContainer>
//                     {/* <LinkContainer to="/association">
//                       <NavDropdown.Item>협회 관리</NavDropdown.Item>
//                     </LinkContainer> */}
//                     <LinkContainer to="/admin/dumbgameinput">
//                       <NavDropdown.Item>경기 기록 입력(상세x)</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown>
//                 </>
//               )}
//               {tierAccess([300]) && (
//                 <>
//                   <NavDropdown title="협회 관리자" id="adminmenu">
//                     <LinkContainer to="/association/competition-manage">
//                       <NavDropdown.Item>대회 관리</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/association/competition-notice/write">
//                       <NavDropdown.Item>대회 공지 작성</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown>
//                 </>
//               )}
//               {/* {tierAccess([200]) && (
//                 <>
//                   <NavDropdown title="픽업게임 관리자" id="adminmenu">
//                     <LinkContainer to="/pickupgame/recruit">
//                       <NavDropdown.Item>픽업게임 모집</NavDropdown.Item>
//                     </LinkContainer>
//                     <LinkContainer to="/pickupgame/gameinput">
//                       <NavDropdown.Item>픽업 경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown>
//                 </>
//               )} */}
//               {tierAccess([100]) && (
//                 <>
//                   <NavDropdown title="기록원1 모드" id="adminmenu">
//                     <LinkContainer to="/admin/gameinput">
//                       <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <>
//                       <LinkContainer to="/admin/tmpteaminput">
//                         <NavDropdown.Item>팀 가입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/tmpplayerinput">
//                         <NavDropdown.Item>선수 가입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/competitioninput">
//                         <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
//                       </LinkContainer>
//                       {/* <LinkContainer to="/admin/noticeinput">
//                         <NavDropdown.Item>공지 입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/allcompetition">
//                         <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/competition-image">
//                         <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
//                       </LinkContainer> */}
//                     </>
//                   </NavDropdown>
//                 </>
//               )}
//               {tierAccess([101]) && (
//                 <>
//                   <NavDropdown title="기록원2 모드" id="adminmenu">
//                     <LinkContainer to="/admin/gameinput">
//                       <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown>
//                 </>
//               )}
//               {tierAccess([99]) && (
//                 <>
//                   <NavDropdown title="time4 직원 모드" id="adminmenu">
//                     <LinkContainer to="/admin/gameinput">
//                       <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
//                     </LinkContainer>
//                     <>
//                       <LinkContainer to="/admin/tmpteaminput">
//                         <NavDropdown.Item>팀 가입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/tmpplayerinput">
//                         <NavDropdown.Item>선수 가입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/competitioninput">
//                         <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
//                       </LinkContainer>
//                       {/* <LinkContainer to="/admin/noticeinput">
//                         <NavDropdown.Item>공지 입력</NavDropdown.Item>
//                       </LinkContainer>
//                       <LinkContainer to="/admin/allcompetition">
//                         <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
//                       </LinkContainer> */}
//                       <LinkContainer to="/admin/competition-image">
//                         <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
//                       </LinkContainer>
//                     </>
//                   </NavDropdown>
//                 </>
//               )}
//               {tierAccess([300]) && (
//                 <>
//                   {/* <NavDropdown title="협회관계자 모드" id="adminmenu">
//                     <LinkContainer to="/association">
//                       <NavDropdown.Item>협회 관리</NavDropdown.Item>
//                     </LinkContainer>
//                   </NavDropdown> */}
//                 </>
//               )}
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </header>
//   );
// };

// export default Header;





import React, { useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userActions";
import { dailyLogout } from "../utils/viewSetup";

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  //const { userInfo } = userLogin;
  //console.log(userInfo);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const logoutHandler = () => {
    dispatch(logout());
  };

  const tierAccess = (accessList) => {
    if (userInfo) {
      if (userInfo._id === "643619642e2f6ef00d37f168") return true;
      if (accessList.includes(userInfo.tier)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    dailyLogout();
  }, [dispatch]);

  return (
    <header>
      <Navbar bg="light" variant="black" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">
            <Image
              src={`/logo.png`}
              style={{
                border: "0px solid rgba(0, 0, 0, 0)",
                width: "7rem",
                "margin-right": "10px",
                "margin-top": "2px",
              }}
              className="mb-1"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="/personallistrecord/page/1"
                style={{ marginRight: "10px" }}
              >
                개인기록
              </Nav.Link>
              {/* <Nav.Link
                href="/3on3listrecord/page/1"
                style={{ marginRight: "10px" }}
              >
                개인기록(3대3)
              </Nav.Link> */}
              <Nav.Link
                href="/teamrecord/page/1"
                style={{ marginRight: "10px" }}
              >
                팀기록
              </Nav.Link>
              <NavDropdown title="공식 대회(종별)" style={{ marginRight: "10px" }}>
                <LinkContainer to="/competitionrecord/Official/유소년/page/1">
                  <NavDropdown.Item>유소년</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/중등부/page/1">
                  <NavDropdown.Item>중등부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/고등부/page/1">
                  <NavDropdown.Item>고등부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/대학부/page/1">
                  <NavDropdown.Item>대학부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/직장인/page/1">
                  <NavDropdown.Item>직장인</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/일반부/page/1">
                  <NavDropdown.Item>일반부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/마스터즈/page/1">
                  <NavDropdown.Item>마스터즈</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="공식 대회(시즌)" style={{ marginRight: "10px" }}>
                <LinkContainer to="/competitionrecord/Official/season/2024/page/1">
                  <NavDropdown.Item>2024 시즌</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/season/2023/page/1">
                  <NavDropdown.Item>2023 시즌</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Official/season/2022/page/1">
                  <NavDropdown.Item>2022 시즌</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="TIME4 디비전" style={{ marginRight: "10px" }}>
                <LinkContainer to="/competitionrecord/TBL/division/all/page/1">
                  <NavDropdown.Item>TBL</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Time4Division/division/all/page/1">
                  <NavDropdown.Item>TIME4 디비전</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/SchoolB/division/all/page/1">
                  <NavDropdown.Item>스쿨B</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="농구연구소" style={{ marginRight: "10px" }}>
                <LinkContainer to="/competitionrecord/NonguLab/division/all/page/1">
                  <NavDropdown.Item>전체</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/1/page/1">
                  <NavDropdown.Item>디비전1</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/2/page/1">
                  <NavDropdown.Item>디비전2</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/3/page/1">
                  <NavDropdown.Item>디비전3</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/4/page/1">
                  <NavDropdown.Item>디비전4</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/5/page/1">
                  <NavDropdown.Item>디비전5</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/6/page/1">
                  <NavDropdown.Item>디비전6</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/10/page/1">
                  <NavDropdown.Item>여자부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/11/page/1">
                  <NavDropdown.Item>대학부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/NonguLab/division/12/page/1">
                  <NavDropdown.Item>기타부</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="비공식 대회" style={{ marginRight: "10px" }}>
                <LinkContainer to="/competitionrecord/Unofficial/유소년/page/1">
                  <NavDropdown.Item>유소년</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/중등부/page/1">
                  <NavDropdown.Item>중등부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/고등부/page/1">
                  <NavDropdown.Item>고등부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/대학부/page/1">
                  <NavDropdown.Item>대학부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/직장인/page/1">
                  <NavDropdown.Item>직장인</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/일반부/page/1">
                  <NavDropdown.Item>일반부</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/competitionrecord/Unofficial/마스터즈/page/1">
                  <NavDropdown.Item>마스터즈</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <Nav.Link href="/community/category/자유게시판">
                커뮤니티
              </Nav.Link>
              {userInfo ? (
                <>
                  <NavDropdown title={`마이메뉴(${userInfo.name})`} id="username">
                    <LinkContainer to="/mypage">
                      <NavDropdown.Item>마이페이지</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>내 정보</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      로그아웃
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title={`내 팀 관리`} id="username">
                    <LinkContainer to={`/myteam`}>
                      <NavDropdown.Item>팀 페이지</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={`/playermanage`}>
                      <NavDropdown.Item>팀 관리</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/new-team">
                      <NavDropdown.Item>팀 생성</NavDropdown.Item>
                    </LinkContainer>
                    {/* <LinkContainer to="/competitionapply">
                      <NavDropdown.Item>대회 팀 신청</NavDropdown.Item>
                    </LinkContainer> */}
                  </NavDropdown>
                </>
              ) : (
                <Nav.Link href="/login">
                  <i className="fas fa-user"></i> 로그인
                </Nav.Link>
              )}

              {userInfo && userInfo.isAdmin && (
                <>
                  {/* <NavDropdown title="픽업게임 관리자" id="adminmenu">
                    <LinkContainer to="/pickupgame/recruit">
                      <NavDropdown.Item>픽업게임 모집</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/pickupgame/gameinput">
                      <NavDropdown.Item>픽업 경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown> */}
                  <NavDropdown title="관리자 모드" id="adminmenu">
                    <LinkContainer to="/admin/gameinput">
                      <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/tmpteaminput">
                      <NavDropdown.Item>팀 가입력</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/tmpplayerinput">
                      <NavDropdown.Item>선수 가입력</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/competitioninput">
                      <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/noticeinput">
                      <NavDropdown.Item>공지 입력</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/allcompetition">
                      <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/competition-image">
                      <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/div-award">
                      <NavDropdown.Item>디비전 상장 생성</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/record-swap">
                      <NavDropdown.Item>선수 기록 대치</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/dumbgameinput">
                      <NavDropdown.Item>경기 기록 입력(상세x)</NavDropdown.Item>
                    </LinkContainer>
                    {/* <LinkContainer to="/association">
                      <NavDropdown.Item>협회 관리</NavDropdown.Item>
                    </LinkContainer> */}
                  </NavDropdown>
                </>
              )}
              {tierAccess([300]) && (
                <>
                  <NavDropdown title="협회 관리자" id="adminmenu">
                    <LinkContainer to="/association/competition-manage">
                      <NavDropdown.Item>대회 관리</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/association/competition-notice/write">
                      <NavDropdown.Item>대회 공지 작성</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </>
              )}
              {/* {tierAccess([200]) && (
                <>
                  <NavDropdown title="픽업게임 관리자" id="adminmenu">
                    <LinkContainer to="/pickupgame/recruit">
                      <NavDropdown.Item>픽업게임 모집</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/pickupgame/gameinput">
                      <NavDropdown.Item>픽업 경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </>
              )} */}
              {tierAccess([100]) && (
                <>
                  <NavDropdown title="기록원1 모드" id="adminmenu">
                    <LinkContainer to="/admin/gameinput">
                      <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                    <>
                      <LinkContainer to="/admin/tmpteaminput">
                        <NavDropdown.Item>팀 가입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/tmpplayerinput">
                        <NavDropdown.Item>선수 가입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/competitioninput">
                        <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
                      </LinkContainer>
                      {/* <LinkContainer to="/admin/noticeinput">
                        <NavDropdown.Item>공지 입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/allcompetition">
                        <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/competition-image">
                        <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
                      </LinkContainer> */}
                    </>
                  </NavDropdown>
                </>
              )}
              {tierAccess([101]) && (
                <>
                  <NavDropdown title="기록원2 모드" id="adminmenu">
                    <LinkContainer to="/admin/gameinput">
                      <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </>
              )}
              {tierAccess([99]) && (
                <>
                  <NavDropdown title="time4 직원 모드" id="adminmenu">
                    <LinkContainer to="/admin/gameinput">
                      <NavDropdown.Item>경기 기록 입력</NavDropdown.Item>
                    </LinkContainer>
                    <>
                      <LinkContainer to="/admin/tmpteaminput">
                        <NavDropdown.Item>팀 가입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/tmpplayerinput">
                        <NavDropdown.Item>선수 가입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/competitioninput">
                        <NavDropdown.Item>대회/리그 생성</NavDropdown.Item>
                      </LinkContainer>
                      {/* <LinkContainer to="/admin/noticeinput">
                        <NavDropdown.Item>공지 입력</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/allcompetition">
                        <NavDropdown.Item>대회 선수 통계</NavDropdown.Item>
                      </LinkContainer> */}
                      <LinkContainer to="/admin/competition-image">
                        <NavDropdown.Item>대회 이미지 관리</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  </NavDropdown>
                </>
              )}
              {tierAccess([300]) && (
                <>
                  {/* <NavDropdown title="협회관계자 모드" id="adminmenu">
                    <LinkContainer to="/association">
                      <NavDropdown.Item>협회 관리</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown> */}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
