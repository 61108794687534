import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Button, Table, Row, Col, Container, Modal } from "react-bootstrap";
import './recorderStyle.css';

/**
 * @param {{ onAgree: () => void }} props
 * @constructor
 */

const RecorderMain = ({ setting, teamA, teamB, gameInitSetting, isLoaded, initEvent }) => {
  useEffect(() => {
    console.log(setting);
    console.log(teamA);
    console.log(teamB);
    console.log(gameInitSetting);
  }, [setting, teamA, teamB]);

  const [quarter, setQuarter] = useState(1);
  const [isIntermission, setIsIntermission] = useState(false);

  const MMSStoSecond = (time) => {
    const [min, sec] = time.split(':');

    return parseInt(min) * 60 + parseInt(sec);
  };

  const buttonStyle = {
    minWidth: '50px',       // 버튼 너비를 50px로 설정
    minHeight: '50px',       // 버튼 높이를 40px로 설정
    margin: '2px',        // 작은 마진을 적용
    padding: '5px 10px',  // 안쪽 여백을 적용
    fontSize: '12px'      // 작은 글씨 크기를 적용
  };

  const [gameJSON, setGameJSON] = useState(gameInitSetting);
  const [playTimeList, setPlayTimeList] = useState([[],[]]);
  const [selectedPlayer, setSelectedPlayer] = useState({ playerId: teamA.players[0].value, vsTeamIndex: gameInitSetting.vsTeam.indexOf(teamA.teamId) });

  const [isTimerOn, setIsTimerOn] = useState(false);
  const [timer, setTimer] = useState(MMSStoSecond(setting.gameTime));

  const [isShotClockOn, setIsShotClockOn] = useState(false);
  const [shotClock, setShotClock] = useState(MMSStoSecond(setting.shotClock));

  const secondToMMSS = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec
      }`;
  };

  const [events, setEvents] = useState(initEvent);
  const [showedEvents, setShowedEvents] = useState([]);
  const [showingAllEvents, setShowingAllEvents] = useState(true);

  const [history, setHistory] = useState([]);
  const [eventRedoStack, setEventRedoStack] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const moveQuarter = () => {
    if (!isIntermission) {
      setIsIntermission(true);
      setTimer(MMSStoSecond(setting.quarterBreak));
      setIsTimerOn(true);
    } else {
      if (quarter < 5) {
        setQuarter(quarter + 1);
      } else {
        setQuarter(5);
      }
      setIsIntermission(false);
      setTimer(MMSStoSecond(setting.gameTime));
      setIsTimerOn(false);
    }
  };

  const undoHandler = () => {
    if (history.length > 0) {
      setEventRedoStack([...eventRedoStack, events]);
      setEvents(history[history.length - 1]);
      setHistory(history.slice(0, history.length - 1));
    }
  };

  const redoHandler = () => {
    if (eventRedoStack.length > 0) {
      setHistory([...history, events]);
      setEvents(eventRedoStack[eventRedoStack.length - 1]);
      setEventRedoStack(eventRedoStack.slice(0, eventRedoStack.length - 1));
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      const copy = { ...gameJSON };
      const initPlayTimeList = [[], []];
      copy.playerRecord = [[], []];

      for (let i = 0; i < teamA.players.length; i++) {
        copy.playerRecord[0].push({
          playerId: teamA.players[i].value,
          score: 0,
          fieldGoal: [0, 0],
          threePointThrow: [0, 0],
          freeThrow: [0, 0],
          rebound: [0, 0],
          steal: 0,
          block: 0,
          turnOver: 0,
          foul: [0, 0],
          assist: 0,
          jerseyNum: teamA.players[i].jerseyNum,

          goodD: 0,
          fastBreak: 0,
          plusOne: 0,
          // playTime: 0,
          playingNow: teamA.players[i].playingNow,
        });

        initPlayTimeList[0].push({ playerId: teamA.players[i].value, playTime: 0 });
      }

      for (let i = 0; i < teamB.players.length; i++) {
        copy.playerRecord[1].push({
          playerId: teamB.players[i].value,
          score: 0,
          fieldGoal: [0, 0],
          threePointThrow: [0, 0],
          freeThrow: [0, 0],
          rebound: [0, 0],
          steal: 0,
          block: 0,
          turnOver: 0,
          foul: [0, 0],
          assist: 0,
          jerseyNum: teamB.players[i].jerseyNum,

          goodD: 0,
          fastBreak: 0,
          plusOne: 0,
          // playTime: 0,
          playingNow: teamB.players[i].playingNow,
        });

        initPlayTimeList[1].push({ playerId: teamB.players[i].value, playTime: 0 });
      }

      setGameJSON(copy);
      setPlayTimeList(initPlayTimeList);
    }
  }, []);

  useEffect(() => {
    if (gameJSON.playerRecord.length === 2) {
      let copyPlayerRecord = [...gameJSON.playerRecord];

      for (let i = 0; i < 2; i++) {
        for (let j = 0; j < copyPlayerRecord[i].length; j++) {
          const newObj = {};
          for (let key in copyPlayerRecord[i][j]) {
            if (key === 'playerId' || key === 'jerseyNum' || key === 'playingNow' || key === 'playTime') {
              newObj[key] = copyPlayerRecord[i][j][key];
            } else if (Array.isArray(copyPlayerRecord[i][j][key])) {
              newObj[key] = [...copyPlayerRecord[i][j][key]].map(() => 0);
            } else {
              newObj[key] = 0;
            }
          }
          copyPlayerRecord[i][j] = newObj;
        }
      }

      let teamRebound = [[0, 0], [0, 0]];
      let quarterScore = [[0, 0], [0, 0], [0, 0], [0, 0]];

      for (let event of events) {
        if (event.playerId !== null) {
          const playerRecord = copyPlayerRecord[event.vsTeamIndex].find((p) => p.playerId.toString() === event.playerId.toString());

          switch (event.recordType) {
            case 'fieldGoal0':
              playerRecord.fieldGoal[0]++;
              break;
            case 'fieldGoal1':
              playerRecord.fieldGoal[0]++;
              playerRecord.fieldGoal[1]++;
              playerRecord.score += 2;
              quarterScore[event.quarter - 1][event.vsTeamIndex] += 2;
              break;
            case 'threePointThrow0':
              playerRecord.threePointThrow[0]++;
              break;
            case 'threePointThrow1':
              playerRecord.threePointThrow[0]++;
              playerRecord.threePointThrow[1]++;
              playerRecord.score += 3;
              quarterScore[event.quarter - 1][event.vsTeamIndex] += 3;
              break;
            case 'freeThrow0':
              playerRecord.freeThrow[0]++;
              break;
            case 'freeThrow1':
              playerRecord.freeThrow[0]++;
              playerRecord.freeThrow[1]++;
              playerRecord.score++;
              quarterScore[event.quarter - 1][event.vsTeamIndex]++;
              break;
            case 'rebound0':
              playerRecord.rebound[0]++;
              break;
            case 'rebound1':
              playerRecord.rebound[1]++;
              break;
            case 'steal':
              playerRecord.steal++;
              break;
            case 'block':
              playerRecord.block++;
              break;
            case 'turnOver':
              playerRecord.turnOver++;
              break;
            case 'assist':
              playerRecord.assist++;
              break;
            case 'goodD':
              playerRecord.goodD++;
              break;
            case 'fastBreak':
              playerRecord.fastBreak++;
              break;
            case 'plusOne':
              playerRecord.plusOne++;
              break;
            case 'foul0':
              playerRecord.foul[0]++;
              break;
            case 'foul1':
              playerRecord.foul[1]++;
              break;
            default:
              break;
          }
        } else {
          switch (event.recordType) {
            case 'teamRebound0':
              teamRebound[event.vsTeamIndex][0]++;
              break;
            case 'teamRebound1':
              teamRebound[event.vsTeamIndex][1]++;
              break;
            default:
              break;
          }
        }
      }

      setGameJSON({ ...gameJSON, playerRecord: copyPlayerRecord, teamRebound: teamRebound, quarter: quarterScore });
    }
  }, [events]);


  const gameJSONRef = useRef(gameJSON);

  useEffect(() => {
    gameJSONRef.current = gameJSON; // 항상 최신 gameJSON을 유지
  }, [gameJSON]);

  useEffect(() => {
    if (isTimerOn) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 0) {
            setIsTimerOn(false);
            return 0;
          }
          return prevTimer - 1;
        });

        const copyPlayTimeList = [...playTimeList];

        for (let i = 0; i < teamA.players.length; i++) {
          if (gameJSONRef.current.playerRecord[0][i].playingNow) {
            copyPlayTimeList[0][i].playTime++;
          }
        }

        for (let i = 0; i < teamB.players.length; i++) {
          if (gameJSONRef.current.playerRecord[1][i].playingNow) {
            copyPlayTimeList[1][i].playTime++;
          }
        }

        setPlayTimeList(copyPlayTimeList);
          
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isTimerOn, timer]);

  const localSaveHandler = () => {
    const a = document.createElement('a');
    const file = new Blob([JSON.stringify({ gameJSON, events, setting, teamA, teamB })], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = `gameRecord_${new Date().toISOString()}.json`;
    a.click();
  };

  const localLoadHandler = (e) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = JSON.parse(e.target.result);
        setGameJSON(content.gameJSON);
        setEvents(content.events);
      };
      reader.readAsText(file);
    };
    input.click();
  };

  const PauseAndRebound = ({ vsTeamIndex }) => {
    const onTeamReboundClick = (isOR) => {
      const copyHistory = [...history];
      copyHistory.push(events);
      setHistory(copyHistory);
      setEventRedoStack([]);

      // const copy = { ...gameJSON };
      // const index = isOR ? 0 : 1;

      // copy.teamRebound[vsTeamIndex][index]++;

      // setGameJSON(copy);

      const copyEvents = [...events];

      const newEvent = {
        text: `${1}쿼터 ${secondToMMSS(MMSStoSecond(setting.gameTime) - timer)} 팀 ${isOR ? '공격' : '수비'} 리바운드`,
        vsTeamIndex: vsTeamIndex,
        recordType: isOR ? 'teamRebound0' : 'teamRebound1',
        playerId: null,
        quarter: quarter,
      };

      copyEvents.push(newEvent);

      setEvents(copyEvents);
    };


    return (
      <Container className="p-3">
        {/* Time-Out Section */}
        <Row className="mb-3" style={{ border: '1px solid black', borderRadius: '10px', padding: '10px' }}>
          <Col className="d-flex align-items-center">
            <span style={{ fontSize: '15px' }}>작전타임</span>
            <div className="d-flex justify-content-around">
              <Button variant="outline-dark" className="rounded-circle mx-1" style={{ width: '30px', height: '30px', padding: '0' }}></Button>
              <Button variant="outline-dark" className="rounded-circle mx-1" style={{ width: '30px', height: '30px', padding: '0' }}></Button>
              <Button variant="outline-dark" className="rounded-circle mx-1" style={{ width: '30px', height: '30px', padding: '0' }}></Button>
              <Button variant="outline-dark" className="rounded-circle mx-1" style={{ width: '30px', height: '30px', padding: '0' }}></Button>
            </div>
          </Col>
        </Row>

        {/* Team Rebound Table */}
        <Table
          striped
          className="table-sm text-center mt-3"
          style={{ borderCollapse: 'collapse', border: '1px solid black' }}
        >
          <thead>
            <tr>
              <th colSpan="2" style={{ border: '1px solid black', padding: '5px' }}>Team Rebound</th>
              <th rowSpan="2" style={{ border: '1px solid black', padding: '5px', verticalAlign: 'middle' }}>TTO</th> {/* rowSpan을 사용하여 두 행을 합침 */}
            </tr>
            <tr>
              <th style={{ border: '1px solid black', padding: '5px' }} onClick={() => onTeamReboundClick(true)}>OR</th>
              <th style={{ border: '1px solid black', padding: '5px' }} onClick={() => onTeamReboundClick(false)}>DR</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid black', padding: '5px' }} onClick={() => onTeamReboundClick(true)}>{gameJSON.teamRebound[vsTeamIndex][0]}</td>
              <td style={{ border: '1px solid black', padding: '5px' }} onClick={() => onTeamReboundClick(false)}>{gameJSON.teamRebound[vsTeamIndex][1]}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{gameJSON && gameJSON.teamRebound[vsTeamIndex][0] + gameJSON.teamRebound[vsTeamIndex][1]}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    );
  };

  const ScoreAndTimerBox = () => {
    return (
      <Container>
        {/* Score Table */}
        <Table
          striped
          hover
          className="table-sm text-center"
          style={{ padding: '0', borderCollapse: 'collapse' }}
        >
          <thead>
            <tr>
              <th
                style={{ fontSize: '12px', padding: '6px' }}
              >
                팀명
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: quarter === 1 ? 'white' : 'lightgray' }}
              >
                1쿼터
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: quarter === 2 ? 'white' : 'lightgray' }}
              >
                2쿼터
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: quarter === 3 ? 'white' : 'lightgray' }}
              >
                3쿼터
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: quarter === 4 ? 'white' : 'lightgray' }}
              >
                4쿼터
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: quarter === 5 ? 'white' : 'lightgray' }}
              >
                연장
              </th>
              <th
                style={{ fontSize: '12px', padding: '6px', backgroundColor: 'lightgray' }}
              >
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody
            style={{ fontSize: '12px' }}
          >
            <tr>
              <td>{teamA.teamName}</td>
              <td style={{ backgroundColor: quarter === 1 ? 'white' : 'lightgray' }}>{gameJSON.quarter[0][0]}</td>
              <td style={{ backgroundColor: quarter === 2 ? 'white' : 'lightgray' }}>{gameJSON.quarter[1][0]}</td>
              <td style={{ backgroundColor: quarter === 3 ? 'white' : 'lightgray' }}>{gameJSON.quarter[2][0]}</td>
              <td style={{ backgroundColor: quarter === 4 ? 'white' : 'lightgray' }}>{gameJSON.quarter[3][0]}</td>
              <td style={{ backgroundColor: quarter === 5 ? 'white' : 'lightgray' }}>{gameJSON.overtime[0]}</td>
              <td style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>{
                gameJSON.quarter[0][0] +
                gameJSON.quarter[1][0] +
                gameJSON.quarter[2][0] +
                gameJSON.quarter[3][0] +
                gameJSON.overtime[0]
              }</td>
            </tr>
            <tr>
              <td>{teamB.teamName}</td>
              <td style={{ backgroundColor: quarter === 1 ? 'white' : 'lightgray' }}>{gameJSON.quarter[0][1]}</td>
              <td style={{ backgroundColor: quarter === 2 ? 'white' : 'lightgray' }}>{gameJSON.quarter[1][1]}</td>
              <td style={{ backgroundColor: quarter === 3 ? 'white' : 'lightgray' }}>{gameJSON.quarter[2][1]}</td>
              <td style={{ backgroundColor: quarter === 4 ? 'white' : 'lightgray' }}>{gameJSON.quarter[3][1]}</td>
              <td style={{ backgroundColor: quarter === 5 ? 'white' : 'lightgray' }}>{gameJSON.overtime[1]}</td>
              <td style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>{
                gameJSON.quarter[0][1] +
                gameJSON.quarter[1][1] +
                gameJSON.quarter[2][1] +
                gameJSON.quarter[3][1] +
                gameJSON.overtime[1]
              }</td>
            </tr>
          </tbody>
        </Table>

        {/* Timer and Quarter Control */}
        <Container fluid>
          <Row
            className="align-items-center g-0"
            style={{ fontSize: '20px', textAlign: 'center', margin: '0 10px 10px 10px', padding: '0' }}  // 기본적인 마진, 패딩 제거
          >
            <Col style={{ padding: '1px', margin: '1px' }}>  {/* 간격을 줄이기 위해 padding 설정 */}
              <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '5px 10px' }}>
                1
              </Button>
            </Col>
            <Col width='10px' style={{ padding: '0', margin: '0', width: '10px' }}>◀︎</Col>  {/* Col 간의 패딩을 줄여 간격 조절 */}
            <Col style={{ padding: '1px', margin: '1px' }}>
            <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '5px 30px' }}>
        {secondToMMSS(timer)}
      </Button>
            </Col>
            <Col style={{ padding: '0', margin: '0' }}>▶︎</Col>
            <Col style={{ padding: '1px', margin: '1px' }}>
              <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '5px 10px' }}>
                2
              </Button>
            </Col>
          </Row>
          <Row className="align-items-center text-center g-0" style={{ margin: '0 10px 10px 10px', padding: '0' }}>
            <Col style={{ padding: '1px', margin: '1px' }}>
              <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '15px 20px' }}>
                {gameJSON.gameResult[0]}
              </Button>
            </Col>
            <Col style={{ padding: '1px', margin: '1px' }}>
              <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '15px 20px' }}>
                {shotClock}
              </Button>
            </Col>
            <Col style={{ padding: '1px', margin: '1px' }}>
              <Button variant="outline-dark" style={{ borderRadius: '5px', fontSize: '20px', padding: '15px 20px' }}>
                {gameJSON.gameResult[1]}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  const PlayerSwap = ({ teamInfo, vsTeamIndex }) => {
    console.log("swap",teamInfo)
    const buttonStyle = {
      padding: '10px 0px',
      fontSize: '12px',
      width: '100%',  // 버튼이 Col 전체 너비를 채우도록 설정
      whiteSpace: 'normal',  // 줄바꿈 허용
      wordBreak: 'break-word',  // 긴 단어 줄바꿈 허용
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [selectedPlayingNow, setSelectedPlayingNow] = useState([]);
    const [selectedNotPlayingNow, setSelectedNotPlayingNow] = useState([]);

    const handleSelectPlayingNow = (player) => {
      setSelectedPlayingNow((prev) =>
        prev.includes(player) ? prev.filter((p) => p !== player) : [...prev, player]
      );
    };

    const handleSelectNotPlayingNow = (player) => {
      setSelectedNotPlayingNow((prev) =>
        prev.includes(player) ? prev.filter((p) => p !== player) : [...prev, player]
      );
    };

    const handleSwap = () => {
      if (selectedPlayingNow.length !== selectedNotPlayingNow.length) {
        alert("선택한 숫자의 개수가 동일해야 합니다.");
        return;
      }

      const copy = { ...gameJSON };

      for (let i = 0; i < selectedPlayingNow.length; i++) {
        const playerIndex1 = copy.playerRecord[vsTeamIndex].findIndex((p) => p.playerId.toString() === selectedPlayingNow[i].toString());
        const playerIndex2 = copy.playerRecord[vsTeamIndex].findIndex((p) => p.playerId.toString() === selectedNotPlayingNow[i].toString());

        copy.playerRecord[vsTeamIndex][playerIndex1].playingNow = false;
        copy.playerRecord[vsTeamIndex][playerIndex2].playingNow = true;
      }

      setGameJSON(copy);

      setSelectedPlayingNow([]);
      setSelectedNotPlayingNow([]);
      handleClose();
    };

    const playerChangerHandler = () => {
      handleShow();
    };

    const getPlayers = (isNowPlaying, vsTeamIndex) => {
      if (gameJSON.playerRecord.length !== 2) { return []; }

      const playerIdList = gameJSON.playerRecord[vsTeamIndex].filter((player) => (isNowPlaying ? player.playingNow : !player.playingNow)).map((player) => player.playerId);
      
      return teamInfo.players.filter((player) => playerIdList.includes(player.value));
    }

    return (
      <Container>
        {/* Title */}
        <Row className="mb-3">
          <Col>
            <Button variant="outline-dark" className="w-100" style={{ fontSize: '20px', fontWeight: 'bold', padding: '5px 40px' }} onClick={playerChangerHandler}>
              {teamInfo.teamName}
            </Button>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>선수 교체</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col>
                      <h6>출전 선수</h6>
                      {getPlayers(true, vsTeamIndex).map((player) => (
                        <Form.Check
                          key={player.value}
                          type="checkbox"
                          label={player.label}
                          onChange={() => handleSelectPlayingNow(player.value)}
                          checked={selectedPlayingNow.includes(player.value)}
                        />
                      ))}
                    </Col>
                    <Col>
                      <h6>미출전 선수</h6>
                      {getPlayers(false, vsTeamIndex).map((player) => (
                        <Form.Check
                          key={player.value}
                          type="checkbox"
                          label={player.label}
                          onChange={() => handleSelectNotPlayingNow(player.value)}
                          checked={selectedNotPlayingNow.includes(player.value)}
                        />
                      ))}
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  닫기
                </Button>
                <Button variant="primary" onClick={handleSwap}>
                  교체
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  };

  const playerSwapA = React.useMemo(() => <PlayerSwap teamInfo={teamA} vsTeamIndex={0} />, [teamA, showModal]);
  const playerSwapB = React.useMemo(() => <PlayerSwap teamInfo={teamB} vsTeamIndex={1} />, [teamB, showModal]);

  const PlayerList = ({ teamInfo, vsTeamIndex }) => {
    const buttonStyle = {
      padding: '10px 0px',
      fontSize: '12px',
      width: '100%',  // 버튼이 Col 전체 너비를 채우도록 설정
      whiteSpace: 'normal',  // 줄바꿈 허용
      wordBreak: 'break-word',  // 긴 단어 줄바꿈 허용
    };

    const onPlayerClick = (playerId) => {
      setSelectedPlayer({ playerId, vsTeamIndex: vsTeamIndex });
    }

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [selectedPlayingNow, setSelectedPlayingNow] = useState([]);
    const [selectedNotPlayingNow, setSelectedNotPlayingNow] = useState([]);

    const handleSelectPlayingNow = (player) => {
      setSelectedPlayingNow((prev) =>
        prev.includes(player) ? prev.filter((p) => p !== player) : [...prev, player]
      );
    };

    const handleSelectNotPlayingNow = (player) => {
      setSelectedNotPlayingNow((prev) =>
        prev.includes(player) ? prev.filter((p) => p !== player) : [...prev, player]
      );
    };

    const handleSwap = () => {
      if (selectedPlayingNow.length !== selectedNotPlayingNow.length) {
        alert("선택한 숫자의 개수가 동일해야 합니다.");
        return;
      }

      const copy = { ...gameJSON };

      for (let i = 0; i < selectedPlayingNow.length; i++) {
        const playerIndex1 = copy.playerRecord[vsTeamIndex].findIndex((p) => p.playerId.toString() === selectedPlayingNow[i].toString());
        const playerIndex2 = copy.playerRecord[vsTeamIndex].findIndex((p) => p.playerId.toString() === selectedNotPlayingNow[i].toString());

        copy.playerRecord[vsTeamIndex][playerIndex1].playingNow = false;
        copy.playerRecord[vsTeamIndex][playerIndex2].playingNow = true;
      }

      setGameJSON(copy);

      setSelectedPlayingNow([]);
      setSelectedNotPlayingNow([]);
      handleClose();
    };

    const playerChangerHandler = () => {
      handleShow();
    };

    const getPlayers = (isNowPlaying, vsTeamIndex) => {
      if (gameJSON.playerRecord.length !== 2) { return []; }

      const playerIdList = gameJSON.playerRecord[vsTeamIndex].filter((player) => (isNowPlaying ? player.playingNow : !player.playingNow)).map((player) => player.playerId);
      
      return teamInfo.players.filter((player) => playerIdList.includes(player.value));
    }

    return (
      <Container>
        {/* Title */}
        {/* <Row className="mb-3">
          <Col>
            <Button variant="outline-dark" className="w-100" style={{ fontSize: '20px', fontWeight: 'bold', padding: '5px 40px' }} onClick={playerChangerHandler}>
              {teamInfo.teamName}
            </Button>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>선수 교체</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col>
                      <h6>출전 선수</h6>
                      {getPlayers(true, vsTeamIndex).map((player) => (
                        <Form.Check
                          key={player.value}
                          type="checkbox"
                          label={player.label}
                          onChange={() => handleSelectPlayingNow(player.value)}
                          checked={selectedPlayingNow.includes(player.value)}
                        />
                      ))}
                    </Col>
                    <Col>
                      <h6>미출전 선수</h6>
                      {getPlayers(false, vsTeamIndex).map((player) => (
                        <Form.Check
                          key={player.value}
                          type="checkbox"
                          label={player.label}
                          onChange={() => handleSelectNotPlayingNow(player.value)}
                          checked={selectedNotPlayingNow.includes(player.value)}
                        />
                      ))}
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  닫기
                </Button>
                <Button variant="primary" onClick={handleSwap}>
                  교체
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row> */}
        {gameJSON.playerRecord.length === 2 &&
          getPlayers(true, vsTeamIndex).map((player, index) => (
            <Row className="mb-1" key={index} style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
              <Col style={{ flexBasis: '70%', maxWidth: '60%', padding: '2px' }}>
                <Button
                  variant={selectedPlayer.playerId === player.value ? 'dark' : 'outline-dark'}
                  style={buttonStyle}
                  onClick={() => onPlayerClick(player.value)}
                >
                  {player.label}({player.jerseyNum})
                </Button>
              </Col>
              <Col style={{ flexBasis: '30%', maxWidth: '40%', padding: '2px' }}>
                <Button variant="outline-dark" style={buttonStyle} disabled>
                  {/* {secondToMMSS(gameJSON.playerRecord[vsTeamIndex]
                    .find((p) => p.playerId.toString() === player.value.toString()).playTime)} */}
                  {secondToMMSS(playTimeList[vsTeamIndex].find((p) => p.playerId.toString() === player.value.toString()).playTime)}
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    );
  };

  const StatsTable = () => {
    const cellStyle = {
      padding: '8px',  // 셀의 패딩을 줄여줍니다.
      margin: '8px',
      fontSize: '10px', // 폰트 크기도 줄입니다.
      border: '1px solid black', // 테두리 스타일 지정
    };

    const getPlayerRecord = (playerId, vsTeamIndex, recordType) => {
      const playerRecord = gameJSON.playerRecord[vsTeamIndex].find((p) => p.playerId.toString() === playerId.toString());

      return playerRecord[recordType];
    };

    const onCellClick = useCallback((recordType, playerId) => {
      const copyHistory = [...history];
      copyHistory.push(events);
      setHistory(copyHistory);
      setEventRedoStack([]);
    
      const playerRecord = gameJSON.playerRecord[selectedPlayer.vsTeamIndex].find((p) => p.playerId.toString() === selectedPlayer.playerId.toString());
      const copy = { ...gameJSON };
    
      const playerIndex = copy.playerRecord[selectedPlayer.vsTeamIndex].indexOf(playerRecord);
    
      const recordTypeToKorean = (recordType) => {
        switch (recordType) {
          case 'fieldGoal0': return '2점 시도';
          case 'fieldGoal1': return '2점 성공';
          case 'threePointThrow0': return '3점 시도';
          case 'threePointThrow1': return '3점 성공';
          case 'freeThrow0': return '자유투 시도';
          case 'freeThrow1': return '자유투 성공';
          case 'rebound0': return '공격 리바운드';
          case 'rebound1': return '수비 리바운드';
          case 'steal': return '스틸';
          case 'block': return '블락';
          case 'turnOver': return '턴오버';
          case 'assist': return '어시스트';
          case 'goodD': return '굿디';
          case 'fastBreak': return '속공';
          case 'plusOne': return '+1';
          case 'foul0': return '파울(일반)';
          case 'foul1': return '파울(자유투)';
          default: return '';
        }
      };
    
      const playerIdToName = (playerId) => {
        const player = teamA.players.concat(teamB.players).find((p) => p.value.toString() === playerId.toString());
        return player.label;
      };
    
      const copyEvents = [...events];
    
      const newEvent = {
        text: `${1}쿼터 ${secondToMMSS(MMSStoSecond(setting.gameTime) - timer)} ${playerIdToName(playerRecord.playerId)}(${playerRecord.jerseyNum}) ${recordTypeToKorean(recordType)}`,
        vsTeamIndex: selectedPlayer.vsTeamIndex,
        recordType: recordType,
        playerId: playerId,
        quarter: quarter,
      };
    
      copyEvents.push(newEvent);
    
      setEvents(copyEvents);
    
      switch (recordType) {
        case 'fieldGoal0':
          playerRecord.fieldGoal[0]++;
          break;
        case 'fieldGoal1':
          playerRecord.fieldGoal[0]++;
          playerRecord.fieldGoal[1]++;
          playerRecord.score += 2;
          copy.gameResult[selectedPlayer.vsTeamIndex] += 2;
          break;
        case 'threePointThrow0':
          playerRecord.threePointThrow[0]++;
          break;
        case 'threePointThrow1':
          playerRecord.threePointThrow[0]++;
          playerRecord.threePointThrow[1]++;
          playerRecord.score += 3;
          copy.gameResult[selectedPlayer.vsTeamIndex] += 3;
          break;
        case 'freeThrow0':
          playerRecord.freeThrow[0]++;
          break;
        case 'freeThrow1':
          playerRecord.freeThrow[0]++;
          playerRecord.freeThrow[1]++;
          playerRecord.score++;
          copy.gameResult[selectedPlayer.vsTeamIndex]++;
          break;
        case 'rebound0':
          playerRecord.rebound[0]++;
          break;
        case 'rebound1':
          playerRecord.rebound[1]++;
          break;
        case 'steal':
          playerRecord.steal++;
          break;
        case 'block':
          playerRecord.block++;
          break;
        case 'turnOver':
          playerRecord.turnOver++;
          break;
        case 'assist':
          playerRecord.assist++;
          break;
        case 'goodD':
          playerRecord.goodD++;
          break;
        case 'fastBreak':
          playerRecord.fastBreak++;
          break;
        case 'plusOne':
          playerRecord.plusOne++;
          break;
        case 'foul0':
          playerRecord.foul[0]++;
          break;
        case 'foul1':
          playerRecord.foul[1]++;
          break;
        default:
          break;
      }
    
      copy.playerRecord[selectedPlayer.vsTeamIndex][playerIndex] = playerRecord;
      setGameJSON(copy);
    }, [events, gameJSON, selectedPlayer, timer, quarter, teamA, teamB, history]);
    
    return (
      <Container style={{ maxWidth: '600px' }}>
        {gameJSON.playerRecord.length === 2 &&
          <Table bordered style={{ textAlign: 'center', fontSize: '10px', border: '1px solid black', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={cellStyle} onClick={() => onCellClick('fieldGoal0', selectedPlayer.playerId)}>2점시도</th>
                <th style={cellStyle} onClick={() => onCellClick('fieldGoal1', selectedPlayer.playerId)}>2점성공</th>
                <th style={cellStyle} onClick={() => onCellClick('threePointThrow0', selectedPlayer.playerId)}>3점시도</th>
                <th style={cellStyle} onClick={() => onCellClick('threePointThrow1', selectedPlayer.playerId)}>3점성공</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={cellStyle} onClick={() => onCellClick('fieldGoal0', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'fieldGoal')[0]}</td>
                <td style={cellStyle} onClick={() => onCellClick('fieldGoal1', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'fieldGoal')[1]}</td>
                <td style={cellStyle} onClick={() => onCellClick('threePointThrow0', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'threePointThrow')[0]}</td>
                <td style={cellStyle} onClick={() => onCellClick('threePointThrow1', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'threePointThrow')[1]}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={cellStyle} onClick={() => onCellClick('freeThrow0', selectedPlayer.playerId)}>자유투시도</th>
                <th style={cellStyle} onClick={() => onCellClick('freeThrow1', selectedPlayer.playerId)}>자유투성공</th>
                <th style={cellStyle} onClick={() => onCellClick('rebound0', selectedPlayer.playerId)}>공격 리바운드</th>
                <th style={cellStyle} onClick={() => onCellClick('rebound1', selectedPlayer.playerId)}>수비 리바운드</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={cellStyle} onClick={() => onCellClick('freeThrow0', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'freeThrow')[0]}</td>
                <td style={cellStyle} onClick={() => onCellClick('freeThrow1', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'freeThrow')[1]}</td>
                <td style={cellStyle} onClick={() => onCellClick('rebound0', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'rebound')[0]}</td>
                <td style={cellStyle} onClick={() => onCellClick('rebound1', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'rebound')[1]}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={cellStyle} onClick={() => onCellClick('steal', selectedPlayer.playerId)}>스틸</th>
                <th style={cellStyle} onClick={() => onCellClick('block', selectedPlayer.playerId)}>블락</th>
                <th style={cellStyle} onClick={() => onCellClick('assist', selectedPlayer.playerId)}>어시스트</th>
                <th style={cellStyle} onClick={() => onCellClick('turnOver', selectedPlayer.playerId)}>턴오버</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={cellStyle} onClick={() => onCellClick('steal', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'steal')}</td>
                <td style={cellStyle} onClick={() => onCellClick('block', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'block')}</td>
                <td style={cellStyle} onClick={() => onCellClick('assist', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'assist')}</td>
                <td style={cellStyle} onClick={() => onCellClick('turnOver', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'turnOver')}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={cellStyle} onClick={() => onCellClick('fastBreak', selectedPlayer.playerId)}>속공</th>
                <th style={cellStyle} onClick={() => onCellClick('goodD', selectedPlayer.playerId)}>굿디</th>
                <th style={cellStyle} onClick={() => onCellClick('plusOne', selectedPlayer.playerId)}>+1</th>
                <th style={{ ...cellStyle, backgroundColor: '#d3d3d3' }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={cellStyle} onClick={() => onCellClick('fastBreak', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'fastBreak')}</td>
                <td style={cellStyle} onClick={() => onCellClick('goodD', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'goodD')}</td>
                <td style={cellStyle} onClick={() => onCellClick('plusOne', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'plusOne')}</td>
                <td style={{ ...cellStyle, backgroundColor: '#d3d3d3' }}></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th style={cellStyle} onClick={() => onCellClick('foul0', selectedPlayer.playerId)}>파울(일반)</th>
                <th style={cellStyle} onClick={() => onCellClick('foul1', selectedPlayer.playerId)}>파울(자유투)</th>
                <th style={cellStyle}>U파울</th>
                <th style={cellStyle}>T파울</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={cellStyle} onClick={() => onCellClick('foul0', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'foul')[0]}</td>
                <td style={cellStyle} onClick={() => onCellClick('foul1', selectedPlayer.playerId)}>{getPlayerRecord(selectedPlayer.playerId, selectedPlayer.vsTeamIndex, 'foul')[1]}</td>
                <td style={cellStyle}></td>
                <td style={cellStyle}></td>
              </tr>
            </tbody>
          </Table>
        }
      </Container>
    );
  };

  const statsTable = React.useMemo(() => <StatsTable />, [gameJSON, selectedPlayer]);
  

  const EventLog = () => {
    const removeEventHandler = (index) => {
      const copyHistory = [...history];
      copyHistory.push(events);
      setHistory(copyHistory);
      setEventRedoStack([]);

      const removeEvent = events[index];
      const gameJSONCopy = { ...gameJSON };

      switch (removeEvent.recordType) {
        case 'fieldGoal0':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).fieldGoal[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score -= 2;
          break;
        case 'fieldGoal1':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).fieldGoal[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).fieldGoal[1]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score -= 2;
          gameJSONCopy.gameResult[removeEvent.vsTeamIndex] -= 2;
          break;
        case 'threePointThrow0':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).threePointThrow[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score -= 3;
          break;
        case 'threePointThrow1':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).threePointThrow[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).threePointThrow[1]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score -= 3;
          gameJSONCopy.gameResult[removeEvent.vsTeamIndex] -= 3;
          break;
        case 'freeThrow0':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).freeThrow[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score--;
          break;
        case 'freeThrow1':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).freeThrow[0]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).freeThrow[1]--;
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).score--;
          gameJSONCopy.gameResult[removeEvent.vsTeamIndex]--;
          break;
        case 'rebound0':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).rebound[0]--;
          break;
        case 'rebound1':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).rebound[1]--;
          break;
        case 'steal':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).steal--;
          break;
        case 'block':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).block--;
          break;
        case 'turnOver':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).turnOver--;
          break;
        case 'assist':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).assist--;
          break;
        case 'goodD':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).goodD--;
          break;
        case 'fastBreak':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).fastBreak--;
          break;
        case 'plusOne':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).plusOne--;
          break;
        case 'foul0':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).foul[0]--;
          break;
        case 'foul1':
          gameJSONCopy.playerRecord[removeEvent.vsTeamIndex].find((p) => p.playerId.toString() === removeEvent.playerId.toString()).foul[1]--;
          break;
        case 'teamRebound0':
          gameJSONCopy.teamRebound[removeEvent.vsTeamIndex][0]--;
          break;
        case 'teamRebound1':
          gameJSONCopy.teamRebound[removeEvent.vsTeamIndex][1]--;
          break;
        default:
          break;
      }

      setGameJSON(gameJSONCopy);

      const copyEvents = [...events];
      copyEvents.splice(index, 1);
      setEvents(copyEvents);
    }

    const changeShowedEvents = (eventType) => {
      switch (eventType) {
        case 'all':
          setShowingAllEvents(true);
          return setShowedEvents(events);
        case 'score':
          setShowingAllEvents(false);
          return setShowedEvents(events.filter((event) => ['fieldGoal1', 'threePointThrow1', 'freeThrow1'].includes(event.recordType)));
        case 'rebound':
          setShowingAllEvents(false);
          return setShowedEvents(events.filter((event) => ['rebound0', 'rebound1'].includes(event.recordType)));
        case 'steal':
          setShowingAllEvents(false);
          return setShowedEvents(events.filter((event) => event.recordType === 'steal'));
        case 'assist':
          setShowingAllEvents(false);
          return setShowedEvents(events.filter((event) => event.recordType === 'assist'));
        case 'etc':
          setShowingAllEvents(false);
          return setShowedEvents(events.filter((event) => !['fieldGoal1', 'threePointThrow1', 'freeThrow1', 'rebound0', 'rebound1', 'steal', 'assist'].includes(event.recordType)));
        default:
          return setShowedEvents(events);
      }
    }
    
    
    return (
      <Container>
        <Row className="g-0 mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 8px' }} onClick={() => changeShowedEvents('all')}>
              전체보기
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 8px' }} onClick={() => changeShowedEvents('score')}>
              득점
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 8px' }} onClick={() => changeShowedEvents('rebound')}>
              리바운드
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 8px' }} onClick={() => changeShowedEvents('steal')}>
              스틸
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 8px' }} onClick={() => changeShowedEvents('assist')}>
              어시스트
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="w-100" style={{ fontSize: '10px', padding: '5px 10px' }} onClick={() => changeShowedEvents('etc')}>
              기타
            </Button>
          </Col>
        </Row>
        <div
          style={{
            maxHeight: '270px',
            overflowY: 'scroll',
            border: 'none',
            padding: '10px',
          }}
        >
          {(showingAllEvents ? events.slice().reverse() : showedEvents.slice().reverse()).map((event, index) => (
            <Row key={index} style={{ justifyContent: event.vsTeamIndex === 0 ? 'flex-start' : 'flex-end' }}>
              <Col xs="auto">
                <div
                  style={{
                    backgroundColor: event.color,
                    color: event.textColor || 'black',
                    borderRadius: '8px',
                    display: 'inline-block',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    padding: '5px',
                  }}
                  onClick={() => removeEventHandler(index)}
                >
                  <div className={event.vsTeamIndex === 0 ? 'speech-bubble-left' : 'speech-bubble-right'}>
                    {event.text}
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
        {/* <div ref={eventEndRef} /> */}
      </Container>
    );
  };

  const eventLog = React.useMemo(() => <EventLog />, [events, showedEvents, showingAllEvents]);

  const ControlPanel = () => {
    const button1Style = {
      border: '1px solid black',
      borderRadius: '8px',
      padding: '30px 8px',
      margin: '5px',
      backgroundColor: 'white',
    };

    const button2Style = {
      border: '1px solid black',
      borderRadius: '8px',
      padding: '10px',
      margin: '5px',
      backgroundColor: 'white',
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Main Time Start/Stop */}
        <Button className="d-flex justify-content-center align-items-center" style={button1Style} onClick={() => setIsTimerOn(!isTimerOn)}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>main time</div>
            <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>{isTimerOn ? 'STOP' : 'START'}</div>
          </div>
        </Button>

        {/* Main Time Reset */}
        <Button className="d-flex justify-content-center align-items-center" style={button1Style} onClick={() => setTimer(MMSStoSecond(setting.gameTime))}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>main time</div>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>RESET</div>
          </div>
        </Button>

        {/* Minute Control */}
        <Button className="d-flex justify-content-center align-items-center" style={button2Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button variant="outline-dark" style={{ padding: '2px' }} onClick={() => setTimer((prev) => prev + 60)}>▲</Button>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>minute</div>
            <Button variant="outline-dark" style={{ padding: '2px' }} onClick={() => setTimer((prev) => prev - 60 > 0 ? prev - 60 : 0)}>▼</Button>
          </div>
        </Button>

        {/* Second Control */}
        <Button className="d-flex justify-content-center align-items-center" style={button2Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button variant="outline-dark" style={{ padding: '2px' }} onClick={() => setTimer((prev) => prev + 1)}>▲</Button>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>second</div>
            <Button variant="outline-dark" style={{ padding: '2px' }} onClick={() => setTimer((prev) => prev - 1 > 0 ? prev - 1 : 0)}>▼</Button>
          </div>
        </Button>

        {/* Buzzer */}
        <Button className="d-flex justify-content-center align-items-center" style={{ ...button1Style, height: '100px' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'black' }}>BUZZER</div>
        </Button>

        {/* Control Buttons */}
        <Button className="d-flex justify-content-center align-items-center" style={button1Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '2px' }}>
              <Button variant="outline-dark" style={{ padding: '3px 6px' }}>◀</Button>
              <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 3px' }}>작전타임</div>
              <Button variant="outline-dark" style={{ padding: '3px 6px' }}>▶</Button>
            </div>
          </div>
        </Button>

        {/* Shot Clock Start/Stop */}
        <Button className="d-flex justify-content-center align-items-center" style={button1Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>shot clock</div>
            <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>START</div>
          </div>
        </Button>

        {/* Shot Clock Reset */}
        <Button className="d-flex justify-content-center align-items-center" style={button1Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>shot clock</div>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>RESET</div>
          </div>
        </Button>

        {/* 14초 Display */}
        <Button className="d-flex justify-content-center align-items-center" style={{ ...button1Style, height: '100px' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'black' }}>14초</div>
        </Button>

        {/* Second Control */}
        <Button className="d-flex justify-content-center align-items-center" style={button2Style}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button variant="outline-dark" style={{ padding: '2px' }}>▲</Button>
            <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}>second</div>
            <Button variant="outline-dark" style={{ padding: '2px' }}>▼</Button>
          </div>
        </Button>
      </div>
    );
  };

  return (
    <Container
      style={{
        maxWidth: '1600px', // 고정된 너비를 설정합니다.
        overflowX: 'auto',  // 가로 스크롤을 허용합니다.
        whiteSpace: 'nowrap', // 요소들이 한 줄로 나타나도록 설정합니다.
        display: 'flex',  // 요소들을 가로로 나열합니다.
        flexDirection: 'column',  // 요소들을 세로로 나열합니다.
        alignItems: 'center',  // 요소들을 수직 가운데로 정렬합니다.
      }}
    >
      <div className="d-flex justify-content-center">
        <div style={{ display: 'inline-block' }}> {/* Scrollable Content */}
          <Row className="mb-2">
            <Col className="d-flex justify-content-around">
              <Button variant="outline-dark" style={{ ...buttonStyle, }} onClick={localLoadHandler}>+</Button>
              <Button variant="outline-dark" style={{ ...buttonStyle, }}>⚙️</Button>
              <Button variant="outline-dark" style={{ ...buttonStyle, }} onClick={localSaveHandler}>💾</Button>
              {!isIntermission ? (
                <Button variant="outline-dark" style={{ ...buttonStyle, lineHeight: '14px' }} onClick={moveQuarter}>쿼터<br />끝내기</Button>
              ) : (
                <Button variant="outline-dark" style={{ ...buttonStyle, lineHeight: '14px' }} onClick={moveQuarter}>경기<br />재개</Button>
              )}
            </Col>
          </Row>
          <PauseAndRebound vsTeamIndex={0} />
        </div>

        <div style={{ display: 'inline-block' }}>
          <ScoreAndTimerBox />
        </div>

        <div style={{ display: 'inline-block' }}>
          <Row className="mb-2">
            <Col className="d-flex justify-content-around">
              <Button variant="outline-dark" style={{ ...buttonStyle, width: '40px', height: '40px' }} onClick={undoHandler}>↩️</Button>
              <Button variant="outline-dark" style={{ ...buttonStyle, width: '40px', height: '40px' }} onClick={redoHandler}>↪️</Button>
              <Button variant="outline-dark" style={{ ...buttonStyle, width: '80px', height: '40px' }}>결과 출력</Button>
            </Col>
          </Row>
          <PauseAndRebound vsTeamIndex={1} />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div style={{ display: 'inline-block' }}>
          {playerSwapA}
          <PlayerList teamInfo={teamA} vsTeamIndex={0} />
        </div>
        <div style={{ display: 'inline-block' }}>
          {statsTable}
        </div>
        <div style={{ display: 'inline-block' }}>
          {playerSwapB}
          <PlayerList teamInfo={teamB} vsTeamIndex={1} />
        </div>
        <div style={{ display: 'inline-block' }}>
          {eventLog}
        </div>
      </div>
      <div style={{ display: 'inline-block' }}>
        <ControlPanel />
      </div>
    </Container>
  );
};

export default RecorderMain;