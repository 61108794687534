import React, { useState } from 'react';
import { Form, Button, Table, Row, Col, Container } from "react-bootstrap";
import InputMask from 'react-input-mask';

/**
 * @param {{ onAgree: () => void }} props
 * @constructor
 */

const RecorderSetting = ({ onNext }) => {
  const [is3vs3, setIs3vs3] = useState(false);
  const [isQuarterGame, setIsQuarterGame] = useState(true);

  const [gameTime, setGameTime] = useState('07:00');
  const [overtime, setOvertime] = useState('02:00');
  const [shotClock, setShotClock] = useState('00:24');
  const [quarterBreak, setQuarterBreak] = useState('01:00');
  const [firstHalfBreak, setFirstHalfBreak] = useState('02:00');
  const [timeout, setTimeout] = useState('01:30');

  const [individualFoul, setIndividualFoul] = useState(5);
  const [teamFoul, setTeamFoul] = useState(5);
  const [firstHalfTimeoutCnt, setFirstHalfTimeoutCnt] = useState(1);
  const [secondHalfTimeoutCnt, setSecondHalfTimeoutCnt] = useState(1);
  const [overtimeTimeoutCnt, setOvertimeTimeoutCnt] = useState(1);

  const [isLocalRule, setIsLocalRule] = useState(false);
  const [formerProLimit, setFormerProLimit] = useState(3);
  const [remainPlayer, setRemainPlayer] = useState(5);

  const submitHandler = () => {
    const gameSetting = {
      is3vs3,
      isQuarterGame,
      gameTime,
      overtime,
      shotClock,
      quarterBreak,
      firstHalfBreak,
      timeout,
      individualFoul,
      teamFoul,
      firstHalfTimeoutCnt,
      secondHalfTimeoutCnt,
      overtimeTimeoutCnt,
      isLocalRule,
      formerProLimit,
      remainPlayer,
    };

    onNext(gameSetting);
  }

  const localSaveHandler = () => {
    //위 gameSetting을 pc에 .json파일로 저장

    const gameSetting = {
      is3vs3,
      isQuarterGame,
      gameTime,
      overtime,
      shotClock,
      quarterBreak,
      firstHalfBreak,
      timeout,
      individualFoul,
      teamFoul,
      firstHalfTimeoutCnt,
      secondHalfTimeoutCnt,
      overtimeTimeoutCnt,
      isLocalRule,
      formerProLimit,
      remainPlayer,
    };

    const a = document.createElement('a');
    const file = new Blob([JSON.stringify(gameSetting)], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = 'gameSetting.json';
    a.click();
  }

  const localLoadHandler = () => {
    //pc에 저장된 .json파일을 불러와서 위 gameSetting에 적용
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = JSON.parse(e.target.result);
        setIs3vs3(result.is3vs3);
        setIsQuarterGame(result.isQuarterGame);
        setGameTime(result.gameTime);
        setOvertime(result.overtime);
        setShotClock(result.shotClock);
        setQuarterBreak(result.quarterBreak);
        setFirstHalfBreak(result.firstHalfBreak);
        setTimeout(result.timeout);
        setIndividualFoul(result.individualFoul);
        setTeamFoul(result.teamFoul);
        setFirstHalfTimeoutCnt(result.firstHalfTimeoutCnt);
        setSecondHalfTimeoutCnt(result.secondHalfTimeoutCnt);
        setOvertimeTimeoutCnt(result.overtimeTimeoutCnt);
        setIsLocalRule(result.isLocalRule);
        setFormerProLimit(result.formerProLimit);
        setRemainPlayer(result.remainPlayer);
      }
      reader.readAsText(file);
    }
    input.click();
  }


  const setTitleStyle = {
    border: '1px solid #ced4da',
    padding: '10px',
    width: '100px',
    height: '50px',
    textAlign: 'center',
    display: 'flex',       // 플렉스박스로 변환
    alignItems: 'center',  // 수직 중앙 정렬
    justifyContent: 'center' // 수평 중앙 정렬
  };


  return (
    <Container style={{ maxWidth: '900px' }}>
      {/* Game Type */}
      <Row className="mb-3">
        <Col>
          <Button variant="outline-secondary" onClick={localLoadHandler}>설정 불러오기(로컬)</Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {/* 게임유형 */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={setTitleStyle}>게임 유형</div>
            <div style={{ marginLeft: '10px' }}>
              <div style={{ display: 'flex' }}>
                <Form.Check type="radio" label="3 vs 3" style={{ marginRight: '10px' }} checked={is3vs3} onChange={() => setIs3vs3(!is3vs3)} />
                <Form.Check type="radio" label="5 vs 5" checked={!is3vs3} onChange={() => setIs3vs3(!is3vs3)} />
              </div>
              <div style={{ display: 'flex' }}>
                <Form.Check type="radio" label="전, 후반제" style={{ marginRight: '10px' }} checked={!isQuarterGame} onChange={() => setIsQuarterGame(!isQuarterGame)} />
                <Form.Check type="radio" label="쿼터제" checked={isQuarterGame} onChange={() => setIsQuarterGame(!isQuarterGame)} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Time Settings */}
      <Row className="mb-3">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={setTitleStyle}>시간 설정</div>
            <Table bordered style={{ fontSize: '12px', width: 'auto', marginLeft: '10px' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>경기시간</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>연장전시간</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>샷클락</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>쿼터휴식시간</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>전후반휴식시간</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>작전타임</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={gameTime} onChange={(e) => setGameTime(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={overtime} onChange={(e) => setOvertime(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={shotClock} onChange={(e) => setShotClock(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={quarterBreak} onChange={(e) => setQuarterBreak(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={firstHalfBreak} onChange={(e) => setFirstHalfBreak(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <InputMask mask="99:99" value={timeout} onChange={(e) => setTimeout(e.target.value)} style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>


      {/* Basic Rule Settings */}
      <Row className="mb-3">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={setTitleStyle}>기본 규칙</div>
            <Table bordered style={{ fontSize: '12px', width: 'auto', marginLeft: '10px' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>개인파울</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>팀파울</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>작전타임(전반)</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>작전타임(후반)</th>
                  <th style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>작전타임(연장)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setIndividualFoul(e.target.value)} value={individualFoul} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setTeamFoul(e.target.value)} value={teamFoul} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setFirstHalfTimeoutCnt(e.target.value)} value={firstHalfTimeoutCnt} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setSecondHalfTimeoutCnt(e.target.value)} value={secondHalfTimeoutCnt} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center', minWidth: '100px' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setOvertimeTimeoutCnt(e.target.value)} value={overtimeTimeoutCnt} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/* Local Rules */}
      <Row className="mb-3">
        <Col>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ border: '1px solid #ced4da', padding: '10px', width: '100px', height: '50px', textAlign: 'center', whiteSpace: 'nowrap' }}>로컬룰</div>
            <div style={{ display: 'flex', marginLeft: '10px' }}>
              <Form.Check type="radio" label="+1 적용" style={{ marginRight: '10px' }} />
              <Form.Check type="radio" label="+1 미적용" defaultChecked />
            </div>
            <Table bordered style={{ fontSize: '12px', width: 'auto', marginLeft: '10px' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'center' }}>선출제한</th>
                  <th style={{ padding: '5px', textAlign: 'center' }}>잔류선수</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '5px', textAlign: 'center' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setFormerProLimit(e.target.value)} value={formerProLimit} />
                  </td>
                  <td style={{ padding: '5px', textAlign: 'center' }}>
                    <input type="text" style={{ width: '60px', textAlign: 'center', fontSize: '12px' }} onChange={(e) => setRemainPlayer(e.target.value)} value={remainPlayer} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={submitHandler} style={{ marginRight: '10px' }}>다음</Button>
          <Button variant="outline-secondary" onClick={localSaveHandler}>설정 저장(로컬)</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default RecorderSetting;