import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import { Redirect } from "react-router-dom";
import {
  GAME_ARTICLE_LIST_REQUEST,
  GAME_ARTICLE_LIST_SUCCESS,
  GAME_ARTICLE_LIST_FAIL,
  GAME_ARTICLE_READ_REQUEST,
  GAME_ARTICLE_READ_SUCCESS,
  GAME_ARTICLE_READ_FAIL,
  GAME_ARTICLE_WRITE_REQUEST,
  GAME_ARTICLE_WRITE_SUCCESS,
  GAME_ARTICLE_WRITE_FAIL,
  GAME_ARTICLE_UPDATE_REQUEST,
  GAME_ARTICLE_UPDATE_SUCCESS,
  GAME_ARTICLE_UPDATE_FAIL,
  GAME_ARTICLE_DELETE_REQUEST,
  GAME_ARTICLE_DELETE_SUCCESS,
  GAME_ARTICLE_DELETE_FAIL,
} from "../constants/gameArticleConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const readGameArticle = (gameId) => async (dispatch) => {
  try {
    dispatch({ type: GAME_ARTICLE_READ_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game-article?gameId=${gameId}`);
    dispatch({ type: GAME_ARTICLE_READ_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GAME_ARTICLE_READ_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const writeGameArticle = (content, gameId) => async (dispatch) => {
  try {
    dispatch({ type: GAME_ARTICLE_WRITE_REQUEST });
    const { data } = await axios.post(`${API_SERVER_URL}/api/game-article`, { content, gameId });
    dispatch({ type: GAME_ARTICLE_WRITE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GAME_ARTICLE_WRITE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const updateGameArticle = (article) => async (dispatch) => {
  try {
    dispatch({ type: GAME_ARTICLE_UPDATE_REQUEST });
    const { data } = await axios.put(`${API_SERVER_URL}/api/game-article/${article._id}`, article);
    dispatch({ type: GAME_ARTICLE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GAME_ARTICLE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const deleteGameArticle = (id) => async (dispatch) => {
  try {
    dispatch({ type: GAME_ARTICLE_DELETE_REQUEST });
    const { data } = await axios.delete(`${API_SERVER_URL}/api/game-article/${id}`);
    dispatch({ type: GAME_ARTICLE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GAME_ARTICLE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}