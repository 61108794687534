import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";

const CommunitySearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/community/search/${keyword}`);
    } else {
      window.alert("한가지 이상 검색어를 입력하세요.");
    }
  };

  return (
    <Form
      onSubmit={submitHandler}
      className="d-flex flex-row justify-content-around"
    >
      <Form.Control
        type="text"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        placeholder="검색어를 입력하세요.."
      ></Form.Control>
      <Button type="submit" className="p-2">
        <FontAwesomeIcon icon={faBasketball} style={{ fontSize: "25px" }} />
      </Button>
    </Form>
  );
};

export default CommunitySearchBox;
