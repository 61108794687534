import React, { useState, useEffect } from "react";
import { listPosts } from "../actions/communityActions";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import CommunitySearchBox from "../components/CommunitySearchBox";
import { COMMUNITY_LIST_RESET } from "../constants/communityConstants";
import PaginateCommunity from "../components/PaginateCommunity";

const CommunityListScreen = ({ match, history }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const [category, setCategory] = useState(
    match.params.category ? match.params.category : "자유게시판"
  );
  const [rSelected, setRSelected] = useState(1);

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const communityList = useSelector((state) => state.communityList);
  const { loading, error, posts, page, pages } = communityList;

  const categoryHandler = (cat, e) => {
    e.preventDefault();
    setCategory(cat);
    history.push(`/community/category/${cat}`);
  };

  const categorySimplify = (cat) => {
    switch (cat) {
      case "자유게시판":
        return "자유";
      case "질문게시판":
        return "질문";
      case "정보게시판":
        return "정보";
      case "공지사항":
        return "공지";
      default:
        return "";
    }
  };

  const categoryButton = (cat) => {
    return (
      <Button
        color="primary"
        outline
        active={category === cat}
        style={{
          fontSize: "0.75rem",
          padding: "8px 6px",
          backgroundColor: category === cat ? "#000" : "#fff",
          border: category === cat ? "1px solid #000" : "1px solid #838383",
          borderRadius: "4px",
          color: category === cat ? "#fff" : "#838383",
          marginTop: "8px",
          marginBottom: "8px",
          marginRight: "10px",
        }}
        onClick={(e) => categoryHandler(cat, e)}
      >
        {cat}
      </Button>
    );
  };

  useEffect(() => {
    dispatch({ type: COMMUNITY_LIST_RESET });
    dispatch(listPosts(keyword, pageNumber, category));
    setCategory(match.params.category);
  }, [dispatch, keyword, pageNumber, category]);

  return (
    <>
      <h2 className="mt-3 mb-3">커뮤니티</h2>
      <div
        className="p-3"
        style={{
          "border-style": "solid",
          "border-radius": "10px",
          "border-color": "#e9ecef",
        }}
      >
        ⓘ 상업성광고, 저속한 표현, 특정인에 대한 비방, 정치적 목적이나 성향,
        반복적 게시물 등은 관리자에 의해 통보없이 삭제될수 있습니다.
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Link to="/community">
              <Button
                color="primary"
                outline
                active={category === undefined}
                style={{ fontSize: '0.75rem', padding: '8px 6px', "border-color": "yellow" }}
              >
                전체
              </Button>
            </Link> */}
            <div>
              <ButtonGroup>
                {categoryButton("자유게시판")}
                {categoryButton("공지사항")}
                {categoryButton("대회공지")}
              </ButtonGroup>
            </div>
            <div>
              <Link
                to={`/write-post`}
                style={{
                  "font-size": "16px",
                }}
              >
                글쓰기
              </Link>
            </div>
          </div>
          <span className="on__pc__info">
            <Table striped bordered hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "10px" }}>번호</th>
                  <th style={{ textAlign: "left", width: "300px" }}>제목</th>
                  <th style={{ width: "90px" }}>글쓴이</th>
                  <th style={{ width: "90px" }}>작성일</th>
                  <th style={{ width: "50px" }}>조회</th>
                  <th style={{ width: "50px" }}>추천</th>
                </tr>
              </thead>
              <tbody>
                {posts &&
                  posts.map((post, index) => (
                    <tr
                      key={post._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/community/${post._id}`)}
                    >
                      <td style={{ textAlign: "center", width: "5px" }}>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {post.postNum}
                        </div>
                      </td>
                      <td style={{ textAlign: "left", width: "300px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <div
                          style={{
                            backgroundColor: "#424242",
                            color: "#ffffff",
                            fontSize: "0.8rem",
                            padding: "2px 5px",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {categorySimplify(post.category)}
                        </div> */}
                          {post.title}
                          <span
                            style={{ fontSize: "0.8rem", marginLeft: "5px" }}
                          >
                            [{post.reply.length}]
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {post.author ? post.author.name : ""}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {post.date.substring(0, 10)}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {post.view}
                        </div>
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {post.recommend.length}
                        </div>
                      </td>
                      {/* {userInfo && userInfo.isAdmin && (
                    <td>
                      <Link
                        class="btn btn-danger pull-right"
                        to={`/admin/user/${player._id}`}
                      >
                        수정
                      </Link>
                    </td>
                  )} */}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </span>
          {/* 모바일 버전 */}
          <span className="on__mobile__info">
            <Table hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "40px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    번호
                  </th>
                  <th>정보</th>
                </tr>
              </thead>
              <tbody>
                {posts &&
                  posts.map((post, index) => (
                    <tr
                      key={post._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/community/${post._id}`)}
                    >
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "0px",
                        }}
                      >
                        <div
                          className="hideextra"
                          style={{ textAlign: "center", fontSize: "0.8rem" }}
                        >
                          {post.postNum}
                        </div>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <div>
                          <div>
                            {post.title}
                            <span
                              style={{
                                fontSize: "10px",
                                marginLeft: "4px",
                                color: "#c47002",
                              }}
                            >
                              [{post.reply.length}]
                            </span>
                          </div>
                          <div className="community__list__container__mobile">
                            <span
                              className="community__list__mobile"
                              style={{ marginLeft: "0px" }}
                            >
                              {post.author ? post.author.name : ""}
                            </span>
                            |
                            <span className="community__list__mobile">
                              {post.date.substring(0, 10)}
                            </span>
                            |
                            <span className="community__list__mobile">
                              조회 {post.view}
                            </span>
                            |
                            <span className="community__list__mobile">
                              추천 {post.recommend.length}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </span>
          <div className="mt-4">
            <Route
              render={({ history }) => <CommunitySearchBox history={history} />}
            />
          </div>
        </>
      )}
      <PaginateCommunity
        pages={pages}
        page={page}
        keyword={keyword ? keyword : ""}
        pageType={"community"}
        category={category}
      />
    </>
  );
};

export default CommunityListScreen;
