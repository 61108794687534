import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import {
  TEAM_LIST_FAIL,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_CONSONANT_REQUEST,
  TEAM_LIST_CONSONANT_SUCCESS,
  TEAM_LIST_CONSONANT_FAIL,
  TEAM_LIST_TYPE_REQUEST,
  TEAM_LIST_TYPE_SUCCESS,
  TEAM_LIST_TYPE_FAIL,
  TEAM_NAME_FAIL,
  TEAM_NAME_REQUEST,
  TEAM_NAME_SUCCESS,
  TEAM_DETAIL_FAIL,
  TEAM_DETAIL_REQUEST,
  TEAM_DETAIL_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_SUCCESS,
  TEAM_DETAIL_FAIL1,
  TEAM_DETAIL_REQUEST1,
  TEAM_DETAIL_SUCCESS1,
  TEAM_INPUT_REQUEST,
  TEAM_INPUT_SUCCESS,
  TEAM_INPUT_FAIL,
  TEAM_SEASON_RECORD_REQUEST,
  TEAM_SEASON_RECORD_SUCCESS,
  TEAM_SEASON_RECORD_FAIL,
  TEAM_SEASON_MEMBERS_RECORD_REQUEST,
  TEAM_SEASON_MEMBERS_RECORD_SUCCESS,
  TEAM_SEASON_MEMBERS_RECORD_FAIL,
  TEAM_COMPETITION_RECORD_REQUEST,
  TEAM_COMPETITION_RECORD_SUCCESS,
  TEAM_COMPETITION_RECORD_FAIL,
  TEAM_COMPETITION_LIST_REQUEST,
  TEAM_COMPETITION_LIST_SUCCESS,
  TEAM_COMPETITION_LIST_FAIL,
  TEAM_COMPETITION_LIST_RESET,
  TEAM_DELETE_REQUEST,
  TEAM_DELETE_SUCCESS,
  TEAM_DELETE_FAIL,
} from "../constants/teamConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const teamName = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_NAME_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/team/${teamId}`
    );
    dispatch({ type: TEAM_NAME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTeams =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: TEAM_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/team?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: TEAM_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TEAM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTeamsConsonant =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      // dispatch({ type: TEAM_SEASON_RECORD_RESET });
      dispatch({ type: TEAM_LIST_CONSONANT_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/team/consonant/key?consonant=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: TEAM_LIST_CONSONANT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TEAM_LIST_CONSONANT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTeamsType =
  (keyword = "", pageNumber = "") =>
    async (dispatch) => {
      try {
        // dispatch({ type: TEAM_SEASON_RECORD_RESET });
        dispatch({ type: TEAM_LIST_TYPE_REQUEST });
        const { data } = await axios.get(
          `${API_SERVER_URL}/api/team/type/key?teamType=${keyword}&pageNumber=${pageNumber}`
        );
        dispatch({ type: TEAM_LIST_TYPE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: TEAM_LIST_TYPE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const teamDetailsInfo = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_DETAIL_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/team/${teamId}`
    );
    dispatch({ type: TEAM_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const teamDetailsInfo1 = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_DETAIL_REQUEST1 });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/team/${teamId}`
    );
    dispatch({ type: TEAM_DETAIL_SUCCESS1, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_DETAIL_FAIL1,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const teamInput =
  (name, image, leader, foundedAt, teamType, masterId) => async (dispatch) => {
    try {
      dispatch({ type: TEAM_INPUT_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };
      const { data } = await axios.post(
        `${API_SERVER_URL}/api/team`,
        {
          name,
          image,
          leader,
          foundedAt,
          teamType,
          masterId
        },
        config
      );
      dispatch({ type: TEAM_INPUT_SUCCESS, payload: data });
      alert("[성공] 팀 등록에 성공하였습니다.");
      window.location.reload();
    } catch (error) {
      dispatch({
        type: TEAM_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const teamSeasonRecord = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_SEASON_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/team-record/season/${team.teamId}?year=${team.year}`
    );
    dispatch({ type: TEAM_SEASON_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_SEASON_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const teamSeasonMembersRecord = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_SEASON_MEMBERS_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/members-record/season/${team.teamId}?year=${team.year}`
    );
    dispatch({ type: TEAM_SEASON_MEMBERS_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_SEASON_MEMBERS_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const teamCompetitionRecord = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_COMPETITION_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/team-record/competition/${team._id}?competitionId=${team.competitionId}`
    );
    dispatch({ type: TEAM_COMPETITION_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_COMPETITION_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTeamCompetition = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_COMPETITION_LIST_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/team/team-competition/${team._id}?year=${team.year}`
    );
    dispatch({ type: TEAM_COMPETITION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_COMPETITION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTeam = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_UPDATE_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };
    const { data } = await axios.put(
      `${API_SERVER_URL}/api/team/${team._id}`,
      team,
      config
    );
    dispatch({ type: TEAM_UPDATE_SUCCESS, payload: data });

    alert("[성공] 처리되었습니다.");

    window.location.reload();
  } catch (error) {
    dispatch({
      type: TEAM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const deleteTeam = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_DELETE_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };
    const { data } = await axios.delete(
      `${API_SERVER_URL}/api/team/${teamId}`,
      config
    );
    dispatch({ type: TEAM_DELETE_SUCCESS, payload: data });
    alert("[성공] 팀 삭제에 성공하였습니다.");
    window.history.back();
  } catch (error) {
    dispatch({
      type: TEAM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}