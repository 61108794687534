import React, { useState } from "react";
import { Link } from "react-router-dom";

const ShowRemainingTeams = ({ competition }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-backdrop") {
      toggleModal();
    }
  };


  const startEndDate = (startDate, endDate) => {
    if (startDate !== endDate) {
      return (
        <>
          {startDate && startDate.substring(2, 10)} ~{" "}
          {endDate && endDate.substring(5, 10)}
        </>
      )
    } else {
      return (
        <>
          {startDate && startDate.substring(2, 10)}
        </>
      )
    }
  }

  const competitionStatus = (status) => {
    const statusLabels = ["진행 전", "진행 중", "종료"];
    return (
      <div
        style={{
          backgroundColor: "#000000",
          color: "#ffffff",
          fontSize: "0.8rem",
          padding: "1px 3px",
          marginRight: "4px",
          fontWeight: "bold",
        }}
      >
        {statusLabels[status]}
      </div>
    );
  };

  return (
    <>
      <span style={{ color: "brown", fontSize: "11px", display: "flex", alignItems: "center" }}>
        {competitionStatus(competition.status)} {startEndDate(competition.startDate, competition.endDate)}
      </span>
      <Link to={`/leagueround/${competition._id}`}>
        {competition.name}
      </Link>
      <br />
      <span style={{ color: "brown", fontSize: "11px" }}>
        {competition.teamNames.length > 4
          ? `${competition.teamNames.slice(0, 4).join(", ")} 외 ${competition.teamNames.length - 4}팀`
          : competition.teamNames.join(", ")}
        {competition.teamNames.length > 4 && (
          <button onClick={toggleModal} style={{ color: "blue", border: "none", background: "none", cursor: "pointer", marginLeft: "4px" }}>
            모두 보기
          </button>
        )}
      </span>
      {showModal && (
        <div id="modal-backdrop" onClick={handleOutsideClick} style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ backgroundColor: "white", padding: "30px", position: "relative" }}>
            <span>{competition.teamNames.join(", ")}</span>
            <button onClick={toggleModal} style={{ position: "absolute", top: "3px", right: "3px", cursor: "pointer", fontSize: "12px", borderColor: "gray" }}>X</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ShowRemainingTeams;
