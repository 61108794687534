import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { writePost } from "../actions/communityActions";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { getOwnAssociation } from "../actions/associationActions";
import { writeCompetitionNotice } from "../actions/noticeActions";

const CompetitionNoticeWriteScreen = () => {
  const [competitionName, setCompetitionName] = useState("");
  const [recruitCount, setRecruitCount] = useState(0);
  const [competitionDate, setCompetitionDate] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("자유게시판");

  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(null);

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const associationOwn = useSelector((state) => state.associationOwn);
  const { association } = associationOwn;

  useEffect(() => {
    dispatch(getOwnAssociation(userId));
  }, [dispatch, userId]);

  const submitHandler = () => {
    const competitionNotice = {
      hostId: userId,
      associationId: association && association._id,
      competitionName: competitionName,
      competitionDate: competitionDate,
      recruitCount: recruitCount,
      content: content
  };

  dispatch(writeCompetitionNotice(competitionNotice));
};

  const categoryHandler = (r, cat) => {
    setRSelected(r);
    setCategory(cat);
  };

  const categoryButton = (r, cat) => {
    return (
      <Button
        color="primary"
        outline
        onClick={(e) => categoryHandler(r, cat)}
        active={rSelected === r}
        style={{
          fontSize: "0.75rem",
          padding: "8px 6px",
          borderRadius: "10px",
          marginRight: "8px",
          marginBottom: "8px",
        }}
      >
        {cat}
      </Button>
    );
  };

  return (
    <div className="hideextra" style={{ "min-width": "5px" }}>
      <div className="form-wrapper">
        <h4 className="mx-1">대회 공지 작성</h4>
        <Row>
          <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>대회명</span>
            <input
              type="text"
              name="competitionName"
              onChange={(e) => setCompetitionName(e.target.value)}
              style={{ width: "50%", height: "30px" }}
            />
          </div>
        </Row>
        <Row>
          <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
            <span style={{ marginRight: "10px" }}>모집 팀 수</span>
            <input
              type="number"
              name="recruitCount"
              onChange={(e) => setRecruitCount(e.target.value)}
              style={{ width: "10%", height: "30px" }}
            />
          </div>
        </Row>
        <Row>
          <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
            <span>대회 일자</span>
            <input
              type="date"
              name="date"
              onChange={(e) => setCompetitionDate(e.target.value)}
              style={{ width: "20%", height: "30px" }}
            />
          </div>
        </Row>
        <CKEditor
          editor={ClassicEditor}
          // config={ {plugins: [ Image ]}}
          data=""
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
        />
        <Button
          className="submit-button mt-2"
          onClick={submitHandler}
          style={{ width: "100px" }}
        >
          등록하기
        </Button>
      </div>
    </div>
  );
};

export default CompetitionNoticeWriteScreen;
