import axios from "axios";
import { API_SERVER_URL } from "./actionUrl";
import {
  COMPETITION_GAME_FAIL,
  COMPETITION_GAME_REQUEST,
  COMPETITION_GAME_SUCCESS,
  COMPETITION_INFO_FAIL,
  COMPETITION_INFO_REQUEST,
  COMPETITION_INFO_SUCCESS,
  COMPETITION_DETAIL_INFO_FAIL,
  COMPETITION_DETAIL_INFO_SUCCESS,
  COMPETITION_DETAIL_INFO_REQUEST,
  COMPETITION_INPUT_FAIL,
  COMPETITION_INPUT_REQUEST,
  COMPETITION_INPUT_SUCCESS,
  COMPETITION_ASSOCIATION_INPUT_FAIL,
  COMPETITION_ASSOCIATION_INPUT_REQUEST,
  COMPETITION_ASSOCIATION_INPUT_SUCCESS,
  COMPETITION_LIST_FAIL,
  COMPETITION_LIST_REQUEST,
  COMPETITION_LIST_SUCCESS,
  COMPETITION_RESULT_FAIL,
  COMPETITION_RESULT_REQUEST,
  COMPETITION_RESULT_SUCCESS,
  COMPETITION_GAME_INPUT_REQUEST,
  COMPETITION_GAME_INPUT_SUCCESS,
  COMPETITION_GAME_INPUT_FAIL,
  COMPETITION_GAME_UPDATE_REQUEST,
  COMPETITION_GAME_UPDATE_SUCCESS,
  COMPETITION_GAME_UPDATE_FAIL,
  COMPETITION_GAME_DELETE_REQUEST,
  COMPETITION_GAME_DELETE_SUCCESS,
  COMPETITION_GAME_DELETE_FAIL,
  COMPETITION_ROUNDINFO_FAIL,
  COMPETITION_ROUNDINFO_REQUEST,
  COMPETITION_ROUNDINFO_SUCCESS,
  COMPETITION_ROUNDGAME_FAIL,
  COMPETITION_ROUNDGAME_REQUEST,
  COMPETITION_ROUNDGAME_SUCCESS,
  COMPETITION_PARTICIPANTS_FAIL,
  COMPETITION_PARTICIPANTS_REQUEST,
  COMPETITION_PARTICIPANTS_SUCCESS,
  COMPETITION_UPDATE_REQUEST,
  COMPETITION_UPDATE_SUCCESS,
  COMPETITION_UPDATE_FAIL,
  COMPETITION_GAME_ORDER_REQUEST,
  COMPETITION_GAME_ORDER_SUCCESS,
  COMPETITION_GAME_ORDER_FAIL,
  COMPETITION_STATS_FAIL,
  COMPETITION_STATS_REQUEST,
  COMPETITION_STATS_SUCCESS,
  COMPETITION_ROUND_RANK_REQUEST,
  COMPETITION_ROUND_RANK_SUCCESS,
  COMPETITION_ROUND_RANK_FAIL,
  COMPETITION_ROUND_RANK_RESET,
  TEAM_GAME_LIST_REQUEST,
  TEAM_GAME_LIST_SUCCESS,
  TEAM_GAME_LIST_FAIL,
  TEAM_GAME_LIST_RESET,
  SCHEDULED_GAME_LIST_REQUEST,
  SCHEDULED_GAME_LIST_SUCCESS,
  SCHEDULED_GAME_LIST_FAIL,
  SCHEDULED_GAME_LIST_RESET,
} from "../constants/competitionConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const listCompetition =
  (
    keyword = "",
    category = "",
    teamType = "",
    isThree = "",
    division = "",
    season = "",
    pageNumber = "",
    getParticipatedTeams = ""
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: COMPETITION_LIST_REQUEST });

        const { data } = await axios.get(
          `${API_SERVER_URL}/api/competition?keyword=${keyword}&category=${category}&teamType=${teamType}&isThree=${isThree}&division=${division}&season=${season}&pageNumber=${pageNumber}&getParticipatedTeams=${getParticipatedTeams}`
        );

        dispatch({ type: COMPETITION_LIST_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: COMPETITION_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const listLeagueRound = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_ROUNDINFO_REQUEST });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/competition/round/${id}`
    );

    dispatch({ type: COMPETITION_ROUNDINFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_ROUNDINFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const competitionRoundGameList = (id, round) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_ROUNDGAME_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/competition/${id}/${round}`
    );
    dispatch({ type: COMPETITION_ROUNDGAME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_ROUNDGAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCompetitionInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_INFO_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game/${id}`);
    dispatch({ type: COMPETITION_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailCompetitionInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_DETAIL_INFO_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game/${id}`);
    dispatch({ type: COMPETITION_DETAIL_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_DETAIL_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const competitionResultInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_RESULT_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game/result/${id}`);
    dispatch({ type: COMPETITION_RESULT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const competitionGameList = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_GAME_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/competition/${id}`);
    dispatch({ type: COMPETITION_GAME_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_GAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const competitionInput = (competition) => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState();

    dispatch({ type: COMPETITION_INPUT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.post(
      `${API_SERVER_URL}/api/competition?competitionNoticeId=${competition.competitionNoticeId ? competition.competitionNoticeId : ""}`,
      competition,
      config
    );
    dispatch({ type: COMPETITION_INPUT_SUCCESS, payload: data });
    alert("[성공] 대회 등록에 성공하였습니다.");
    window.location.reload();
  } catch (error) {
    dispatch({
      type: COMPETITION_INPUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    alert(`[실패] 대회 등록에 실패하였습니다. ${error}`);
  }
};

export const competitionGameInput =
  (
    gameDate,
    competitionId,
    teamType,
    gamePlace,
    gameName,
    round,
    vsTeam = [],
    gameResult,
    quarter = [],
    overtime,
    playerRecord = [],
    vsTeamName
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: COMPETITION_GAME_INPUT_REQUEST });
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userInfo.token}`
          },
        };
        const { data } = await axios.post(
          `${API_SERVER_URL}/api/game`,
          {
            gameDate,
            competitionId,
            teamType,
            gamePlace,
            gameName,
            round,
            vsTeam,
            gameResult,
            quarter,
            overtime,
            playerRecord,
            vsTeamName,
          },
          config
        );
        dispatch({ type: COMPETITION_GAME_INPUT_SUCCESS, payload: data });
        alert("[성공] 경기 결과 등록에 성공하였습니다.");
        window.location.reload();
      } catch (error) {
        dispatch({
          type: COMPETITION_GAME_INPUT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
        alert(
          `[실패] 경기 결과 등록에 실패하였습니다. 기록을 다시 확인하시기 바랍니다. ${error}`
        );
      }
    };

export const competitionGameUpdate = (game) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMPETITION_GAME_UPDATE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/game/${game._id}`,
      game,
      config
    );

    dispatch({ type: COMPETITION_GAME_UPDATE_SUCCESS, payload: data });
    alert("[성공] 정상적으로 업데이트 되었습니다.");
    window.location.reload();

    // dispatch({ type: COMPETITION_GAME_SUCCESS, payload: data });
  } catch (error) {
    alert(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    dispatch({
      type: COMPETITION_GAME_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteGame = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_GAME_DELETE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    await axios.delete(
      `${API_SERVER_URL}/api/game/${id}`,
      config
    );

    dispatch({ type: COMPETITION_GAME_DELETE_SUCCESS });
    alert("삭제 되었습니다.");
    window.location.reload();
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: COMPETITION_GAME_DELETE_FAIL, payload: message });
  }
};

export const listCompetitionParticipants = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_PARTICIPANTS_REQUEST });
    const competition = await axios.get(
      `${API_SERVER_URL}/api/competition/participants/record/${id}`
    );

    const { data } = competition;
    dispatch({ type: COMPETITION_PARTICIPANTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_PARTICIPANTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCompetition =
  (competition) => async (dispatch, getState) => {
    try {
      const { userLogin: { userInfo } } = getState();
      
      dispatch({
        type: COMPETITION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/competition/${competition._id}`,
        competition,
        config
      );

      dispatch({
        type: COMPETITION_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch({ type: COMPETITION_GAME_SUCCESS, payload: data });
      window.history.back();
    } catch (error) {
      dispatch({
        type: COMPETITION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const gameOrderChange = (game) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMPETITION_GAME_ORDER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/game/game-order/${game._id}?gameNum=${game.gameNum}`,
      game,
      config
    );

    dispatch({ type: COMPETITION_GAME_ORDER_SUCCESS, payload: data });
    alert("[성공] 정상적으로 업데이트 되었습니다.");
    window.location.reload();
  } catch (error) {
    alert(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    dispatch({
      type: COMPETITION_GAME_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const competitionStatsAction =
  (id = "", round = "", roundNum = "", isRoundStat = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: COMPETITION_STATS_REQUEST });
        const competition = await axios.get(
          `${API_SERVER_URL}/api/competition/participants/stats/${id}?round=${round}&roundNum=${roundNum}&isRoundStat=${isRoundStat}`
        );

        const { data } = competition;
        dispatch({ type: COMPETITION_STATS_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: COMPETITION_STATS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const competitionRoundRank =
  (id = "", round = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: COMPETITION_ROUND_RANK_REQUEST });
        const competition = await axios.get(
          `${API_SERVER_URL}/api/competition/round/rank/${id}?round=${round}`
        );

        const { data } = competition;
        dispatch({ type: COMPETITION_ROUND_RANK_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: COMPETITION_ROUND_RANK_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const addCompetitionGameAssociation = (gameOrder, competitionNoticeId) => async (dispatch) => {
  try {
    dispatch({ type: COMPETITION_ASSOCIATION_INPUT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/competition/competition-notice-game/${competitionNoticeId}`,
      gameOrder,
      config
    );

    dispatch({ type: COMPETITION_ASSOCIATION_INPUT_SUCCESS, payload: data });
    alert("[성공] 대회 등록에 성공하였습니다.");
    window.location.reload();
  } catch (error) {
    dispatch({
      type: COMPETITION_ASSOCIATION_INPUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTeamGameList = (team) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_GAME_LIST_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game/team-record/games/${team._id}?year=${team.year}`);
    dispatch({ type: TEAM_GAME_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_GAME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getScheduledGameList = (team) => async (dispatch) => {
  try {
    dispatch({ type: SCHEDULED_GAME_LIST_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/game/scheduled/${team._id}`);
    dispatch({ type: SCHEDULED_GAME_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SCHEDULED_GAME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};