import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  readCompetitionNotice,
  updateCompetitionNotice
} from "../actions/noticeActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Button, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { API_SERVER_URL } from "../actions/actionUrl";

const CompetitionTeamAcceptScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const competitionNoticeRead = useSelector((state) => state.competitionNoticeRead);

  const { loading, error, competitionNotice } = competitionNoticeRead;

  async function increaseView(id) {
    axios.put(
      `${API_SERVER_URL}/api/competition-notice/increase-view/${id}`
    );
  }

  useEffect(() => {
    dispatch(readCompetitionNotice(id));
    increaseView(id);
  }, [dispatch, id]);

  const applyQueueOption = [];

  competitionNotice &&
    competitionNotice.applyQueueInfo?.map((team) => {
      applyQueueOption.push({
        value: team.teamId,
        label: team.teamName
      });
    });


  const checkHandler = (e, status, index) => {
    if (status === 0) {
      e.target.checked = true;
      document.getElementById(`acceptList${index}`).checked = false;
      document.getElementById(`rejectList${index}`).checked = false;
    } else if (status === 1) {
      e.target.checked = true;
      document.getElementById(`applyQueue${index}`).checked = false;
      document.getElementById(`rejectList${index}`).checked = false;
    } else {
      e.target.checked = true;
      document.getElementById(`applyQueue${index}`).checked = false;
      document.getElementById(`acceptList${index}`).checked = false;
    }
  }

  const isDefaultChecked = (teamId, status) => {
    if (status === 0) {
      return competitionNotice.applyQueue.includes(teamId) && competitionNotice.acceptList.includes(teamId) === false && competitionNotice.rejectList.includes(teamId) === false;
    } else if (status === 1) {
      return competitionNotice.acceptList.includes(teamId);
    } else {
      return competitionNotice.rejectList.includes(teamId);
    }
  };

  const submitHandler = () => {
    const acceptList = [];
    const rejectList = [];

    for (let i = 0; i < competitionNotice.applyQueueInfo.length; i++) {
      if (document.getElementById(`acceptList${i}`).checked) {
        acceptList.push(competitionNotice.applyQueueInfo[i].teamId);
      } else if (document.getElementById(`rejectList${i}`).checked) {
        rejectList.push(competitionNotice.applyQueueInfo[i].teamId);
      }
    }

    const copy = { ...competitionNotice };

    copy.acceptList = acceptList;
    copy.rejectList = rejectList;

    dispatch(updateCompetitionNotice(copy));

    window.location.reload();
  }

  return (
    <>
      <h2 className="mt-3 mb-3">대회 팀 승인</h2>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div>
            <Table
              striped
              hover
              responsive
              className="table-sm text-center"
              style={{ borderCollapse: "collapse" }}
            >
              <thead
                style={{
                  "background-color": "white",
                  postition: "sticky",
                  top: "0",
                }}
              >
                <tr>
                  <th
                    style={{
                      position: "sticky",
                      top: "0",
                      padding: "4px",
                    }}
                    className="align-middle"
                  >
                    팀명
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0",
                      padding: "4px",
                    }}
                    className="align-middle"
                  >
                    대기
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0",
                      padding: "4px",
                    }}
                    className="align-middle"
                  >
                    승인
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0",
                      padding: "4px",
                    }}
                    className="align-middle"
                  >
                    반려
                  </th>
                </tr>
              </thead>
              <tbody>
                {competitionNotice?.applyQueueInfo?.map((team, index) => (
                  <tr key={team.teamId}>
                    <td>
                      {team.teamName}
                    </td>
                    <td>
                      <input
                        id={`applyQueue${index}`}
                        type="checkbox"
                        value={team.teamId}
                        onChange={(e) => checkHandler(e, 0, index)}
                        defaultChecked={isDefaultChecked(team.teamId, 0)}
                      />
                    </td>
                    <td>
                      <input
                        id={`acceptList${index}`}
                        type="checkbox"
                        value={team.teamId}
                        onChange={(e) => checkHandler(e, 1, index)}
                        defaultChecked={isDefaultChecked(team.teamId, 1)}
                      />
                    </td>
                    <td>
                      <input
                        id={`rejectList${index}`}
                        type="checkbox"
                        value={team.teamId}
                        onChange={(e) => checkHandler(e, 2, index)}
                        defaultChecked={isDefaultChecked(team.teamId, 2)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              variant="primary"
              onClick={submitHandler}
              className="mt-3"
            >
              확인
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CompetitionTeamAcceptScreen;
