import React, { useEffect, useState } from "react";
import axios from "axios";

const TeamName1 = ({ id }) => {
  let [teamInfo, setTeamInfo] = useState("");

  useEffect(() => {
    getTeamInfo();
  }, []);

  const getTeamInfo = async () => {
    await axios
      .get(`${API_SERVER_URL}/api/team/name/${id}`)
      .then((res) => {
        setTeamInfo(res.data);
      });
  };

  return teamInfo.name;
};

export default TeamName1;
