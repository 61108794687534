import React, { useState, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { competitionAssociationInput, competitionInput } from "../actions/competitionActions";
import { listTeams, teamDetailsInfo } from "../actions/teamActions";
import { accessControl } from "../utils/access";
import optionsType from "../static/optionsType";
import { readCompetitionNotice } from "../actions/noticeActions";

const CompetitionGenerateScreen = ({ history, match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [place, setPlace] = useState("");
  const [teamTypeObject, setTeamTypeObject] = useState("");
  const isLeague = false;
  const isOfficial = true;
  const [division, setDivision] = useState("일반");
  // 경기 시작 하이픈 자동 생성
  const [startDate, setStartDate] = useState("");
  const startRef = useRef();

  const [teamIdA, setTeamIdA] = useState([]);
  const [teamIdB, setTeamIdB] = useState([]);
  const [teamIdC, setTeamIdC] = useState([]);
  const [teamIdD, setTeamIdD] = useState([]);
  const [teamIdE, setTeamIdE] = useState([]);
  const [teamIdF, setTeamIdF] = useState([]);
  const [teamIdG, setTeamIdG] = useState([]);
  const [teamIdH, setTeamIdH] = useState([]);

  const [optionsTeam, setOptionsTeam] = useState([]);
  const [originalOptionsTeam, setOriginalOptionsTeam] = useState([]);

  const [groupCount, setGroupCount] = useState(0);
  const [groupCountRenderer, setGroupCountRenderer] = useState(0);
  const [applyQueueOption, setApplyQueueOption] = useState([]);
  const [teamMemberOption, setTeamMemberOption] = useState([]);
  const [teamPlayerId, setTeamPlayerId] = useState([]);
  const [teamIndex, setTeamIndex] = useState(0);
  const [teamInfo, setTeamInfo] = useState([]);
  const [groupTeam, setGroupTeam] = useState([]);
  const [isGrouped, setIsGrouped] = useState(false);
  const [allGame, setAllGame] = useState([]);

  let teamName;

  const group = [];

  const competitionNoticeRead = useSelector((state) => state.competitionNoticeRead);

  const {
    loading: loadingCompetitionNotice,
    error: errorCompetitionNotice,
    competitionNotice
  } = competitionNoticeRead;

  useEffect(() => {
    dispatch(readCompetitionNotice(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (competitionNotice) {
      setName(competitionNotice.competitionName);
      setHost(competitionNotice.associationName);

      const tempOptionsTeam = competitionNotice?.applyQueueInfo.map(item => ({
        value: item.teamId,
        label: item.teamName
      }));

      setOptionsTeam(tempOptionsTeam);
      setOriginalOptionsTeam(tempOptionsTeam);
    }
  }, [competitionNotice]);

  const alphabetMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H"
  }

  const groupMaker = () => {
    const maker = [];
    for (let i = 0; i < groupCountRenderer; i++) {
      maker.push(
        <div key={i} className="group-section">
          <h4>조명 ({i + 1}) :</h4>
          <div className="group-input">
            <input
              type="text"
              id={`groupName${i}`}
              defaultValue={`${alphabetMap[i]}조`}
              value={teamName}
            />
          </div>
          <p>팀 선택</p>
          <Select
            options={optionsTeam}
            closeMenuOnSelect={false}
            isSearchable
            isMulti
            onChange={(e) => handleTeamChange(e, i)}
            defaultValue={groupTeam[i]}
          />
          <hr className="group-divider" />
        </div>
      );
    }
    return maker;
  };

  const groupMakerRenderer = (e) => {
    setGroupTeam(new Array(Number(groupCount)).fill([]));
    setGroupCountRenderer(groupCount);
    const initArray = [];
    for (let i = 0; i < groupCount; i++) {
      initArray.push([]);
    }
    setTeamPlayerId(initArray);

  };

  const groupCountInputHandler = (e) => {
    setGroupCount(e.target.value);

    const number = parseInt(e.target.value);
    const newArray = Array.from({ length: number }, (_, index) => index + 1);
    setTeamMemberOption(newArray);
    setTeamInfo(new Array(number).fill(""));
  };

  const handleTeamChange = (e, i) => {
    const selectedTeams = [...groupTeam[i], e[e.length - 1].value];

    const copy = [...groupTeam];
    copy[i] = selectedTeams;

    setGroupTeam(copy);

    const newOptionsTeam = optionsTeam?.filter((team) => {
      return !selectedTeams.includes(team.value);
    });

    setOptionsTeam(newOptionsTeam);
  };


  useEffect(() => {
    accessControl([99, 100, 300], history);
    dispatch(listTeams());
  }, [dispatch]);

  const handleStart = (e) => {
    const value = startRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    startRef.current.value = result;
    setStartDate(e.target.value);
  };

  const handleTeamType = (selected) => {
    if (selected.length >= 1) {
      setTeamTypeObject(selected);
    } else {
      setTeamTypeObject([]);
    }
  };

  // 경기 종료일 하이픈 자동 생성
  const [endDate, setEndDate] = useState("");
  const endRef = useRef();

  const handleEnd = (e) => {
    const value = endRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    endRef.current.value = result;
    setEndDate(e.target.value);
  };

  const handleAllGame = () => {
    setIsGrouped(true);

    const gameOrder = [];

    for (let i = 0; i < groupCount; i++) {
      gameOrder.push({
        groupName: "",
        allGame: []
      });
    }

    for (let i = 0; i < groupTeam.length; i++) {
      gameOrder[i].groupName = document.getElementById(`groupName${i}`).value;
      // console.log(gameOrder)

      const temp = [];
      for (let j = 0; j < groupTeam[i].length; j++) {
        for (let k = j + 1; k < groupTeam[i].length; k++) {
          temp.push([groupTeam[i][j], groupTeam[i][k]]);
        }
      }

      gameOrder[i].allGame = temp;
    }
    setAllGame(gameOrder);
  };


  const divisionOption = [
    {
      value: 0,
      label: "일반",
    },
    {
      value: 1,
      label: "디비전1",
    },
    {
      value: 2,
      label: "디비전2",
    },
    {
      value: 3,
      label: "디비전3",
    },
    {
      value: 4,
      label: "디비전4",
    }
  ];

  const adminCompetitionInput = useSelector(
    (state) => state.adminCompetitionInput
  );
  const { loading, error } = adminCompetitionInput;

  const submitHandler = (e) => {
    e.preventDefault();
    if (teamIdA.length > 0) {
      group.push({ groupName: "A", teamId: teamIdA });
    }
    if (teamIdB.length > 0) {
      group.push({ groupName: "B", teamId: teamIdB });
    }
    if (teamIdC.length > 0) {
      group.push({ groupName: "C", teamId: teamIdC });
    }
    if (teamIdD.length > 0) {
      group.push({ groupName: "D", teamId: teamIdD });
    }
    if (teamIdE.length > 0) {
      group.push({ groupName: "E", teamId: teamIdE });
    }
    if (teamIdF.length > 0) {
      group.push({ groupName: "F", teamId: teamIdF });
    }
    if (teamIdG.length > 0) {
      group.push({ groupName: "G", teamId: teamIdG });
    }
    if (teamIdH.length > 0) {
      group.push({ groupName: "H", teamId: teamIdH });
    }
    const teamType = [];
    teamTypeObject.map((type) => teamType.push(type.value));

    const places = place.split(',');
    for (let i = 0; i < places.length; i++) {
      if (places[i][0] === ' ') { places[i] = places[i].slice(1) }
      if (places[i][places[i].length - 1] === ' ') { places[i] = places[i].slice(0, -1) }
    }

    const tempTeam = [];

    if (groupCount > 0) {
      for (let i = 0; i < groupCount; i++) {
        let teamPlayerIdValue = [];

        for (let j = 0; j < teamPlayerId[i].length; j++) {
          teamPlayerIdValue.push(teamPlayerId[i][j].value);
        }

        tempTeam.push({
          baseTeamId: teamInfo[i]._id,
          tempTeamName: document.getElementById(`teamName${i}`).value,
          image: teamInfo[i].image,
          members: teamPlayerIdValue,
        });
      }
    }

    dispatch(
      competitionInput({
        name: name,
        host: host,
        place: places,
        teamType: teamType,
        startDate: startDate,
        endDate: endDate,
        isLeague: isLeague,
        isOfficial: isOfficial,
        division: division,
        group: group,
        tempTeam: tempTeam,
        competitionNoticeId: id,
      })
    );
  };

  return (
    <FormContainer>
      <h1>대회 생성</h1>
      {error && (
        <Message variant="danger">
          대회 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Form.Group controlId="name">
          <Form.Label>대회명</Form.Label>
          <Form.Control
            type="name"
            placeholder="대회명"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="host" className="mt-2">
          <Form.Label>대회 주최자</Form.Label>
          <Form.Control
            disabled
            type="host"
            placeholder="대회 주최자"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="place" className="mt-2">
          <Form.Label>개최지</Form.Label>
          <Form.Control
            type="name"
            placeholder="쉼표(,)로 구분해서 여러 장소 입력 가능. 예) 와동체육관,선부체육관"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="teamType" className="mt-2">
          <Form.Label>종별</Form.Label>
          <Select
            options={optionsType}
            isSearchable
            isMulti
            placeholder="복수 선택 가능"
            onChange={handleTeamType} />
        </Form.Group>
        <Form.Group controlId="division" className="mt-2">
          <Form.Label>디비전</Form.Label>
          <Select
            options={divisionOption}
            onChange={(e) => setDivision(e.value)}
          />
        </Form.Group>
        <Form.Group controlId="startDate" className="mt-2">
          <Form.Label>대회 시작일</Form.Label>
          <Form.Control
            type="startDate"
            placeholder="예) 2022-01-01"
            ref={startRef}
            value={startDate}
            onChange={handleStart}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="endDate" className="mt-2">
          <Form.Label>대회 종료일</Form.Label>
          <Form.Control
            type="endDate"
            placeholder="예) 2022-01-31"
            ref={endRef}
            value={endDate}
            onChange={handleEnd}
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          대회 등록
        </Button>
      </Form>
    </FormContainer>
  );
};

export default CompetitionGenerateScreen;
