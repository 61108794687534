export const COMMUNITY_LIST_REQUEST = "COMMUNITY_LIST_REQUEST";
export const COMMUNITY_LIST_SUCCESS = "COMMUNITY_LIST_SUCCESS";
export const COMMUNITY_LIST_FAIL = "COMMUNITY_LIST_FAIL";
export const COMMUNITY_LIST_RESET = "COMMUNITY_LIST_RESET";

export const COMMUNITY_READ_REQUEST = "COMMUNITY_READ_REQUEST";
export const COMMUNITY_READ_SUCCESS = "COMMUNITY_READ_SUCCESS";
export const COMMUNITY_READ_FAIL = "COMMUNITY_READ_FAIL";

export const COMMUNITY_WRITE_REQUEST = "COMMUNITY_WRITE_REQUEST";
export const COMMUNITY_WRITE_SUCCESS = "COMMUNITY_WRITE_SUCCESS";
export const COMMUNITY_WRITE_FAIL = "COMMUNITY_WRITE_FAIL";

export const COMMUNITY_UPDATE_REQUEST = "COMMUNITY_UPDATE_REQUEST";
export const COMMUNITY_UPDATE_SUCCESS = "COMMUNITY_UPDATE_SUCCESS";
export const COMMUNITY_UPDATE_FAIL = "COMMUNITY_UPDATE_FAIL";

export const COMMUNITY_DELETE_REQUEST = "COMMUNITY_DELETE_REQUEST";
export const COMMUNITY_DELETE_SUCCESS = "COMMUNITY_DELETE_SUCCESS";
export const COMMUNITY_DELETE_FAIL = "COMMUNITY_DELETE_FAIL";