import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMyPageInfo, getUserDetails, updateUserProfile } from "../actions/userActions";
import { listPlayerDetails } from "../actions/playerActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const UserProfileScreen = () => {
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  const birthdayFormat = (birthday) => {
    if (!birthday) return "";
    return `${birthday.slice(0, 4)}-${birthday.slice(4, 6)}-${birthday.slice(6, 8)}`;
  }

  const renderField = (label, value, action = null) => {
    return (
      <div style={{ marginBottom: "15px", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <p style={{ flex: "1", margin: "0" }}>{label}</p>
        <p style={{ flex: "1", margin: "0" }}>{value}</p>
        {action && <div style={{ flex: "1", margin: "0", textAlign: "right" }}>{action}</div>}
      </div>
    );
  };

  const renderInputField = (label, value, setValue) => {
    return (
      <div style={{ marginBottom: "15px", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <p style={{ flex: "1", margin: "0" }}>{label}</p>
        <textarea
          style={{ flex: "2", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
          defaultValue={value}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    )
  };

  const submitHandler = (e) => {
    e.preventDefault();
    
    dispatch(updateUserProfile({
      email,
      address,
      phoneNum
    }));

    alert("회원정보가 정상적으로 수정되었습니다.");
  };

  useEffect(() => {
    dispatch(getUserDetails(userInfo._id));
  }, [dispatch, userInfo._id]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setAddress(user.address);
      setPhoneNum(user.phoneNum);
    }
  }, [user]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div style={{ maxWidth: "400px", margin: "auto", padding: "20px", border: "1px solid #ccc", borderRadius: "5px" }}>
          <h1 style={{ textAlign: "center" }}>유저 정보</h1>
          {renderField("아이디", user.userId)}
          {renderField("비밀번호", "*******", <Link to="/id-find">변경</Link>)}
          {renderField("성명", user.name)}
          {renderField("생년월일", birthdayFormat(user.birthday))}
          {renderInputField("이메일", email, setEmail)}
          {renderInputField("주소", address, setAddress)}
          {renderInputField("전화번호", phoneNum, setPhoneNum)}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{ padding: "5px 10px", fontSize: "12px", borderRadius: "5px", border: "none", cursor: "pointer" }}
              onClick={submitHandler}
            >확인</button>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfileScreen;