import React from "react";
import { Image } from "react-bootstrap";

const RegisterCompleteScreen = () => {
  return (
    <>
      <div className="register__summary">
        <h1>환영합니다</h1>
        <Image
          src={`/time4_img_register.png`}
          style={{ width: "22rem", borderRadius: "15px" }}
        />
        <p>
          회원가입이 완료 되었습니다!
          <br />
          지금 바로 로그인하여 다양한 기능을 이용해보세요
        </p>
        <a href={"/"}>메인으로</a>
      </div>
    </>
  );
};

export default RegisterCompleteScreen;