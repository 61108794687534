import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { viewLocalSetup, increaseView } from "../utils/viewSetup";
import { deletePost, readPost } from "../actions/communityActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { updatePost } from "../actions/communityActions";
import moment from "moment";

const CommunityReadScreen = ({ match, history }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const communityRead = useSelector((state) => state.communityRead);
  const { loading, error, post } = communityRead;

  const recommendHandler = (e) => {
    e.preventDefault();

    if (post.recommend.includes(userInfo._id)) {
      alert("이미 추천하셨습니다.");
      return;
    }

    const recommend = post.recommend;
    recommend.push(userInfo._id);

    dispatch(
      updatePost({
        _id: id,
        title: post.title,
        date: post.date,
        content: post.content,
        view: post.view,
        category: post.category,
        author: post.author,
        recommend: recommend,
        reply: post.reply,
      })
    );

    window.location.reload();
  };

  const replySubmitHandler = (e) => {
    e.preventDefault();

    const reply = post.reply;
    reply.push({
      userId: userInfo._id,
      name: userInfo.name,
      date: moment().add(9, "hours"),
      content: document.getElementById("reply1").value,
    });

    dispatch(
      updatePost({
        _id: id,
        title: post.title,
        date: post.date,
        content: post.content,
        view: post.view,
        category: post.category,
        author: post.author,
        recommend: post.recommend,
        reply: reply,
      })
    );

    window.location.reload();
  };

  const replyDeleteHandler = (replyId) => {
    let reply = post.reply;

    reply = reply.filter((item) => item._id !== replyId);
    dispatch(
      updatePost({
        _id: id,
        title: post.title,
        date: post.date,
        content: post.content,
        view: post.view,
        category: post.category,
        author: post.author,
        reply: reply,
      })
    );

    window.location.reload();
  };

  const postDeleteHandler = () => {
    dispatch(deletePost(id));
    history.push("/community/category/자유게시판");

    window.location.reload();
  };

  useEffect(() => {
    viewLocalSetup(id);
    dispatch(readPost(id));
    increaseView(id, "community");
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div className="post__main">
            <p>{post.category}</p>
            <h3
              className="mt-3 mb-3"
              style={{ color: "white", "font-weight": 700 }}
            >
              {post.title}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <p style={{ fontSize: "0.8rem", marginBottom: 0 }}>
              {post.author && post.author.name}
            </p>
            <p style={{ fontSize: "0.8rem", marginBottom: 0 }}>
              추천 {post.recommend && post.recommend.length} | 댓글{" "}
              {post.reply && post.reply.length} | 조회 {post.view} |{" "}
              {post.date && post.date.substring(0, 10)}{" "}
              {post.date && post.date.substring(11, 19)}
              {userInfo && post.author && userInfo._id === post.author._id && (
                <Button
                  style={{
                    backgroundColor: "#ff0000",
                    color: "#fff",
                    border: "none",
                    padding: "1px 10px",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                  }}
                  onClick={postDeleteHandler}
                >
                  글 삭제
                </Button>
              )}
            </p>
          </div>
          <div
            style={{
              borderBottom: "1px solid #ccc",
              marginBottom: "10px",
            }}
          />
          <div className="post__content">{ReactHtmlParser(post.content)}</div>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button
              variant="primary"
              style={{
                display: "inline-block",
                margin: "0 auto",
                fontSize: "0.8rem",
                padding: "5px 10px",
                marginTop: "8px",
              }}
              onClick={recommendHandler}
            >
              추천하기
              <br />
              <span style={{ fontSize: "0.7rem" }}>
                ({post && post.recommend && post.recommend.length})
              </span>
            </Button>
          </div>
          {post && post.reply && post.reply.length > 0 && (
            <div className="post__reply">
              <h4>댓글</h4>
              {post.reply.map((reply) => (
                <div
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                    {reply.name}
                  </div>
                  <div
                    style={{
                      marginBottom: "5px",
                      fontSize: "0.8rem",
                      color: "#888",
                    }}
                  >
                    {reply.date && reply.date.substring(0, 10)}{" "}
                    {reply.date && reply.date.substring(11, 19)}
                  </div>
                  <div style={{ fontSize: "1rem", marginBottom: "5px" }}>
                    {reply.content}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ff0000",
                        color: "#fff",
                        border: "none",
                        padding: "1px 10px",
                        fontSize: "0.8rem",
                        cursor: "pointer",
                      }}
                      onClick={() => replyDeleteHandler(reply._id)}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{ marginBottom: "5px", fontWeight: "bold", color: "gray" }}
            >
              {userInfo ? userInfo.name : "로그인이 필요합니다."}
            </div>
            <form onSubmit={replySubmitHandler}>
              <textarea
                id="reply1"
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  resize: "vertical",
                }}
                placeholder={userInfo ? "댓글을 입력하세요." : ""}
                disabled={userInfo ? false : true}
              />
              {userInfo ? (
              <button
                type="submit"
                style={{
                  backgroundColor: "#000000",
                  color: "#fff",
                  border: "none",
                  padding: "5px 10px",
                  fontSize: "0.8rem",
                  cursor: "pointer",
                }}
              >
                댓글 작성
              </button>
              ) : (
                <button
                type="submit"
                style={{
                  backgroundColor: "#000000",
                  color: "#fff",
                  border: "none",
                  padding: "5px 10px",
                  fontSize: "0.8rem",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/login")}
              >
                댓글 작성
              </button>
              )}
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CommunityReadScreen;
