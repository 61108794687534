import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listPlayerDetails, updatePlayer } from "../actions/playerActions";
import { listTeams } from "../actions/teamActions";
import { PLAYER_DETAIL_RESET, PLAYER_UPDATE_RESET } from "../constants/playerConstants";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ModifyPlayerScreen = ({ match, history }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [birthday, setBirthday] = useState("");
  const [height, setHeight] = useState("");
  const [footSize, setFootSize] = useState("");
  const [wingspan, setWingspan] = useState("");
  const [sargent, setSargent] = useState("");
  const [position, setPosition] = useState("");
  const [jerseyNum, setJerseyNum] = useState("");
  const [teamInfos, setTeamInfos] = useState([]);

  const [positionState, setPositionState] = useState([false, false, false, false, false]);

  const cropperRef = useRef(null);
  const [inputImage, setInputImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [imageDeleteFlag, setImageDeleteFlag] = useState(false);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas());
  };

  const playerId = match.params.id;
  const dispatch = useDispatch();

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  const playerDetails = useSelector((state) => state.playerDetails);
  const { loading, error, player } = playerDetails;

  const playerUpdate = useSelector((state) => state.playerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = playerUpdate;

  useEffect(() => {
    accessControl([99, 100], history);
  }, [history]);

  useEffect(() => {
    dispatch(listTeams());
  }, [dispatch]);

  useEffect(() => {
    if (player && player._id === playerId) {
      setName(player.name);
      setImage(player.image);
      setOriginalImage(player.image);
      setBirthday(birthday8to6(player.birthday));
      setHeight(player.height);
      setFootSize(player.footSize);
      setWingspan(player.wingspan);
      setSargent(player.sargent);
      setPosition(player.position);
      setJerseyNum(player.jerseyNum);
      let teamInfos = [];
      for (let i = 0; i < player.teamId?.length; i++) {
        teamInfos.push({
          teamId: player.teamId[i],
          teamName: player.teamName[i],
          jerseyNum: player.teamJerseyNum.find((teamJerseyNum) => teamJerseyNum.teamId === player.teamId[i])?.jerseyNum,
          position: player.teamJerseyNum.find((teamJerseyNum) => teamJerseyNum.teamId === player.teamId[i])?.position,
        });
      }
      setTeamInfos(teamInfos);
      let positionState = [];
      for (let i = 0; i < teamInfos.length; i++) {
        for (let j = 0; j < 5; j++) {
          positionState.push(player.teamJerseyNum.find((teamJerseyNum) => teamJerseyNum.teamId === player.teamId[i])?.position.includes(positionList[j].value));
        }
      }
      setPositionState(positionState);
    } else {
      dispatch(listPlayerDetails(playerId));
    }
  }, [dispatch, history, playerId, player]);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PLAYER_DETAIL_RESET });
      dispatch({ type: PLAYER_UPDATE_RESET });
    }
  }, [dispatch, successUpdate]);

  const imageContainerStyle = {
    position: 'relative',
    display: 'inline-block'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'red',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '18px'
  };
  // 팀 리스트 선택
  const options = [];

  teams &&
    teams.map((team) =>
      options.push({
        value: team._id,
        label: team.name,
      })
    );

  const positionList = [
    {
      value: "PG",
      label: "PG (1번)",
    },
    {
      value: "SG",
      label: "SG (2번)",
    },
    {
      value: "SF",
      label: "SF (3번)",
    },
    {
      value: "PF",
      label: "PF (4번)",
    },
    {
      value: "C",
      label: "C (5번)",
    },
  ];

  const handleTeamChange = (index, selected) => {
    //no duplicate
    if (teamInfos.some((teamInfo) => teamInfo.teamId === selected.value)) {
      alert("중복된 팀이 있습니다.");
      return;
    }

    const updatedTeamInfos = [...teamInfos];
    updatedTeamInfos[index].teamId = selected.value;
    updatedTeamInfos[index].teamName = selected.label;
    setTeamInfos(updatedTeamInfos);
  };

  const birthday6to8 = (birthday) => {
    if (birthday && birthday.length === 6) {
      if (birthday.substring(0, 2) > Number(new Date().getFullYear().toString().substring(2, 4))) {
        return "19" + birthday;
      } else {
        return "20" + birthday;
      }
    }
    return birthday;
  };

  const birthday8to6 = (birthday) => {
    if (birthday && birthday.length === 8) {
      return birthday.substring(2, 8);
    }
    return birthday;
  };

  // 선수 이미지 등록
  const [uploading, setUploading] = useState(false);

  const deleteImageHandler = () => {
    setImage("");
    setImageDeleteFlag(true);
  };

  const deleteFileHandler = async (key) => {
    try {
      await axios.delete(`${API_SERVER_URL}/api/upload/${key}`);
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleAddteamId = () => {
    setTeamInfos([...teamInfos, { teamId: "", teamName: "", jerseyNum: "", position: [] }]);
  };

  const handleDeleteTeam = (index) => {
    const updatedTeamInfos = [...teamInfos];
    updatedTeamInfos.splice(index, 1);
    setTeamInfos(updatedTeamInfos);
  };


  const onImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setInputImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handlePositionChange = (index, i) => {
    const updatedPositionState = [...positionState];

    updatedPositionState[index * 5 + i] = !updatedPositionState[index * 5 + i];
    setPositionState(updatedPositionState);
  };

  const handleMainTeamChange = (index) => {
    for (let i = 0; i < teamInfos.length; i++) {
      if (i !== index) {
        document.getElementById(`mainTeam${i}`).checked = false;
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const teamIdList = [];

    teamInfos.map((teamInfo) => {
      if (teamInfo.teamId !== "") {
        teamIdList.push(teamInfo.teamId);
      }
    });

    const teamJerseyNum = [];
    let mainTeamIndex = 0;

    for (let i = 0; i < teamInfos.length; i++) {
      const jerseyNum = document.getElementById(`jerseyNum${i}`).value;

      let position = [];

      for (let j = 0; j < 5; j++) {
        if (positionState[i * 5 + j]) {
          position.push(positionList[j].value);
        }
      }

      teamJerseyNum.push({ teamId: teamInfos[i].teamId, jerseyNum, position });

      if (document.getElementById(`mainTeam${i}`).checked) {
        mainTeamIndex = i;
      }
    }

    //teamJerseyNum배열에서 mainTeamIndex의 object를 0번째 index로 옮기기
    const temp = teamJerseyNum[mainTeamIndex];
    teamJerseyNum.splice(mainTeamIndex, 1);
    teamJerseyNum.unshift(temp);

    // return;

    const isDelete = imageDeleteFlag || (originalImage !== "" && image !== originalImage);

    if (isDelete) {
      let key = originalImage.match(/\/([^\/]+)$/) ? originalImage.match(/\/([^\/]+)$/)[1] : "";
      if (key !== "") { deleteFileHandler(key); }
    }

    if (croppedImage !== null) {
      const formData = new FormData();

      console.log(croppedImage);
      croppedImage.toBlob(async (blob) => {
        formData.append("image", blob, "image.jpg");

        setUploading(true);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const { data } = await axios.post(
            `${API_SERVER_URL}/api/upload`,
            formData,
            config
          );

          setUploading(false);

          dispatch(
            updatePlayer({
              _id: playerId,
              name,
              image: data,
              birthday: birthday6to8(birthday.split(' ').join('')),
              height,
              footSize,
              wingspan,
              sargent,
              position,
              jerseyNum,
              teamId: teamIdList,
              teamJerseyNum
            })
          );
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      }, "image/jpeg");
    } else {
      dispatch(
        updatePlayer({
          _id: playerId,
          name,
          image: image,
          birthday: birthday6to8(birthday.split(' ').join('')),
          height,
          footSize,
          wingspan,
          sargent,
          position,
          jerseyNum,
          teamId: teamIdList,
          teamJerseyNum
        })
      );
    }
    
    // alert("선수 정보가 수정되었습니다.");
    // window.location.href = `/personallistrecord/search/${player?.name}/page/1`
    //reload
    // window.location.reload();
  };

  let jerseyNum1;

  return (
    <FormContainer>
      <Row className="mb-3" style={{ width: "40%", float: "right" }}>
        <Link to="/admin/tmpplayerinput">
          <Button variant="danger">선수 가입력 페이지 이동</Button>
        </Link>
      </Row>
      <h1 className="mt-2">선수 정보 수정</h1>
      <p className="mt-4">
        선수 정보가 불러와지지 않는 경우, 새로고침을 해주세요.
      </p>
      {error && (
        <Message variant="danger">
          선수 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      {loadingTeam ? (
        <Loader />
      ) : errorTeam ? (
        <Message variant="danger">
          [오류] 팀 정보를 불러오지 못했습니다. 개발자에게 문의해주세요.
        </Message>
      ) : (
        <Form className="mt-3">
          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>선수명</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="선수명"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="birthday">
                <Form.Label>생년월일</Form.Label>
                <Form.Control
                  type="birthday"
                  placeholder="예) 920101"
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="image" className="mt-3">
            <Form.Label>이미지</Form.Label>
            <Form.Group controlId="crop" className="mt-3">
              <input type="file" accept="image/*" onChange={onImageChange} />
              <Cropper
                ref={cropperRef}
                src={inputImage}
                viewMode={1}
                crop={onCrop}
                zoomable={false}
                initialAspectRatio={16 / 16}
              />
            </Form.Group>
            <div style={imageContainerStyle}>
              {(image !== "") && (croppedImage === null) && <>
                <img src={image} alt="image" style={{ width: '100%' }} />
                <button style={closeButtonStyle} onClick={deleteImageHandler}>X</button>
              </>}
              {image === "" && croppedImage === null &&
                <>
                  <img src={"/player_default_img.png"} alt="image" style={{ width: '100%' }} />
                </>}
            </div>
          </Form.Group>
          {uploading && <Loader />}
          {teamInfos.map((teamInfo, index) => (
            <Form.Group controlId={`teamId${index}`} className="mt-1">
              <Form.Label>소속 팀 - {index + 1}</Form.Label>
              <Form.Check
                type="radio"
                label="대표 팀 설정"
                id={`mainTeam${index}`}
                // defaultChecked={player?.teamJerseyNum[0]?.teamId === teamInfo.teamId}
                defaultChecked={player && player.teamJerseyNum && player.teamJerseyNum[0]?.teamId === teamInfo.teamId}
                onChange={() => handleMainTeamChange(index)}
              />
              <Select
                options={options}
                isSearchable
                onChange={(selected) => { handleTeamChange(index, selected) }}
                value={{
                  label: teamInfo.teamName,
                  value: teamInfo.teamId,
                }}
              />
              <input
                type="text"
                placeholder="등번호"
                value={jerseyNum1}
                defaultValue={teamInfo.jerseyNum}
                id={`jerseyNum${index}`}
              />
              {positionList.map((position, i) => {
                return (
                  <Form.Check
                    type="checkbox"
                    label={position.label}
                    id={`position${index}-${i}`}
                    value={position}
                    checked={positionState[index * 5 + i]}
                    defaultChecked={teamInfo?.position?.includes(position.value)}
                    onChange={() => handlePositionChange(index, i)}
                  />
                )
              })}
              <button
                type="button"
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  fontSize: '1rem',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteTeam(index);
                }}
              >
                ❌
              </button>
            </Form.Group>
          ))}
          <div style={{ textAlign: "center" }}>
            <Button onClick={handleAddteamId}>팀 추가</Button>
          </div>
          <Row>
            <Col>
              <Form.Group controlId="height">
                <Form.Label>신장</Form.Label>
                <Form.Control
                  type="height"
                  placeholder="ex) 175"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="footSize">
                <Form.Label>발크기</Form.Label>
                <Form.Control
                  type="footSize"
                  placeholder="ex) 275"
                  value={footSize}
                  onChange={(e) => setFootSize(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="wingspan">
                <Form.Label>윙스팬</Form.Label>
                <Form.Control
                  type="wingspan"
                  placeholder="윙스팬"
                  value={wingspan}
                  onChange={(e) => setWingspan(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="sargent">
                <Form.Label>서전트 점프</Form.Label>
                <Form.Control
                  type="sargent"
                  placeholder="서전트 점프"
                  value={sargent}
                  onChange={(e) => setSargent(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button
            onClick={submitHandler}
            variant="primary"
            className="mt-5"
            style={{ width: "100%" }}
          >
            정보 업데이트
          </Button>
          {/* <Button
            onClick={() => history.goBack()}
            variant="danger"
            className="mt-3"
            style={{ width: "100%" }}
          >
            뒤로 가기
          </Button> */}
        </Form>
      )}
    </FormContainer>
  );
};

export default ModifyPlayerScreen;