import React, { useState, useEffect } from "react";
import { Table, Row, Col, Image, Button } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  listPickupCompetitionDetail,
  pickupCompetitionStatsAction,
} from "../actions/pickupActions";
import {
  PICKUP_COMPETITION_DETAIL_RESET,
  PICKUP_COMPETITION_LIST_RESET,
} from "../constants/pickupConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import PickupGameSchedule from "../components/PickupGameSchedule";
import Select from "react-select";

const PickupGameRecordScreen = ({ match }) => {
  const id = match.params.id;

  const [statsType, setStatsType] = useState("score");

  const dispatch = useDispatch();

  const pickupCompetitionDetail = useSelector(
    (state) => state.pickupCompetitionDetail
  );

  const { loading, error, pickupCompetition } = pickupCompetitionDetail;

  const pickupCompetitionStats = useSelector(
    (state) => state.pickupCompetitionStats
  );
  const {
    loading: loadingStats,
    error: errorStats,
    stats,
  } = pickupCompetitionStats;

  const statsTypeOption = [
    {
      label: "득점 평균",
      value: "score",
    },
    {
      label: "어시스트 평균",
      value: "assist",
    },
    {
      label: "리바운드 평균",
      value: "rebound",
    },
    {
      label: "블락 평균",
      value: "block",
    },
    {
      label: "스틸 평균",
      value: "steal",
    },
    {
      label: "자유투 확률",
      value: "freeThrow",
    },
    {
      label: "야투율",
      value: "fieldGoal",
    },
    {
      label: "3점슛 확률",
      value: "threePointThrow",
    },
  ];

  const statsTypeHandler = (selected) => {
    setStatsType(selected.value);
  };

  const getStatsByType = (type) => {
    if (type === "score") {
      return stats && stats.score;
    } else if (type === "assist") {
      return stats && stats.assist;
    } else if (type === "rebound") {
      return stats && stats.rebound;
    } else if (type === "block") {
      return stats && stats.block;
    } else if (type === "steal") {
      return stats && stats.steal;
    } else if (type === "freeThrow") {
      return stats && stats.freeThrow;
    } else if (type === "fieldGoal") {
      return stats && stats.fieldGoal;
    } else if (type === "threePointThrow") {
      return stats && stats.threePointThrow;
    }
  };

  const tableHeader = (stats) => {
    const headerNameToKorean = (name) => {
      switch (name) {
        case "rank":
          return "순위";
        case "teamName":
          return "소속 팀";
        case "name":
          return "선수명";
        case "image":
          return "선수 사진";
        case "avg":
          return "평균";
        case "acc":
          if (
            statsType === "freeThrow" ||
            statsType === "fieldGoal" ||
            statsType === "threePointThrow"
          ) {
            return "성공-시도";
          } else {
            return "누적";
          }
        case "gameCount":
          return "경기 수";
        case "total":
          return "합계";
        case "throwRate":
          return "성공률";
        default:
          return name;
      }
    };

    const headerNames = [];
    for (const property in stats[0]) {
      headerNames.push(headerNameToKorean(property));
    }
    return headerNames.map((name) => (
      <th>
        <div>{name}</div>
      </th>
    ));
  };

  const tableBody = (stat) => {
    const renderResult = [];
    for (const property in stat) {
      if (property === "image") {
        if (stat[property] === "") {
          renderResult.push(
            <td>
              <div>
                <Image
                  style={{
                    width: "6rem",
                    border: "0px solid rgba(0, 0, 0, 0)",
                    "border-radius": "25px 25px 25px 25px",
                    "text-align": "center",
                  }}
                  src="/player_default_img.png"
                  alt={stat.name}
                  fluid
                  rounded
                />
              </div>
            </td>
          );
        } else {
          renderResult.push(
            <td>
              <div>
                <Image
                  style={{
                    width: "6rem",
                    border: "0px solid rgba(0, 0, 0, 0)",
                    "border-radius": "25px 25px 25px 25px",
                    "text-align": "center",
                  }}
                  src={stat[property]}
                  alt={stat.name}
                  fluid
                  rounded
                />
              </div>
            </td>
          );
        }
        continue;
      }
      if (
        (statsType === "rebound" && property === "avg") ||
        ((statsType === "fieldGoal" ||
          statsType === "threePointThrow" ||
          statsType === "freeThrow") &&
          property === "acc")
      ) {
        renderResult.push(
          <td>
            <div>
              {stat[property][0]} - {stat[property][1]}
            </div>
          </td>
        );
        continue;
      }
      if (property === "throwRate") {
        renderResult.push(
          <td>
            <div>{stat[property]} %</div>
          </td>
        );
        continue;
      }
      renderResult.push(
        <td>
          <div>{stat[property]}</div>
        </td>
      );
    }
    return renderResult;
  };

  useEffect(() => {
    dispatch(listPickupCompetitionDetail(id));
    dispatch({ type: PICKUP_COMPETITION_LIST_RESET });
    dispatch({ type: PICKUP_COMPETITION_DETAIL_RESET });

    dispatch(pickupCompetitionStatsAction(id));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {pickupCompetition && pickupCompetition.length !== 0 ? (
            <Row>
              <h2
                style={{
                  color: "white",
                  "background-color": "#031b31",
                  "padding-top": "20px",
                  "padding-bottom": "20px",
                  "margin-top": "-16px",
                }}
              >
                {pickupCompetition.name + " 경기 결과"}
              </h2>
              <div style={{ "padding-left": "0px", "padding-right": "0px" }}>
                {/* <p>경기일 : {roundInfo && roundInfo.dates[0].substring(0, 10)}</p> */}
                <Table responsive className="table-sm text-center mt-3">
                  <thead>
                    <tr className="table__header">
                      <th
                        style={{
                          "padding-left": "4px",
                          "padding-right": "4px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBasketball}
                          style={{
                            fontSize: "13px",
                            color: "white",
                          }}
                        />
                      </th>
                      <th>경기명</th>
                      <th>HOME</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>AWAY</th>
                      <span className="mobile__hide">
                        <th style={{ "background-color": "#031b31" }}></th>
                      </span>
                    </tr>
                  </thead>
                  <tbody>
                    {pickupCompetition.games?.map((game, i) => (
                      <tr key={i}>
                        <PickupGameSchedule key={i} id={game} index={i} />
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Row>
          ) : (
            <Row className="justify-content-center mt-5 mb-5">
              <h2 className="mt-5 mb-5 text-center">
                경기 결과가 곧 업데이트 됩니다.
              </h2>
              <Image
                src={`/time4_logo_message.png`}
                style={{ width: "26rem" }}
                className="text-center mt-5 mb-5"
              />
            </Row>
          )}
          {pickupCompetition && pickupCompetition.image[0] !== "" && (
            <>
              <Row className="mt-3">
                <Col md={6} className="mt-3">
                  <Image
                    src={pickupCompetition && pickupCompetition.image[0]}
                    alt={pickupCompetition && pickupCompetition.image[0]}
                    fluid
                  />
                </Col>
                {pickupCompetition && pickupCompetition.image[1] !== "" && (
                  <Col md={6} className="mt-3">
                    <Image
                      src={pickupCompetition && pickupCompetition.image[1]}
                      alt={pickupCompetition && pickupCompetition.image[1]}
                      fluid
                    />
                  </Col>
                )}
              </Row>
              {pickupCompetition && pickupCompetition.image[2] !== "" && (
                <Row>
                  <Col md={6} className="mt-3">
                    <Image
                      src={pickupCompetition && pickupCompetition.image[2]}
                      alt={pickupCompetition && pickupCompetition.image[2]}
                      fluid
                    />
                  </Col>
                  {pickupCompetition && pickupCompetition.image[3] !== "" && (
                    <Col md={6} className="mt-3">
                      <Image
                        src={pickupCompetition && pickupCompetition.image[3]}
                        alt={pickupCompetition && pickupCompetition.image[3]}
                        fluid
                      />
                    </Col>
                  )}
                </Row>
              )}
              {pickupCompetition && pickupCompetition.image[4] !== "" && (
                <Row>
                  <Col md={6} className="mt-3">
                    <Image
                      src={pickupCompetition && pickupCompetition.image[4]}
                      alt={pickupCompetition && pickupCompetition.image[4]}
                      fluid
                    />
                  </Col>
                  {pickupCompetition && pickupCompetition.image[5] !== "" && (
                    <Col md={6} className="mt-3">
                      <Image
                        src={pickupCompetition && pickupCompetition.image[5]}
                        alt={pickupCompetition && pickupCompetition.image[5]}
                        fluid
                      />
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          <>
            <h3
              style={{
                "font-weight": "900",
                color: "black",
                "text-align": "center",
              }}
            >
              대회 통계
            </h3>
            <Select
              options={statsTypeOption}
              onChange={statsTypeHandler}
              defaultValue={statsTypeOption[0]}
              isSearchable={false}
            />
            <Table
              striped
              bordered
              hover
              responsive
              className="table-sm text-center"
            >
              <thead>
                <tr>{stats && tableHeader(getStatsByType(statsType))}</tr>
              </thead>
              <tbody>
                {stats &&
                  getStatsByType(statsType).map((stat, index) => (
                    <tr key={index}>{tableBody(stat)}</tr>
                  ))}
              </tbody>
            </Table>
          </>
        </>
      )}
    </>
  );
};

export default PickupGameRecordScreen;
