import React from "react";
import { Image } from "react-bootstrap";

const DefaultScreen = () => {
  return (
    <div className="text-center mt-5">
      <Image
        src={`/banner.png`}
        thumbnail
        className="px-5 mt-3 mb-3"
        style={{ border: "0px solid rgba(0, 0, 0, 0)" }}
      />
      <h1 className="mt-5">서비스 준비 중입니다.</h1>
    </div>
  );
};

export default DefaultScreen;
