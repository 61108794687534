export const PICKUP_COMPETITION_LIST_REQUEST = "PICKUP_COMPETITION_LIST_REQUEST";
export const PICKUP_COMPETITION_LIST_SUCCESS = "PICKUP_COMPETITION_LIST_SUCCESS";
export const PICKUP_COMPETITION_LIST_FAIL = "PICKUP_COMPETITION_LIST_FAIL";
export const PICKUP_COMPETITION_LIST_RESET = "PICKUP_COMPETITION_LIST_RESET";

export const PICKUP_COMPETITION_INPUT_REQUEST = "PICKUP_COMPETITION_INPUT_REQUEST";
export const PICKUP_COMPETITION_INPUT_SUCCESS = "PICKUP_COMPETITION_INPUT_SUCCESS";
export const PICKUP_COMPETITION_INPUT_FAIL = "PICKUP_COMPETITION_INPUT_FAIL";
export const PICKUP_COMPETITION_INPUT_RESET = "PICKUP_COMPETITION_INPUT_RESET";

export const PICKUP_COMPETITION_DETAIL_REQUEST = "PICKUP_COMPETITION_DETAIL_REQUEST";
export const PICKUP_COMPETITION_DETAIL_SUCCESS = "PICKUP_COMPETITION_DETAIL_SUCCESS";
export const PICKUP_COMPETITION_DETAIL_FAIL = "PICKUP_COMPETITION_DETAIL_FAIL";
export const PICKUP_COMPETITION_DETAIL_RESET = "PICKUP_COMPETITION_DETAIL_RESET";

export const PICKUP_COMPETITION_UPDATE_REQUEST = "PICKUP_COMPETITION_UPDATE_REQUEST";
export const PICKUP_COMPETITION_UPDATE_SUCCESS = "PICKUP_COMPETITION_UPDATE_SUCCESS";
export const PICKUP_COMPETITION_UPDATE_FAIL = "PICKUP_COMPETITION_UPDATE_FAIL";
export const PICKUP_COMPETITION_UPDATE_RESET = "PICKUP_COMPETITION_UPDATE_RESET";

export const PICKUP_COMPETITION_HOST_LIST_REQUEST = "PICKUP_COMPETITION_HOST_LIST_REQUEST";
export const PICKUP_COMPETITION_HOST_LIST_SUCCESS = "PICKUP_COMPETITION_HOST_LIST_SUCCESS";
export const PICKUP_COMPETITION_HOST_LIST_FAIL = "PICKUP_COMPETITION_HOST_LIST_FAIL";
export const PICKUP_COMPETITION_HOST_LIST_RESET = "PICKUP_COMPETITION_HOST_LIST_RESET";

export const PICKUP_COMPETITION_STATS_REQUEST = "PICKUP_COMPETITION_STATS_REQUEST";
export const PICKUP_COMPETITION_STATS_SUCCESS = "PICKUP_COMPETITION_STATS_SUCCESS";
export const PICKUP_COMPETITION_STATS_FAIL = "PICKUP_COMPETITION_STATS_FAIL";
export const PICKUP_COMPETITION_STATS_RESET = "PICKUP_COMPETITION_STATS_RESET";


export const PICKUP_GAME_INPUT_REQUEST = "PICKUP_GAME_INPUT_REQUEST";
export const PICKUP_GAME_INPUT_SUCCESS = "PICKUP_GAME_INPUT_SUCCESS";
export const PICKUP_GAME_INPUT_FAIL = "PICKUP_GAME_INPUT_FAIL";
export const PICKUP_GAME_INPUT_RESET = "PICKUP_GAME_INPUT_RESET";

export const PICKUP_GAME_DETAIL_REQUEST = "PICKUP_GAME_DETAIL_REQUEST";
export const PICKUP_GAME_DETAIL_SUCCESS = "PICKUP_GAME_DETAIL_SUCCESS";
export const PICKUP_GAME_DETAIL_FAIL = "PICKUP_GAME_DETAIL_FAIL";
export const PICKUP_GAME_DETAIL_RESET = "PICKUP_GAME_DETAIL_RESET";

export const PICKUP_GAME_LIST_REQUEST = "PICKUP_GAME_LIST_REQUEST";
export const PICKUP_GAME_LIST_SUCCESS = "PICKUP_GAME_LIST_SUCCESS";
export const PICKUP_GAME_LIST_FAIL = "PICKUP_GAME_LIST_FAIL";
export const PICKUP_GAME_LIST_RESET = "PICKUP_GAME_LIST_RESET";

export const PICKUP_GAME_RESULT_REQUEST = "PICKUP_GAME_RESULT_REQUEST";
export const PICKUP_GAME_RESULT_SUCCESS = "PICKUP_GAME_RESULT_SUCCESS";
export const PICKUP_GAME_RESULT_FAIL = "PICKUP_GAME_RESULT_FAIL";
export const PICKUP_GAME_RESULT_RESET = "PICKUP_GAME_RESULT_RESET";

export const PICKUP_PLAYER_HOST_LIST_REQUEST = "PICKUP_PLAYER_HOST_LIST_REQUEST";
export const PICKUP_PLAYER_HOST_LIST_SUCCESS = "PICKUP_PLAYER_HOST_LIST_SUCCESS";
export const PICKUP_PLAYER_HOST_LIST_FAIL = "PICKUP_PLAYER_HOST_LIST_FAIL";
export const PICKUP_PLAYER_HOST_LIST_RESET = "PICKUP_PLAYER_HOST_LIST_RESET";

export const PICKUP_PLAYER_HOST_RECORD_REQUEST = "PICKUP_PLAYER_HOST_RECORD_REQUEST";
export const PICKUP_PLAYER_HOST_RECORD_SUCCESS = "PICKUP_PLAYER_HOST_RECORD_SUCCESS";
export const PICKUP_PLAYER_HOST_RECORD_FAIL = "PICKUP_PLAYER_HOST_RECORD_FAIL";
export const PICKUP_PLAYER_HOST_RECORD_RESET = "PICKUP_PLAYER_HOST_RECORD_RESET";




export const PICKUP_LIST_REQUEST = "PICKUP_LIST_REQUEST";
export const PICKUP_LIST_SUCCESS = "PICKUP_LIST_SUCCESS";
export const PICKUP_LIST_FAIL = "PICKUP_LIST_FAIL";
export const PICKUP_LIST_RESET = "PICKUP_LIST_RESET";

export const PICKUP_DETAIL_REQUEST = "PICKUP_DETAIL_REQUEST";
export const PICKUP_DETAIL_SUCCESS = "PICKUP_DETAIL_SUCCESS";
export const PICKUP_DETAIL_FAIL = "PICKUP_DETAIL_FAIL";

export const PICKUP_CREATE_REQUEST = "PICKUP_CREATE_REQUEST";
export const PICKUP_CREATE_SUCCESS = "PICKUP_CREATE_SUCCESS";
export const PICKUP_CREATE_FAIL = "PICKUP_CREATE_FAIL";
export const PICKUP_CREATE_RESET = "PICKUP_CREATE_RESET";

export const PICKUP_UPDATE_REQUEST = "PICKUP_UPDATE_REQUEST";
export const PICKUP_UPDATE_SUCCESS = "PICKUP_UPDATE_SUCCESS";
export const PICKUP_UPDATE_FAIL = "PICKUP_UPDATE_FAIL";
export const PICKUP_UPDATE_RESET = "PICKUP_UPDATE_RESET";
