import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listApplies } from "../actions/competitionApplyActions";
import { competitionGameList, listCompetition } from "../actions/competitionActions";
import Select from "react-select";

const CompetitionApplyFindScreen = ({ match, history }) => {
  const competitionId = match.params.id;

  const dispatch = useDispatch();

  const [applyPassword, setApplyPassword] = useState("");

  const [isFinding, setIsFinding] = useState(false);

  const competitionApplyList = useSelector((state) => state.competitionApplyList);
  const { loading, error, applies } = competitionApplyList;

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const competitionGame = useSelector((state) => state.competitionGame);
  const { competition } = competitionGame;

  useEffect(() => {
    dispatch(competitionGameList(competitionId));
  }, [dispatch, competitionId]);

  useEffect(() => {
    dispatch(listCompetition());
  }, [dispatch]);

  const findHandler = () => {
    setIsFinding(true);
    if (competitionId === "") {
      alert("대회를 선택해주세요.");
      return;
    } else if (applyPassword === "") {
      alert("비밀번호를 입력해주세요.");
      return;
    }
    dispatch(listApplies(competitionId, applyPassword));
  }

  useEffect(() => {
    if (applies && isFinding) {
      if (applies.length > 0) {
        history.push(`/admin/competition-apply/detail/${applies[0]._id}/0`);
      } else {
        alert("대회 신청서를 찾을 수 없습니다.");
      }
    }
  }, [applies]);

  return (
    <>
      <div>
        <span style={{ color: 'blue', fontSize: '30px' }}>{competition?.name}</span>
        <span style={{ fontSize: '30px' }}> 신청서 조회</span>
      </div>
      <input
        type="text"
        placeholder="비밀번호"
        value={applyPassword}
        onChange={(e) => setApplyPassword(e.target.value)}
        style={{ marginRight: '10px', padding: '5px'}}
      />
      <Button onClick={findHandler}>찾기</Button>
    </>
  );
}

export default CompetitionApplyFindScreen;