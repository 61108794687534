import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ProgressBar, Table, Image } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  listCompetitionInfo,
  competitionResultInfo,
} from "../actions/competitionActions";
import PlayerName from "../components/PlayerName";
import {
  COMPETITION_INFO_RESET,
  COMPETITION_LIST_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";
import { viewLocalSetup, increaseView } from "../utils/viewSetup";

const DetailGameRecordScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const competitionInfoList = useSelector((state) => state.competitionInfoList);
  const { loading, error, competition } = competitionInfoList;

  const competitionResult = useSelector((state) => state.competitionResultInfo);
  const {
    loading: loadingResult,
    error: errorResult,
    result,
  } = competitionResult;

  const imgStyle = {
    maxHeight: 128,
    maxWidth: 128,
  };

  useEffect(() => {
    dispatch({ type: COMPETITION_INFO_RESET });
    dispatch(listCompetitionInfo(id));
    dispatch(competitionResultInfo(id));
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, id]);

  useEffect(() => {
    if (competition[0]) {
      viewLocalSetup(competition[0].competitionId);
      increaseView(competition[0].competitionId, "competition");
    }
  }, [competition]);

  const reduceLength =
    competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .length;

  const bestScore = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.score > current.score ? prev : current;
    }).score
    : 0;

  const bestScorePlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.score === bestScore;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const bestAssist = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.assist > current.assist ? prev : current;
    }).assist
    : 0;

  const bestAssistPlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.assist === bestAssist;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const bestSteal = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.steal > current.steal ? prev : current;
    }).steal
    : 0;

  const bestStealPlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.steal === bestSteal;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const bestRebound = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.rebound[0] + prev.rebound[1] >
        current.rebound[0] + current.rebound[1]
        ? prev
        : current;
    }).rebound[0] +
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.rebound[0] + prev.rebound[1] >
        current.rebound[0] + current.rebound[1]
        ? prev
        : current;
    }).rebound[1]
    : 0;

  const bestReboundPlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.rebound[0] + player.rebound[1] === bestRebound;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const bestBlock = reduceLength
    ? competition[0] &&
    [
      ...competition[0].playerRecord[0],
      ...competition[0].playerRecord[1],
    ].reduce(function (prev, current) {
      return prev.block > current.block ? prev : current;
    }).block
    : 0;

  const bestBlockPlayerIdArray = reduceLength
    ? competition[0] &&
    [...competition[0].playerRecord[0], ...competition[0].playerRecord[1]]
      .filter(function (player) {
        return player.block === bestBlock;
      })
      .map(function (player) {
        return player.playerId;
      })
    : [];

  const isThree = competition[0] && competition[0].isThree;

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h3
            className="text-center"
            style={{
              color: "white",
              "background-color": "#031b31",
              "padding-top": "20px",
              "padding-bottom": "20px",
              "margin-top": "-16px",
              "margin-left": "-12px",
              "margin-right": "-12px",
            }}
          >
            {competition[0] && competition[0].round}{" "}
            {competition[0] && competition[0].gameName} 정보
          </h3>
          {loadingResult ? (
            <Loader />
          ) : errorResult ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Row className="text-center mt-5">
              <span className="on__pc__gameDetails">
                <Row className="text-center mx-5">
                  <Col md={5}>
                    {competition[0] && competition[0].vsTeamImage[0] === "" ? (
                      <Image
                        src={"/team_default_img.png"}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[0]}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    ) : (
                      <Image
                        src={competition[0] && competition[0].vsTeamImage[0]}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[0]}
                        fluid
                        className="mb-3 mx-3 teamImg__mobile"
                      />
                    )}
                    <h5>{competition[0] && competition[0].vsTeamName[0]}</h5>
                    <h1 className="text-center mt-3">
                      {result.gameResult && result.gameResult[0]}
                    </h1>
                  </Col>
                  <Col md={2} className="pt-5">
                    <h1>VS</h1>
                  </Col>
                  <Col md={5}>
                    {competition[0] && competition[0].vsTeamImage[1] === "" ? (
                      <Image
                        src={"/team_default_img.png"}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[1]}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    ) : (
                      <Image
                        src={competition[0] && competition[0].vsTeamImage[1]}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[1]}
                        fluid
                        className="mb-3 mx-3 teamImg__mobile"
                      />
                    )}
                    <h5>{competition[0] && competition[0].vsTeamName[1]}</h5>
                    <h1 className="text-center mt-3">
                      {result.gameResult && result.gameResult[1]}
                    </h1>
                  </Col>
                </Row>
              </span>
              <span
                className="on__mobile__gameDetails"
                style={{ "padding-left": "4px", "padding-right": "4px" }}
              >
                <div
                  className="detailGame__justify__content"
                  style={{
                    border: "1px solid #D2D2D2",
                    "padding-top": "20px",
                    "padding-right": "10px",
                    "padding-left": "10px",
                  }}
                >
                  <div>
                    {competition[0] && competition[0].vsTeamImage[0] === "" ? (
                      <Image
                        src={"/team_default_img.png"}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[0]}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    ) : (
                      <Image
                        src={competition[0] && competition[0].vsTeamImage[0]}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[0]}
                        fluid
                        className="mb-3 mx-3 teamImg__mobile"
                      />
                    )}
                    <h5>{competition[0] && competition[0].vsTeamName[0]}</h5>
                  </div>
                  <h1
                    className="text-center"
                    style={{ "font-size": "20px", "font-weight": 700 }}
                  >
                    {result.gameResult && result.gameResult[0]}
                  </h1>
                  <div>
                    <h1>:</h1>
                  </div>
                  <h1
                    className="text-center"
                    style={{ "font-size": "20px", "font-weight": 700 }}
                  >
                    {result.gameResult && result.gameResult[1]}
                  </h1>
                  <div>
                    {competition[0] && competition[0].vsTeamImage[1] === "" ? (
                      <Image
                        src={"/team_default_img.png"}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[1]}
                        fluid
                        className="mb-3 imgStyle"
                      />
                    ) : (
                      <Image
                        src={competition[0] && competition[0].vsTeamImage[1]}
                        style={imgStyle}
                        alt={competition[0] && competition[0].vsTeamName[1]}
                        fluid
                        className="mb-3 mx-3 teamImg__mobile"
                      />
                    )}
                    <h5>{competition[0] && competition[0].vsTeamName[1]}</h5>
                  </div>
                </div>
              </span>
              <Row>
                <Col md={2}></Col>
                <Col
                  md={8}
                  className="text-center"
                  style={{
                    "margin-left": "12px",
                    "margin-right": "12px",
                    "padding-left": "4px",
                    "padding-right": "4px",
                  }}
                >
                  {!isThree && (<>
                  {competition[0] &&
                    competition[0].quarter &&
                    !competition[0].quarter[0][0] &&
                    !competition[0].quarter[0][1] ? (
                    <Table responsive className="table-sm text-center">
                      <thead>
                        <tr
                          style={{
                            color: "white",
                            "background-color": "#031b31",
                          }}
                        >
                          <th>팀</th>
                          <th>
                            <div
                              className="hideextra"
                              style={{ "min-width": "10px" }}
                            >
                              전반
                            </div>
                          </th>
                          <th>
                            <div
                              className="hideextra"
                              style={{ "min-width": "10px" }}
                            >
                              후반
                            </div>
                          </th>
                          {competition[0] &&
                            competition[0].overtime[0] +
                            competition[0].overtime[1] !==
                            0 &&
                            competition[0].overtime[0] !== null && (
                              <th>
                                <div
                                  className="hideextra"
                                  style={{ "min-width": "10px" }}
                                >
                                  연장
                                </div>
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <tr key={competition[0] && competition[0]._id}>
                          <td>
                            {competition[0] && competition[0].vsTeamName[0]}{" "}
                          </td>
                          <td>
                            {competition[0] &&
                              competition[0].quarter &&
                              competition[0].quarter[1][0] < 0
                              ? "N/A"
                              : competition[0] && competition[0].quarter[1][0]}
                          </td>
                          <td>
                            {competition[0] &&
                              competition[0].quarter &&
                              competition[0].quarter[3][0] < 0
                              ? "N/A"
                              : competition[0] && competition[0].quarter[3][0]}
                          </td>
                          {competition[0] &&
                            competition[0].overtime[0] +
                            competition[0].overtime[1] !==
                            0 && (
                              <td>
                                {competition[0] && competition[0].overtime[0]}
                              </td>
                            )}
                        </tr>
                        <tr key={competition[0] && competition[0]._id}>
                          <td>
                            {competition[0] && competition[0].vsTeamName[1]}{" "}
                          </td>
                          <td>
                            {competition[0] &&
                              competition[0].quarter &&
                              competition[0].quarter[1][1] < 0
                              ? "N/A"
                              : competition[0] && competition[0].quarter[1][1]}
                          </td>
                          <td>
                            {competition[0] &&
                              competition[0].quarter &&
                              competition[0].quarter[3][1] < 0
                              ? "N/A"
                              : competition[0] && competition[0].quarter[3][1]}
                          </td>
                          {competition[0] &&
                            competition[0].overtime[0] +
                            competition[0].overtime[1] !==
                            0 && (
                              <td>
                                {competition[0] && competition[0].overtime[1]}
                              </td>
                            )}
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <Table responsive className="table-sm text-center">
                      <thead>
                        <tr
                          style={{
                            color: "white",
                            "background-color": "#031b31",
                          }}
                        >
                          <th>팀</th>
                          {competition[0] &&
                            competition[0].quarter.length === 2 && (
                              <>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    전반
                                  </div>
                                </th>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    후반
                                  </div>
                                </th>
                              </>
                            )}
                          {competition[0] &&
                            competition[0].quarter.length === 4 && (
                              <>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    1Q
                                  </div>
                                </th>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    2Q
                                  </div>
                                </th>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    3Q
                                  </div>
                                </th>
                                <th>
                                  <div
                                    className="hideextra"
                                    style={{ "min-width": "10px" }}
                                  >
                                    4Q
                                  </div>
                                </th>
                              </>
                            )}

                          {competition[0] &&
                            competition[0].overtime[0] +
                            competition[0].overtime[1] !==
                            0 &&
                            competition[0].overtime[0] !== null && (
                              <th>
                                <div
                                  className="hideextra"
                                  style={{ "min-width": "10px" }}
                                >
                                  연장
                                </div>
                              </th>
                            )}
                        </tr>
                      </thead>
                      {competition[0] &&
                        competition[0].quarter.length === 2 && (
                          <tbody
                            style={{
                              border: "1px solid #D2D2D2",
                            }}
                          >
                            <tr key={competition[0] && competition[0]._id}>
                              <td>
                                {competition[0] && competition[0].vsTeamName[0]}{" "}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[0][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[0][0]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[1][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[1][0]}
                              </td>
                              {competition[0] &&
                                competition[0].overtime[0] +
                                competition[0].overtime[1] !==
                                0 && (
                                  <td>
                                    {competition[0] &&
                                      competition[0].overtime[0]}
                                  </td>
                                )}
                            </tr>
                            <tr key={competition[0] && competition[0]._id}>
                              <td>
                                {competition[0] && competition[0].vsTeamName[1]}{" "}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[0][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[0][1]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[1][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[1][1]}
                              </td>
                              {competition[0] &&
                                competition[0].overtime[0] +
                                competition[0].overtime[1] !==
                                0 && (
                                  <td>
                                    {competition[0] &&
                                      competition[0].overtime[1]}
                                  </td>
                                )}
                            </tr>
                          </tbody>
                        )}
                      {competition[0] &&
                        competition[0].quarter.length === 4 && (
                          <tbody
                            style={{
                              border: "1px solid #D2D2D2",
                            }}
                          >
                            <tr key={competition[0] && competition[0]._id}>
                              <td>
                                {competition[0] && competition[0].vsTeamName[0]}{" "}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[0][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[0][0]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[1][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[1][0]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[2][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[2][0]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[3][0] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[3][0]}
                              </td>
                              {competition[0] &&
                                competition[0].overtime[0] +
                                competition[0].overtime[1] !==
                                0 && (
                                  <td>
                                    {competition[0] &&
                                      competition[0].overtime[0]}
                                  </td>
                                )}
                            </tr>
                            <tr key={competition[0] && competition[0]._id}>
                              <td>
                                {competition[0] && competition[0].vsTeamName[1]}{" "}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[0][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[0][1]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[1][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[1][1]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[2][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[2][1]}
                              </td>
                              <td>
                                {competition[0] &&
                                  competition[0].quarter &&
                                  competition[0].quarter[3][1] < 0
                                  ? "N/A"
                                  : competition[0] &&
                                  competition[0].quarter[3][1]}
                              </td>
                              {competition[0] &&
                                competition[0].overtime[0] +
                                competition[0].overtime[1] !==
                                0 && (
                                  <td>
                                    {competition[0] &&
                                      competition[0].overtime[1]}
                                  </td>
                                )}
                            </tr>
                          </tbody>
                        )}
                    </Table>
                  )}
                  </>)}
                  {/* <Table responsive className="table-sm text-center">
                    <thead>
                      <tr
                        style={{
                          color: "white",
                          "background-color": "#031b31",
                        }}
                      >
                        <th>팀</th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            1Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            2Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            3Q
                          </div>
                        </th>
                        <th>
                          <div
                            className="hideextra"
                            style={{ "min-width": "10px" }}
                          >
                            4Q
                          </div>
                        </th>
                        {competition[0] &&
                          competition[0].overtime[0] +
                          competition[0].overtime[1] !==
                          0 &&
                          competition[0].overtime[0] !== null && (
                            <th>
                              <div
                                className="hideextra"
                                style={{ "min-width": "10px" }}
                              >
                                연장
                              </div>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        border: "1px solid #D2D2D2",
                      }}
                    >
                      <tr key={competition[0] && competition[0]._id}>
                        <td>
                          {competition[0] && competition[0].vsTeamName[0]}{" "}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[0][0] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[0][0]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[1][0] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[1][0]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[2][0] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[2][0]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[3][0] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[3][0]}
                        </td>
                        {competition[0] &&
                          competition[0].overtime[0] +
                          competition[0].overtime[1] !==
                          0 && (
                            <td>
                              {competition[0] && competition[0].overtime[0]}
                            </td>
                          )}
                      </tr>
                      <tr key={competition[0] && competition[0]._id}>
                        <td>
                          {competition[0] && competition[0].vsTeamName[1]}{" "}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[0][1] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[0][1]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[1][1] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[1][1]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[2][1] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[2][1]}
                        </td>
                        <td>
                          {competition[0] &&
                            competition[0].quarter &&
                            competition[0].quarter[3][1] < 0
                            ? "N/A"
                            : competition[0] && competition[0].quarter[3][1]}
                        </td>
                        {competition[0] &&
                          competition[0].overtime[0] +
                          competition[0].overtime[1] !==
                          0 && (
                            <td>
                              {competition[0] && competition[0].overtime[1]}
                            </td>
                          )}
                      </tr>
                    </tbody>
                  </Table> */}
                </Col>
                <Col md={2}></Col>
              </Row>
              <span className="on__pc__gameDetails">
                <Row className="mt-5">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    {isThree ? "1점" : "2점"}슛 성공
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                    />
                  </Col>
                </Row>
                {!isThree && (<>
                  <Row className="mt-3">
                    <Col md={5}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].fieldGoal[1] /
                              result.playerRecord[0].fieldGoal[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        className="right"
                        variant="danger"
                        label={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].fieldGoal[1] /
                              result.playerRecord[0].fieldGoal[0]) *
                            100
                          )
                        }
                      />
                    </Col>
                    <Col md={2} className="text-center">
                      2점슛 성공률
                    </Col>
                    <Col md={5}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].fieldGoal[1] /
                              result.playerRecord[1].fieldGoal[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        variant="success"
                        label={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].fieldGoal[1] /
                              result.playerRecord[1].fieldGoal[0]) *
                            100
                          )
                        }
                      />
                    </Col>
                  </Row>
                </>)}
                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    {isThree ? "2점" : "3점"}슛 성공
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                    />
                  </Col>
                </Row>
                {!isThree && (<>
                  <Row className="mt-3">
                    <Col md={5}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].threePointThrow[1] /
                              result.playerRecord[0].threePointThrow[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        className="right"
                        variant="danger"
                        label={
                          result.playerRecord &&
                            result.playerRecord[0].threePointThrow[0] === 0
                            ? 0
                            : Math.round(
                              result.playerRecord &&
                              (result.playerRecord[0].threePointThrow[1] /
                                result.playerRecord[0].threePointThrow[0]) *
                              100
                            )
                        }
                      />
                    </Col>
                    <Col md={2} className="text-center">
                      3점슛 성공률
                    </Col>
                    <Col md={5}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].threePointThrow[1] /
                              result.playerRecord[1].threePointThrow[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        variant="success"
                        label={
                          result.playerRecord &&
                            result.playerRecord[0].threePointThrow[0] === 0
                            ? 0
                            : Math.round(
                              result.playerRecord &&
                              (result.playerRecord[1].threePointThrow[1] /
                                result.playerRecord[1].threePointThrow[0]) *
                              100
                            )
                        }
                      />
                    </Col>
                  </Row>
                </>)}
                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    리바운드
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].assist}
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].assist
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    어시스트
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].assist}
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].assist
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].steal}
                      max={22}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].steal
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    스틸
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].steal}
                      max={22}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].steal
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].block}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].block
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    블록슛
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].block}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].block
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                        result.playerRecord[0].foul[1]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                        result.playerRecord[0].foul[1]
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    파울
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                        result.playerRecord[1].foul[1]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                        result.playerRecord[1].foul[1]
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        (result.playerRecord[0].turnOver < 0
                          ? 0
                          : result.playerRecord[0].turnOver)
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                    />
                  </Col>
                  <Col md={2} className="text-center">
                    턴오버
                  </Col>
                  <Col md={5}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        (result.playerRecord[1].turnOver < 0
                          ? 0
                          : result.playerRecord[1].turnOver)
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                    />
                  </Col>
                </Row>
              </span>

              <span className="on__mobile__gameDetails">
                <hr />
                <h2 className="mt-3 mb-3">팀 간 결과비교</h2>
                {isThree ? "1점" : "2점"} 성공
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].fieldGoal[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                      max={40}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].fieldGoal[1]
                      }
                    />
                  </div>
                </div>
                {!isThree && (<>
                  2점슛 성공률
                  <div className="detailGame__graph__justify__content">
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].fieldGoal[1] /
                              result.playerRecord[0].fieldGoal[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        className="right"
                        variant="danger"
                        label={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].fieldGoal[1] /
                              result.playerRecord[0].fieldGoal[0]) *
                            100
                          )
                        }
                      />
                    </div>
                    <div style={{ width: "20px" }}></div>
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].fieldGoal[1] /
                              result.playerRecord[1].fieldGoal[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        variant="success"
                        label={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].fieldGoal[1] /
                              result.playerRecord[1].fieldGoal[0]) *
                            100
                          )
                        }
                      />
                    </div>
                  </div>
                </>)}
                {isThree ? "2점" : "3점"}슛 성공
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].threePointThrow[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].threePointThrow[1]
                      }
                    />
                  </div>
                </div>
                {!isThree && (<>
                  3점슛 성공률
                  <div className="detailGame__graph__justify__content">
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[0].threePointThrow[1] /
                              result.playerRecord[0].threePointThrow[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        className="right"
                        variant="danger"
                        label={
                          result.playerRecord &&
                            result.playerRecord[0].threePointThrow[0] === 0
                            ? 0
                            : Math.round(
                              result.playerRecord &&
                              (result.playerRecord[0].threePointThrow[1] /
                                result.playerRecord[0].threePointThrow[0]) *
                              100
                            )
                        }
                      />
                    </div>
                    <div style={{ width: "20px" }}></div>
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        now={
                          result.playerRecord &&
                          Math.round(
                            (result.playerRecord[1].threePointThrow[1] /
                              result.playerRecord[1].threePointThrow[0]) *
                            100
                          )
                        }
                        max={100}
                        min={0}
                        variant="success"
                        label={
                          result.playerRecord &&
                            result.playerRecord[0].threePointThrow[0] === 0
                            ? 0
                            : Math.round(
                              result.playerRecord &&
                              (result.playerRecord[1].threePointThrow[1] /
                                result.playerRecord[1].threePointThrow[0]) *
                              100
                            )
                        }
                      />
                    </div>
                  </div>
                </>)}
                리바운드
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].rebound[0]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].rebound[0]
                      }
                    />
                  </div>
                </div>
                어시스트
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].assist}
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].assist
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].assist}
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].assist
                      }
                    />
                  </div>
                </div>
                스틸
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].steal}
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].steal
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].steal}
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].steal
                      }
                    />
                  </div>
                </div>
                블록슛
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[0].block}
                      max={15}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].block
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={result.playerRecord && result.playerRecord[1].block}
                      max={15}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].block
                      }
                    />
                  </div>
                </div>
                파울
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                        result.playerRecord[0].foul[1]
                      }
                      max={30}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord &&
                        result.playerRecord[0].foul[0] +
                        result.playerRecord[0].foul[1]
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                        result.playerRecord[1].foul[1]
                      }
                      max={30}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord &&
                        result.playerRecord[1].foul[0] +
                        result.playerRecord[1].foul[1]
                      }
                    />
                  </div>
                </div>
                턴오버
                <div className="detailGame__graph__justify__content">
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                      max={20}
                      min={0}
                      className="right"
                      variant="danger"
                      label={
                        result.playerRecord && result.playerRecord[0].turnOver
                      }
                    />
                  </div>
                  <div style={{ width: "20px" }}></div>
                  <div style={{ width: "100%" }}>
                    <ProgressBar
                      now={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                      max={20}
                      min={0}
                      variant="success"
                      label={
                        result.playerRecord && result.playerRecord[1].turnOver
                      }
                    />
                  </div>
                </div>
              </span>
            </Row>
          )}
          <hr />
          <h2 className="mt-3 text-center">경기 상세 기록</h2>
          <p
            className="text-center"
            style={{ color: "#011241", "font-weight": "600" }}
          >
            선수명을 클릭하면, 개인기록 조회가 가능합니다.
          </p>
          <h3 className="mt-4">
            {competition[0] && competition[0].vsTeamName[0]} 기록
          </h3>
          <Table
            striped
            hover
            responsive
            className="table-sm text-center table__padding__sm"
          >
            <thead>
              <tr className="table__header">
                <th>
                  <div
                    className="hideextra"
                    style={{
                      width: "35px",
                    }}
                  >
                    🏆️
                  </div>
                </th>
                <th
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "2",
                    "background-color": "#031b31",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "50px",
                      textAlign: "left",
                    }}
                  >
                    이름
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    득점
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "1점"
                      :
                      <>2점<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "40px",
                      }}
                    >
                      야투율
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "2점"
                      :
                      <>3점<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "30px",
                      }}
                    >
                      3점<br />성공률
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "자유투"
                      :
                      <>자유투<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "35px",
                      }}
                    >
                      자유투<br />성공률
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    공격
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    수비
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    총<br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    어시스트
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    스틸
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    블락
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    턴오버
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    파울
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {competition[0] &&
                competition[0].playerRecord[0].map((player) => (
                  <tr key={player._id}>
                    <td style={{ padding: 0, width: "30px" }}>
                      {bestScorePlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            득점
                          </p>
                        </span>
                      )}
                      {/* {bestAssistPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            어시
                          </p>
                        </span>
                      )}
                      {bestStealPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            스틸
                          </p>
                        </span>
                      )}
                      {bestReboundPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            리바
                          </p>
                        </span>
                      )}
                      {bestBlockPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            블락
                          </p>
                        </span>
                      )} */}
                      {player.isMVP && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏆</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            MVP
                          </p>
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "1",
                        "background-color": "white",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        style={{
                          margin: "auto",
                          textAlign: "left",
                        }}
                      >
                        <PlayerName key={player._id} id={player.playerId} />
                        {player.jerseyNum ? (
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#031b31",
                              fontWeight: "700",
                            }}
                          >
                            {player.jerseyNum}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>{player.score}</td>
                    <td>
                      {isThree ?
                      player.fieldGoal[1]
                      :
                      <>{player.fieldGoal[1]} - {player.fieldGoal[0]}</>
                    }
                    </td>
                    {!isThree && (
                      <td>
                        {player.fieldGoal[1] === 0
                          ? 0
                          : Math.ceil(
                            (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                          )}
                        %
                      </td>
                    )}
                    <td>
                    {isThree ?
                      player.threePointThrow[1]
                      :
                      <>{player.threePointThrow[1]} - {player.threePointThrow[0]}</>
                    }
                    </td>
                    {!isThree && (
                    <td>
                      {player.threePointThrow[1] === 0
                        ? 0
                        : Math.ceil(
                          (player.threePointThrow[1] /
                            player.threePointThrow[0]) *
                          100
                        )}
                      %
                    </td>
                    )}
                    <td>
                    {isThree ?
                      player.freeThrow[1]
                      :
                      <>{player.freeThrow[1]} - {player.freeThrow[0]}</>
                    }
                    </td>
                    {!isThree && (
                    <td>
                      {player.freeThrow[1] === 0
                        ? 0
                        : Math.ceil(
                          (player.freeThrow[1] / player.freeThrow[0]) * 100
                        )}
                      %
                    </td>
                    )}
                    <td>{player.rebound[0]}</td>
                    <td>{player.rebound[1]}</td>
                    <td>{player.rebound[0] + player.rebound[1]}</td>
                    <td>{player.assist}</td>
                    <td>{player.steal}</td>
                    <td>{player.block}</td>
                    <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                    <td>{player.foul[0] + player.foul[1]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <h3 className="mt-3">
            {competition[0] && competition[0].vsTeamName[1]} 기록
          </h3>
          <Table
            striped
            hover
            responsive
            className="table-sm text-center table__padding__sm"
          >
            <thead>
              <tr className="table__header">
                <th>
                  <div
                    className="hideextra"
                    style={{
                      width: "35px",
                    }}
                  >
                    🏆️
                  </div>
                </th>
                <th
                  style={{
                    position: "sticky",
                    left: "0",
                    "z-index": "2",
                    "background-color": "#031b31",
                  }}
                >
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "50px",
                      textAlign: "left",
                    }}
                  >
                    이름
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "30px",
                    }}
                  >
                    득점
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "1점"
                      :
                      <>2점<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "40px",
                      }}
                    >
                      야투율
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "2점"
                      :
                      <>3점<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "30px",
                      }}
                    >
                      3점<br />성공률
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    {isThree ?
                      "자유투"
                      :
                      <>자유투<br />성공-시도</>
                    }
                  </div>
                </th>
                {!isThree && (
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "35px",
                      }}
                    >
                      자유투<br />성공률
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    공격
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    수비
                    <br /> 리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    총<br />
                    리바운드
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "40px",
                    }}
                  >
                    어시스트
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    스틸
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    블락
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    턴오버
                  </div>
                </th>
                <th>
                  <div
                    className="hideextra"
                    style={{
                      "min-width": "25px",
                    }}
                  >
                    파울
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {competition[0] &&
                competition[0].playerRecord[1].map((player) => (
                  <tr key={player._id}>
                    <td style={{ padding: 0, width: "30px" }}>
                      {bestScorePlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            득점
                          </p>
                        </span>
                      )}
                      {/* {bestAssistPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            어시
                          </p>
                        </span>
                      )}
                      {bestStealPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            스틸
                          </p>
                        </span>
                      )}
                      {bestReboundPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            리바
                          </p>
                        </span>
                      )}
                      {bestBlockPlayerIdArray.includes(player.playerId) && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏅</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            블락
                          </p>
                        </span>
                      )} */}
                      {player.isMVP && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "20px", margin: 0 }}>🏆</p>
                          <p
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              marginTop: "-7px",
                              color: "#d63f04",
                              fontWeight: "700",
                            }}
                          >
                            MVP
                          </p>
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "1",
                        "background-color": "white",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div style={{ margin: "auto", textAlign: "left" }}>
                        <PlayerName key={player._id} id={player.playerId} />
                        {player.jerseyNum ? (
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#031b31",
                              fontWeight: "700",
                            }}
                          >
                            {player.jerseyNum}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>{player.score}</td>
                    <td>
                    {isThree ?
                      player.fieldGoal[1]
                      :
                      <>{player.fieldGoal[1]} - {player.fieldGoal[0]}</>
                    }
                    </td>
                    {!isThree && (
                      <td>
                        {player.fieldGoal[1] === 0
                          ? 0
                          : Math.ceil(
                            (player.fieldGoal[1] / player.fieldGoal[0]) * 100
                          )}
                        %
                      </td>
                    )}
                    <td>
                    {isThree ?
                      player.threePointThrow[1]
                      :
                      <>{player.threePointThrow[1]} - {player.threePointThrow[0]}</>
                    }
                    </td>
                    {!isThree && (
                    <td>
                      {player.threePointThrow[1] === 0
                        ? 0
                        : Math.ceil(
                          (player.threePointThrow[1] /
                            player.threePointThrow[0]) *
                          100
                        )}
                      %
                    </td>
                    )}
                    <td>
                    {isThree ?
                      player.freeThrow[1]
                      :
                      <>{player.freeThrow[1]} - {player.freeThrow[0]}</>
                    }
                    </td>
                    {!isThree && (
                    <td>
                      {player.freeThrow[1] === 0
                        ? 0
                        : Math.ceil(
                          (player.freeThrow[1] / player.freeThrow[0]) * 100
                        )}
                      %
                    </td>
                    )}
                    <td>{player.rebound[0]}</td>
                    <td>{player.rebound[1]}</td>
                    <td>{player.rebound[0] + player.rebound[1]}</td>
                    <td>{player.assist}</td>
                    <td>{player.steal}</td>
                    <td>{player.block}</td>
                    <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                    <td>{player.foul[0] + player.foul[1]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {competition[0] &&
            competition[0].image &&
            competition[0].image.length > 1 && (
              <div className="text-center mt-3">
                <Image
                  src={competition[0] && competition[0].image}
                  style={{ "max-width": "80%" }}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};

export default DetailGameRecordScreen;
