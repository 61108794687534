import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Table, FormCheck } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { competitionGameInput, competitionGameList, listLeagueRound } from "../actions/competitionActions";
import { listCompetition } from "../actions/competitionActions";
import {
  listTeams,
  teamDetailsInfo,
  teamDetailsInfo1,
} from "../actions/teamActions";
import { Row, Col } from "react-bootstrap";
import PlayerNameInput from "../components/PlayerNameInput";
import { accessControl } from "../utils/access";

export const DumbGameInput = ({ history }) => {
  // 경기일 하이픈 자동 생성
  const [gameDate, setGameDate] = useState(new Date().toISOString().substring(0, 10));
  const gameRef = useRef();

  const handleStart = (e) => {
    const value = gameRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    gameRef.current.value = result;
    setGameDate(e.target.value);
  };
  const [competitionId, setCompetitionId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [gamePlace, setGamePlace] = useState("");
  const [gameName, setGameName] = useState("");
  const [round, setRound] = useState("");

  let vsTeam = [];
  let gameResult = [];
  let quarter = [];
  let overtime = [];
  let vsTeamName = [];

  const [isThree, setIsThree] = useState(false);

  const [optionsRound, setOptionsRound] = useState([]);
  const [optionsPlace, setOptionsPlace] = useState([]);
  const [optionsType, setOptionsType] = useState([]);

  const [roundDisable, setRoundDisable] = useState(true);

  const [gameScoreA, setGameScoreA] = useState(0);
  const [gameScoreB, setGameScoreB] = useState(0);
  const [teamAObj, setTeamAObj] = useState("");
  const [teamBObj, setTeamBObj] = useState("");

  let gameResultA, gameResultB, vsTeamNameA, vsTeamNameB;

  const dispatch = useDispatch();

  const adminCompetitionGameInput = useSelector(
    (state) => state.adminCompetitionGameInput
  );
  const { loading, error } = adminCompetitionGameInput;

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamDetails1 = useSelector((state) => state.teamDetails1);
  const {
    loading: loadingTeamDetails1,
    error: errorTeamDetails1,
    team1,
  } = teamDetails1;

  const playerDetails = useSelector((state) => state.playerDetails);
  const {
    loading: loadingPlayerDetails,
    error: errorPlayerDetails,
    player,
  } = playerDetails;

  const competitionRoundInfo = useSelector((state) => state.competitionRoundInfo);
  const {
    loading: loadingRoundInfo,
    error: errorRoundInfo,
    roundInfo
  } = competitionRoundInfo;

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition1,
    error: errorCompetition1,
    competition,
  } = competitionGame;

  useEffect(() => {
    accessControl([99, 100, 101, 300], history);
    dispatch(listCompetition());
    dispatch(listTeams());
  }, [dispatch]);

  // 대회 설정
  // const optionsCompetition = [];``

  // competitions &&
  //   competitions
  //     .filter(competition => competition.status <= 1)
  //     .map((competition) =>
  //       optionsCompetition.push({
  //         value: competition._id,
  //         label: competition.name,
  //       })
  //     );

  const [optionsCompetition, setOptionsCompetition] = useState([]);
  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (competitions) {
      let tempOptionsCompetition = [];

      competitions
        .filter(competition => competition.status <= status)
        .map((competition) =>
          tempOptionsCompetition.push({
            value: competition._id,
            label: competition.name,
          })
        );
      setOptionsCompetition(tempOptionsCompetition);
    }
  }, [competitions, status]);

  useEffect(() => {
    if (roundInfo) {
      let roundOption = [];
      roundInfo.round.map((r) =>
        roundOption.push({
          value: r[1],
          label: r[1],
        })
      );
      roundOption.push({
        value: "",
        label: "직접입력",
      });
      setOptionsRound(roundOption);
    }
  }, [roundInfo]);

  useEffect(() => {
    if (competition) {
      let placeOption = [];
      competition.place.map((p) =>
        placeOption.push({
          value: p,
          label: p,
        })
      );
      setOptionsPlace(placeOption);
      setGamePlace(placeOption[0].value);
      setTeamType(competition.teamType);
    }
  }, [competition]);

  const optionsTeam = [];

  teams &&
    teams.map((team) =>
      optionsTeam.push({
        value: team._id,
        label: team.name,
      })
    );

  useEffect(() => {
    if (team)
      setTeamA(team && team.members);
  }, [team]);

  useEffect(() => {
    if (team1)
      setTeamB(team1 && team1.members);
  }, [team1]);

  const handlePlaceChange = (selected) => {
    setGamePlace(selected.value);
    let copy = [...optionsPlace];
    copy.splice(copy.indexOf(selected), 1);
    copy.unshift(selected);
    setOptionsPlace(copy);
  };

  const handleCompetitionChange = (selected) => {
    setCompetitionId(selected.value);
    dispatch(listLeagueRound(selected.value));
    dispatch(competitionGameList(selected.value));
  };

  useEffect(() => {
    if (competitionId !== "") {
      setIsThree(competitions.find((obj) => obj._id === competitionId).isThree);
    }
  }, [competitionId]);

  const handleRoundChange = (selected) => {
    if (selected.label === "직접입력") {
      setRoundDisable(false);
    } else {
      setRoundDisable(true);
    }
    setRound(selected.value);
  };

  const compareTotalScore = (t, totalScore) => {
    let gameScore = 0;
    if (t === "A") { gameScore = gameScoreA; }
    if (t === "B") { gameScore = gameScoreB; }

    if (Number(gameScore) === totalScore) {
      return (
        <>
          <div style={{ "color": "blue" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ "color": "red" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
            <br />
            총점이 일치하지 않습니다.
          </div>
        </>
      )
    }
  }

  const submitHandler = (e) => {
    let isRecordNotEmpty = true;
    let isTryLarger = true;
    let isTotalScoreCorrect = true;

    let invalidMemberRecord = [];
    let invalidMemberTry = [];
    let totalScore = [0, 0];
    let inCorrectScoreTeam = [];

    e.preventDefault();
    vsTeam = [null, null];
    vsTeamName = [document.getElementById(`vsTeamNameA`).value, document.getElementById(`vsTeamNameB`).value];
    gameResult = [document.getElementById(`gameResultA`).value, document.getElementById(`gameResultB`).value];
    vsTeamName = [document.getElementById(`vsTeamNameA`).value, document.getElementById(`vsTeamNameB`).value];

    quarter = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0]
    ];
    overtime = [0, 0];

    let playerRecord = [[], []];

    dispatch(
      competitionGameInput(
        gameDate,
        competitionId,
        teamType,
        gamePlace,
        gameName,
        round,
        vsTeam,
        gameResult,
        quarter,
        overtime,
        playerRecord,
        vsTeamName
      )
    );
  }

  return (
    <>
      <FormContainer>
        <h1>경기 기록 입력</h1>
        {error && (
          <Message variant="경기 등록에 실패하였습니다. 정보 확인 부탁드립니다."></Message>
        )}
        {loading && <Loader />}
        {loadingCompetition || loadingTeam ? (
          <Loader />
        ) : errorCompetition || errorTeam ? (
          <Message variant="danger">
            [오류] 대회 정보 또는 팀 정보를 불러오지 못했습니다. 개발자에게
            문의해주세요.
          </Message>
        ) : (
          <Form onSubmit={submitHandler} className="mt-3">
            <Form.Group controlId="birthday" className="mt-3">
              <Form.Label>경기 일자</Form.Label>
              <Form.Control
                type="gameDate"
                placeholder="예) 2022-01-01"
                ref={gameRef}
                value={gameDate}
                onChange={handleStart}
              ></Form.Control>
            </Form.Group>
            <Row>
              <Col md={9}>
                <Form.Group controlId="competitionId" className="mt-3">
                  <Form.Label>대회명</Form.Label>
                  <Select
                    options={optionsCompetition}
                    isSearchable
                    onChange={handleCompetitionChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex justify-content-center align-items-center">
                <Form.Group controlId="status" className="mt-3">
                  <Form.Label>종료된 대회 표시</Form.Label>
                  <FormCheck
                    type="checkbox"
                    id="status"
                    checked={status === 2}
                    onChange={(e) => setStatus(e.target.checked ? 2 : 1)}
                  ></FormCheck>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="gamePlace" className="mt-2">
                  <Form.Label>대회 장소</Form.Label>
                  <Select
                    options={optionsPlace}
                    onChange={handlePlaceChange}
                    value={optionsPlace[0]}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="gameName" className="mt-2">
                  <Form.Label>경기명</Form.Label>
                  <Form.Control
                    type="gameName"
                    placeholder="예) A1"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="competitionId" className="mt-2">
                  <Form.Label>라운드</Form.Label>
                  <Select
                    options={optionsRound}
                    onChange={handleRoundChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="round" className="mt-2">
                  <Form.Label>-</Form.Label>
                  <Form.Control
                    type="round"
                    value={round}
                    disabled={roundDisable}
                    onChange={(e) => setRound(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Form.Group controlId="teamIdA" className="mt-3">
              <Form.Label>홈 팀</Form.Label>
              <Row>
                <Col md={8}>
                  <Form.Control
                    id="vsTeamNameA"
                    value={vsTeamNameA}
                  ></Form.Control>
                </Col>
                <Col md={4}>
                  <Form.Control
                    id="gameResultA"
                    placeholder="총점 예) 50"
                    value={gameResultA}
                    onChange={(e) => setGameScoreA(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
            <hr />
            <Form.Group controlId="teamIdB" className="mt-3">
              <Form.Label>어웨이 팀</Form.Label>
              <Row>
                <Col md={8}>
                  <Form.Control
                    id="vsTeamNameB"
                    value={vsTeamNameB}
                  ></Form.Control>
                </Col>
                <Col md={4}>
                  <Form.Control
                    id="gameResultB"
                    placeholder="총점 예) 50"
                    value={gameResultB}
                    onChange={(e) => setGameScoreB(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        )}
      </FormContainer>
      <Button
        type="submit"
        variant="primary"
        className="mt-5"
        style={{ width: "100%" }}
        onClick={submitHandler}
      >
        경기 결과 등록
      </Button>
    </>
  );
};

export default DumbGameInput;
