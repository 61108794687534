import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Table, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  competitionGameUpdate,
  detailCompetitionInfo,
} from "../actions/competitionActions";
import FormContainer from "../components/FormContainer";
import {
  listTeams,
  teamDetailsInfo,
  teamDetailsInfo1,
} from "../actions/teamActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Select from "react-select";
import PlayerNameInput from "../components/PlayerNameInput";
import axios from "axios";
import {
  COMPETITION_DETAIL_INFO_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";

export const ModifyGameScreen = ({ match, history }) => {
  const gameId = match.params.id;

  useEffect(() => {
    const reloadFlag = sessionStorage.getItem("reloadFlag");
    if (reloadFlag === "false") {
      sessionStorage.setItem("reloadFlag", "true");
      window.location.reload();
    }
  }, []);

  // 입력 변수
  const [gameDate, setGameDate] = useState("");
  const gameRef = useRef();

  const handleStart = (e) => {
    const value = gameRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    gameRef.current.value = result;
    setGameDate(e.target.value);
  };

  const [competitionId, setCompetitionId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [gamePlace, setGamePlace] = useState("");
  const [gameNum, setGameNum] = useState("");
  const [gameName, setGameName] = useState("");
  const [round, setRound] = useState("");
  const [roundNum, setRoundNum] = useState("");
  const [teamIdA, setTeamIdA] = useState("");
  const [teamIdB, setTeamIdB] = useState("");
  const [playerList, setPlayerList] = useState([]);
  let vsTeam = [];
  let gameResult = [];
  const [gameResultA, setGameResultA] = useState();
  const [gameResultB, setGameResultB] = useState();
  const [quarterA1, setQuarterA1] = useState();
  const [quarterA2, setQuarterA2] = useState();
  const [quarterA3, setQuarterA3] = useState();
  const [quarterA4, setQuarterA4] = useState();
  const [quarterB1, setQuarterB1] = useState();
  const [quarterB2, setQuarterB2] = useState();
  const [quarterB3, setQuarterB3] = useState();
  const [quarterB4, setQuarterB4] = useState();
  let quarter = [];
  const [overtimeA, setOvertimeA] = useState(null);
  const [overtimeB, setOvertimeB] = useState(null);
  let overtime = [];
  const [image, setImage] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [videoId, setVideoId] = useState("");

  const [gameScoreA, setGameScoreA] = useState(0);
  const [gameScoreB, setGameScoreB] = useState(0);
  const [totalScoreA, setTotalScoreA] = useState(0);
  const [totalScoreB, setTotalScoreB] = useState(0);

  // dispatch
  const dispatch = useDispatch();

  const competitionDetailInfo = useSelector(
    (state) => state.competitionDetailInfo
  );
  const { loading, error, competitionDetail } = competitionDetailInfo;

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamDetails1 = useSelector((state) => state.teamDetails1);
  const {
    loading: loadingTeamDetails1,
    error: errorTeamDetails1,
    team1,
  } = teamDetails1;

  // 대회 설정
  const optionsCompetition = [];

  competitions &&
    competitions.map((competition) =>
      optionsCompetition.push({
        value: competition._id,
        label: competition.name,
      })
    );

  // 팀 리스트 선택
  const optionsTeam = [];

  teams &&
    teams.map((team) =>
      optionsTeam.push({
        value: team._id,
        label: team.name,
      })
    );

  // const memberLength_h = competitionDetail && competitionDetail.playerList[0].length > 0 ?
  //   competitionDetail && competitionDetail.playerList[0].length : team && team.members.length;

  // const memberLength_a = competitionDetail && competitionDetail.playerList[1].length > 0 ?
  //   competitionDetail && competitionDetail.playerList[1].length : team1 && team1.members.length;

  const memberLength_h = team && team.members.length;
  const memberLength_a = team1 && team1.members.length;

  let score_h = [],
    score_a = [];
  let fieldGoal_h = [],
    fieldGoal_a = [];
  let threePointThrow_h = [],
    threePointThrow_a = [];
  let freeThrow_h = [],
    freeThrow_a = [];
  let rebound_h = [],
    rebound_a = [];
  let steal_h = [],
    steal_a = [];
  let block_h = [],
    block_a = [];
  let turnOver_h = [],
    turnOver_a = [];
  let foul_h = [],
    foul_a = [];
  let assist_h = [],
    assist_a = [];
  let isMVP_h = [],
    isMVP_a = [];

  let score;
  let fieldGoal = [];
  let threePointThrow = [];
  let freeThrow = [];
  let rebound = [];
  let steal;
  let block;
  let turnOver;
  let foul = [];
  let assist;
  let isMVP;

  let memberRecord;

  const [isThree, setIsThree] = useState(false);

  const fieldGoalInput = isThree ? 1 : 2;
  const threePointThrowInput = isThree ? 2 : 3;

  const scoreChangeHandler = (e, length, t) => {
    let totalScore = 0;
    for (let i = 0; i < length; i++) {
      totalScore += document.getElementById(`score_${t}${i}`).value * 1;
    }
    if (t === "h") { setTotalScoreA(totalScore); }
    if (t === "a") { setTotalScoreB(totalScore); }
  }

  const quarterChangeHandler = (e, t) => {
    if (t === "A") {
      setQuarterA1(document.getElementById(`quarter${t}1`).value);
      setQuarterA2(document.getElementById(`quarter${t}2`).value);
      setQuarterA3(document.getElementById(`quarter${t}3`).value);
      setQuarterA4(document.getElementById(`quarter${t}4`).value);
      setOvertimeA(document.getElementById(`overtime${t}`).value);

      setGameScoreA(document.getElementById(`gameResult${t}`).value);
      setGameResultA(document.getElementById(`quarter${t}1`).value * 1 + document.getElementById(`quarter${t}2`).value * 1 + document.getElementById(`quarter${t}3`).value * 1 + document.getElementById(`quarter${t}4`).value * 1 + document.getElementById(`overtime${t}`).value * 1);
    }
    if (t === "B") {
      setQuarterB1(document.getElementById(`quarter${t}1`).value);
      setQuarterB2(document.getElementById(`quarter${t}2`).value);
      setQuarterB3(document.getElementById(`quarter${t}3`).value);
      setQuarterB4(document.getElementById(`quarter${t}4`).value);
      setOvertimeB(document.getElementById(`overtime${t}`).value);

      setGameScoreB(document.getElementById(`gameResult${t}`).value);
      setGameResultB(document.getElementById(`quarter${t}1`).value * 1 + document.getElementById(`quarter${t}2`).value * 1 + document.getElementById(`quarter${t}3`).value * 1 + document.getElementById(`quarter${t}4`).value * 1 + document.getElementById(`overtime${t}`).value * 1);
    }
  }

  const recordInputChangeHandler = (e, index, t, length) => {
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput + 1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput + 1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    scoreChangeHandler(e, length, t);
  };

  const handleTeamChangeA = (selected) => {
    setTeamIdA(selected.value);
    dispatch(teamDetailsInfo(selected.value));
  };

  const handleTeamChangeB = (selected) => {
    setTeamIdB(selected.value);
    dispatch(teamDetailsInfo1(selected.value));
  };

  const handleTeamType = (selected) => {
    setTeamType(selected.value);
  };

  const handleCompetitionChange = (selected) => {
    setCompetitionId(selected.value);
  };

  const typingScore2Handler = (e, index, t, length) => {
    if (e.target.value === "q" || e.target.value === "Q" || e.target.value === "ㅂ") {
      e.target.value = "";
      document.getElementById(`freeThrow0_${t}${index}`).focus();
      document.getElementById(`freeThrow0_${t}${index}`).select();
    }
    if (e.target.value === "w" || e.target.value === "W" || e.target.value === "ㅈ") {
      e.target.value = "";
      document.getElementById(`rebound0_${t}${index}`).focus();
      document.getElementById(`rebound0_${t}${index}`).select();
    }
    if (!(document.getElementById(`score_${t}${index}`).value ||
      document.getElementById(`fieldGoal0_${t}${index}`).value ||
      document.getElementById(`fieldGoal1_${t}${index}`).value ||
      document.getElementById(`threePointThrow0_${t}${index}`).value ||
      document.getElementById(`threePointThrow1_${t}${index}`).value ||
      document.getElementById(`freeThrow0_${t}${index}`).value ||
      document.getElementById(`freeThrow1_${t}${index}`).value ||
      document.getElementById(`rebound0_${t}${index}`).value ||
      document.getElementById(`rebound1_${t}${index}`).value ||
      document.getElementById(`steal_${t}${index}`).value ||
      document.getElementById(`block_${t}${index}`).value ||
      document.getElementById(`turnOver_${t}${index}`).value ||
      document.getElementById(`foul0_${t}${index}`).value ||
      // document.getElementById(`foul1_${t}${index}`).value ||
      document.getElementById(`assist_${t}${index}`).value)) {
      document.getElementById(`score_${t}${index}`).value = 0;
      document.getElementById(`fieldGoal0_${t}${index}`).value = 0;
      document.getElementById(`fieldGoal1_${t}${index}`).value = 0;
      document.getElementById(`threePointThrow0_${t}${index}`).value = 0;
      document.getElementById(`threePointThrow1_${t}${index}`).value = 0;
      document.getElementById(`freeThrow0_${t}${index}`).value = 0;
      document.getElementById(`freeThrow1_${t}${index}`).value = 0;
      document.getElementById(`rebound0_${t}${index}`).value = 0;
      document.getElementById(`rebound1_${t}${index}`).value = 0;
      document.getElementById(`steal_${t}${index}`).value = 0;
      document.getElementById(`block_${t}${index}`).value = 0;
      document.getElementById(`turnOver_${t}${index}`).value = 0;
      document.getElementById(`foul0_${t}${index}`).value = 0;
      // document.getElementById(`foul1_${t}${index}`).value = 0;
      document.getElementById(`assist_${t}${index}`).value = 0;
    }
    if (!(document.getElementById(`fieldGoal0_${t}${index}`).value && document.getElementById(`fieldGoal0_${t}${index}`).value)) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = 0
      document.getElementById(`fieldGoal1_${t}${index}`).value = 0
    }
    if (e.target.value == 1) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = Number(document.getElementById(`fieldGoal0_${t}${index}`).value) + 1;
    }
    if (e.target.value == 2) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = Number(document.getElementById(`fieldGoal0_${t}${index}`).value) + 1;
      document.getElementById(`fieldGoal1_${t}${index}`).value = Number(document.getElementById(`fieldGoal1_${t}${index}`).value) + 1;
    }
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput + 1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput + 1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    e.target.value = "";

    scoreChangeHandler(e, length, t);
  };
  const typingScore3Handler = (e, index, t, length) => {
    if (e.target.value === "q" || e.target.value === "Q" || e.target.value === "ㅂ") {
      e.target.value = "";
      document.getElementById(`freeThrow0_${t}${index}`).focus();
      document.getElementById(`freeThrow0_${t}${index}`).select();
    }
    if (e.target.value === "w" || e.target.value === "W" || e.target.value === "ㅈ") {
      e.target.value = "";
      document.getElementById(`rebound0_${t}${index}`).focus();
      document.getElementById(`rebound0_${t}${index}`).select();
    }
    if (!(document.getElementById(`threePointThrow0_${t}${index}`).value && document.getElementById(`threePointThrow0_${t}${index}`).value)) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = 0
      document.getElementById(`threePointThrow1_${t}${index}`).value = 0
    }
    if (e.target.value == 1) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = Number(document.getElementById(`threePointThrow0_${t}${index}`).value) + 1;
    }
    if (e.target.value == 3) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = Number(document.getElementById(`threePointThrow0_${t}${index}`).value) + 1;
      document.getElementById(`threePointThrow1_${t}${index}`).value = Number(document.getElementById(`threePointThrow1_${t}${index}`).value) + 1;
    }
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput + 1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput + 1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    e.target.value = "";

    scoreChangeHandler(e, length, t);
  };

  // 선수 이미지 등록
  const [uploading, setUploading] = useState(false);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload`,
        formData,
        config
      );

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const deleteFileHandler = async (key) => {
    try {
      await axios.delete(`${API_SERVER_URL}/api/upload/${key}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch({ type: COMPETITION_DETAIL_INFO_RESET });
  }, [dispatch]);

  useEffect(() => {
    accessControl([99, 100, 101], history);
    dispatch(listTeams());
    dispatch(teamDetailsInfo(competitionDetail && competitionDetail.vsTeam[0]));
    dispatch(
      teamDetailsInfo1(competitionDetail && competitionDetail.vsTeam[1])
    );
    if (!competitionDetail) {
      dispatch(detailCompetitionInfo(gameId));
    } else {
      setGameDate(
        competitionDetail && competitionDetail.gameDate.substring(0, 10)
      );
      setCompetitionId(competitionDetail && competitionDetail.competitionId);
      setTeamType(competitionDetail && competitionDetail.teamType);
      setGamePlace(competitionDetail && competitionDetail.gamePlace);
      setGameNum(competitionDetail && competitionDetail.gameNum);
      setGameName(competitionDetail && competitionDetail.gameName);
      setRound(competitionDetail && competitionDetail.round);
      setRoundNum(competitionDetail && competitionDetail.roundNum);
      setTeamIdA(competitionDetail && competitionDetail.vsTeam[0]);
      setTeamIdB(competitionDetail && competitionDetail.vsTeam[1]);
      setPlayerList(competitionDetail && competitionDetail.playerList);
      // vsTeam = competitionDetail && competitionDetail.vsTeam;
      // setGameResult(competitionDetail && competitionDetail.gameResult);
      setGameResultA(competitionDetail && competitionDetail.gameResult[0]);
      setGameResultB(competitionDetail && competitionDetail.gameResult[1]);
      setQuarterA1(
        competitionDetail && competitionDetail.quarter[0]
          ? competitionDetail && competitionDetail.quarter[0][0]
          : 0
      );
      setQuarterA2(
        competitionDetail && competitionDetail.quarter[1]
          ? competitionDetail && competitionDetail.quarter[1][0]
          : 0
      );
      setQuarterA3(
        competitionDetail && competitionDetail.quarter[2]
          ? competitionDetail && competitionDetail.quarter[2][0]
          : 0
      );
      setQuarterA4(
        competitionDetail && competitionDetail.quarter[3]
          ? competitionDetail && competitionDetail.quarter[3][0]
          : 0
      );
      setQuarterB1(
        competitionDetail && competitionDetail.quarter[0]
          ? competitionDetail && competitionDetail.quarter[0][1]
          : 0
      );
      setQuarterB2(
        competitionDetail && competitionDetail.quarter[1]
          ? competitionDetail && competitionDetail.quarter[1][1]
          : 0
      );
      setQuarterB3(
        competitionDetail && competitionDetail.quarter[2]
          ? competitionDetail && competitionDetail.quarter[2][1]
          : 0
      );
      setQuarterB4(
        competitionDetail && competitionDetail.quarter[3]
          ? competitionDetail && competitionDetail.quarter[3][1]
          : 0
      );
      // quarter = competitionDetail && competitionDetail.quarter;
      setOvertimeA(competitionDetail && competitionDetail.overtime[0]);
      setOvertimeB(competitionDetail && competitionDetail.overtime[1]);
      setImage(
        (competitionDetail && competitionDetail.image) === undefined
          ? ""
          : competitionDetail && competitionDetail.image
      );
      setOriginalImage(
        (competitionDetail && competitionDetail.image) === undefined
          ? ""
          : competitionDetail && competitionDetail.image
      );
      setVideoId(competitionDetail && competitionDetail.videoId);
    }
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, gameId, competitionDetail]);

  const compareTotalScore = (t, totalScore) => {
    let gameScore = 0;
    if (t === "A") { gameScore = gameScoreA; }
    if (t === "B") { gameScore = gameScoreB; }

    if (Number(gameScore) === totalScore) {
      return (
        <>
          <div style={{ "color": "blue" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ "color": "red" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
            <br />
            총점이 일치하지 않습니다.
          </div>
        </>
      )
    }
  }

  const isNumeric = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  const filterText = (e) => {
    if (!isNumeric(e.target.value)) {
      if (e.target.value.toString().length > 1) {
        e.target.value = e.target.value.slice(0, -1);
      } else {
        e.target.value = "";
      }
    }
  }

  const submitHandler = (e) => {
    let isRecordValid = true;
    let invalidMember = [];
    let MVPCnt = 0;

    e.preventDefault();
    vsTeam = [teamIdA, teamIdB];
    gameResult = [parseInt(gameResultA), parseInt(gameResultB)];
    quarter = [
      [parseInt(quarterA1), parseInt(quarterB1)],
      [parseInt(quarterA2), parseInt(quarterB2)],
      [parseInt(quarterA3), parseInt(quarterB3)],
      [parseInt(quarterA4), parseInt(quarterB4)],
    ];
    overtime = [parseInt(overtimeA), parseInt(overtimeB)];
    let recordIndex;
    let playerRecord = [[], []];
    for (recordIndex = 0; recordIndex < memberLength_h; recordIndex++) {
      if (document.getElementById(`score_h${recordIndex}`).value) {
        score_h = document.getElementById(`score_h${recordIndex}`).value;
        fieldGoal_h = [
          document.getElementById(`fieldGoal0_h${recordIndex}`).value,
          document.getElementById(`fieldGoal1_h${recordIndex}`).value,
        ];
        threePointThrow_h = [
          document.getElementById(`threePointThrow0_h${recordIndex}`).value,
          document.getElementById(`threePointThrow1_h${recordIndex}`).value,
        ];
        freeThrow_h = [
          document.getElementById(`freeThrow0_h${recordIndex}`).value,
          document.getElementById(`freeThrow1_h${recordIndex}`).value,
        ];
        rebound_h = [
          document.getElementById(`rebound0_h${recordIndex}`).value,
          document.getElementById(`rebound1_h${recordIndex}`).value,
        ];
        steal_h = document.getElementById(`steal_h${recordIndex}`).value;
        block_h = document.getElementById(`block_h${recordIndex}`).value;
        turnOver_h = document.getElementById(`turnOver_h${recordIndex}`).value;
        // foul_h = [
        //   document.getElementById(`foul0_h${recordIndex}`).value,
        //   document.getElementById(`foul1_h${recordIndex}`).value,
        // ];
        foul_h = [document.getElementById(`foul0_h${recordIndex}`).value, 0];
        assist_h = document.getElementById(`assist_h${recordIndex}`).value;
        isMVP_h = document.getElementById(`isMVP_h${recordIndex}`)
          ? document.getElementById(`isMVP_h${recordIndex}`).checked
          : false;
        if (isMVP_h) {
          MVPCnt++;
        }

        if (
          [
            score_h,
            fieldGoal_h[0],
            fieldGoal_h[1],
            threePointThrow_h[0],
            threePointThrow_h[1],
            freeThrow_h[0],
            freeThrow_h[1],
            rebound_h[0],
            rebound_h[1],
            steal_h,
            block_h,
            turnOver_h,
            foul_h[0],
            foul_h[1],
            assist_h,
            isMVP_h,
          ].includes("")
        ) {
          isRecordValid = false;
          invalidMember.push(team.memberInfo[recordIndex].name);
        }

        playerRecord[0].push({
          playerId: team.members[recordIndex],
          score: Number(score_h),
          fieldGoal: [Number(fieldGoal_h[0]), Number(fieldGoal_h[1])],
          threePointThrow: [
            Number(threePointThrow_h[0]),
            Number(threePointThrow_h[1]),
          ],
          freeThrow: [Number(freeThrow_h[0]), Number(freeThrow_h[1])],
          rebound: [Number(rebound_h[0]), Number(rebound_h[1])],
          steal: Number(steal_h),
          block: Number(block_h),
          turnOver: Number(turnOver_h),
          foul: [Number(foul_h[0]), Number(foul_h[1])],
          assist: Number(assist_h),
          isMVP: isMVP_h,
          jerseyNum: team.memberInfo[recordIndex].jerseyNum,
        });
      }
    }
    for (recordIndex = 0; recordIndex < memberLength_a; recordIndex++) {
      if (document.getElementById(`score_a${recordIndex}`).value) {
        score_a = document.getElementById(`score_a${recordIndex}`).value;
        fieldGoal_a = [
          document.getElementById(`fieldGoal0_a${recordIndex}`).value,
          document.getElementById(`fieldGoal1_a${recordIndex}`).value,
        ];
        threePointThrow_a = [
          document.getElementById(`threePointThrow0_a${recordIndex}`).value,
          document.getElementById(`threePointThrow1_a${recordIndex}`).value,
        ];
        freeThrow_a = [
          document.getElementById(`freeThrow0_a${recordIndex}`).value,
          document.getElementById(`freeThrow1_a${recordIndex}`).value,
        ];
        rebound_a = [
          document.getElementById(`rebound0_a${recordIndex}`).value,
          document.getElementById(`rebound1_a${recordIndex}`).value,
        ];
        steal_a = document.getElementById(`steal_a${recordIndex}`).value;
        block_a = document.getElementById(`block_a${recordIndex}`).value;
        // turnOver_a = document.getElementById(`turnOver_a${recordIndex}`).value;
        // foul_a = [
        //   document.getElementById(`foul0_a${recordIndex}`).value,
        //   document.getElementById(`foul1_a${recordIndex}`).value,
        // ];
        turnOver_a = document.getElementById(`turnOver_a${recordIndex}`).value;
        foul_a = [document.getElementById(`foul0_a${recordIndex}`).value, 0];
        assist_a = document.getElementById(`assist_a${recordIndex}`).value;
        isMVP_a = document.getElementById(`isMVP_a${recordIndex}`)
          ? document.getElementById(`isMVP_a${recordIndex}`).checked
          : false;
        if (isMVP_a) {
          MVPCnt++;
        }

        if (
          [
            score_a,
            fieldGoal_a[0],
            fieldGoal_a[1],
            threePointThrow_a[0],
            threePointThrow_a[1],
            freeThrow_a[0],
            freeThrow_a[1],
            rebound_a[0],
            rebound_a[1],
            steal_a,
            block_a,
            turnOver_a,
            foul_a[0],
            foul_a[1],
            assist_a,
            isMVP_a,
          ].includes("")
        ) {
          isRecordValid = false;
          invalidMember.push(team1.memberInfo[recordIndex].name);
        }

        playerRecord[1].push({
          playerId: team1.members[recordIndex],
          score: Number(score_a),
          fieldGoal: [Number(fieldGoal_a[0]), Number(fieldGoal_a[1])],
          threePointThrow: [
            Number(threePointThrow_a[0]),
            Number(threePointThrow_a[1]),
          ],
          freeThrow: [Number(freeThrow_a[0]), Number(freeThrow_a[1])],
          rebound: [Number(rebound_a[0]), Number(rebound_a[1])],
          steal: Number(steal_a),
          block: Number(block_a),
          turnOver: Number(turnOver_a),
          foul: [Number(foul_a[0]), Number(foul_a[1])],
          assist: Number(assist_a),
          isMVP: isMVP_a,
          jerseyNum: team1.memberInfo[recordIndex].jerseyNum,
        });
      }
    }
    if (MVPCnt > 1) {
      isRecordValid = false;
      alert("MVP는 한 명만 지정할 수 있습니다.");
    } else if (isRecordValid) {
      if (originalImage !== "" && (image === "" || image !== originalImage)) {
        let key = originalImage.match(/\/([^\/]+)$/)
          ? originalImage.match(/\/([^\/]+)$/)[1]
          : "";
        if (key !== "") {
          deleteFileHandler(key);
        }
      }

      // if (competitionDetailInfo && competitionDetailInfo.vsTeam[0] !== teamIdA) {
      //   setPlayerList([[], []]);
      // }

      setPlayerList([[], []]);

      dispatch(
        competitionGameUpdate({
          _id: gameId,
          gameDate,
          competitionId,
          teamType,
          gamePlace,
          gameNum,
          gameName,
          round,
          roundNum,
          vsTeam,
          gameResult,
          quarter,
          overtime,
          playerRecord,
          videoId,
          image,
          playerList,
        })
      );
    } else {
      alert(`'${invalidMember}' 선수의 기록이 정확하지 않습니다.`);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <FormContainer>
            <h1>경기 기록 수정</h1>
            {error && (
              <Message variant="경기 등록에 실패하였습니다. 정보 확인 부탁드립니다."></Message>
            )}
            {loading && <Loader />}
            {loadingCompetition || loadingTeam ? (
              <Loader />
            ) : errorCompetition || errorTeam ? (
              <Message variant="danger">
                [오류] 대회 정보 또는 팀 정보를 불러오지 못했습니다. 개발자에게
                문의해주세요.
              </Message>
            ) : (
              <Form onSubmit={submitHandler} className="mt-3">
                <Form.Group controlId="birthday" className="mt-3">
                  <Form.Label>경기 일자</Form.Label>
                  <Form.Control
                    type="gameDate"
                    placeholder="예) 2022-01-01"
                    ref={gameRef}
                    value={gameDate}
                    onChange={handleStart}
                  ></Form.Control>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="gamePlace" className="mt-2">
                      <Form.Label>대회 장소</Form.Label>
                      <Form.Control
                        type="gamePlace"
                        placeholder="예) 와동체육관"
                        value={gamePlace}
                        onChange={(e) => setGamePlace(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group controlId="gameNum" className="mt-2">
                      <Form.Label>경기 순번</Form.Label>
                      <Form.Control
                        type="gameNum"
                        placeholder="예) 3"
                        value={gameNum}
                        onChange={(e) => setGameNum(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col> */}
                  <Col>
                    <Form.Group controlId="gameName" className="mt-2">
                      <Form.Label>경기명</Form.Label>
                      <Form.Control
                        type="gameName"
                        placeholder="예) A1"
                        value={gameName}
                        onChange={(e) => setGameName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group controlId="round" className="mt-2">
                      <Form.Label>라운드</Form.Label>
                      <Form.Control
                        type="round"
                        placeholder="예) 1"
                        value={round}
                        onChange={(e) => setRound(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="roundNum" className="mt-2">
                      <Form.Label>라운드 순번</Form.Label>
                      <Form.Control
                        type="roundNum"
                        placeholder="예) 1"
                        value={roundNum}
                        onChange={(e) => setRoundNum(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col> */}
                </Row>
                <hr />
                <Form.Group controlId="teamIdA" className="mt-3">
                  <Form.Label>홈 팀</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Select
                        options={optionsTeam}
                        isSearchable
                        onChange={handleTeamChangeA}
                        defaultValue={{
                          label:
                            competitionDetail &&
                            competitionDetail.vsTeamName[0],
                          value:
                            competitionDetail && competitionDetail.vsTeam[0],
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        id="gameResultA"
                        placeholder="총점 예) 50"
                        value={gameResultA}
                        onChange={(e) => setGameResultA(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="quarterA1" className="mt-2">
                        <Form.Label>1쿼터</Form.Label>
                        <Form.Control
                          id="quarterA1"
                          placeholder="예) 10"
                          value={quarterA1}
                          onChange={(e) => quarterChangeHandler(e, "A")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterA2" className="mt-2">
                        <Form.Label>2쿼터</Form.Label>
                        <Form.Control
                          id="quarterA2"
                          placeholder="예) 10"
                          value={quarterA2}
                          onChange={(e) => quarterChangeHandler(e, "A")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterA3" className="mt-2">
                        <Form.Label>3쿼터</Form.Label>
                        <Form.Control
                          id="quarterA3"
                          placeholder="예) 10"
                          value={quarterA3}
                          onChange={(e) => quarterChangeHandler(e, "A")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterA4" className="mt-2">
                        <Form.Label>4쿼터</Form.Label>
                        <Form.Control
                          id="quarterA4"
                          placeholder="예) 10"
                          value={quarterA4}
                          onChange={(e) => quarterChangeHandler(e, "A")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="overtimeA" className="mt-2">
                        <Form.Label>연장전</Form.Label>
                        <Form.Control
                          id="overtimeA"
                          placeholder="예) 없었다면 0 입력"
                          value={overtimeA}
                          onChange={(e) => quarterChangeHandler(e, "A")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
                <hr />
                <Form.Group controlId="teamIdB" className="mt-3">
                  <Form.Label>어웨이 팀</Form.Label>
                  <Row>
                    <Col md={8}>
                      <Select
                        options={optionsTeam}
                        isSearchable
                        onChange={handleTeamChangeB}
                        defaultValue={{
                          label:
                            competitionDetail &&
                            competitionDetail.vsTeamName[1],
                          value:
                            competitionDetail && competitionDetail.vsTeam[1],
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        id="gameResultB"
                        placeholder="총점 예) 50"
                        value={gameResultB}
                        onChange={(e) => setGameResultB(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="quarterB1" className="mt-2">
                        <Form.Label>1쿼터</Form.Label>
                        <Form.Control
                          id="quarterB1"
                          placeholder="예) 10"
                          value={quarterB1}
                          onChange={(e) => quarterChangeHandler(e, "B")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterB2" className="mt-2">
                        <Form.Label>2쿼터</Form.Label>
                        <Form.Control
                          id="quarterB2"
                          placeholder="예) 10"
                          value={quarterB2}
                          onChange={(e) => quarterChangeHandler(e, "B")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterB3" className="mt-2">
                        <Form.Label>3쿼터</Form.Label>
                        <Form.Control
                          id="quarterB3"
                          placeholder="예) 10"
                          value={quarterB3}
                          onChange={(e) => quarterChangeHandler(e, "B")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="quarterB4" className="mt-2">
                        <Form.Label>4쿼터</Form.Label>
                        <Form.Control
                          id="quarterB4"
                          placeholder="예) 10"
                          value={quarterB4}
                          onChange={(e) => quarterChangeHandler(e, "B")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="overtimeB" className="mt-2">
                        <Form.Label>연장전</Form.Label>
                        <Form.Control
                          id="overtimeB"
                          placeholder="예) 없었다면 0 입력"
                          value={overtimeB}
                          onChange={(e) => quarterChangeHandler(e, "B")}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="image" className="mt-3">
                    <Form.Label>이미지</Form.Label>
                    <p style={{ color: "red" }}>
                      이미지가 없다면, 값을 비워주세요.
                    </p>
                    {image !== "" && <Image src={image} alt="image" fluid />}
                    <Form.Control
                      type="text"
                      placeholder="[임의로 수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
                      value={image}
                      onChange={(e) => setImage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Control
                    type="file"
                    id="image-file"
                    label="이미지 선택"
                    custom="true"
                    onChange={uploadFileHandler}
                  ></Form.Control>
                  {uploading && <Loader />}
                </Form.Group>
              </Form>
            )}
          </FormContainer>
          <Button
            onClick={() => { setIsThree(!isThree) }}
          >
            {isThree ? "5대5" : "3대3"} 기록 입력
          </Button>
          <h3 className="mt-5 mx-5">홈 팀</h3>
          <form>
            <Table
              striped
              bordered
              hover
              responsive
              className="table-sm text-center"
            >
              <thead>
                <tr>
                  <th>
                    <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                      +1
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "20px", margin: "8px 0px" }}
                    >
                      {isThree ? "1점*" : "2점*"}
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "20px", margin: "8px 0px" }}
                    >
                      {isThree ? "2점*" : "3점*"}
                    </div>
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "2",
                      "background-color": "white",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ width: "50px", margin: "8px 0px" }}
                    >
                      이름
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ minWidth: "30px", margin: "8px 0px" }}
                    >
                      득점
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "1점" : "2점"}
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "1점" : "2점"}
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "2점" : "3점"}
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "2점" : "3점"}
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      자유투
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      자유투
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      공격
                      <br />
                      리바
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      수비
                      <br />
                      리바
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      어시
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      스틸
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      블락
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      파울
                      <br />
                      (일반)
                    </div>
                  </th>
                  {/* <th>
                <div className="hideextra" style={{ "min-width": "70px" }}>
                  파울(자유투)
                </div>
              </th> */}
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      턴오버
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "20px" }}>
                      MVP
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {competitionDetailInfo.competitionDetail && team && (competitionDetailInfo.competitionDetail.playerList[0].length === 0 ? team.members : competitionDetailInfo.competitionDetail.playerList[0].map((x) => x.playerId)).map( */}
                {competitionDetailInfo.competitionDetail &&
                  team && team.members.map(
                    (member, index) => (
                      (memberRecord =
                        competitionDetailInfo &&
                        competitionDetailInfo.competitionDetail.playerRecord[0].find(
                          (r) => r.playerId === member
                        )),
                      (
                        <tr key={index}>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="checkbox"
                              id={`plus1_h${index}`}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              style={{ width: "30px" }}
                              onChange={(e) => {
                                filterText(e);
                                typingScore2Handler(e, index, "h", team && team.members.length);
                              }}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              style={{ width: "30px" }}
                              onChange={(e) => {
                                filterText(e);
                                typingScore3Handler(e, index, "h", team && team.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              position: "sticky",
                              left: "0",
                              "z-index": "2",
                              "background-color": "white",
                              padding: "4px",
                            }}
                          >
                            <PlayerNameInput id={member} />
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`score_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.score
                              }
                              value={score}
                              onChange={(e) => {
                                filterText(e);
                                scoreChangeHandler(e, team && team.members.length, "h");
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`fieldGoal0_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.fieldGoal[0]
                              }
                              value={fieldGoal[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`fieldGoal1_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.fieldGoal[1]
                              }
                              value={fieldGoal[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "h", team && team.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#A3A3A3",
                            }}
                          >
                            <input
                              type="text"
                              id={`threePointThrow0_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.threePointThrow[0]
                              }
                              value={threePointThrow[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#A3A3A3",
                            }}
                          >
                            <input
                              type="text"
                              id={`threePointThrow1_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.threePointThrow[1]
                              }
                              value={threePointThrow[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "h", team && team.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`freeThrow0_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.freeThrow[0]
                              }
                              value={freeThrow[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`freeThrow1_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.freeThrow[1]
                              }
                              value={freeThrow[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "h", team && team.members.length);
                              }}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`rebound0_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.rebound[0]
                              }
                              value={rebound[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`rebound1_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.rebound[1]
                              }
                              value={rebound[1]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`assist_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.assist
                              }
                              value={assist}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`steal_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.steal
                              }
                              value={steal}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`block_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.block
                              }
                              value={block}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          {/* <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`foul1_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.foul[1]
                              }
                              value={foul[1]}
                            ></input>
                          </td> */}
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`foul0_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.foul[0]
                              }
                              value={foul[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`turnOver_h${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.turnOver
                              }
                              value={turnOver}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          {memberRecord !== undefined ? (
                            <td style={{ padding: "4px" }}>
                              <input
                                type="checkbox"
                                id={`isMVP_h${index}`}
                                style={{ width: "30px" }}
                                defaultChecked={
                                  memberRecord === undefined
                                    ? null
                                    : memberRecord.isMVP
                                }
                                checked={isMVP}
                              ></input>
                            </td>
                          ) : (
                            <td />
                          )}
                          <Button onClick={(e) => {
                            document.getElementById(`score_h${index}`).value = "";
                            document.getElementById(`fieldGoal0_h${index}`).value = "";
                            document.getElementById(`fieldGoal1_h${index}`).value = "";
                            document.getElementById(`threePointThrow0_h${index}`).value = "";
                            document.getElementById(`threePointThrow1_h${index}`).value = "";
                            document.getElementById(`freeThrow0_h${index}`).value = "";
                            document.getElementById(`freeThrow1_h${index}`).value = "";
                            document.getElementById(`rebound0_h${index}`).value = "";
                            document.getElementById(`rebound1_h${index}`).value = "";
                            document.getElementById(`assist_h${index}`).value = "";
                            document.getElementById(`steal_h${index}`).value = "";
                            document.getElementById(`block_h${index}`).value = "";
                            document.getElementById(`foul0_h${index}`).value = "";
                            // document.getElementById(`foul1_h${index}`).value = "";
                            document.getElementById(`turnOver_h${index}`).value = "";
                          }}>reset</Button>
                        </tr>
                      )
                    )
                  )}
              </tbody>
            </Table>
            {compareTotalScore("A", totalScoreA)}
          </form>
          <h3 className="mt-5 mx-5">어웨이 팀</h3>
          <form>
            <Table
              striped
              bordered
              hover
              responsive
              className="table-sm text-center"
            >
              <thead>
                <tr>
                  <th>
                    <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                      +1
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "20px", margin: "8px 0px" }}
                    >
                      {isThree ? "1점*" : "2점*"}
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "20px", margin: "8px 0px" }}
                    >
                      {isThree ? "2점*" : "3점*"}
                    </div>
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "2",
                      "background-color": "white",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ width: "50px", margin: "8px 0px" }}
                    >
                      이름
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ minWidth: "30px", margin: "8px 0px" }}
                    >
                      득점
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "1점" : "2점"}
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "1점" : "2점"}
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "2점" : "3점"}
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {isThree ? "2점" : "3점"}
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      자유투
                      <br />
                      시도
                    </div>
                  </th>{" "}
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      자유투
                      <br />
                      성공
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      공격
                      <br />
                      리바
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      수비
                      <br />
                      리바
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      어시
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      스틸
                    </div>
                  </th>
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      블락
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      파울
                      <br />
                      (일반)
                    </div>
                  </th>
                  {/* <th>
                <div className="hideextra" style={{ "min-width": "70px" }}>
                  파울(자유투)
                </div>
              </th> */}
                  <th>
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", margin: "8px 0px" }}
                    >
                      턴오버
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "20px" }}>
                      MVP
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {competitionDetailInfo.competitionDetail && team1 && (competitionDetailInfo.competitionDetail.playerList[1].length === 0 ? team1.members : competitionDetailInfo.competitionDetail.playerList[1].map((x) => x.playerId)).map( */}
                {competitionDetailInfo.competitionDetail &&
                  team1 && team1.members.map(
                    (member, index) => (
                      (memberRecord =
                        competitionDetailInfo &&
                        competitionDetailInfo.competitionDetail.playerRecord[1].find(
                          (r) => r.playerId === member
                        )),
                      (
                        <tr key={index}>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="checkbox"
                              id={`plus1_a${index}`}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              style={{ width: "30px" }}
                              onChange={(e) => {
                                filterText(e);
                                typingScore2Handler(e, index, "a", team1 && team1.members.length);
                              }}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              style={{ width: "30px" }}
                              onChange={(e) => {
                                filterText(e);
                                typingScore3Handler(e, index, "a", team1 && team1.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              position: "sticky",
                              left: "0",
                              "z-index": "2",
                              "background-color": "white",
                              padding: "4px",
                            }}
                          >
                            <PlayerNameInput id={member} />
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`score_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.score
                              }
                              value={score}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`fieldGoal0_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.fieldGoal[0]
                              }
                              value={fieldGoal[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`fieldGoal1_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.fieldGoal[1]
                              }
                              value={fieldGoal[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "a", team1 && team1.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#A3A3A3",
                            }}
                          >
                            <input
                              type="text"
                              id={`threePointThrow0_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.threePointThrow[0]
                              }
                              value={threePointThrow[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#A3A3A3",
                            }}
                          >
                            <input
                              type="text"
                              id={`threePointThrow1_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.threePointThrow[1]
                              }
                              value={threePointThrow[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "a", team1 && team1.members.length);
                              }}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`freeThrow0_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.freeThrow[0]
                              }
                              value={freeThrow[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`freeThrow1_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.freeThrow[1]
                              }
                              value={freeThrow[1]}
                              onChange={(e) => {
                                filterText(e);
                                recordInputChangeHandler(e, index, "a", team1 && team1.members.length);
                              }}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`rebound0_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.rebound[0]
                              }
                              value={rebound[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`rebound1_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.rebound[1]
                              }
                              value={rebound[1]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td
                            style={{
                              padding: "4px",
                              backgroundColor: "#D2D2D2",
                            }}
                          >
                            <input
                              type="text"
                              id={`assist_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.assist
                              }
                              value={assist}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`steal_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.steal
                              }
                              value={steal}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`block_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.block
                              }
                              value={block}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`foul0_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.foul[0]
                              }
                              value={foul[0]}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          {/* <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`foul1_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.foul[1]
                              }
                              value={foul[1]}
                            ></input>
                          </td> */}
                          <td style={{ padding: "4px" }}>
                            <input
                              type="text"
                              id={`turnOver_a${index}`}
                              style={{ width: "30px" }}
                              defaultValue={
                                memberRecord === undefined
                                  ? null
                                  : memberRecord.turnOver
                              }
                              value={turnOver}
                              onChange={(e) => filterText(e)}
                            ></input>
                          </td>
                          {memberRecord !== undefined ? (
                            <td style={{ padding: "4px" }}>
                              <input
                                type="checkbox"
                                id={`isMVP_a${index}`}
                                style={{ width: "30px" }}
                                defaultChecked={
                                  memberRecord === undefined
                                    ? null
                                    : memberRecord.isMVP
                                }
                                checked={isMVP}
                              ></input>
                            </td>
                          ) : (
                            <td />
                          )}
                          <Button
                            onClick={(e) => {
                              document.getElementById(`score_a${index}`).value =
                                "";
                              document.getElementById(
                                `fieldGoal0_a${index}`
                              ).value = "";
                              document.getElementById(
                                `fieldGoal1_a${index}`
                              ).value = "";
                              document.getElementById(
                                `threePointThrow0_a${index}`
                              ).value = "";
                              document.getElementById(
                                `threePointThrow1_a${index}`
                              ).value = "";
                              document.getElementById(
                                `freeThrow0_a${index}`
                              ).value = "";
                              document.getElementById(
                                `freeThrow1_a${index}`
                              ).value = "";
                              document.getElementById(
                                `rebound0_a${index}`
                              ).value = "";
                              document.getElementById(
                                `rebound1_a${index}`
                              ).value = "";
                              document.getElementById(
                                `assist_a${index}`
                              ).value = "";
                              document.getElementById(`steal_a${index}`).value =
                                "";
                              document.getElementById(`block_a${index}`).value =
                                "";
                              document.getElementById(`foul0_a${index}`).value =
                                "";
                              // document.getElementById(`foul1_a${index}`).value =
                              //   "";
                              document.getElementById(
                                `turnOver_a${index}`
                              ).value = "";
                            }}
                          >
                            reset
                          </Button>
                        </tr>
                      )
                    )
                  )}
              </tbody>
            </Table>
            {compareTotalScore("B", totalScoreB)}
          </form>
          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            style={{ width: "100%" }}
            onClick={submitHandler}
          >
            경기 결과 등록
          </Button>
        </>
      )}
    </>
  );
};

export default ModifyGameScreen;
