import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ProgressBar, Table, Image, Button } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  listCompetitionInfo,
  competitionResultInfo,
} from "../actions/competitionActions";
import PlayerName from "../components/PlayerName";
import {
  COMPETITION_INFO_RESET,
  COMPETITION_LIST_RESET,
  COMPETITION_ROUNDGAME_RESET,
} from "../constants/competitionConstants";
import { viewLocalSetup, increaseView } from "../utils/viewSetup";

const RecordPrintScreen = ({ match }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const competitionInfoList = useSelector((state) => state.competitionInfoList);
  const { loading, error, competition } = competitionInfoList;

  const competitionResult = useSelector((state) => state.competitionResultInfo);
  const {
    loading: loadingResult,
    error: errorResult,
    result,
  } = competitionResult;

  useEffect(() => {
    dispatch({ type: COMPETITION_INFO_RESET });
    dispatch(listCompetitionInfo(id));
    dispatch(competitionResultInfo(id));
    dispatch({ type: COMPETITION_LIST_RESET });
    dispatch({ type: COMPETITION_ROUNDGAME_RESET });
  }, [dispatch, id]);

  useEffect(() => {
    if (competition[0]) {
      viewLocalSetup(competition[0].competitionId);
      increaseView(competition[0].competitionId, "competition");
    }
  }, [competition]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div className="print-area">
          <h2
            className="text-center"
            style={{
              color: "white",
              "background-color": "#031b31",
              "padding-top": "20px",
              "padding-bottom": "20px",
              "margin-top": "-16px",
              "margin-left": "-12px",
              "margin-right": "-12px",
            }}
          >
            {competition[0] && competition[0].round}{" "}
            {competition[0] && competition[0].gameName} 정보
          </h2>
          {loadingResult ? (
            <Loader />
          ) : errorResult ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              <h3 className="mt-4">
                {competition[0] && competition[0].vsTeamName[0]} 기록
              </h3>
              <Table
                bordered
                responsive
                className="table-sm text-center table__padding__sm"
                style={{ border: "1px" }}
              >
                <thead>
                  <tr
                    className="table__header"
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <th
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "2",
                      }}
                    >
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "50px",
                        }}
                      >
                        이름
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "30px",
                        }}
                      >
                        득점
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        2점
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        야투율
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        3점
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "30px",
                        }}
                      >
                        3점
                        <br /> 성공률
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        자유투
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "35px",
                        }}
                      >
                        자유투
                        <br />
                        성공율
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        공격
                        <br /> 리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        수비
                        <br /> 리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        총<br />
                        리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        어시스트
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        스틸
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        블락
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        턴오버
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        파울
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competition[0] &&
                    competition[0].playerRecord[0].map((player) => (
                      <tr key={player._id}>
                        <td
                          style={{
                            position: "sticky",
                            left: "0",
                            "z-index": "1",
                            "background-color": "white",
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <div
                            style={{
                              margin: "auto",
                            }}
                          >
                            <PlayerName key={player._id} id={player.playerId} />
                            {player.jerseyNum ? (
                              <span
                                style={{
                                  fontSize: "10px",
                                  color: "#031b31",
                                  fontWeight: "700",
                                }}
                              >
                                {player.jerseyNum}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>{player.score}</td>
                        <td>
                          {player.fieldGoal[1]} - {player.fieldGoal[0]}
                        </td>
                        <td>
                          {player.fieldGoal[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.fieldGoal[1] / player.fieldGoal[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {player.threePointThrow[1]} -{" "}
                          {player.threePointThrow[0]}
                        </td>
                        <td>
                          {player.threePointThrow[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.threePointThrow[1] /
                                  player.threePointThrow[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {player.freeThrow[1]} - {player.freeThrow[0]}
                        </td>
                        <td>
                          {player.freeThrow[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.freeThrow[1] / player.freeThrow[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>{player.rebound[0]}</td>
                        <td>{player.rebound[1]}</td>
                        <td>{player.rebound[0] + player.rebound[1]}</td>
                        <td>{player.assist}</td>
                        <td>{player.steal}</td>
                        <td>{player.block}</td>
                        <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                        <td>{player.foul[0] + player.foul[1]}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <h3 className="mt-3 second-table">
                {competition[0] && competition[0].vsTeamName[1]} 기록
              </h3>
              <Table
                bordered
                responsive
                className="table-sm text-center table__padding__sm "
                style={{ border: "1px" }}
              >
                <thead>
                  <tr
                    className="table__header"
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <th
                      style={{
                        position: "sticky",
                        left: "0",
                        "z-index": "2",
                      }}
                    >
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "50px",
                        }}
                      >
                        이름
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "30px",
                        }}
                      >
                        득점
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        2점
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        야투율
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        3점
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "30px",
                        }}
                      >
                        3점
                        <br /> 성공률
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        자유투
                        <br /> 성공-시도
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "35px",
                        }}
                      >
                        자유투
                        <br />
                        성공율
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        공격
                        <br /> 리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        수비
                        <br /> 리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        총<br />
                        리바운드
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "40px",
                        }}
                      >
                        어시스트
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        스틸
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        블락
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        턴오버
                      </div>
                    </th>
                    <th>
                      <div
                        className="hideextra"
                        style={{
                          "min-width": "25px",
                        }}
                      >
                        파울
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competition[0] &&
                    competition[0].playerRecord[1].map((player) => (
                      <tr key={player._id}>
                        <td
                          style={{
                            position: "sticky",
                            left: "0",
                            "z-index": "1",
                            "background-color": "white",
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <div style={{ margin: "auto" }}>
                            <PlayerName key={player._id} id={player.playerId} />
                            {player.jerseyNum ? (
                              <span
                                style={{
                                  fontSize: "10px",
                                  color: "#031b31",
                                  fontWeight: "700",
                                }}
                              >
                                {player.jerseyNum}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>{player.score}</td>
                        <td>
                          {player.fieldGoal[1]} - {player.fieldGoal[0]}
                        </td>
                        <td>
                          {player.fieldGoal[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.fieldGoal[1] / player.fieldGoal[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {player.threePointThrow[1]} -{" "}
                          {player.threePointThrow[0]}
                        </td>
                        <td>
                          {player.threePointThrow[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.threePointThrow[1] /
                                  player.threePointThrow[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>
                          {player.freeThrow[1]} - {player.freeThrow[0]}
                        </td>
                        <td>
                          {player.freeThrow[1] === 0
                            ? 0
                            : Math.ceil(
                                (player.freeThrow[1] / player.freeThrow[0]) *
                                  100
                              )}
                          %
                        </td>
                        <td>{player.rebound[0]}</td>
                        <td>{player.rebound[1]}</td>
                        <td>{player.rebound[0] + player.rebound[1]}</td>
                        <td>{player.assist}</td>
                        <td>{player.steal}</td>
                        <td>{player.block}</td>
                        <td>{player.turnOver < 0 ? "N/A" : player.turnOver}</td>
                        <td>{player.foul[0] + player.foul[1]}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div class="text-center mt-5 print_button">
                <Button onClick={handlePrint}>인쇄하기</Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RecordPrintScreen;
