import {
  TEAM_LIST_FAIL,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_RESET,
  TEAM_LIST_CONSONANT_REQUEST,
  TEAM_LIST_CONSONANT_SUCCESS,
  TEAM_LIST_CONSONANT_FAIL,
  TEAM_LIST_CONSONANT_RESET,
  TEAM_LIST_TYPE_REQUEST,
  TEAM_LIST_TYPE_SUCCESS,
  TEAM_LIST_TYPE_FAIL,
  TEAM_LIST_TYPE_RESET,
  TEAM_NAME_FAIL,
  TEAM_NAME_REQUEST,
  TEAM_NAME_RESET,
  TEAM_NAME_SUCCESS,
  TEAM_DETAIL_REQUEST,
  TEAM_DETAIL_SUCCESS,
  TEAM_DETAIL_FAIL,
  TEAM_DETAIL_RESET,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_UPDATE_RESET,
  TEAM_DETAIL_REQUEST1,
  TEAM_DETAIL_SUCCESS1,
  TEAM_DETAIL_FAIL1,
  TEAM_DETAIL_RESET1,
  TEAM_INPUT_REQUEST,
  TEAM_INPUT_SUCCESS,
  TEAM_INPUT_FAIL,
  TEAM_SEASON_RECORD_REQUEST,
  TEAM_SEASON_RECORD_SUCCESS,
  TEAM_SEASON_RECORD_FAIL,
  TEAM_SEASON_MEMBERS_RECORD_REQUEST,
  TEAM_SEASON_MEMBERS_RECORD_SUCCESS,
  TEAM_SEASON_MEMBERS_RECORD_FAIL,
  TEAM_COMPETITION_RECORD_REQUEST,
  TEAM_COMPETITION_RECORD_SUCCESS,
  TEAM_COMPETITION_RECORD_FAIL,
  TEAM_COMPETITION_RECORD_RESET,
  TEAM_COMPETITION_LIST_REQUEST,
  TEAM_COMPETITION_LIST_SUCCESS,
  TEAM_COMPETITION_LIST_FAIL,
  TEAM_COMPETITION_LIST_RESET,
  TEAM_DELETE_REQUEST,
  TEAM_DELETE_SUCCESS,
  TEAM_DELETE_FAIL,
} from "../constants/teamConstants";

export const teamNameReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_NAME_REQUEST:
      return { ...state, loading: true };
    case TEAM_NAME_SUCCESS:
      return { loading: false, info: action.payload };
    case TEAM_NAME_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_NAME_RESET:
      return {};
    default:
      return state;
  }
};

export const teamListReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAM_LIST_REQUEST:
      return { loading: true };
    case TEAM_LIST_SUCCESS:
      return {
        loading: false,
        teams: action.payload.team,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TEAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_LIST_RESET:
      return { teams: [] };
    default:
      return state;
  }
};

export const teamListConsonantReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAM_LIST_CONSONANT_REQUEST:
      return { loading: true };
    case TEAM_LIST_CONSONANT_SUCCESS:
      return {
        loading: false,
        teams: action.payload.team,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TEAM_LIST_CONSONANT_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_LIST_CONSONANT_RESET:
      return { teams: [] };
    default:
      return state;
  }
};

export const teamListTypeReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAM_LIST_TYPE_REQUEST:
      return { loading: true };
    case TEAM_LIST_TYPE_SUCCESS:
      return {
        loading: false,
        teams: action.payload.team,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TEAM_LIST_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_LIST_TYPE_RESET:
      return { teams: [] };
    default:
      return state;
  }
};

export const teamDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DETAIL_REQUEST:
      return { ...state, loading: true };
    case TEAM_DETAIL_SUCCESS:
      return { loading: false, team: action.payload };
    case TEAM_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const teamDetailsReducer1 = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DETAIL_REQUEST1:
      return { ...state, loading: true };
    case TEAM_DETAIL_SUCCESS1:
      return { loading: false, team1: action.payload };
    case TEAM_DETAIL_FAIL1:
      return { loading: false, error: action.payload };
    case TEAM_DETAIL_RESET1:
      return {};
    default:
      return state;
  }
};

export const teamInputReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_INPUT_REQUEST:
      return { ...state, loading: true };
    case TEAM_INPUT_SUCCESS:
      return { loading: false };
    case TEAM_INPUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamSeasonRecordReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_SEASON_RECORD_REQUEST:
      return { ...state, loading: true };
    case TEAM_SEASON_RECORD_SUCCESS:
      return { loading: false, seasonTeam: action.payload };
    case TEAM_SEASON_RECORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamSeasonMembersRecordReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_SEASON_MEMBERS_RECORD_REQUEST:
      return { ...state, loading: true };
    case TEAM_SEASON_MEMBERS_RECORD_SUCCESS:
      return { loading: false, seasonMembersTeam: action.payload };
    case TEAM_SEASON_MEMBERS_RECORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamCompetitionRecordReducer = (state = { competitionRecord: []}, action) => {
  switch (action.type) {
    case TEAM_COMPETITION_RECORD_REQUEST:
      return { ...state, loading: true };
    case TEAM_COMPETITION_RECORD_SUCCESS:
      return {
        loading: false,
        competitionRecord: [...state.competitionRecord, action.payload],
      };
    case TEAM_COMPETITION_RECORD_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_COMPETITION_RECORD_RESET:
      return { competitionRecord: []};
    default:
      return state;
  }
};

export const teamCompetitionListReducer = (state = { competitions: [] }, action) => {
  switch (action.type) {
    case TEAM_COMPETITION_LIST_REQUEST:
      return { ...state, loading: true };
    case TEAM_COMPETITION_LIST_SUCCESS:
      return { loading: false, competitions: action.payload };
    case TEAM_COMPETITION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_COMPETITION_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const teamUpdateReducer = (state = { team: {} }, action) => {
  switch (action.type) {
    case TEAM_UPDATE_REQUEST:
      return { loading: true };
    case TEAM_UPDATE_SUCCESS:
      return { loading: false, success: true, team: action.payload };
    case TEAM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const teamDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DELETE_REQUEST:
      return { loading: true };
    case TEAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TEAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};