import React, { useEffect } from "react";
import { Image, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import { listCompetitionNotice, listNotice } from "../actions/noticeActions";
import { dailyLogout, setupOptimize } from "../utils/viewSetup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  const competitionNoticeList = useSelector(
    (state) => state.competitionNoticeList
  );
  const {
    loading: loadingCompetitionNotice,
    error: errorCompetitionNotice,
    competitionNotice,
  } = competitionNoticeList;

  const noticeList = useSelector((state) => state.noticeList);
  const { loading: loadingNotice, error: errorNotice, notices } = noticeList;

  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const carouselImages = [
    "img_mainbnr01.png",
    "img_mainbnr02.png",
    "img_mainbnr03.png",
  ];

  const carouselImagesMobile = [
    "mb_img_mainbnr01.png",
    "mb_img_mainbnr02.png",
    "mb_img_mainbnr03.png",
  ];

  useEffect(() => {
    setupOptimize();
    // dailyLogout();
    dispatch(listNotice());
    dispatch(listCompetitionNotice());
  }, [dispatch]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <Carousel pause="hover" className="bg-light mx-2 carousel__pc__image">
        {carouselImages.map((image) => (
          <Carousel.Item key={image}>
            <Image src={image} alt="main-img" fluid />
          </Carousel.Item>
        ))}
      </Carousel>
      <Carousel pause="hover" className="bg-light mx-2 carousel__mobile__image">
        {carouselImagesMobile.map((image) => (
          <Carousel.Item key={image}>
            <Image src={image} alt="main-img" fluid />
          </Carousel.Item>
        ))}
      </Carousel>
      <span className="on__mobile">
        <div className="mobile__home__flex__box">
          <div>
            <Link to={"/personallistrecord/page/1"}>
              <button className="mobile__home__btn" variant="success">
                개인 기록
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/teamrecord/page/1"}>
              <button className="mobile__home__btn" variant="success">
                팀 기록
              </button>
            </Link>
          </div>
        </div>
        <div>
          <Link to={"/competitionrecord/Time4Division/division/all/page/1"}>
            <button className="mobile__home__btn__division" variant="success">
              <FontAwesomeIcon icon={faMedal} className="mx-2" />
              디비전 대회 기록
            </button>
          </Link>
        </div>
        <div className="mobile__home__flex__box">
          <div>
            <Link to={"/competitionrecord/Official/season/2024/page/1"}>
              <button className="mobile__home__btn" variant="success">
                대회 기록
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/competitionrecord/Official/유소년/page/1"}>
              <button className="mobile__home__btn" variant="success">
                유소년
                <br />
                대회 기록
              </button>
            </Link>
          </div>
        </div>
      </span>
      {/* <div className="notice__title__box">
        <div>
          <h3 className="mt-5">
            <FontAwesomeIcon icon={faBasketball} /> | 대회소식
          </h3>
        </div>
        {competitionNotice && (
          <div>
            <Link to="/competition-notice/list">
              <button
                className="result__btn mt-5 mb-1"
                style={{ backgroundColor: "#011241" }}
              >
                더보기 +
              </button>
            </Link>
          </div>
        )}
      </div>
      <Row className="text-center">
        {competitionNotice &&
          competitionNotice.result &&
          competitionNotice.result.map((notice) => (
            <Col md={3} key={notice.competitionNotice._id}>
              <Link
                to={`/competition-notice/detail/${notice.competitionNotice._id}`}
              >
                <div className="competition__notice__box">
                  <div className="notice__box__under">
                    <p
                      className="notice__title"
                      style={{ marginBottom: "8px" }}
                    >
                      {notice.competitionNotice.title}
                    </p>
                  </div>
                  <div className="notice__competition__info">
                    <p className="notice__competition__name">
                      {notice.competitionInfo.name}
                    </p>
                    <p className="notice__competition__info__text">
                      {notice.competitionInfo.host} |{" "}
                      {notice.competitionInfo.place}{" "}
                      {notice.competitionInfo.sponser &&
                        `| ${notice.competitionInfo.sponser}`}
                    </p>
                    <div className="notice__competition__recruiting">
                      <button className="notice__compeittion__status">
                        {notice.competitionInfo.isRecruiting
                          ? "모집중"
                          : "모집마감"}
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
      </Row> */}

      {/* <div className="notice__title__box">
        <div>
          <h3 className="mt-1">
            <FontAwesomeIcon icon={faBell} /> | 공지사항
          </h3>
        </div>
        {userInfo && userInfo.isAdmin && (
          <Link to="/notice/new">
            <Button>새 공지사항 작성</Button>
          </Link>
        )}
        {notices && notices.notice && (
          <div>
            <Link to="/notice/list">
              <button
                className="result__btn"
                style={{ backgroundColor: "#011241" }}
              >
                더보기 +
              </button>
            </Link>
          </div>
        )}
      </div>
      <Row className="text-center">
        {notices && notices.notice && notices.notice.length > 0 ? (
          notices.notice.slice(0, 4).map((notice) => (
            <Col md={3} key={notice._id}>
              <Link to={`/notice/detail/${notice._id}`}>
                <div className="notice__box">
                  <p className="notice__title">{notice.title}</p>
                  <div className="notice__date__box">
                    <p className="notice__date">
                      {notice.date.substring(0, 10)}
                    </p>
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      style={{ color: "#011241" }}
                    />
                  </div>
                </div>
              </Link>
            </Col>
          ))
        ) : (
          <h4 className="mt-3">현재 작성된 공지사항이 없습니다.</h4>
        )}
      </Row> */}
      {/* <h5 className="text-center mt-5 text__mobile__h5">
        현재 TIME4는 베타서비스 운영 중입니다. <br />
        정보가 부정확할 수 있다는 점 양해 부탁드립니다.
      </h5> */}
    </>
  );
};

export default HomeScreen;
