import React from 'react';
import { Button } from 'react-bootstrap';

const SelectRecorderTypeScreen = ({ history }) => {
  return (
    <>
      <div>
        <Button variant="primary" onClick={() => { history.push('recorder/new-game') }}>새 경기</Button>
        <Button variant="primary" onClick={() => { history.push('recorder/load-game/local') }}>불러오기(로컬)</Button>
        <Button variant="primary" onClick={() => { history.push('recorder/load-game/server') }}>불러오기(서버)</Button>
      </div>
    </>
  )
};

export default SelectRecorderTypeScreen;