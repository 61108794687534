import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listApplies } from "../actions/competitionApplyActions";
import { competitionGameList } from "../actions/competitionActions";

const CompetitionApplyListScreen = ({ match, history }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const competitionApplyList = useSelector((state) => state.competitionApplyList);
  const { loading, error, applies } = competitionApplyList;

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competition,
  } = competitionGame;

  useEffect(() => {
    dispatch(listApplies(id, ""));
    dispatch(competitionGameList(id));
  }, [dispatch, id]);

  return (
    <>
      <h2 className="mt-3 mb-3">{competition && competition.name} 팀 신청서 목록</h2>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <span className="on__pc__info">
            <Table striped bordered hover size="sm" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>팀명</th>
                  <th style={{ width: "10%" }}>인원 수</th>
                  <th style={{ width: "10%" }}>감독</th>
                  <th style={{ width: "20%" }}>감독 휴대폰</th>
                  <th style={{ width: "30%" }}>신청일</th>
                </tr>
              </thead>
              <tbody>
                {applies &&
                  applies.map((apply, index) => (
                    <tr
                      key={apply._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`detail/${apply._id}/1`)}
                    >
                      <td style={{ textAlign: "center", width: "300px" }}>
                        {apply.teamName}
                      </td>
                      <td style={{ textAlign: "center", width: "300px" }}>
                        {apply.playerList.length}
                      </td>
                      <td style={{ textAlign: "center", width: "300px" }}>
                        {apply.leaderName}
                      </td>
                      <td>
                          {apply.leaderPhoneNum ? apply.leaderPhoneNum : ""}
                      </td>
                      <td>
                        <div
                          className="hideextra"
                          style={{ textAlign: "center" }}
                        >
                          {apply.applyDate.substring(0, 10)}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </span>
        </>
      )}
    </>
  );
};

export default CompetitionApplyListScreen;
