import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listCompetitionParticipants } from "../actions/competitionActions";
import AdminPlayerDetailCompetition from "../components/AdminPlayerDetailCompetition";
import AdminPlayerDetailCompetitionAverage from "../components/AdminPlayerDetailCompetitionAverage";
import Select from "react-select";
import { accessControl } from "../utils/access";

const CompetitionStatsScreen = ({ match, history }) => {
  const competitionId = match.params.id;
  const [sortKey, setSortKey] = useState("score");

  const dispatch = useDispatch();

  const competitionParticipants = useSelector(
    (state) => state.competitionParticipants
  );
  const { loading, error, competition } = competitionParticipants;

  const options = [
    {
      value: "gameCount",
      label: "경기 수",
    },
    {
      value: "score",
      label: "득점",
    },
    {
      value: "fieldGoal[1]",
      label: "2점 성공",
    },
    {
      value: "fieldGoal[0]",
      label: "2점 시도",
    },
    {
      value: "fieldGoal_avg",
      label: "2점 성공률",
    },
    {
      value: "threePointThrow[1]",
      label: "3점 성공",
    },
    {
      value: "threePointThrow[0]",
      label: "3점 시도",
    },
    {
      value: "threePointThrow_avg",
      label: "3점 성공률",
    },
    {
      value: "freeThrow[1]",
      label: "자유투 성공",
    },
    {
      value: "freeThrow[0]",
      label: "자유투 시도",
    },
    {
      value: "freeThrow_avg",
      label: "자유투 성공률",
    },
    {
      value: "rebound[0]",
      label: "공격 리바운드",
    },
    {
      value: "rebound[1]",
      label: "수비 리바운드",
    },
    {
      value: "rebound_all",
      label: "전체 리바운드",
    },
    {
      value: "steal",
      label: "스틸",
    },
    {
      value: "block",
      label: "블락",
    },
    {
      value: "turnOver",
      label: "턴오버",
    },
    {
      value: "foul[0]",
      label: "파울(일반)",
    },
    {
      value: "assist",
      label: "어시스트",
    },
  ]

  const recordSort = (type, record, isAvg) => {
    if (type === "gameCount") {
      return record.sort((a, b) => {
        return b.gameCount - a.gameCount;
      });
    } else if (type === "score") {
      return record.sort((a, b) => {
        if (!isAvg) return b.score - a.score;
        else return b.score / b.gameCount - a.score / a.gameCount;
      });
    } else if (type === "fieldGoal[1]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.fieldGoal[1] - a.fieldGoal[1];
        else return b.fieldGoal[1] / b.gameCount - a.fieldGoal[1] / a.gameCount;
      });
    } else if (type === "fieldGoal[0]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.fieldGoal[0] - a.fieldGoal[0];
        else return b.fieldGoal[0] / b.gameCount - a.fieldGoal[0] / a.gameCount;
      });
    } else if (type === "fieldGoal_avg") {
      return record.sort((a, b) => {
        let b_ = b.fieldGoal[1] * 100 / b.fieldGoal[0];
        let a_ = a.fieldGoal[1] * 100 / a.fieldGoal[0];
        if (b.fieldGoal[0] === 0) { return -1 }
        else if (a.fieldGoal[0] === 0) { return 1 }
        else { return b_ - a_; }
      });
    } else if (type === "threePointThrow[1]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.threePointThrow[1] - a.threePointThrow[1];
        else return b.threePointThrow[1] / b.gameCount - a.threePointThrow[1] / a.gameCount;
      });
    } else if (type === "threePointThrow[0]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.threePointThrow[0] - a.threePointThrow[0];
        else return b.threePointThrow[0] / b.gameCount - a.threePointThrow[0] / a.gameCount;
      });
    } else if (type === "threePointThrow_avg") {
      return record.sort((a, b) => {
        let b_ = b.threePointThrow[1] * 100 / b.threePointThrow[0];
        let a_ = a.threePointThrow[1] * 100 / a.threePointThrow[0];
        if (b.threePointThrow[0] === 0) { return -1 }
        else if (a.threePointThrow[0] === 0) { return 1 }
        else { return b_ - a_; }
      });
    } else if (type === "freeThrow[1]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.freeThrow[1] - a.freeThrow[1];
        else return b.freeThrow[1] / b.gameCount - a.freeThrow[1] / a.gameCount;
      });
    } else if (type === "freeThrow[0]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.freeThrow[0] - a.freeThrow[0];
        else return b.freeThrow[0] / b.gameCount - a.freeThrow[0] / a.gameCount;
      });
    } else if (type === "freeThrow_avg") {
      return record.sort((a, b) => {
        let b_ = b.freeThrow[1] * 100 / b.freeThrow[0];
        let a_ = a.freeThrow[1] * 100 / a.freeThrow[0];
        if (b.freeThrow[0] === 0) { return -1 }
        else if (a.freeThrow[0] === 0) { return 1 }
        else { return b_ - a_; }
      });
    } else if (type === "rebound[0]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.rebound[0] - a.rebound[0];
        else return b.rebound[0] / b.gameCount - a.rebound[0] / a.gameCount;
      });
    } else if (type === "rebound[1]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.rebound[1] - a.rebound[1];
        else return b.rebound[1] / b.gameCount - a.rebound[1] / a.gameCount;
      });
    } else if (type === "rebound_all") {
      return record.sort((a, b) => {
        if (!isAvg) return b.rebound[0] + b.rebound[1] - a.rebound[0] - a.rebound[1];
        else return (b.rebound[0] + b.rebound[1]) / b.gameCount - (a.rebound[0] + a.rebound[1]) / a.gameCount;
      });
    } else if (type === "steal") {
      return record.sort((a, b) => {
        if (!isAvg) return b.steal - a.steal;
        else return b.steal / b.gameCount - a.steal / a.gameCount;
      });
    } else if (type === "block") {
      return record.sort((a, b) => {
        if (!isAvg) return b.block - a.block;
        else return b.block / b.gameCount - a.block / a.gameCount;
      });
    } else if (type === "turnOver") {
      return record.sort((a, b) => {
        if (!isAvg) return b.turnOver - a.turnOver;
        else return b.turnOver / b.gameCount - a.turnOver / a.gameCount;
      });
    } else if (type === "foul[0]") {
      return record.sort((a, b) => {
        if (!isAvg) return b.foul[0] - a.foul[0];
        else return b.foul[0] / b.gameCount - a.foul[0] / a.gameCount;
      });
    } else if (type === "assist") {
      return record.sort((a, b) => {
        if (!isAvg) return b.assist - a.assist;
        else return b.assist / b.gameCount - a.assist / a.gameCount;
      });
    }     
  }

  const handleKeyChange = (selected) => {
    setSortKey(selected.value);
  };

  const [isTotalRecord, setIsTotalRecord] = useState(true);

  useEffect(() => {
    accessControl([], history);
    dispatch(listCompetitionParticipants(competitionId));
  }, [dispatch, competitionId]);

  return (
    <>
      <h4 className="mt-2">{competition && competition.name}</h4>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">
              정보를 불러오는데 실패하였습니다.
            </Message>
          ) : (
            competition &&
            competition.participants.length !== 0 && (
              <>
                <h5>경기 참여 선수 명단</h5>
                <h6>
                  모든 대회 참여 선수의 기록을 모두 불러오는데 시간이 1~2분
                  소요될수 있습니다.
                  <br />
                  새로고침을 가급적 피하시고, 만약 정보가 더이상 불러와지지 않을
                  경우에만 새로고침을 눌러주세요.
                </h6>
                <div className="mt-1 mb-2">
                  <Button onClick={() => setIsTotalRecord(true)}>
                    종합 누적 기록
                  </Button>
                  <Button
                    className="mx-3"
                    onClick={() => setIsTotalRecord(false)}
                  >
                    경기별 평균 기록
                  </Button>
                  <Select
                    options={options}
                    onChange={handleKeyChange}
                    defaultValue={options[1]}
                  />
                </div>
                {isTotalRecord === true ? (
                  <h3>종합 누적 기록</h3>
                ) : (
                  <h3>경기별 평균 기록</h3>
                )}
                {competition && (
                  <div id="seasonRecordTable">
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="table-sm text-center"
                      style={{ borderCollapse: "collapse" }}
                    >
                      <thead
                        style={{
                          "background-color": "white",
                          postition: "sticky",
                          top: "0",
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              position: "sticky",
                              left: "0",
                              "background-color": "white",
                              "z-index": "2",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "50px" }}
                            >
                              팀명
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              left: "0",
                              "background-color": "white",
                              "z-index": "2",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "50px" }}
                            >
                              이름
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{
                                "min-width": "30px",
                              }}
                            >
                              경기수
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "30px" }}
                            >
                              득점
                            </div>
                          </th>

                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              2점 <br />
                              성공
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              2점 <br />
                              시도
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              2점 <br />
                              성공률
                            </div>
                          </th>

                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              3점 <br />
                              성공
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              3점 <br />
                              시도
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "30px" }}
                            >
                              3점 <br />
                              성공률
                            </div>
                          </th>

                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              자유투 <br />
                              성공
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              자유투 <br />
                              시도
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "35px" }}
                            >
                              자유투 <br />
                              성공률
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              공격 <br />
                              리바운드
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              수비 <br />
                              리바운드
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              전체 <br />
                              리바운드
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "35px" }}
                            >
                              스틸
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "35px" }}
                            >
                              블락
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "35px" }}
                            >
                              턴오버
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "35px" }}
                            >
                              파울
                              <br />
                              (일반)
                            </div>
                          </th>
                          <th
                            style={{
                              position: "sticky",
                              top: "0",
                              padding: "4px",
                            }}
                            className="align-middle"
                          >
                            <div
                              className="hideextra"
                              style={{ "min-width": "40px" }}
                            >
                              어시스트
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isTotalRecord === true &&
                          competition &&
                          recordSort(sortKey, competition.record, false)
                          .map((r, index) => (
                            <tr key={index}>
                              <AdminPlayerDetailCompetition
                                record={r}
                              />
                            </tr>
                          ))}
                        {isTotalRecord === false &&
                          competition &&
                          recordSort(sortKey, competition.record, true)
                          .map((r, index) => (
                            <tr key={index}>
                              <AdminPlayerDetailCompetitionAverage
                                record={r}
                              />
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            )
          )}
        </>
      )}
    </>
  );
};

export default CompetitionStatsScreen;
