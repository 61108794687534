import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { teamCompetitionRecord } from "../actions/teamActions";
import { TEAM_COMPETITION_RECORD_RESET } from "../constants/teamConstants";
import Loader from "./Loader";
import Message from "./Message";

const TeamDetailCompetition = ({ id, i, competitionId }) => {
  const dispatch = useDispatch();

  const teamCompetition = useSelector((state) => state.teamCompetition);
  const { loading, error, competitionRecord } = teamCompetition;

  useEffect(() => {
    dispatch({ type: TEAM_COMPETITION_RECORD_RESET });
    dispatch(teamCompetitionRecord({ _id: id, competitionId: competitionId }));
  }, [dispatch, id, competitionId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        competitionRecord[i] &&
        competitionRecord[i].gameCount !== 0 && (
          <>
            <h5>
              {competitionRecord[i] && competitionRecord[i].competitionName}
            </h5>
            <Table
              striped
              hover
              responsive
              className="table-sm text-center table__padding__sm"
            >
              <thead>
                <tr className="table__header">
                  <th>
                    <div
                      className="hideextra"
                      style={{
                        "min-width": "30px",
                      }}
                    >
                      경기수
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      득점
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      2점 <br />
                      성공-시도
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      2점 <br />
                      성공률
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      3점 <br />
                      성공-시도
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      3점 <br />
                      성공률
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "100px" }}>
                      자유투 <br />
                      성공-시도
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      자유투
                      <br /> 성공률
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      공격 <br />
                      리바운드
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      수비 <br />
                      리바운드
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      스틸
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      블락
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      턴오버
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "35px" }}>
                      파울
                    </div>
                  </th>
                  <th>
                    <div className="hideextra" style={{ "min-width": "40px" }}>
                      어시스트
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr style={{ "line-height": "120%" }}>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div className="hideextra" style={{ "min-width": "30px" }}>
                      {competitionRecord[i] && competitionRecord[i].gameCount}
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].score /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].score})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].fieldGoal[0] === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].fieldGoal[1] /
                            competitionRecord[i].gameCount
                          ).toFixed(1) +
                            " - " +
                            (
                              competitionRecord[i].fieldGoal[0] /
                              competitionRecord[i].gameCount
                            ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] &&
                        competitionRecord[i].fieldGoal[1]}{" "}
                      -{" "}
                      {competitionRecord[i] &&
                        competitionRecord[i].fieldGoal[0]}
                      )
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].fieldGoal[1] === 0
                        ? 0
                        : (
                            (competitionRecord[i] &&
                              competitionRecord[i].fieldGoal[1] /
                                competitionRecord[i].fieldGoal[0]) * 100
                          ).toFixed(1)}
                      %
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].threePointThrow[0] === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].threePointThrow[1] /
                            competitionRecord[i].gameCount
                          ).toFixed(1) +
                            " - " +
                            (
                              competitionRecord[i].threePointThrow[0] /
                              competitionRecord[i].gameCount
                            ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] &&
                        competitionRecord[i].threePointThrow[1]}{" "}
                      -{" "}
                      {competitionRecord[i] &&
                        competitionRecord[i].threePointThrow[0]}
                      )
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "30px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].threePointThrow[1] === 0
                        ? 0
                        : (
                            (competitionRecord[i] &&
                              competitionRecord[i].threePointThrow[1] /
                                competitionRecord[i].threePointThrow[0]) * 100
                          ).toFixed(1)}
                      %
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].freeThrow[0] === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].freeThrow[1] /
                            competitionRecord[i].gameCount
                          ).toFixed(1) +
                            " - " +
                            (
                              competitionRecord[i].freeThrow[0] /
                              competitionRecord[i].gameCount
                            ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] &&
                        competitionRecord[i].freeThrow[1]}{" "}
                      -{" "}
                      {competitionRecord[i] &&
                        competitionRecord[i].freeThrow[0]}
                      )
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "35px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].freeThrow[1] === 0
                        ? 0
                        : (
                            (competitionRecord[i] &&
                              competitionRecord[i].freeThrow[1] /
                                competitionRecord[i].freeThrow[0]) * 100
                          ).toFixed(1)}
                      %
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].rebound[0] /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].rebound[0]})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].rebound[1] /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].rebound[1]})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "35px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].steal /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].steal})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "35px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].block /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].block})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "35px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].turnOver /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].turnOver})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "35px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].foul[0] /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].foul[0]})
                    </div>
                  </td>
                  <td
                    style={{
                      "z-index": "-2",
                      position: "relative",
                      "background-color": "white",
                      padding: "6px",
                    }}
                  >
                    <div
                      className="hideextra"
                      style={{ "min-width": "40px", "line-height": "120%" }}
                    >
                      {competitionRecord[i] &&
                      competitionRecord[i].gameCount === 0
                        ? 0
                        : competitionRecord[i] &&
                          (
                            competitionRecord[i].assist /
                            competitionRecord[i].gameCount
                          ).toFixed(1)}
                      <br />(
                      {competitionRecord[i] && competitionRecord[i].assist})
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )
      )}
    </>
  );
};

export default TeamDetailCompetition;
