import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Table, FormCheck } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Select from "react-select";
import { competitionGameInput, competitionGameList, listLeagueRound } from "../actions/competitionActions";
import { listCompetition } from "../actions/competitionActions";
import {
  listTeams,
  teamDetailsInfo,
  teamDetailsInfo1,
} from "../actions/teamActions";
import { Row, Col } from "react-bootstrap";
import PlayerNameInput from "../components/PlayerNameInput";
import { accessControl } from "../utils/access";

export const TempGameInput = ({ history }) => {
  // 경기일 하이픈 자동 생성
  const [gameDate, setGameDate] = useState(new Date().toISOString().substring(0,10));
  const gameRef = useRef();

  const handleStart = (e) => {
    const value = gameRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    gameRef.current.value = result;
    setGameDate(e.target.value);
  };
  const [competitionId, setCompetitionId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [gamePlace, setGamePlace] = useState("");
  const [gameNum, setGameNum] = useState("");
  const [gameName, setGameName] = useState("");
  const [round, setRound] = useState("");
  const [roundNum, setRoundNum] = useState("");
  const [roundLabel, setRoundLabel] = useState("");
  const [teamIdA, setTeamIdA] = useState("");
  const [teamIdB, setTeamIdB] = useState("");
  let vsTeam = [];
  let gameResult = [];
  let quarter = [];
  let overtime = [];
  let vsTeamName = [];

  const [isThree, setIsThree] = useState(false);

  const [optionsRound, setOptionsRound] = useState([]);
  const [optionsPlace, setOptionsPlace] = useState([]);
  const [optionsType, setOptionsType] = useState([]);

  const [roundDisable, setRoundDisable] = useState(true);

  const [gameScoreA, setGameScoreA] = useState(0);
  const [gameScoreB, setGameScoreB] = useState(0);
  const [totalScoreA, setTotalScoreA] = useState(0);
  const [totalScoreB, setTotalScoreB] = useState(0);

  const [teamA, setTeamA] = useState([]);
  const [teamB, setTeamB] = useState([]);
  const [teamAObj, setTeamAObj] = useState("");
  const [teamBObj, setTeamBObj] = useState("");

  let gameResultA, gameResultB,
    quarterA1, quarterA2, quarterA3, quarterA4,
    quarterB1, quarterB2, quarterB3, quarterB4,
    overtimeA, overtimeB;

  let score_h = [],
    score_a = [];
  let fieldGoal_h = [],
    fieldGoal_a = [];
  let threePointThrow_h = [],
    threePointThrow_a = [];
  let freeThrow_h = [],
    freeThrow_a = [];
  let rebound_h = [],
    rebound_a = [];
  let steal_h = [],
    steal_a = [];
  let block_h = [],
    block_a = [];
  let turnOver_h = [],
    turnOver_a = [];
  let foul_h = [],
    foul_a = [];
  let assist_h = [],
    assist_a = [];

  let score;
  let fieldGoal = [];
  let threePointThrow = [];
  let freeThrow = [];
  let rebound = [];
  let steal;
  let block;
  let turnOver;
  let foul = [];
  let assist;

  let memberRecord;

  const fieldGoalInput = isThree ? 1 : 2;
  const threePointThrowInput = isThree ? 2 : 3;

  const isNumeric = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  const filterText = (e) => {
    if (!isNumeric(e.target.value)) { 
      if (e.target.value.toString().length > 1) {
        e.target.value = e.target.value.slice(0, -1);
      } else {
        e.target.value = "";
      }
    }
  }

  const scoreChangeHandler = (e, length, t) => {
    let totalScore = 0;
    
    for (let i = 0; i < length; i++) {
      totalScore += document.getElementById(`score_${t}${i}`).value * 1;
    }
    if (t === "h") { setTotalScoreA(totalScore); }
    if (t === "a") { setTotalScoreB(totalScore); }
  }

  const quarterChangeHandler = (e, t) => {
    document.getElementById(`gameResult${t}`).value = document.getElementById(`quarter${t}1`).value * 1 + document.getElementById(`quarter${t}2`).value * 1 + document.getElementById(`quarter${t}3`).value * 1 + document.getElementById(`quarter${t}4`).value * 1 + document.getElementById(`overtime${t}`).value * 1;
    if (t === "A") { setGameScoreA(document.getElementById(`gameResult${t}`).value); }
    if (t === "B") { setGameScoreB(document.getElementById(`gameResult${t}`).value); }
  }

  const recordInputChangeHandler = (e, index, t, length) => {
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput+1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput+1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    scoreChangeHandler(e, length, t);
  };



  const typingScore2Handler = (e, index, t, length) => {
    if (e.target.value === "q" || e.target.value === "Q" || e.target.value === "ㅂ") {
      e.target.value = "";
      document.getElementById(`freeThrow0_${t}${index}`).focus();
      document.getElementById(`freeThrow0_${t}${index}`).select();
    }
    if (e.target.value === "w" || e.target.value === "W" || e.target.value === "ㅈ") {
      e.target.value = "";
      document.getElementById(`rebound0_${t}${index}`).focus();
      document.getElementById(`rebound0_${t}${index}`).select();
    }
    if (!(document.getElementById(`score_${t}${index}`).value ||
    document.getElementById(`fieldGoal0_${t}${index}`).value ||
    document.getElementById(`fieldGoal1_${t}${index}`).value ||
    document.getElementById(`threePointThrow0_${t}${index}`).value ||
    document.getElementById(`threePointThrow1_${t}${index}`).value ||
    document.getElementById(`freeThrow0_${t}${index}`).value ||
    document.getElementById(`freeThrow1_${t}${index}`).value ||
    document.getElementById(`rebound0_${t}${index}`).value ||
    document.getElementById(`rebound1_${t}${index}`).value ||
    document.getElementById(`steal_${t}${index}`).value ||
    document.getElementById(`block_${t}${index}`).value ||
    document.getElementById(`turnOver_${t}${index}`).value ||
    document.getElementById(`foul0_${t}${index}`).value ||
    // document.getElementById(`foul1_${t}${index}`).value ||
    document.getElementById(`assist_${t}${index}`).value)) {
      document.getElementById(`score_${t}${index}`).value = 0;
      document.getElementById(`fieldGoal0_${t}${index}`).value = 0;
      document.getElementById(`fieldGoal1_${t}${index}`).value = 0;
      document.getElementById(`threePointThrow0_${t}${index}`).value = 0;
      document.getElementById(`threePointThrow1_${t}${index}`).value = 0;
      document.getElementById(`freeThrow0_${t}${index}`).value = 0;
      document.getElementById(`freeThrow1_${t}${index}`).value = 0;
      document.getElementById(`rebound0_${t}${index}`).value = 0;
      document.getElementById(`rebound1_${t}${index}`).value = 0;
      document.getElementById(`steal_${t}${index}`).value = 0;
      document.getElementById(`block_${t}${index}`).value = 0;
      document.getElementById(`turnOver_${t}${index}`).value = 0;
      document.getElementById(`foul0_${t}${index}`).value = 0;
      // document.getElementById(`foul1_${t}${index}`).value = 0;
      document.getElementById(`assist_${t}${index}`).value = 0;
    }
    if (!(document.getElementById(`fieldGoal0_${t}${index}`).value && document.getElementById(`fieldGoal0_${t}${index}`).value)) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = 0
      document.getElementById(`fieldGoal1_${t}${index}`).value = 0
    }
    if (e.target.value == 1) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = Number(document.getElementById(`fieldGoal0_${t}${index}`).value) + 1;
    }
    if (e.target.value == 2) {
      document.getElementById(`fieldGoal0_${t}${index}`).value = Number(document.getElementById(`fieldGoal0_${t}${index}`).value) + 1;
      document.getElementById(`fieldGoal1_${t}${index}`).value = Number(document.getElementById(`fieldGoal1_${t}${index}`).value) + 1;
    }
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput+1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput+1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    e.target.value = "";

    scoreChangeHandler(e, length, t);
  };
  const typingScore3Handler = (e, index, t, length) => {
    if (e.target.value === "q" || e.target.value === "Q" || e.target.value === "ㅂ") {
      e.target.value = "";
      document.getElementById(`freeThrow0_${t}${index}`).focus();
      document.getElementById(`freeThrow0_${t}${index}`).select();
    }
    if (e.target.value === "w" || e.target.value === "W" || e.target.value === "ㅈ") {
      e.target.value = "";
      document.getElementById(`rebound0_${t}${index}`).focus();
      document.getElementById(`rebound0_${t}${index}`).select();
    }
    if (!(document.getElementById(`threePointThrow0_${t}${index}`).value && document.getElementById(`threePointThrow0_${t}${index}`).value)) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = 0
      document.getElementById(`threePointThrow1_${t}${index}`).value = 0
    }
    if (e.target.value == 1) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = Number(document.getElementById(`threePointThrow0_${t}${index}`).value) + 1;
    }
    if (e.target.value == 3) {
      document.getElementById(`threePointThrow0_${t}${index}`).value = Number(document.getElementById(`threePointThrow0_${t}${index}`).value) + 1;
      document.getElementById(`threePointThrow1_${t}${index}`).value = Number(document.getElementById(`threePointThrow1_${t}${index}`).value) + 1;
    }
    if (document.getElementById(`plus1_${t}${index}`).checked) {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * (fieldGoalInput+1) + document.getElementById(`threePointThrow1_${t}${index}`).value * (threePointThrowInput+1) + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    } else {
      document.getElementById(`score_${t}${index}`).value = document.getElementById(`fieldGoal1_${t}${index}`).value * fieldGoalInput + document.getElementById(`threePointThrow1_${t}${index}`).value * threePointThrowInput + document.getElementById(`freeThrow1_${t}${index}`).value * 1;
    }
    e.target.value = "";

    scoreChangeHandler(e, length, t);
  };

  const dispatch = useDispatch();

  const adminCompetitionGameInput = useSelector(
    (state) => state.adminCompetitionGameInput
  );
  const { loading, error } = adminCompetitionGameInput;

  const competitionList = useSelector((state) => state.competitionList);
  const {
    loading: loadingCompetition,
    error: errorCompetition,
    competitions,
  } = competitionList;

  const teamList = useSelector((state) => state.teamList);
  const { loading: loadingTeam, error: errorTeam, teams } = teamList;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamDetails1 = useSelector((state) => state.teamDetails1);
  const {
    loading: loadingTeamDetails1,
    error: errorTeamDetails1,
    team1,
  } = teamDetails1;

  const playerDetails = useSelector((state) => state.playerDetails);
  const {
    loading: loadingPlayerDetails,
    error: errorPlayerDetails,
    player,
  } = playerDetails;

  const competitionRoundInfo = useSelector((state) => state.competitionRoundInfo);
  const {
    loading: loadingRoundInfo,
    error: errorRoundInfo,
    roundInfo
  } = competitionRoundInfo;

  const competitionGame = useSelector((state) => state.competitionGame);
  const {
    loading: loadingCompetition1,
    error: errorCompetition1,
    competition,
  } = competitionGame;

  useEffect(() => {
    accessControl([99, 100, 101, 300], history);
    dispatch(listCompetition());
    dispatch(listTeams());
  }, [dispatch]);

  // 대회 설정
  // const optionsCompetition = [];``

  // competitions &&
  //   competitions
  //     .filter(competition => competition.status <= 1)
  //     .map((competition) =>
  //       optionsCompetition.push({
  //         value: competition._id,
  //         label: competition.name,
  //       })
  //     );

  const [optionsCompetition, setOptionsCompetition] = useState([]);
  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (competitions) {
      let tempOptionsCompetition = [];

      competitions
        .filter(competition => competition.status <= status)
        .map((competition) =>
          tempOptionsCompetition.push({
            value: competition._id,
            label: competition.name,
          })
        );
      setOptionsCompetition(tempOptionsCompetition);
    }
  }, [competitions, status]);

  useEffect(() => {
    if (roundInfo) {
      let roundOption = [];
      roundInfo.round.map((r) =>
        roundOption.push({
          value: r[1],
          label: r[1],
        })
      );
      roundOption.push({
        value: "",
        label: "직접입력",
      });
      setOptionsRound(roundOption);
    }
  }, [roundInfo]);

  useEffect(() => {
    if (competition) {
      let placeOption = [];
      competition.place.map((p) =>
        placeOption.push({
          value: p,
          label: p,
        })
      );
      setOptionsPlace(placeOption);
      setGamePlace(placeOption[0].value);
      // setGamePlace(placeOption[0].value);

      setTeamType(competition.teamType);
    }
  }, [competition]);

  const optionsTeam = [];
  const [optionsTempTeam, setOptionsTempTeam] = useState([]);

  teams &&
    teams.map((team) =>
      optionsTeam.push({
        value: team._id,
        label: team.name,
      })
    );

  useEffect(() => {
    if (team)
      setTeamA(team && team.members);
  }, [team]);

  useEffect(() => {
    if (team1)
      setTeamB(team1 && team1.members);
  }, [team1]);

  const handleTeamChangeA = (selected) => {
    setTeamIdA(selected.value);
    dispatch(teamDetailsInfo(selected.value));
  };

  const handleTeamChangeB = (selected) => {
    setTeamIdB(selected.value);
    dispatch(teamDetailsInfo1(selected.value));
  };

  const handleTempTeamChangeA = (selected) => {
    setTeamIdA(selected.value.baseTeamId);
    setTeamA(selected.value.members);
    setTeamAObj(selected.value);
  }

  const handleTempTeamChangeB = (selected) => {
    setTeamIdB(selected.value.baseTeamId);
    setTeamB(selected.value.members);
    setTeamBObj(selected.value);
  }

  const handleTeamTypeChange = (selected) => {
    setTeamType(selected.value);
    let copy = [...optionsType];
    copy.splice(copy.indexOf(selected), 1);
    copy.unshift(selected);
    setOptionsType(copy);
  };

  const handlePlaceChange = (selected) => {
    setGamePlace(selected.value);
    let copy = [...optionsPlace];
    copy.splice(copy.indexOf(selected), 1);
    copy.unshift(selected);
    setOptionsPlace(copy);
  };

  const handleCompetitionChange = (selected) => {
    setCompetitionId(selected.value);
    dispatch(listLeagueRound(selected.value));
    dispatch(competitionGameList(selected.value));
  };
  
  useEffect(() => {
    if (competitionId !== "") {
      setIsThree(competitions.find((obj) => obj._id === competitionId).isThree);
    }
  }, [competitionId]);

  const handleRoundChange = (selected) => {
    if (selected.label === "직접입력") {
      setRoundDisable(false);
    } else {
      setRoundDisable(true);
    }
    setRound(selected.value);
  };

  const compareTotalScore = (t, totalScore) => {
    let gameScore = 0;
    if (t === "A") { gameScore = gameScoreA; }
    if (t === "B") { gameScore = gameScoreB; }

    if (Number(gameScore) === totalScore) {
      return (
        <>
          <div style={{ "color": "blue" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ "color": "red" }}>
            입력 총점 : {gameScore}
            <br />
            합산 총점 : {totalScore}
            <br />
            총점이 일치하지 않습니다.
          </div>
        </>
      )
    }
  }

  const submitHandler = (e) => {
    let isRecordNotEmpty = true;
    let isTryLarger = true;
    let isTotalScoreCorrect = true;

    let invalidMemberRecord = [];
    let invalidMemberTry = [];
    let totalScore = [0, 0];
    let inCorrectScoreTeam = [];

    e.preventDefault();
    vsTeam = [teamIdA, teamIdB];
    vsTeamName = [team && team.name, team1 && team1.name];
    gameResult = [document.getElementById(`gameResultA`).value, document.getElementById(`gameResultB`).value];
    // quarter = [
    //   [document.getElementById(`quarterA1`).value, document.getElementById(`quarterB1`).value],
    //   [document.getElementById(`quarterA2`).value, document.getElementById(`quarterB2`).value],
    //   [document.getElementById(`quarterA3`).value, document.getElementById(`quarterB3`).value],
    //   [document.getElementById(`quarterA4`).value, document.getElementById(`quarterB4`).value]
    // ];
    if (!isThree) {
      quarter = [
        [document.getElementById(`quarterA1`).value, document.getElementById(`quarterB1`).value],
        [document.getElementById(`quarterA2`).value, document.getElementById(`quarterB2`).value],
        [document.getElementById(`quarterA3`).value, document.getElementById(`quarterB3`).value],
        [document.getElementById(`quarterA4`).value, document.getElementById(`quarterB4`).value]
      ];
      overtime = [document.getElementById(`overtimeA`).value, document.getElementById(`overtimeB`).value];
    } else {
      quarter = [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0]
      ];
      overtime = [0, 0];
    }
    
    let recordIndex;
    let playerRecord = [[], []];
    for (recordIndex = 0; recordIndex < teamA.length; recordIndex++) {
      if (document.getElementById(`score_h${recordIndex}`).value) {
        score_h = document.getElementById(`score_h${recordIndex}`).value;
        fieldGoal_h = [document.getElementById(`fieldGoal0_h${recordIndex}`).value, document.getElementById(`fieldGoal1_h${recordIndex}`).value];
        threePointThrow_h = [document.getElementById(`threePointThrow0_h${recordIndex}`).value, document.getElementById(`threePointThrow1_h${recordIndex}`).value];
        freeThrow_h = [document.getElementById(`freeThrow0_h${recordIndex}`).value, document.getElementById(`freeThrow1_h${recordIndex}`).value];
        rebound_h = [document.getElementById(`rebound0_h${recordIndex}`).value, document.getElementById(`rebound1_h${recordIndex}`).value];
        steal_h = document.getElementById(`steal_h${recordIndex}`).value;
        block_h = document.getElementById(`block_h${recordIndex}`).value;
        turnOver_h = document.getElementById(`turnOver_h${recordIndex}`).value;
        // foul_h = [document.getElementById(`foul0_h${recordIndex}`).value, document.getElementById(`foul1_h${recordIndex}`).value];
        foul_h = [document.getElementById(`foul0_h${recordIndex}`).value, 0];
        assist_h = document.getElementById(`assist_h${recordIndex}`).value;

        if ([score_h, fieldGoal_h[0], fieldGoal_h[1], threePointThrow_h[0], threePointThrow_h[1], freeThrow_h[0], freeThrow_h[1], rebound_h[0], rebound_h[1], steal_h, block_h, turnOver_h, foul_h[0], foul_h[1], assist_h].includes('')) {
          isRecordNotEmpty = false;
          invalidMemberRecord.push(
            (competition && competition.tempTeam.length === 0) ?
            team.memberInfo[recordIndex].name :
            teamAObj.memberName[recordIndex]
            );
        }
        if (Number(fieldGoal_h[0]) < Number(fieldGoal_h[1]) || Number(threePointThrow_h[0]) < Number(threePointThrow_h[1]) || Number(freeThrow_h[0]) < Number(freeThrow_h[1])) {
          isTryLarger = false;
          invalidMemberTry.push(
            (competition && competition.tempTeam.length === 0) ?
            team.memberInfo[recordIndex].name :
            teamAObj.memberName[recordIndex]
          );
        }

        totalScore[0] += Number(score_h);

        playerRecord[0].push({
          playerId: teamA[recordIndex],
          score: Number(score_h),
          fieldGoal: [Number(fieldGoal_h[0]), Number(fieldGoal_h[1])],
          threePointThrow: [Number(threePointThrow_h[0]), Number(threePointThrow_h[1])],
          freeThrow: [Number(freeThrow_h[0]), Number(freeThrow_h[1])],
          rebound: [Number(rebound_h[0]), Number(rebound_h[1])],
          steal: Number(steal_h),
          block: Number(block_h),
          turnOver: Number(turnOver_h),
          foul: [Number(foul_h[0]), Number(foul_h[1])],
          assist: Number(assist_h),
        });
      }
    }
    for (recordIndex = 0; recordIndex < teamB.length; recordIndex++) {
      if (document.getElementById(`score_a${recordIndex}`).value) {
        score_a = document.getElementById(`score_a${recordIndex}`).value;
        fieldGoal_a = [document.getElementById(`fieldGoal0_a${recordIndex}`).value, document.getElementById(`fieldGoal1_a${recordIndex}`).value];
        threePointThrow_a = [document.getElementById(`threePointThrow0_a${recordIndex}`).value, document.getElementById(`threePointThrow1_a${recordIndex}`).value];
        freeThrow_a = [document.getElementById(`freeThrow0_a${recordIndex}`).value, document.getElementById(`freeThrow1_a${recordIndex}`).value];
        rebound_a = [document.getElementById(`rebound0_a${recordIndex}`).value, document.getElementById(`rebound1_a${recordIndex}`).value];
        steal_a = document.getElementById(`steal_a${recordIndex}`).value;
        block_a = document.getElementById(`block_a${recordIndex}`).value;
        turnOver_a = document.getElementById(`turnOver_a${recordIndex}`).value;
        // foul_a = [document.getElementById(`foul0_a${recordIndex}`).value, document.getElementById(`foul1_a${recordIndex}`).value];
        foul_a = [document.getElementById(`foul0_a${recordIndex}`).value, 0];
        assist_a = document.getElementById(`assist_a${recordIndex}`).value;

        if ([score_a, fieldGoal_a[0], fieldGoal_a[1], threePointThrow_a[0], threePointThrow_a[1], freeThrow_a[0], freeThrow_a[1], rebound_a[0], rebound_a[1], steal_a, block_a, turnOver_a, foul_a[0], foul_a[1], assist_a].includes('')) {
          isRecordNotEmpty = false;
          invalidMemberRecord.push(
            (competition && competition.tempTeam.length === 0) ?
            team1.memberInfo[recordIndex].name :
            teamBObj.memberName[recordIndex]
          );
        } else if (Number(fieldGoal_a[0]) < Number(fieldGoal_a[1]) || Number(threePointThrow_a[0]) < Number(threePointThrow_a[1]) || Number(freeThrow_a[0]) < Number(freeThrow_a[1])) {
          isTryLarger = false;
          invalidMemberTry.push(
            (competition && competition.tempTeam.length === 0) ?
            team1.memberInfo[recordIndex].name :
            teamBObj.memberName[recordIndex]
          );
        }

        totalScore[1] += Number(score_a);

        playerRecord[1].push({
          playerId: teamB[recordIndex],
          score: Number(score_a),
          fieldGoal: [Number(fieldGoal_a[0]), Number(fieldGoal_a[1])],
          threePointThrow: [Number(threePointThrow_a[0]), Number(threePointThrow_a[1])],
          freeThrow: [Number(freeThrow_a[0]), Number(freeThrow_a[1])],
          rebound: [Number(rebound_a[0]), Number(rebound_a[1])],
          steal: Number(steal_a),
          block: Number(block_a),
          turnOver: Number(turnOver_a),
          foul: [Number(foul_a[0]), Number(foul_a[1])],
          assist: Number(assist_a),
        });
      }
    }



    //init (checkAllandSetJerseyNum0) when initPlayerList checkbox is checked
    // if (document.getElementById("initPlayerList").checked) {
    // }


    if (totalScore[0] != gameResult[0]) {
      isTotalScoreCorrect = false;
      inCorrectScoreTeam.push("홈 팀");
    }
    if (totalScore[1] != gameResult[1]) {
      isTotalScoreCorrect = false;
      inCorrectScoreTeam.push("어웨이 팀");
    }

    if (isRecordNotEmpty && isTryLarger) {
      dispatch(
        competitionGameInput(
            gameDate,
            competitionId,
            teamType,
            gamePlace,
            // gameNum,
            gameName,
            round,
            // roundNum,
            vsTeam,
            gameResult,
            quarter,
            overtime,
            playerRecord,
            vsTeamName
        )
      );
    } else {
      let alertMessage = '';
      if (!isRecordNotEmpty) {
        alertMessage += `'${invalidMemberRecord}' 선수의 기록에 빈칸이 있습니다. \n`;
      }
      if (!isTryLarger) {
        alertMessage += `'${invalidMemberTry}' 선수의 시도 횟수가 성공 횟수보다 많습니다. \n`;
      }
      // if (!isTotalScoreCorrect) {
      //   alertMessage += `'${inCorrectScoreTeam}' 의 총점이 경기 결과와 다릅니다. \n`;
      // }
      alert(alertMessage);
    }
  };
  // Player record



  return (
    <>
      <FormContainer>
        <h1>경기 기록 입력</h1>
        {error && (
          <Message variant="경기 등록에 실패하였습니다. 정보 확인 부탁드립니다."></Message>
        )}
        {loading && <Loader />}
        {loadingCompetition || loadingTeam ? (
          <Loader />
        ) : errorCompetition || errorTeam ? (
          <Message variant="danger">
            [오류] 대회 정보 또는 팀 정보를 불러오지 못했습니다. 개발자에게
            문의해주세요.
          </Message>
        ) : (
          <Form onSubmit={submitHandler} className="mt-3">
            <Form.Group controlId="birthday" className="mt-3">
              <Form.Label>경기 일자</Form.Label>
              <Form.Control
                type="gameDate"
                placeholder="예) 2022-01-01"
                ref={gameRef}
                value={gameDate}
                onChange={handleStart}
              ></Form.Control>
            </Form.Group>
            <Row>
              <Col md={9}>
                <Form.Group controlId="competitionId" className="mt-3">
                  <Form.Label>대회명</Form.Label>
                  <Select
                    options={optionsCompetition}
                    isSearchable
                    onChange={handleCompetitionChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex justify-content-center align-items-center">
                <Form.Group controlId="status" className="mt-3">
                  <Form.Label>종료된 대회 표시</Form.Label>
                  <FormCheck
                    type="checkbox"
                    id="status"
                    checked={status === 2}
                    onChange={(e) => setStatus(e.target.checked ? 2 : 1)}
                  ></FormCheck>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="gamePlace" className="mt-2">
                  <Form.Label>대회 장소</Form.Label>
                  <Select
                    options={optionsPlace}
                    onChange={handlePlaceChange}
                    value={optionsPlace[0]}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="gameName" className="mt-2">
                  <Form.Label>경기명</Form.Label>
                  <Form.Control
                    type="gameName"
                    placeholder="예) A1"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="competitionId" className="mt-2">
                  <Form.Label>라운드</Form.Label>
                  <Select
                    options={optionsRound}
                    onChange={handleRoundChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="round" className="mt-2">
                  <Form.Label>-</Form.Label>
                  <Form.Control
                    type="round"
                    value={round}
                    disabled={roundDisable}
                    onChange={(e) => setRound(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Form.Group controlId="teamIdA" className="mt-3">
              <Form.Label>홈 팀</Form.Label>
              <Row>
                <Col md={8}>
                  <Select
                    options={optionsTeam}
                    isSearchable
                    onChange={handleTeamChangeA}
                  />
                </Col>
                <Col md={4}>
                  <Form.Control
                    id="gameResultA"
                    placeholder="총점 예) 50"
                    value={gameResultA}
                    onChange={(e) => setGameScoreA(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
              {!isThree && (<>
              <Row>
                <Col>
                  <Form.Group controlId="quarterA1" className="mt-2">
                    <Form.Label>1쿼터</Form.Label>
                    <Form.Control
                      id="quarterA1"
                      placeholder="예) 10"
                      value={quarterA1}
                      onChange={(e) => quarterChangeHandler(e, "A")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterA2" className="mt-2">
                    <Form.Label>2쿼터</Form.Label>
                    <Form.Control
                      id="quarterA2"
                      placeholder="예) 10"
                      value={quarterA2}
                      onChange={(e) => quarterChangeHandler(e, "A")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterA3" className="mt-2">
                    <Form.Label>3쿼터</Form.Label>
                    <Form.Control
                      id="quarterA3"
                      placeholder="예) 10"
                      value={quarterA3}
                      onChange={(e) => quarterChangeHandler(e, "A")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterA4" className="mt-2">
                    <Form.Label>4쿼터</Form.Label>
                    <Form.Control
                      id="quarterA4"
                      placeholder="예) 10"
                      value={quarterA4}
                      onChange={(e) => quarterChangeHandler(e, "A")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="overtimeA" className="mt-2">
                    <Form.Label>연장전</Form.Label>
                    <Form.Control
                      id="overtimeA"
                      placeholder="예) 없었다면 0 입력"
                      value={overtimeA}
                      onChange={(e) => quarterChangeHandler(e, "A")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              </>)}
            </Form.Group>
            <hr />
            <Form.Group controlId="teamIdB" className="mt-3">
              <Form.Label>어웨이 팀</Form.Label>
              <Row>
                <Col md={8}>
                  <Select
                    options={optionsTeam}
                    isSearchable
                    onChange={handleTeamChangeB}
                  />
                </Col>
                <Col md={4}>
                  <Form.Control
                    id="gameResultB"
                    placeholder="총점 예) 50"
                    value={gameResultB}
                    onChange={(e) => setGameScoreB(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
              {!isThree && (<>
              <Row>
                <Col>
                  <Form.Group controlId="quarterB1" className="mt-2">
                    <Form.Label>1쿼터</Form.Label>
                    <Form.Control
                      id="quarterB1"
                      placeholder="예) 10"
                      value={quarterB1}
                      onChange={(e) => quarterChangeHandler(e, "B")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterB2" className="mt-2">
                    <Form.Label>2쿼터</Form.Label>
                    <Form.Control
                      id="quarterB2"
                      placeholder="예) 10"
                      value={quarterB2}
                      onChange={(e) => quarterChangeHandler(e, "B")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterB3" className="mt-2">
                    <Form.Label>3쿼터</Form.Label>
                    <Form.Control
                      id="quarterB3"
                      placeholder="예) 10"
                      value={quarterB3}
                      onChange={(e) => quarterChangeHandler(e, "B")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="quarterB4" className="mt-2">
                    <Form.Label>4쿼터</Form.Label>
                    <Form.Control
                      id="quarterB4"
                      placeholder="예) 10"
                      value={quarterB4}
                      onChange={(e) => quarterChangeHandler(e, "B")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="overtimeB" className="mt-2">
                    <Form.Label>연장전</Form.Label>
                    <Form.Control
                      id="overtimeB"
                      placeholder="예) 없었다면 0 입력"
                      value={overtimeB}
                      onChange={(e) => quarterChangeHandler(e, "B")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              </>)}
            </Form.Group>
          </Form>
        )}
      </FormContainer>
      <h3 className="mt-5 mx-5">홈 팀</h3>
      <form>
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
        >
          <thead>
            <tr>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                  +1
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                  {isThree ? "1점*" : "2점*"}
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                  {isThree ? "2점*" : "3점*"}
                </div>
              </th>
              <th
                style={{
                  position: "sticky",
                  left: "0",
                  "z-index": "2",
                  "background-color": "white",
                }}
              >
                <div className="hideextra" style={{ width: "50px", "margin": "8px 0px" }}>
                  이름
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ minWidth: "30px", "margin": "8px 0px" }}>
                  득점
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  {isThree ? "1점" : "2점"}<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                {isThree ? "1점" : "2점"}<br />성공
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  {isThree ? "2점" : "3점"}<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  {isThree ? "2점" : "3점"}<br />성공
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  자유투<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  자유투<br />성공
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  공격<br />리바
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  수비<br />리바
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  어시
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  스틸
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  블락
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  파울<br />(일반)
                </div>
              </th>
              {/* <th>
                <div className="hideextra" style={{ "min-width": "70px" }}>
                  파울(자유투)
                </div>
              </th> */}
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  턴오버
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {teamA.map((member, index) => (
                <tr key={index}>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="checkbox"
                      id={`plus1_h${index}`}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      style={{ width: "30px" }}
                      onChange={(e) => typingScore2Handler(e, index, "h", teamA.length)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      style={{ width: "30px" }}
                      onChange={(e) => typingScore3Handler(e, index, "h", teamA.length)}
                    ></input>
                  </td>
                  <td
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "2",
                      "background-color": "white",
                      padding: "4px",
                    }}
                  >
                    <PlayerNameInput id={member} />
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`score_h${index}`}
                      style={{ width: "30px" }}
                      value={score}
                      onChange={(e) => {
                        filterText(e)
                        scoreChangeHandler(e, teamA.length , "h");
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`fieldGoal0_h${index}`}
                      style={{ width: "30px" }}
                      onChange={(e) => filterText(e)}
                      value={fieldGoal[0]}></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`fieldGoal1_h${index}`}
                      style={{ width: "30px" }}
                      value={fieldGoal[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "h", teamA.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#A3A3A3" }}>
                    <input
                      type="text"
                      id={`threePointThrow0_h${index}`}
                      style={{ width: "30px" }}
                      value={threePointThrow[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#A3A3A3" }}>
                    <input
                      type="text"
                      id={`threePointThrow1_h${index}`}
                      style={{ width: "30px" }}
                      value={threePointThrow[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "h", teamA.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`freeThrow0_h${index}`}
                      style={{ width: "30px" }}
                      value={freeThrow[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`freeThrow1_h${index}`}
                      style={{ width: "30px" }}
                      value={freeThrow[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "h", teamA.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`rebound0_h${index}`}
                      style={{ width: "30px" }}
                      value={rebound[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`rebound1_h${index}`}
                      style={{ width: "30px" }}
                      value={rebound[1]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`assist_h${index}`}
                      style={{ width: "30px" }}
                      value={assist}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`steal_h${index}`}
                      style={{ width: "30px" }}
                      value={steal}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`block_h${index}`}
                      style={{ width: "30px" }}
                      value={block}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`foul0_h${index}`}
                      style={{ width: "30px" }}
                      value={foul[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  {/* <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`foul1_h${index}`}
                      style={{ width: "30px" }}
                      value={foul[1]}
                    ></input>
                  </td> */}
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`turnOver_h${index}`}
                      style={{ width: "30px" }}
                      value={turnOver}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <Button onClick={(e) => {
                    document.getElementById(`score_h${index}`).value = "";
                    document.getElementById(`fieldGoal0_h${index}`).value = "";
                    document.getElementById(`fieldGoal1_h${index}`).value = "";
                    document.getElementById(`threePointThrow0_h${index}`).value = "";
                    document.getElementById(`threePointThrow1_h${index}`).value = "";
                    document.getElementById(`freeThrow0_h${index}`).value = "";
                    document.getElementById(`freeThrow1_h${index}`).value = "";
                    document.getElementById(`rebound0_h${index}`).value = "";
                    document.getElementById(`rebound1_h${index}`).value = "";
                    document.getElementById(`assist_h${index}`).value = "";
                    document.getElementById(`steal_h${index}`).value = "";
                    document.getElementById(`block_h${index}`).value = "";
                    document.getElementById(`foul0_h${index}`).value = "";
                    // document.getElementById(`foul1_h${index}`).value = "";
                    document.getElementById(`turnOver_h${index}`).value = "";
                    }}>reset</Button>
                </tr>
              ))}
          </tbody>
        </Table>
        {compareTotalScore("A", totalScoreA)}
      </form>
      <h3 className="mt-5 mx-5">어웨이 팀</h3>
      <form>
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
        >
          <thead>
            <tr>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                  +1
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                {isThree ? "1점*" : "2점*"}
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "20px", "margin": "8px 0px" }}>
                {isThree ? "2점*" : "3점*"}
                </div>
              </th>
              <th
                style={{
                  position: "sticky",
                  left: "0",
                  "z-index": "2",
                  "background-color": "white",
                }}
              >
                <div className="hideextra" style={{ width: "50px", "margin": "8px 0px" }}>
                  이름
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ minWidth: "30px", "margin": "8px 0px" }}>
                  득점
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                {isThree ? "1점" : "2점"}<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                {isThree ? "1점" : "2점"}<br />성공
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  {isThree ? "2점" : "3점"}<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  {isThree ? "2점" : "3점"}<br />성공
                </div>
              </th>
              {!isThree && (<>
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  자유투<br />시도
                </div>
              </th>{" "}
              </>)}
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  자유투<br />성공
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  공격<br />리바
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  수비<br />리바
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  어시
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  스틸
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  블락
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "30px" }}>
                  파울<br />(일반)
                </div>
              </th>
              {/* <th>
                <div className="hideextra" style={{ "min-width": "70px" }}>
                  파울(자유투)
                </div>
              </th> */}
              <th>
                <div className="hideextra" style={{ "min-width": "30px", "margin": "8px 0px" }}>
                  턴오버
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {teamB.map((member, index) => (
                <tr key={index}>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="checkbox"
                      id={`plus1_a${index}`}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      style={{ width: "30px" }}
                      onChange={(e) => typingScore2Handler(e, index, "a", teamB.length)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      style={{ width: "30px" }}
                      onChange={(e) => typingScore3Handler(e, index, "a", teamB.length)}
                    ></input>
                  </td>
                  <td
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "2",
                      "background-color": "white",
                      padding: "4px",
                    }}
                  >
                    <PlayerNameInput id={member} />
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`score_a${index}`}
                      style={{ width: "30px" }}
                      value={score}
                      onChange={(e) => {
                        filterText(e);
                        scoreChangeHandler(e, teamB.length , "a");
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`fieldGoal0_a${index}`}
                      style={{ width: "30px" }}
                      value={fieldGoal[0]}

                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`fieldGoal1_a${index}`}
                      style={{ width: "30px" }}
                      value={fieldGoal[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "a", teamB.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#A3A3A3" }}>
                    <input
                      type="text"
                      id={`threePointThrow0_a${index}`}
                      style={{ width: "30px" }}
                      value={threePointThrow[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#A3A3A3" }}>
                    <input
                      type="text"
                      id={`threePointThrow1_a${index}`}
                      style={{ width: "30px" }}
                      value={threePointThrow[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "a", teamB.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`freeThrow0_a${index}`}
                      style={{ width: "30px" }}
                      value={freeThrow[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`freeThrow1_a${index}`}
                      style={{ width: "30px" }}
                      value={freeThrow[1]}
                      onChange={(e) => {
                        filterText(e);
                        recordInputChangeHandler(e, index, "a", teamB.length);
                      }}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`rebound0_a${index}`}
                      style={{ width: "30px" }}
                      value={rebound[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`rebound1_a${index}`}
                      style={{ width: "30px" }}
                      value={rebound[1]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px", backgroundColor: "#D2D2D2" }}>
                    <input
                      type="text"
                      id={`assist_a${index}`}
                      style={{ width: "30px" }}
                      value={assist}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`steal_a${index}`}
                      style={{ width: "30px" }}
                      value={steal}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`block_a${index}`}
                      style={{ width: "30px" }}
                      value={block}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`foul0_a${index}`}
                      style={{ width: "30px" }}
                      value={foul[0]}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  {/* <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`foul1_a${index}`}
                      style={{ width: "30px" }}
                      value={foul[1]}
                    ></input>
                  </td> */}
                  <td style={{ padding: "4px" }}>
                    <input
                      type="text"
                      id={`turnOver_a${index}`}
                      style={{ width: "30px" }}
                      value={turnOver}
                      onChange={(e) => filterText(e)}
                    ></input>
                  </td>
                  <Button onClick={(e) => {
                    document.getElementById(`score_a${index}`).value = "";
                    document.getElementById(`fieldGoal0_a${index}`).value = "";
                    document.getElementById(`fieldGoal1_a${index}`).value = "";
                    document.getElementById(`threePointThrow0_a${index}`).value = "";
                    document.getElementById(`threePointThrow1_a${index}`).value = "";
                    document.getElementById(`freeThrow0_a${index}`).value = "";
                    document.getElementById(`freeThrow1_a${index}`).value = "";
                    document.getElementById(`rebound0_a${index}`).value = "";
                    document.getElementById(`rebound1_a${index}`).value = "";
                    document.getElementById(`assist_a${index}`).value = "";
                    document.getElementById(`steal_a${index}`).value = "";
                    document.getElementById(`block_a${index}`).value = "";
                    document.getElementById(`foul0_a${index}`).value = "";
                    // document.getElementById(`foul1_a${index}`).value = "";
                    document.getElementById(`turnOver_a${index}`).value = "";
                    }}>reset</Button>
                </tr>
              ))}
          </tbody>
        </Table>
        {compareTotalScore("B", totalScoreB)}
      </form>
      {/* <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50px",
        }}
      >출전선수 초기화</div>
      <input
        type="checkbox"
        id="initPlayerList"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50px",
        }}
      ></input> */}
      <Button
        type="submit"
        variant="primary"
        className="mt-5"
        style={{ width: "100%" }}
        onClick={submitHandler}
      >
        경기 결과 등록
      </Button>
    </>
  );
};

export default TempGameInput;
