import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import { Redirect } from "react-router-dom";
import {
  COMMUNITY_LIST_REQUEST,
  COMMUNITY_LIST_SUCCESS,
  COMMUNITY_LIST_FAIL,
  COMMUNITY_READ_REQUEST,
  COMMUNITY_READ_SUCCESS,
  COMMUNITY_READ_FAIL,
  COMMUNITY_WRITE_REQUEST,
  COMMUNITY_WRITE_SUCCESS,
  COMMUNITY_WRITE_FAIL,
  COMMUNITY_UPDATE_REQUEST,
  COMMUNITY_UPDATE_SUCCESS,
  COMMUNITY_UPDATE_FAIL,
  COMMUNITY_DELETE_REQUEST,
  COMMUNITY_DELETE_SUCCESS,
  COMMUNITY_DELETE_FAIL,
} from "../constants/communityConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const listPosts =
  (keyword = "", pageNumber = "", category = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: COMMUNITY_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/community?keyword=${keyword}&pageNumber=${pageNumber}&category=${category}`
      );
      dispatch({ type: COMMUNITY_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMMUNITY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const readPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMMUNITY_READ_REQUEST });
    const { data } = await axios.get(`${API_SERVER_URL}/api/community/${id}`);
    dispatch({ type: COMMUNITY_READ_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMMUNITY_READ_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const writePost = (title, content, category, author) => async (dispatch) => {
  try {
    dispatch({
      type: COMMUNITY_WRITE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };
    const { data } = await axios.post(
      `${API_SERVER_URL}/api/community`,
      {
        title,
        content,
        category,
        author
      },
      config
    );
    dispatch({
      type: COMMUNITY_WRITE_SUCCESS,
      payload: data,
    });
    window.location.href = "/community/category/자유게시판/page/1";
  } catch (error) {
    dispatch({
      type: COMMUNITY_WRITE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePost = (post) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMMUNITY_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/community/${post._id}`,
      post,
      config
    );

    dispatch({
      type: COMMUNITY_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({ type: COMMUNITY_READ_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMMUNITY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePost = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMMUNITY_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    await axios.delete(
      `${API_SERVER_URL}/api/community/${id}`,
      config
    );

    dispatch({
      type: COMMUNITY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: COMMUNITY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}