import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import {
  NOTICE_LIST_REQUEST,
  NOTICE_LIST_SUCCESS,
  NOTICE_LIST_FAIL,
  NOTICE_DETAIL_REQUEST,
  NOTICE_DETAIL_SUCCESS,
  NOTICE_DETAIL_FAIL,
  NOTICE_INPUT_REQUEST,
  NOTICE_INPUT_SUCCESS,
  NOTICE_INPUT_FAIL,
  NOTICE_UPDATE_REQUEST,
  NOTICE_UPDATE_SUCCESS,
  NOTICE_UPDATE_FAIL,
  NOTICE_DELETE_REQUEST,
  NOTICE_DELETE_SUCCESS,
  NOTICE_DELETE_FAIL,
  COMPETITION_NOTICE_LIST_REQUEST,
  COMPETITION_NOTICE_LIST_SUCCESS,
  COMPETITION_NOTICE_LIST_FAIL,
  COMPETITION_NOTICE_DETAIL_REQUEST,
  COMPETITION_NOTICE_DETAIL_SUCCESS,
  COMPETITION_NOTICE_DETAIL_FAIL,
  COMPETITION_NOTICE_INPUT_REQUEST,
  COMPETITION_NOTICE_INPUT_SUCCESS,
  COMPETITION_NOTICE_INPUT_FAIL,
  COMPETITION_NOTICE_UPDATE_REQUEST,
  COMPETITION_NOTICE_UPDATE_SUCCESS,
  COMPETITION_NOTICE_UPDATE_FAIL,
  
  COMPETITION_NOTICE_DELETE_REQUEST,
  COMPETITION_NOTICE_DELETE_SUCCESS,
  COMPETITION_NOTICE_DELETE_FAIL,
} from "../constants/noticeConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const deleteNotice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTICE_DELETE_REQUEST,
    });
    await axios.delete(`${API_SERVER_URL}/api/notice/${id}`);
    dispatch({ type: NOTICE_DELETE_SUCCESS });
    alert("공지가 삭제되었습니다.");
    window.location.href = "/notice/list";
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: NOTICE_DELETE_FAIL, payload: message });
  }
};

export const updateNotice = (notice) => async (dispatch) => {
  try {
    dispatch({
      type: NOTICE_UPDATE_REQUEST,
    });
    const { data } = await axios.put(
      `${API_SERVER_URL}/api/notice/${notice._id}`,
      notice
    );

    dispatch({
      type: NOTICE_UPDATE_SUCCESS,
      payload: data,
    });
    window.location.href = "/notice/list";
    dispatch({ type: NOTICE_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: NOTICE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listNotice = () => async (dispatch) => {
  try {
    dispatch({
      type: NOTICE_LIST_REQUEST,
    });
    const { data } = await axios.get(`${API_SERVER_URL}/api/notice`);
    dispatch({
      type: NOTICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NOTICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const readNotice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTICE_DETAIL_REQUEST,
    });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/notice/${id}`
    );
    dispatch({
      type: NOTICE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NOTICE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const writeNotice = (title, content) => async (dispatch) => {
  try {
    dispatch({
      type: NOTICE_INPUT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${API_SERVER_URL}/api/notice`,
      {
        title,
        content,
      },
      config
    );
    dispatch({
      type: NOTICE_INPUT_SUCCESS,
      payload: data,
    });
    alert("[성공] 공지 등록에 성공하였습니다.");
    window.location.href = "/notice/list";
  } catch (error) {
    dispatch({
      type: NOTICE_INPUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCompetitionNotice = (competitionNotice) => async (dispatch) => {
  try {
    dispatch({
      type: COMPETITION_NOTICE_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/competition-notice/${competitionNotice._id}`,
      competitionNotice,
      config
    );

    dispatch({
      type: COMPETITION_NOTICE_UPDATE_SUCCESS,
      payload: data,
    });
    
    dispatch({ type: COMPETITION_NOTICE_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPETITION_NOTICE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    alert("대회 공지 수정에 실패하였습니다.");
  }
};

export const deleteCompetitionNotice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPETITION_NOTICE_DELETE_REQUEST,
    });
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    await axios.delete(
      `${API_SERVER_URL}/api/competition-notice/${id}`,
      config
    );

    dispatch({
      type: COMPETITION_NOTICE_DELETE_SUCCESS
    });

    alert("공지가 삭제되었습니다.");

    window.location.href = "/competition-notice/list";
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPETITION_NOTICE_DELETE_FAIL,
      payload: message
    });
  }
};

export const listCompetitionNotice =
  (keyword = "", pageNumber = "", associationId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: COMPETITION_NOTICE_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/competition-notice?keyword=${keyword}&pageNumber=${pageNumber}&associationId=${associationId}`
      );
      dispatch({type: COMPETITION_NOTICE_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMPETITION_NOTICE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const readCompetitionNotice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPETITION_NOTICE_DETAIL_REQUEST,
    });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/competition-notice/${id}`
    );
    dispatch({
      type: COMPETITION_NOTICE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPETITION_NOTICE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const writeCompetitionNotice =
  (competitionNotice) => async (dispatch) => {
    try {
      dispatch({
        type: COMPETITION_NOTICE_INPUT_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };
      
      const { data } = await axios.post(
        `${API_SERVER_URL}/api/competition-notice`,
        competitionNotice,
        config
      );

      dispatch({
        type: COMPETITION_NOTICE_INPUT_SUCCESS,
        payload: data,
      });
      alert("[성공] 공지 등록에 성공하였습니다.");
      window.location.href = "/association/competition-manage";
    } catch (error) {
      dispatch({
        type: COMPETITION_NOTICE_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
