import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Table, Image, Form } from "react-bootstrap";
import { API_SERVER_URL } from "../actions/actionUrl";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import {
  teamDetailsInfo,
  updateTeam,
} from "../actions/teamActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Select from "react-select";
import axios from "axios";
import { getUserTeam } from "../actions/userActions";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Link } from "react-router-dom";

export const TeamManageScreen = () => {
  const [selectTeam, setSelectTeam] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [leader, setLeader] = useState("");
  const [foundedAt, setFoundedAt] = useState("");
  const [uploading, setUploading] = useState(false);

  const foundedRef = useRef();

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const userTeam = useSelector((state) => state.userTeam);
  const {
    loading: loadingUserTeam,
    error: errorUserTeam,
    userTeams,
  } = userTeam;

  const teamDetails = useSelector((state) => state.teamDetails);
  const {
    loading: loadingTeamDetails,
    error: errorTeamDetails,
    team,
  } = teamDetails;

  const teamUpdate = useSelector((state) => state.teamUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = teamUpdate;

  const cropperRef = useRef(null);
  const [inputImage, setInputImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageDeleteFlag, setImageDeleteFlag] = useState(false);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas());
  };

  const imageContainerStyle = {
    position: 'relative',
    display: 'inline-block'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'red',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    lineHeight: '18px'
  };


  const onImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setInputImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const deleteImageHandler = () => {
    setImage("");
    setImageDeleteFlag(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // if ((new Date() - new Date(team.lastNameModified) < 1000 * 60 * 60 * 24 * 30) && team.name !== name) {
    //   alert(`팀명 변경은 30일에 한 번만 가능합니다. (마지막 변경 일자 : ${team.lastNameModified.slice(0, 10)})`);
    //   return;
    // }
    const banLetter = [
      "<",
      ">",
      "/",
      "\\",
      "*",
      "?",
      '"',
      "|",
      ":",
      "@",
      "!",
      "#",
      "$",
      "^",
      "~",
      "`",
      "+",
      "=",
    ];
    if (banLetter.some((letter) => name.includes(letter))) {
      alert("팀명에 특수문자는 사용할 수 없습니다.");
      return;
    }

    const isDelete = imageDeleteFlag || (originalImage !== "" && image !== originalImage);

    if (isDelete) {
      let key = originalImage.match(/\/([^\/]+)$/) ? originalImage.match(/\/([^\/]+)$/)[1] : "";
      if (key !== "") { deleteFileHandler(key); }
    }

    if (croppedImage !== null) {
      const formData = new FormData();

      croppedImage.toBlob(async (blob) => {
        formData.append("image", blob, "image.jpg");

        setUploading(true);

        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const { data } = await axios.post(
            `${API_SERVER_URL}/api/upload/teamlogo`,
            formData,
            config
          );

          setUploading(false);


          dispatch(
            updateTeam({
              _id: team._id,
              name: name,
              image: data,
              leader: leader,
              foundedAt: foundedAt,
              teamType: teamType,
              members: team.members,
              masterId: team.masterId,
              applyQueue: team.applyQueue
            })
          );
        } catch (error) {
          console.error(error);
          setUploading(false);
        }
      }, "image/jpeg");
    } else {
      dispatch(
        updateTeam({
          _id: team._id,
          name: name,
          image: image,
          leader: leader,
          foundedAt: foundedAt,
          teamType: teamType,
          members: team.members,
          masterId: team.masterId,
          applyQueue: team.applyQueue
        })
      );
    }
  };

  const handleFounded = (e) => {
    const value = foundedRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    foundedRef.current.value = result;
    setFoundedAt(e.target.value);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload/teamlogo`,
        formData,
        config
      );

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const deleteFileHandler = async (key) => {
    try {
      await axios.delete(`${API_SERVER_URL}/api/upload/${key}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getUserTeam(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (team) {
      setName(team.name);
      setImage(team.image);
      setOriginalImage(team.image);
      setLeader(team.leader);
      setFoundedAt(team.foundedAt ? team.foundedAt.slice(0, 10) : "");
    }
  }, [dispatch, team]);

  const teamOption = [];

  userTeams &&
    userTeams.map((team) => {
      teamOption.push({
        value: team._id,
        label: team.name,
      });
    });

  const handleTeamChange = (e) => {
    setSelectTeam(e.value);
    dispatch(teamDetailsInfo(e.value));
  };

  const birthdayFormat = (birthday) => {
    if (birthday) {
      return birthday.slice(0, 4) + "-" + birthday.slice(4, 6) + "-" + birthday.slice(6, 8);
    }
    return "";
  };

  const acceptHandler = (playerId, isAccepted) => {
    if (
      window.confirm(`가입요청을 ${isAccepted ? "수락" : "거절"}하시겠습니까?`)
    ) {
      const applyQueue = team.applyQueue.filter((id) => id.playerId !== playerId);
      let members;
      if (isAccepted) {
        members = [...team.members, playerId];
      } else {
        members = team.members;
      }

      dispatch(
        updateTeam({
          _id: team._id,
          name: team.name,
          image: team.image,
          leader: team.leader,
          foundedAt: team.foundedAt,
          teamType: team.teamType,
          members: members,
          masterId: team.masterId,
          applyQueue: applyQueue,
        })
      );
    }

    // window.location.reload();
  };

  const deleteHandler = (player) => {
    if (window.confirm(`정말로 ${player.name} 선수를 방출하시겠습니까?`)) {
      const members = team.members.filter((id) => id !== player.playerId);
      dispatch(
        updateTeam({
          _id: team._id,
          name: team.name,
          image: team.image,
          leader: team.leader,
          foundedAt: team.foundedAt,
          teamType: team.teamType,
          members: members,
          masterId: team.masterId,
          applyQueue: team.applyQueue,
        })
      );
    }
  };

  return (
    <>
      <h1
        style={{
          color: "black",
          "background-color": "#e5e5e5",
          "padding-top": "20px",
          "padding-bottom": "20px",
          "padding-left": "30px",
          "margin-top": "-16px",
          "margin-left": "-12px",
          "margin-right": "-12px",
        }}
      >
        팀 관리
      </h1>
      {loadingUserTeam ? (
        <Loader />
      ) : errorUserTeam ? (
        <Message variant="danger">{errorUserTeam}</Message>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
            width: "100%",
            padding: "20px",
          }}
        >
          <div
            style={{
              maxWidth: "250px",
              width: "40%",
              marginBottom: "16px"
            }}
          >
            <Select
              options={teamOption}
              isSearchable
              onChange={handleTeamChange}
              style={{
                width: "50%",
                marginBottom: "16px"
              }}
              placeholder="팀 선택"
            />
          </div>
          <div>
            {loadingTeamDetails ? (
              <Loader />
            ) : errorTeamDetails ? (
              <Message variant="danger">{errorTeamDetails}</Message>
            ) : (
              selectTeam !== "" &&
              <>
                <div style={{ width: "60%", paddingBottom: "30px", paddingTop: "30px", borderBottom: "1px solid #e0e0e0" }}>
                  <h2 style={{ fontSize: "20px", marginBottom: "8px" }}>
                    팀원 관리
                  </h2>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <Table
                      striped
                      bordered
                      hover
                      responsive
                      className="table-sm text-center"
                      style={{ marginBottom: "16px" }}
                    >
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#013B70",
                            color: "white",
                          }}
                        >
                          <th style={{ width: "20%" }}>이름</th>
                          <th style={{ minWidth: "50%" }}>생년월일</th>
                          <th style={{ minWidth: "30px" }}>방출</th>
                        </tr>
                      </thead>
                      <tbody>
                        {team &&
                          team.memberInfo &&
                          team.memberInfo.map((player, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={`/player/${player.playerId}`}
                                  style={{ color: "black" }}
                                >
                                  {player.name}
                                </Link>
                              </td>
                              <td>
                                {birthdayFormat(player.birthday)}
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  onClick={() => deleteHandler(player)}
                                  style={{
                                    height: "50%",
                                    padding: "0",
                                    width: "40%",
                                    "font-size": "12px",
                                  }}
                                >
                                  방출
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div style={{ width: "100%", paddingBottom: "30px", paddingTop: "30px", borderBottom: "1px solid #e0e0e0" }}>
                  <h2 style={{ fontSize: "20px", marginBottom: "8px" }}>
                    팀 가입 요청 목록
                  </h2>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="table-sm text-center"
                    style={{ marginBottom: "16px" }}
                  >
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#013B70",
                          color: "white",
                        }}
                      >
                        <th style={{ width: "10%" }}>이름</th>
                        <th style={{ width: "20%" }}>생년월일</th>
                        <th style={{ width: "50%" }}>요청메세지</th>
                        <th style={{ width: "20%" }}>수락/거절</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team &&
                        team.applyQueueInfo &&
                        team.applyQueueInfo.map((player) => (
                          <tr>
                            <td>
                              <Link
                                to={`/player/${player.playerId}`}
                                style={{ color: "black" }}
                              >
                                {player.name}
                              </Link>
                            </td>
                            <td>{birthdayFormat(player.birthday)}</td>
                            <td>{player.applyMessage}</td>
                            <td>
                              <Button
                                variant="success"
                                onClick={() =>
                                  acceptHandler(player.playerId, true)
                                }
                                style={{
                                  height: "50%",
                                  padding: "0",
                                  width: "40%",
                                  "font-size": "12px",
                                }}
                              >
                                수락
                              </Button>
                              <> </>
                              <Button
                                variant="danger"
                                onClick={() =>
                                  acceptHandler(player.playerId, false)
                                }
                                style={{
                                  height: "50%",
                                  padding: "0",
                                  width: "40%",
                                  "font-size": "12px",
                                }}
                              >
                                거절
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div style={{ width: "100%", paddingBottom: "30px", paddingTop: "30px", borderBottom: "1px solid #e0e0e0" }}>
                  <h2 style={{ fontSize: "20px", marginBottom: "8px" }}>
                    팀 정보 수정
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      width: "60%",
                    }}
                  >
                    {errorUpdate && (
                      <Message variant="danger">
                        팀 업데이트에 실패하였습니다. 정보 확인 부탁드립니다.
                      </Message>
                    )}
                    {loadingTeamDetails && <Loader />}
                    <Form onSubmit={submitHandler} className="mt-3">
                      <Form.Group controlId="name">
                        <Form.Label>팀명</Form.Label>
                        <Form.Control
                          type="name"
                          placeholder="팀명"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="image" className="mt-3">
                        <Form.Label>이미지</Form.Label>
                        <Form.Group controlId="crop" className="mt-3">
                          <input type="file" accept="image/*" onChange={onImageChange} />
                          <Cropper
                            ref={cropperRef}
                            src={inputImage}
                            viewMode={1}
                            crop={onCrop}
                            zoomable={false}
                            initialAspectRatio={16 / 16}
                          />
                        </Form.Group>
                        <div style={imageContainerStyle}>
                          {(image !== "") && (croppedImage === null) && <>
                            <img src={image} alt="image" style={{ width: '100%' }} />
                            <button style={closeButtonStyle} onClick={deleteImageHandler}>X</button>
                          </>}
                          {image === "" && croppedImage === null &&
                            <>
                              <img src={"/player_default_img.png"} alt="image" style={{ width: '100%' }} />
                            </>}
                        </div>
                      </Form.Group>
                      {uploading && <Loader />}
                      <Form.Group controlId="leader">
                        <Form.Label>감독</Form.Label>
                        <Form.Control
                          type="leader"
                          placeholder="감독명"
                          value={leader}
                          onChange={(e) => setLeader(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="foundedAt" className="mt-3">
                        <Form.Label>팀 창설일</Form.Label>
                        <Form.Control
                          type="foundedAt"
                          placeholder="예) 1992-01-01"
                          ref={foundedRef}
                          value={foundedAt}
                          onChange={handleFounded}
                        ></Form.Control>
                      </Form.Group>
                      {/* <Form.Group controlId="teamType" className="mt-3">
                        <Form.Label>종별</Form.Label>
                        <div>
                          {team && team.teamType}
                        </div>
                      </Form.Group> */}
                      <Button
                        onClick={submitHandler}
                        variant="primary"
                        className="mt-5"
                        style={{ width: "100%" }}
                      >
                        팀 정보 수정
                      </Button>
                    </Form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TeamManageScreen;
