import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchBox = ({ history, pageType }) => {
  const [keyword, setKeyword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/${pageType}/search/${keyword}/page/1`);
    } else {
      window.alert("한가지 이상 검색어를 입력하세요.");
    }
  };

  return (
    <Form
      onSubmit={submitHandler}
      className="d-flex flex-row justify-content-around"
    >
      <Form.Control
        type="text"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        placeholder=""
        style={{ "border-radius": "50px","border-style": "solid", "border-color": "#BABABA", "border-width": "1px"}}
      ></Form.Control>
      <Button
        type="submit"
        className="p-2"
        style={{
          "background-color": "inherit",
          color: "#031B31",
        }}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{ fontSize: "25px" }}
        />
      </Button>
    </Form>
  );
};

export default SearchBox;
