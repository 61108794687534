import React, { useEffect, useState } from 'react';
import RecorderMain from './RecorderMain';

const LoadGameLocalScreen = () => {
  const [stage, setStage] = useState(0);
  const [setting, setSetting] = useState({});
  const [teamA, setTeamA] = useState([]);
  const [teamB, setTeamB] = useState([]);
  const [gameInitSetting, setGameInitSetting] = useState({});
  const [initEvent, setInitEvent] = useState([]);

  const LocalJSONLoad = ({ onNext }) => {
    useEffect(() => {
      // if (teamA.length >= 5 && teamB.length >= 5) {
      //   alert(1)
        // onNext(setting, teamA, teamB, gameInitSetting, initEvent);
        console.log(setting, teamA, teamB, gameInitSetting, initEvent);
      // }
    }, [setting, teamA, teamB, gameInitSetting, initEvent]);

    return (
      <div>
        <input type="file" id="file" accept=".json" onChange={(e) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const text = e.target.result;
            const json = JSON.parse(text);
            setSetting(json.setting);
            setTeamA(json.teamA);
            setTeamB(json.teamB);
            setGameInitSetting(json.gameJSON);
            setInitEvent(json.events);
            setStage(1);
          }
          reader.readAsText(e.target.files[0]);
        }} />
      </div>
    )
  }

  return (
    <>
      {stage === 0 && <LocalJSONLoad />}
      {stage === 1 && <RecorderMain
        setting={setting}
        teamA={teamA}
        teamB={teamB}
        gameInitSetting={gameInitSetting}
        isLoaded={true}
        initEvent={initEvent}
      />}
    </>
  )
};

export default LoadGameLocalScreen;