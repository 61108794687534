import React, { useState } from 'react';
import RecorderSetting from './RecorderSetting';
import RecorderTeam from './RecorderTeam';
import RecorderMain from './RecorderMain';


const NewGameScreen = () => {
  const [stage, setStage] = useState(0);
  const [setting, setSetting] = useState({});
  const [teamA, setTeamA] = useState([]);
  const [teamB, setTeamB] = useState([]);
  const [gameInitSetting, setGameInitSetting] = useState({});

  return (
    <>
      {stage === 0 && <RecorderSetting onNext={
        (setting) => {
          setSetting(setting);
          setStage(1);
        }
      } />}
      {stage === 1 && <RecorderTeam onNext={
        (teamA, teamB, gameInitSetting) => {
          setTeamA(teamA);
          setTeamB(teamB);
          setGameInitSetting(gameInitSetting);
          setStage(2);
        }
      } />}
      {stage === 2 && <RecorderMain
        setting={setting}
        teamA={teamA}
        teamB={teamB}
        gameInitSetting={gameInitSetting}
        isLoaded={false}
        initEvent={[]}
      />}
    </>
  )
};

export default NewGameScreen;