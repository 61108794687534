import React, { useState } from "react";
import { API_SERVER_URL } from "../actions/actionUrl";
import axios from "axios";

const PasswordResetScreen = ({ match, history }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const token = match.params.token;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
    } else {
      axios.put(`${API_SERVER_URL}/api/user/reset/password?token=${token}`, { password })
        .then((response) => {
          alert("비밀번호가 변경되었습니다.");
        })
        .catch((error) => {
          alert("[오류] 비밀번호 변경에 실패하였습니다.");
        });
      history.push('/login');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit} style={{ width: '300px', padding: '20px', border: '1px solid #ccc' }}>
        <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>비밀번호 재설정</h2>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>
            비밀번호:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="confirmPassword" style={{ display: 'block', marginBottom: '5px' }}>
            비밀번호 확인:
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            style={{ width: '100%', padding: '5px' }}
          />
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', background: '#007bff', color: '#fff', border: 'none' }}>
          비밀번호 변경
        </button>
      </form>
    </div>
  );
};

export default PasswordResetScreen;
