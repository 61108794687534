import {
  GAME_ARTICLE_LIST_REQUEST,
  GAME_ARTICLE_LIST_SUCCESS,
  GAME_ARTICLE_LIST_FAIL,
  GAME_ARTICLE_LIST_RESET,
  GAME_ARTICLE_READ_REQUEST,
  GAME_ARTICLE_READ_SUCCESS,
  GAME_ARTICLE_READ_FAIL,
  GAME_ARTICLE_WRITE_REQUEST,
  GAME_ARTICLE_WRITE_SUCCESS,
  GAME_ARTICLE_WRITE_FAIL,
  GAME_ARTICLE_UPDATE_REQUEST,
  GAME_ARTICLE_UPDATE_SUCCESS,
  GAME_ARTICLE_UPDATE_FAIL,
  GAME_ARTICLE_DELETE_REQUEST,
  GAME_ARTICLE_DELETE_SUCCESS,
} from "../constants/gameArticleConstants";

export const gameArticleListReducer = (state = { articles: [] }, action) => {
  switch (action.type) {
    case GAME_ARTICLE_LIST_REQUEST:
      return { loading: true, articles: [] };
    case GAME_ARTICLE_LIST_SUCCESS:
      return { loading: false, articles: action.payload };
    case GAME_ARTICLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GAME_ARTICLE_LIST_RESET:
      return { articles: [] };
    default:
      return state;
  }
}

export const gameArticleReadReducer = (state = { article: {} }, action) => {
  switch (action.type) {
    case GAME_ARTICLE_READ_REQUEST:
      return { ...state, loading: true };
    case GAME_ARTICLE_READ_SUCCESS:
      return { loading: false, article: action.payload };
    case GAME_ARTICLE_READ_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gameArticleWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case GAME_ARTICLE_WRITE_REQUEST:
      return { loading: true };
    case GAME_ARTICLE_WRITE_SUCCESS:
      return { loading: false };
    case GAME_ARTICLE_WRITE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gameArticleUpdateReducer = (state = { article: {} }, action) => {
  switch (action.type) {
    case GAME_ARTICLE_UPDATE_REQUEST:
      return { loading: true };
    case GAME_ARTICLE_UPDATE_SUCCESS:
      return { loading: false, success: true, article: action.payload };
    case GAME_ARTICLE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gameArticleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GAME_ARTICLE_DELETE_REQUEST:
      return { loading: true };
    case GAME_ARTICLE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case GAME_ARTICLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}