import React, { useEffect } from "react";
import { deletePlayer, listPlayers } from "../actions/playerActions";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import {
  PLAYER_DETAIL_RESET,
  PLAYER_COMPETITION_RECORD_RESET,
  PLAYER_COMPETITION_LIST_RESET,
} from "../constants/playerConstants";
import Searcher from "../components/Searcher";

const ThreeOnThreeListRecordScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const playerList = useSelector((state) => state.playerList);
  const { loading, error, players, page, pages } = playerList;

  const deleteHandler = (id) => {
    if (window.confirm("정말로 선수를 지우겠습니까?")) {
      dispatch(deletePlayer(id));
    }
  };

  useEffect(() => {
    dispatch({ type: PLAYER_COMPETITION_LIST_RESET });
    dispatch({ type: PLAYER_COMPETITION_RECORD_RESET });
    dispatch({ type: PLAYER_DETAIL_RESET });
    dispatch(listPlayers(keyword, pageNumber));
    // dispatch(teamName());
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <div className="menu__header">
        <h2
          className="mt-3 mb-3"
          style={{ color: "white", "font-weight": 700 }}
        >
          선수 3대3 개인기록조회
        </h2>
        <p style={{ color: "white" }}>
          조회하고자 하는 선수의 이름을 검색하세요
        </p>
      </div>
      <Searcher searchType={"personallistrecord"} />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm text-center"
        >
          <thead>
            <tr>
              <th
                style={{
                  position: "sticky",
                  left: "0",
                  "z-index": "2",
                  "background-color": "white",
                }}
              >
                <div
                  className="hideextra"
                  style={{
                    "min-width": "50px",
                  }}
                >
                  이름
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "80px" }}>
                  생년월일
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "120px" }}>
                  소속팀
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "80px" }}>
                  종별
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  등번호
                </div>
              </th>
              <th>
                <div className="hideextra" style={{ "min-width": "40px" }}>
                  포지션
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {players &&
              players.map((player) => (
                <tr key={player._id}>
                  <td
                    style={{
                      position: "sticky",
                      left: "0",
                      "z-index": "1",
                      "background-color": "white",
                    }}
                  >
                    <Link to={`/player-3on3/${player._id}`}>{player.name}</Link>
                  </td>
                  <td>
                    {player.birthday ?
                    player.birthday.substr(0, 4) + '-' + player.birthday.substr(4, 2) + '-' + player.birthday.substr(6, 2) :
                    "-"}
                  </td>
                  <td>{player.teamName.join(' / ')}</td>
                  <td>{[...new Set(player.playerType)].join(' / ')}</td>
                  <td>{player.jerseyNum}</td>
                  <td>{player.position.join(' / ')}</td>
                  {userInfo && (userInfo.isAdmin || userInfo.tier === 99) && (
                    <>
                      <td>
                        <Link
                          class="btn btn-primary pull-right"
                          to={`/admin/user/${player._id}`}
                        >
                          수정
                        </Link>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => deleteHandler(player._id)}
                        >
                          삭제
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Paginate
        pages={pages}
        page={page}
        keyword={keyword ? keyword : ""}
        pageType={"personallistrecord"}
      />
    </>
  );
};

export default ThreeOnThreeListRecordScreen;
