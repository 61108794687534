import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_SERVER_URL } from "../actions/actionUrl";

const PlayerName = ({ id, isPickup }) => {
  let [name, setName] = useState("");

  async function getName() {
    let res = await axios.get(
      `${API_SERVER_URL}/api/player-profile/name/${id}`
    );
    let data = res.data;
    setName(data);
  }

  getName();
  let resComponent;
  if (isPickup) {
    resComponent = <Link to={`/pickupgame/player/${id}`}>{name}</Link>;
  } else {
    resComponent = <Link to={`/player/${id}`}>{name}</Link>;
  }
  return resComponent;
};

export default PlayerName;
