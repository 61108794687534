import React from "react";

const Prize = ({ prize }) => {
  return (
    <>
      <h2 className="mt-5 mb-3">수상 경력</h2>
      {prize.map((p) => (
        <h5>{p}</h5>
      ))}
    </>
  );
};

export default Prize;
