import axios from "axios";
import { API_SERVER_URL } from './actionUrl';
import { Redirect } from "react-router-dom";
import {
  PLAYER_LIST_REQUEST,
  PLAYER_LIST_SUCCESS,
  PLAYER_LIST_FAIL,
  PLAYER_LIST_CONSONANT_REQUEST,
  PLAYER_LIST_CONSONANT_SUCCESS,
  PLAYER_LIST_CONSONANT_FAIL,
  PLAYER_LIST_TYPE_REQUEST,
  PLAYER_LIST_TYPE_SUCCESS,
  PLAYER_LIST_TYPE_FAIL,
  PLAYER_DETAIL_REQUEST,
  PLAYER_DETAIL_SUCCESS,
  PLAYER_DETAIL_FAIL,
  PLAYER_SEASON_RECORD_REQUEST,
  PLAYER_SEASON_RECORD_SUCCESS,
  PLAYER_SEASON_RECORD_FAIL,
  PLAYER_SEASON_RECORD_RESET,
  PLAYER_ALL_RECORD_REQUEST,
  PLAYER_ALL_RECORD_SUCCESS,
  PLAYER_ALL_RECORD_FAIL,
  PLAYER_INPUT_REQUEST,
  PLAYER_INPUT_SUCCESS,
  PLAYER_INPUT_FAIL,
  PLAYER_UPDATE_REQUEST,
  PLAYER_UPDATE_SUCCESS,
  PLAYER_UPDATE_FAIL,
  PLAYER_COMPETITION_RECORD_REQUEST,
  PLAYER_COMPETITION_RECORD_SUCCESS,
  PLAYER_COMPETITION_RECORD_FAIL,
  PLAYER_DUPLICATE_REQUEST,
  PLAYER_DUPLICATE_SUCCESS,
  PLAYER_DUPLICATE_FAIL,
  PLAYER_DELETE_REQUEST,
  PLAYER_DELETE_SUCCESS,
  PLAYER_DELETE_FAIL,
  PLAYER_COMPETITION_LIST_REQUEST,
  PLAYER_COMPETITION_LIST_SUCCESS,
  PLAYER_COMPETITION_LIST_FAIL,
} from "../constants/playerConstants";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const listPlayers =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PLAYER_SEASON_RECORD_RESET });
      dispatch({ type: PLAYER_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/player-profile?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: PLAYER_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PLAYER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPlayersConsonant =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PLAYER_SEASON_RECORD_RESET });
      dispatch({ type: PLAYER_LIST_CONSONANT_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/player-profile/consonant/key?consonant=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: PLAYER_LIST_CONSONANT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PLAYER_LIST_CONSONANT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPlayersType =
  (keyword = "", pageNumber = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: PLAYER_SEASON_RECORD_RESET });
        dispatch({ type: PLAYER_LIST_TYPE_REQUEST });
        const { data } = await axios.get(
          `${API_SERVER_URL}/api/player-profile/type/key?playerType=${keyword}&pageNumber=${pageNumber}`
        );
        dispatch({ type: PLAYER_LIST_TYPE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: PLAYER_LIST_TYPE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const listPlayerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_DETAIL_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/player-profile/${id}`
    );
    dispatch({ type: PLAYER_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLAYER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const playerSeasonRecord = (player) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_SEASON_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/player-record/season/${player._id}?year=${player.year}&teamId=${player.teamId}&isThree=${player.isThree}`
    );
    dispatch({ type: PLAYER_SEASON_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLAYER_SEASON_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const playerCompetitionRecord = (player) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_COMPETITION_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/player-record/competition/${player._id}?competitionId=${player.competitionId}`
    );
    dispatch({ type: PLAYER_COMPETITION_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLAYER_COMPETITION_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const playerAllRecord = (player) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_ALL_RECORD_REQUEST });
    const { data } = await axios.get(
      `${API_SERVER_URL}/api/game/player-record/${player._id}`
    );
    dispatch({ type: PLAYER_ALL_RECORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLAYER_ALL_RECORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePlayer = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_DELETE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    await axios.delete(
      `${API_SERVER_URL}/api/player-profile/${id}`,
      config
    );
    
    dispatch({ type: PLAYER_DELETE_SUCCESS });
    alert("삭제 되었습니다.");
    window.location.reload();
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PLAYER_DELETE_FAIL, payload: message });
  }
};

export const playerInput = (player) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PLAYER_INPUT_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/player-profile`,
        player,
        config
      );

      dispatch({
        type: PLAYER_INPUT_SUCCESS,
        payload: data,
      });

      alert("[성공] 선수 등록에 성공하였습니다.");
      window.location.reload();
    } catch (error) {
      dispatch({
        type: PLAYER_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const updatePlayer = (player) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PLAYER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/player-profile/${player._id}`,
        player,
        config
      );

      dispatch({
        type: PLAYER_UPDATE_SUCCESS,
        payload: data,
      });

      alert("[성공] 선수 정보 수정되었습니다.");
      window.location.reload();
    } catch (error) {
      dispatch({
        type: PLAYER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const isPlayerDuplicate = (name, birthday) => async (dispatch) => {
    try {
      // dispatch({type:PLAYER_DUPLICATE_RESET});
      dispatch({
        type: PLAYER_DUPLICATE_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/player-profile/duplicate/check?name=${name}&birthday=${birthday}`,
        config
      );
      dispatch({
        type: PLAYER_DUPLICATE_SUCCESS,
        payload: data,
      });
      if (data.isDuplicate) {
        alert(
          `[경고] 중복된 선수가 있습니다. 선수 수정 화면에서 팀을 추가하거나 정보를 수정해주세요.`
        );
        const id = data.id;
        window.location.href = `/admin/user/${id}`;
      }
    } catch (error) {
      dispatch({
        type: PLAYER_DUPLICATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listPlayerCompetition = (player) => async (dispatch) => {
    try {
      dispatch({ type: PLAYER_COMPETITION_LIST_REQUEST });
      const { data } = await axios.get(
        `${API_SERVER_URL}/api/game/player-competition/${player._id}?year=${player.year}&teamId=${player.teamId}&isThree=${player.isThree}`
      );
      dispatch({ type: PLAYER_COMPETITION_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PLAYER_COMPETITION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
