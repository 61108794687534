import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Form, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { teamInput } from "../actions/teamActions";
import Select from "react-select";
import { API_SERVER_URL } from "../actions/actionUrl";
import { accessControl } from "../utils/access";
import { getUserTeam } from "../actions/userActions";
import optionsType from "../static/optionsType";

const NewTeamScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [leader, setLeader] = useState("");
  const [teamType, setTeamType] = useState("");

  // 설립일 하이픈 자동 생성
  const [foundedAt, setFoundedAt] = useState("");
  const foundedRef = useRef();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo && userInfo._id;

  const userTeam = useSelector((state) => state.userTeam);
  const { loading: loadingUserTeam, error: errorUserTeam, userTeams } = userTeam;

  const adminTeamInput = useSelector((state) => state.adminTeamInput);
  const { loading, error } = adminTeamInput;

  const handleFounded = (e) => {
    const value = foundedRef.current.value.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }
    foundedRef.current.value = result;
    setFoundedAt(e.target.value);
  };

  const handleTeamType = (selected) => {
    setTeamType(selected.value);
  };

  // 팀 이미지 등록
  const [uploading, setUploading] = useState(false);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload/teamlogo`,
        formData,
        config
      );

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (!userTeams) {
      dispatch(getUserTeam(userId));
    } else {
      if (!(userTeams.length < 1 || userInfo.tier === 400 || userInfo.isAdmin)) {
        alert("팀 생성은 1개까지만 가능합니다.");
        history.push(`/playermanage`);
      }
    }
  }, [dispatch, userId, userTeams, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    const banLetter = ["<", ">", "/", "\\", "*", "?", '"', "|", ":", "@", "!", "#", "$", "^", "~", "`", "+", "="];
    if (banLetter.some((letter) => name.includes(letter))) {
      alert("팀명에 특수문자는 사용할 수 없습니다.");
      return;
    }
    dispatch(teamInput(name, image, leader, foundedAt, teamType, [userInfo._id]));

    history.push(`/playermanage/${userInfo._id}`);
  };

  return (
    <FormContainer>
      <h1>팀 생성</h1>
      {error && (
        <Message variant="danger">
          팀 등록에 실패하였습니다. 정보 확인 부탁드립니다.
        </Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="mt-3">
        <Form.Group controlId="name">
          <Form.Label>팀명</Form.Label>
          <Form.Control
            type="name"
            placeholder="팀명"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="image" className="mt-3">
          <Form.Label>팀 로고 이미지</Form.Label>
          <p style={{ color: "red" }}>이미지가 없다면, 값을 비워주세요.</p>
          {image !== "" && <Image src={image} alt="image" fluid />}
          <Form.Control
            type="text"
            placeholder="[수정 금지] 업로드가 정상적으로 되면, URL이 자동으로 생성됩니다."
            value={image}
            onChange={(e) => setImage(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Control
          type="file"
          id="image-file"
          label="이미지 선택"
          custom="true"
          onChange={uploadFileHandler}
        ></Form.Control>
        {uploading && <Loader />}

        <Form.Group controlId="leader">
          <Form.Label>감독</Form.Label>
          <Form.Control
            type="leader"
            placeholder="감독명"
            value={leader}
            onChange={(e) => setLeader(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="foundedAt" className="mt-3">
          <Form.Label>팀 창설일</Form.Label>
          <Form.Control
            type="foundedAt"
            placeholder="예) 1992-01-01"
            ref={foundedRef}
            value={foundedAt}
            onChange={handleFounded}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="teamType" className="mt-3">
          <Form.Label>종별</Form.Label>
          <Select options={optionsType} isSearchable onChange={handleTeamType} />
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-5"
          style={{ width: "100%" }}
        >
          팀 등록
        </Button>
      </Form>
    </FormContainer>
  );
};

export default NewTeamScreen;
